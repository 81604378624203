// Örnek servis codiagno login
import axios from "../javascript/custom-axios";
const RESOURCE_NAME = "/groups";
import store from "../store/store";
export default {
  getGroups(type = "", parent = "") {
    if (parent != "All" && type == "lokasyon")
      return axios.get(
        `/admin${RESOURCE_NAME}?type=${type}&ParentId=${
          parent == "sirket" ? "" : parent
        }`
      );
    if (store.getters.getPermissionIds)
      return axios.get(`${RESOURCE_NAME}?type=${type}`);
    return axios.get(`/admin${RESOURCE_NAME}?type=${type}&ParentId=${parent}`);
  },

  getDivisions(type = "Region") {
    return axios.get(`${RESOURCE_NAME}?type=${type}`);
  },

  getGroupsV01(type = "") {
    return axios.get(`${RESOURCE_NAME}?type=${type}`);
  },

  getGroupTypes() {
    return axios.get(`${RESOURCE_NAME}/types`);
  },

  createGroup(body) {
    return axios.post(`${RESOURCE_NAME}/creategroup`, body);
  },

  deleteGroup(id) {
    return axios.delete(`${RESOURCE_NAME}/${id}`);
  },

  getGroupDetails(body) {
    return axios.put(`${RESOURCE_NAME}/updategroup`, body);
  },
};
