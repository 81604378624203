var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticStyle:{"border-radius":"10px"}},[_c('v-card-title',{staticStyle:{"color":"#595959"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"py-2",attrs:{"align":"start"}},[_vm._v(_vm._s(_vm.$t("title.titleReport")))]),_c('v-spacer'),(_vm.selectedStatisticType.Key)?_c('vue-excel-xlsx',{attrs:{"data":_vm.exportData,"columns":_vm.exportHeaders,"file-name":_vm.selectedStatisticType.Key + '.' + _vm.date + '-Raporu',"file-type":'xlsx',"sheet-name":_vm.selectedStatisticType.Value.substring(0, 31)}},[_c('v-btn',{staticClass:"ma-2 mr-4",attrs:{"small":"","elevation":"0","outlined":"","color":"info"}},[_vm._v(" "+_vm._s(_vm.$t("buttons.excelExport"))+" ")])],1):_vm._e(),_c('v-btn',{staticClass:"ma-2 mr-4",attrs:{"small":"","elevation":"0","outlined":"","color":"info"},on:{"click":function($event){return _vm.clearPage()}}},[_vm._v(" "+_vm._s(_vm.$t("buttons.clearFilter"))+" ")])],1)],1),_c('v-divider'),_c('v-card-text',{staticClass:"pt-4"},[_c('v-row',{attrs:{"align":"center","justify":"start"}},[_c('v-col',{attrs:{"cols":"3"}},[_c('v-menu',{ref:"datePickerRef",attrs:{"close-on-content-click":false,"return-value":_vm.date,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$t('labels.selectDateRange'),"prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.dateRangeText),callback:function ($$v) {_vm.dateRangeText=$$v},expression:"dateRangeText"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.datePickerModel),callback:function ($$v) {_vm.datePickerModel=$$v},expression:"datePickerModel"}},[_c('v-date-picker',{attrs:{"locale":"tr-TR","range":""},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.datePickerModel = false;
              _vm.dates = [];}}},[_vm._v(" "+_vm._s(_vm.$t("buttons.cancel"))+" ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.$refs.datePickerRef.save(_vm.date);
              _vm.saveDatePicker();}}},[_vm._v(" "+_vm._s(_vm.$t("buttons.ok"))+" ")])],1)],1)],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-select',{attrs:{"items":_vm.statisticsList,"label":_vm.$t('labels.selectStatistic'),"item-text":"Value","return-object":"","item-value":"Key"},on:{"change":function($event){return _vm.selectStatistic(_vm.selectedStatisticType)}},model:{value:(_vm.selectedStatisticType),callback:function ($$v) {_vm.selectedStatisticType=$$v},expression:"selectedStatisticType"}})],1),(_vm.selectedStatisticType.Key === 'Count_Post')?_c('v-col',{attrs:{"cols":"2"}},[_c('v-select',{attrs:{"items":_vm.countPostTypes,"label":_vm.$t('labels.selectCountPostType'),"item-text":"Value","return-object":"","item-value":"Key"},on:{"change":function($event){return _vm.selectCountPostType(_vm.selectedCountPostType)}},model:{value:(_vm.selectedCountPostType),callback:function ($$v) {_vm.selectedCountPostType=$$v},expression:"selectedCountPostType"}})],1):_vm._e(),(
          _vm.selectedStatisticType.Key === 'GetPostTagCounts' ||
          _vm.selectedStatisticType.Key === 'MostLikedPostsReport' ||
          _vm.selectedStatisticType.Key === 'MostCommentedPostsReport'
        )?_c('v-col',{attrs:{"cols":"3"}},[_c('v-autocomplete',{attrs:{"item-text":"Name","item-value":"Id","items":_vm.groups,"label":_vm.$t('labels.regions')},on:{"change":function($event){return _vm.getMostPosts()}},model:{value:(_vm.groupId),callback:function ($$v) {_vm.groupId=$$v},expression:"groupId"}})],1):_vm._e(),_c('v-col',{staticClass:"ml-auto",attrs:{"cols":"2"}},[_c('v-select',{attrs:{"items":_vm.perPageValues,"label":_vm.$t('labels.selectItemsPerPage'),"item-value":"value","item-text":"name"},on:{"change":function($event){return _vm.changeItemsPerPage()}},model:{value:(_vm.itemsPerPage),callback:function ($$v) {_vm.itemsPerPage=$$v},expression:"itemsPerPage"}})],1)],1),_c('v-data-table',{attrs:{"item-key":"Id","headers":_vm.headers,"items":_vm.tableData,"options":_vm.options,"hide-default-footer":true,"footer-props":{ disablePagination: true, disableItemsPerPage: true },"no-data-text":_vm.$t('description.noList')},on:{"update:options":function($event){_vm.options=$event}},model:{value:(_vm.selectedReports),callback:function ($$v) {_vm.selectedReports=$$v},expression:"selectedReports"}}),_c('div',{staticClass:"text-xs-center pt-2 md4 lg4"},[_c('v-pagination',{attrs:{"value":_vm.pagination.page,"length":_vm.pages,"total-visible":10,"circle":""},on:{"input":_vm.paginationChangeHandler}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }