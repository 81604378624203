var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"persistent":"","max-width":"700"},model:{value:(_vm.foodMenuDetailStatus),callback:function ($$v) {_vm.foodMenuDetailStatus=$$v},expression:"foodMenuDetailStatus"}},[(!_vm.isDetail)?_c('v-card',[_c('v-card-title',{staticClass:"headline indigo lighten-2"},[_c('div',{staticClass:"text-center white--text",staticStyle:{"width":"100%"}},[_vm._v(" "+_vm._s(this.modalHeader)+" ")])]),_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.sendForm)}}},[_c('v-card-text',{staticClass:"py-3"},[_c('v-row',[_c('v-col',{attrs:{"md":"12","xs":"12"}},[_c('v-banner',{staticClass:"mt-5",attrs:{"rounded":"","outlined":""}},[_c('ValidationProvider',{attrs:{"name":"Dosya","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-file-input',{ref:"fileupload",attrs:{"accept":".xlsx, .xls","rules":[
                        function (value) { return !value ||
                          value.size < 10000000 ||
                          'Dosya en fazla 10 MB olmalı.'; } ],"placeholder":_vm.$t('labels.selectExcelFile'),"prepend-icon":"mdi-file","label":_vm.$t('labels.fileUpload'),"error-messages":errors[0],"show-size":""},on:{"change":_vm.changeFile,"input":_vm.changeFile},model:{value:(_vm.excelFile),callback:function ($$v) {_vm.excelFile=$$v},expression:"excelFile"}})]}}],null,true)})],1),_c('v-banner',{staticClass:"mt-5",attrs:{"rounded":"","outlined":""}},[_vm._l((_vm.groupsSelectArray),function(data,rowIndex){return _c('v-row',{key:rowIndex,staticClass:"mt-2"},[_c('v-row',_vm._l((data),function(element,colIndex){return _c('v-col',{key:colIndex,attrs:{"cols":"4"}},[_c('ValidationProvider',{attrs:{"vid":'attribute' + rowIndex + colIndex,"name":"Grup","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('div',{staticClass:"px-5 mt-3",attrs:{"row":"","wrap":""}},[_c('v-select',{attrs:{"items":element.groups,"item-text":"name","item-value":"id","label":element.name == 'Şirket'
                                  ? 'Kuruluş'
                                  : element.name,"error-messages":errors[0],"dense":""},on:{"change":function($event){return _vm.changeGroupFilterModal(
                                  element,
                                  colIndex,
                                  rowIndex,
                                  data
                                )},"click":function($event){return _vm.clickGroupFilterModal(
                                  element,
                                  colIndex,
                                  rowIndex
                                )}},model:{value:(_vm.groupArr[rowIndex][colIndex]),callback:function ($$v) {_vm.$set(_vm.groupArr[rowIndex], colIndex, $$v)},expression:"groupArr[rowIndex][colIndex]"}})],1)]}}],null,true)})],1)}),1)],1)}),_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[(!_vm.groupLengthAlert)?_c('v-btn',{staticClass:"ma-1",attrs:{"small":"","outlined":"","color":"info"},on:{"click":function($event){return _vm.createNewGroup()}}},[_vm._v(" "+_vm._s(_vm.$t("buttons.addNew"))+" ")]):_vm._e()],1),_c('v-col',{staticClass:"mt-2"},[_c('v-alert',{staticClass:"font-weight-black",staticStyle:{"font-size":"1.2em"},attrs:{"border":"left","close-text":"Close Alert","type":"info","color":"#7443a0","dismissible":"","outlined":"","prominent":""},model:{value:(_vm.groupLengthAlert),callback:function ($$v) {_vm.groupLengthAlert=$$v},expression:"groupLengthAlert"}},[_vm._v(" "+_vm._s(_vm.$t("description.groupLengthMessage"))+" ")])],1)],1)],2)],1)],1)],1),_c('v-card-actions',{staticStyle:{"background-color":"white","position":"sticky","bottom":"0px","border-top":"solid grey 2px"}},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","type":"submit","loading":_vm.loadings.send}},[_vm._v(" "+_vm._s(_vm.$t("buttons.save"))+" ")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.foodMenuDetailStatus = false;
                _vm.clearModal();}}},[_vm._v(" "+_vm._s(_vm.$t("buttons.close"))+" ")])],1)],1)]}}],null,false,3514114723)})],1):_vm._e(),(_vm.isDetail)?_c('v-card',{staticStyle:{"overflow-x":"hidden"}},[_c('v-card-title',{staticClass:"headline indigo lighten-2"},[_c('div',{staticClass:"text-center white--text",staticStyle:{"width":"100%"}},[_vm._v(" "+_vm._s(this.modalHeader)+" ")])]),_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-card',{staticClass:"mx-auto my-12",attrs:{"width":"400"}},[_c('v-img',{attrs:{"height":"150","src":"https://cdn.vuetifyjs.com/images/cards/cooking.png"}}),_vm._l((_vm.detailedItem),function(item,index){return _c('v-card-title',{key:index,staticClass:"py-1 px-3",staticStyle:{"border-bottom":"1px solid #d9d9d9"}},[_c('div',{staticClass:"text-h6 font-weight-black",staticStyle:{"width":"100%"}},[_vm._v(" "+_vm._s(item.Type)+" "),_c('v-list',{attrs:{"subheader":"","two-line":""}},_vm._l((item.FoodKind),function(element,i){return _c('v-list-item',{key:i,staticStyle:{"min-height":"30px"}},[_c('v-list-item-content',{staticClass:"ma-0 pa-0"},[_c('v-list-item-title',{staticClass:"font-weight-regular",domProps:{"textContent":_vm._s(element.Name)}})],1),_c('v-list-item-action',{staticClass:"ma-0 text-center"},[_c('v-chip',{staticClass:"ma-1 caption text-center",staticStyle:{"min-width":"100px"},attrs:{"color":"pink","label":"","text-color":"white"}},[_vm._v(" "+_vm._s(element.Calory + " - " + _vm.$t("labels.foodMenuCalory"))+" ")])],1)],1)}),1)],1)])})],2)],1),_c('v-card-actions',{staticStyle:{"background-color":"white","position":"sticky","bottom":"0px","border-top":"solid grey 2px"}},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"#7986CB","dark":"","depressed":""},on:{"click":function($event){_vm.foodMenuDetailStatus = false;
            _vm.isDetail = false;}}},[_vm._v(" "+_vm._s(_vm.$t("buttons.close"))+" ")])],1)],1):_vm._e()],1),_c('delete-modal',{ref:"deleteModalRef",attrs:{"yesFunc":_vm.deleteItem}}),_c('v-card',{staticStyle:{"border-radius":"10px"}},[_c('v-card-title',{staticStyle:{"color":"#595959"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"py-2",attrs:{"align":"start"}},[_vm._v(_vm._s(_vm.$t("title.titleFoodMenu")))]),_c('v-col',{attrs:{"cols":"6","align":"end"}},[_c('v-btn',{staticClass:"ma-2 mr-4",attrs:{"small":"","elevation":"0","outlined":"","color":"info","href":_vm.url + '/admin/downloads/YemekMenuTest.xlsx',"download":""}},[_vm._v(" "+_vm._s(_vm.$t("buttons.sampleFile"))+" ")]),(_vm.selectedFoodMenus.length >= 1)?_c('v-btn',{staticClass:"ma-2 mr-4",attrs:{"small":"","elevation":"0","outlined":"","color":"info"},on:{"click":function($event){return _vm.openDeleteModal()}}},[_vm._v(" "+_vm._s(_vm.$t("buttons.deleteSelected"))+" ")]):_vm._e(),_c('v-btn',{staticClass:"ma-2 mr-4",attrs:{"small":"","elevation":"0","outlined":"","color":"info"},on:{"click":function($event){_vm.foodMenuDetailStatus = true;
              _vm.modalHeader = _vm.$t('title.foodMenuModalCreate');}}},[_vm._v(" "+_vm._s(_vm.$t("buttons.addNew"))+" ")])],1)],1)],1),_c('v-divider'),_c('v-card-text',{staticClass:"pt-4"},[_c('v-row',{attrs:{"align":"center","justify":"space-between"}},[_c('v-col',{attrs:{"cols":"3"}},[_c('v-menu',{ref:"datePickerRef",attrs:{"close-on-content-click":false,"return-value":_vm.date,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$t('labels.selectDate'),"prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.dateRangeText),callback:function ($$v) {_vm.dateRangeText=$$v},expression:"dateRangeText"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.datePickerModel),callback:function ($$v) {_vm.datePickerModel=$$v},expression:"datePickerModel"}},[_c('v-date-picker',{attrs:{"locale":"tr-TR","range":""},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.datePickerModel = false;
                  _vm.dates = [];}}},[_vm._v(" "+_vm._s(_vm.$t("buttons.cancel"))+" ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.$refs.datePickerRef.save(_vm.date);
                  _vm.getTableList();}}},[_vm._v(" "+_vm._s(_vm.$t("buttons.ok"))+" ")])],1)],1)],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-select',{attrs:{"items":_vm.corpGroups[0],"label":_vm.$t('labels.selectCorp'),"item-text":"name","item-value":"id"},on:{"change":function($event){return _vm.changeFilter()}},model:{value:(_vm.selectedCorpGroup),callback:function ($$v) {_vm.selectedCorpGroup=$$v},expression:"selectedCorpGroup"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-select',{attrs:{"items":_vm.locGroups[0],"label":_vm.$t('labels.selectLoc'),"item-text":"name","item-value":"id"},on:{"change":function($event){return _vm.changeFilter()}},model:{value:(_vm.selectedLocGroup),callback:function ($$v) {_vm.selectedLocGroup=$$v},expression:"selectedLocGroup"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-select',{attrs:{"items":_vm.empGroups[0],"label":_vm.$t('labels.selectEmpType'),"item-text":"name","item-value":"id"},on:{"change":function($event){return _vm.changeFilter()}},model:{value:(_vm.selectedEmpGroup),callback:function ($$v) {_vm.selectedEmpGroup=$$v},expression:"selectedEmpGroup"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-select',{attrs:{"items":_vm.perPageValues,"label":_vm.$t('labels.selectItemsPerPage'),"item-value":"value","item-text":"name"},on:{"change":function($event){return _vm.changeItemsPerPage()}},model:{value:(_vm.itemsPerPage),callback:function ($$v) {_vm.itemsPerPage=$$v},expression:"itemsPerPage"}})],1)],1),_c('v-data-table',{attrs:{"item-key":"Id","show-select":"","headers":_vm.headers,"items":_vm.tableData,"loading":_vm.loadings.table,"options":_vm.options,"hide-default-footer":true,"footer-props":{ disablePagination: true, disableItemsPerPage: true },"no-data-text":_vm.$t('description.noList')},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.Date",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.convertToLocal(item.Date))+" tarihli yemek menüsü ")]}},{key:"item.CreatedAt",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.convertToLocal(item.CreatedAt))+" ")]}},{key:"item.User",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(item.User.FirstName + " " + item.User.LastName)+" ")]}},{key:"item.actions",fn:function(ref){
                  var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.getFoodMenuDetails(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-file-eye ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("labels.tooltipEdit")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.openDeleteModal(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("labels.tooltipDelete")))])])]}}]),model:{value:(_vm.selectedFoodMenus),callback:function ($$v) {_vm.selectedFoodMenus=$$v},expression:"selectedFoodMenus"}}),_c('div',{staticClass:"text-xs-center pt-2 md4 lg4"},[_c('v-pagination',{attrs:{"value":_vm.pagination.page,"length":_vm.pages,"total-visible":10,"circle":""},on:{"input":_vm.paginationChangeHandler}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }