<template>
  <v-navigation-drawer
    permanent
    absolute
    dark
    color="#4f326b"
    style="padding-top: 60px; max-width: 300px; padding-bottom: 60px;"
  >
    <v-list nav dense>
      <v-list-item-group>
        <!--eslint-disable-->
        <v-list-item
          v-for="(item, index) in this.$store.state.menuItems"
          :key="index"
          link
          :to="item.to"
        >
          <v-list-item-icon
            style="margin-top: auto; margin-bottom: auto; margin-right: 10px"
            class="abc"
          >
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title
            style="font-size: 14px;"
            >{{ item.label }}</v-list-item-title
          >
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "NavigationDrawer",
  data() {
    return {};
  },
  mounted() {
    this.getMenuData();
  },
  components: {},
  methods: {
    ...mapActions(["getMenuData"]),
  },
};
</script>

<style>
.abc > .theme--light.v-icon {
  color: #cfcfcf;
}
</style>
