// Örnek servis codiagno login
import axios from "../javascript/custom-axios";
const RESOURCE_NAME = "/admin/badContents";

export default {
  getBadContents(pageSize = 15, page = 1, Type = "", ContentStatus = "") {
    return axios.get(`${RESOURCE_NAME}/getBadContents`, {
      params: { page, pageSize, ContentStatus, Type },
    });
  },
  approveContent(body) {
    return axios.put(`${RESOURCE_NAME}/changeContentStatus`, body);
  },
  denyContent(type, contentId) {
    return axios.delete(
      `${RESOURCE_NAME}/deleteBadContent?Type=${type}&Id=${contentId}`
    );
  },
};
