import axios from "../javascript/custom-axios";
const RESOURCE_NAME = "files";

export default {
  getForceUpdate() {
    return axios.get(`${RESOURCE_NAME}/forceupdate`);
  },
  updateForceUpdateFile(body) {
    return axios.put(`${RESOURCE_NAME}/forceupdate`, body);
  },
  
};
