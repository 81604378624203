import axios from "../javascript/custom-axios";
const RESOURCE_NAME = "admin/stories/";
const RESOURCE_NAME2 = "/stories";

export default {
  getStories(search, pageSize = 15, page = 1) {
    return axios.get(`${RESOURCE_NAME}`, {
      params: { Search: search, PageSize: pageSize, Page: page },
    });
  },
  deleteStory(id) {
    return axios.delete(`${RESOURCE_NAME2}/${id}`);
  },
  createStory(body) {
    return axios.post(`${RESOURCE_NAME2}`, body);
  },
};
