// Örnek servis codiagno login
import axios from "../javascript/custom-axios";
const RESOURCE_NAME = "/admin/EndorsementTopics";





export default {
   getEndorsementTopics() {
      
    return axios.get(`${RESOURCE_NAME}`);
   },
   createEndorsementTopic (body) {
      return axios.post(`${RESOURCE_NAME}/create`,body)
    },
    updateEndorsementTopic( body)
    {
       return axios.post(`${RESOURCE_NAME}/update` ,body)
    },
    deleteEndorsementTopic(id)
    {

       return axios.delete(`${RESOURCE_NAME}/delete`, {data : {"Id" : id}})
    },
    getFileById(id)
    {
       return axios.get(`/files/${id}`)
    }
   
 
};
