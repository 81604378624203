// Örnek servis codiagno login
import axios from "../javascript/custom-axios";
const RESOURCE_NAME = "/askhr";

export default {
  getAskHr(type="", status="",pageSize = 15,
    page = 1) {
      if(type =="Tümü"){
        type =" "
      }
      console.log("status : ",status);
      if(status ==""){
    return axios.get(`${RESOURCE_NAME}?Type=${type}&PageSize=${pageSize}&Page=${page}`);
      }
      else{
    return axios.get(`${RESOURCE_NAME}?Type=${type}&PageSize=${pageSize}&Page=${page}&Status=${status}`);
      }
    // return axios.get(`${RESOURCE_NAME}?Type=${type}&PageSize=${pageSize}&Page=${page}&Status=${status}`);
  },
  getQuestionsType() {
    return axios.get(`${RESOURCE_NAME}/questiontypes`);
  },
  createAnswerHr(body) {
    return axios.post(`${RESOURCE_NAME}/answer`, body);
  },
  updateQuestionStatus(body) {
    return axios.put(`${RESOURCE_NAME}`, body);
  },
  downloadFile(url){
    return axios.get(`${url}`)
  }
};
