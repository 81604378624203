import axios from "../javascript/custom-axios";
const RESOURCE_NAME = "admin/posts";

export default {
  getPosts(IsPrivate = '',IsDeclared='', Key= 'akademi', PageSize=15, Page = 1) {
    return axios.get(`/admin/posts`, {params:{IsPrivate, IsDeclared, Key, PageSize,Page}});
  },
  assignDocument(body) {
    return axios.put(`/posts/update`, body);
  },
  
};
