<template>
    <div>
      <!-- Json modal -->
      <v-dialog
        persistent
        max-width="1000"
        v-model="forceUpdateAddStatus"
        ref="forceUpdatePopupModal"
      >
        <v-card>
          <v-card-title class="headline indigo lighten-2">
            <div class="text-center white--text" style="width: 100%">
              {{ this.modalHeader }}
            </div>
          </v-card-title>
          <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
            <form @submit.prevent="handleSubmit(sendForm)">
              <v-card-text class="pt-10 px-10">
                <v-textarea
                  :label="$t('title.forceUpdateLabel')"
                  :value="forceUpdateJson"
                  v-model="forceUpdateJsonUpdate"
                ></v-textarea>
              </v-card-text>
              <v-card-actions
                style="
                  background-color: white;
                  position: sticky;
                  bottom: 0px;
                  border-top: solid grey 2px;
                "
              >
                <v-spacer></v-spacer>
                <v-btn color="primary" type="submit">
                  {{ $t("buttons.assign") }}
                </v-btn>
                <v-btn color="primary" text @click="forceUpdateAddStatus = false; forceUpdateJsonUpdate = '';">
                  {{ $t("buttons.close") }}
                </v-btn>
              </v-card-actions>
            </form>
          </ValidationObserver>
        </v-card>
      </v-dialog>
      <!-- Page content -->
      <v-card style="border-radius: 10px">
        <v-card-title style="color: #595959">
          <v-row>
            <v-col class="py-2">{{ $t("title.forceUpdate") }}</v-col>
            <v-spacer />
            <v-btn
              small
              class="ma-2 mr-4"
              elevation="0"
              outlined
              color="info"
              @click="
                forceUpdateAddStatus = true;
                isDetails = false;
                modalHeader = $t('title.forceUpdateAdd');
                forceUpdateJsonUpdate = forceUpdateJson;
              "
            >
              {{ $t("title.forceUpdateAdd") }}
            </v-btn>
          </v-row>
        </v-card-title>
  
        <v-divider />
  
        <v-card-text class="pt-4">
          <!-- <v-textarea
            :label="$t('title.forceUpdateLabel')"
            :value="forceUpdateJson"
          ></v-textarea> -->
          <pre>{{ forceUpdateJson | pretty }}</pre>
        </v-card-text>
      </v-card>
    </div>
  </template>
  
  <script>
  import { mapActions } from "vuex";
  import ForceUpdateService from "../services/ForceUpdateService";
  
  export default {
    filters: {
      pretty: function(value) {
        return JSON.stringify(JSON.parse(value), null, 5);
      },
    },
    data: () => ({
      //Initial models
      modalHeader: "", //Global header for opened modal
      forceUpdateAddStatus: false,
      forceUpdateJson: "",
      forceUpdateJsonUpdate: "",
    }),
    mounted() {
      this.setup();
    },
  
    methods: {
      //Page setup
      setup() {
        this.modalHeader = this.$t("title.moduleModalCreate");
        this.getCurrentJson();
      },
  
      //Fill's the json list
      getCurrentJson() {
        this.$store.state.loadingStatus = true;
        ForceUpdateService.getForceUpdate()
          .then((res) => {
            console.log("Current json > ", res.data);
            this.forceUpdateJson = JSON.stringify(res.data);
          })
          .catch((err) => {
            window.handleServiceError(err);
          })
          .then(() => {
            this.$store.state.loadingStatus = false;
          });
      },
  
      sendForm() {
        console.log(
          "FORCE UPDATE JSON >> ",
          JSON.parse(this.forceUpdateJsonUpdate)
        );
  
        this.$store.state.loadingStatus = true;
        this.forceUpdateAddStatus = false;
        ForceUpdateService.updateForceUpdateFile(JSON.parse(this.forceUpdateJsonUpdate))
          .then((res) => {
            this.getCurrentJson();
          })
          .catch((err) => {
            window.handleServiceError(err);
            this.forceUpdateAddStatus = false;
          })
          .then(() => {
            this.$store.state.loadingStatus = false;
          });
      },
  
      //Gets sidebar data dynamically
      ...mapActions(["getMenuData"]),
    },
  };
  </script>
  