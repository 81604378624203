import Vue from 'vue'
import App from './App.vue'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import VueExcelXlsx from "vue-excel-xlsx";
// import vuetify from './plugins/vuetify';
import store from './store/store'
import router from './router'
import vuetify from './plugins/vuetify'
import './plugins'
// import VueNumberInput from '@chenfengyuan/vue-number-input';

import localization from './javascript/localization'
// import { ValidationProvider } from 'vee-validate/dist/vee-validate.full.esm';
// import { ValidationObserver } from 'vee-validate';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import './javascript/vee-validate';

// Vue.component('ValidationProvider', ValidationProvider);
// Vue.component('ValidationObserver', ValidationObserver);

// Vue.use(VueNumberInput);
Vue.use( CKEditor );
Vue.use(VueExcelXlsx);
Vue.config.productionTip = false

const i18n = localization.setLanguageSettings(Vue)
store.state.router = router
store.state.i18n = i18n
// store.state.validator = ValidationObserver

new Vue({
  i18n,
  store,
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
