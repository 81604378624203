// Örnek servis codiagno login
import axios from '../javascript/custom-axios'
const RESOURCE_NAME = '/admin/pages'

export default {
  getMyPages (body) {
    return axios.get(`${RESOURCE_NAME}/my`)
  },

  getPages () {
    return axios.get(`${RESOURCE_NAME}`)
  },

  createModule (body) {
    return axios.post(`${RESOURCE_NAME}/create`, body)
  },
  
  deleteModule (id) {
    return axios.put(`${RESOURCE_NAME}/${id}`)
  },

  updateModuleDetails (id,body) {  
    return axios.put(`${RESOURCE_NAME}/${id}`,body)
  },

  getModuleDetails (id) {  
    return axios.get(`${RESOURCE_NAME}/${id}`)
  },

  getCustomFields(params) {
    return axios.get(`/custom_fields`, {params:params})
  },
  
}
