<template>
  <!-- Groups start  -->
  <v-card-text class="px-0 py-0 add-content-card-body">
    <v-banner rounded outlined class="mt-5">
      <v-card-title class="add-content-title">
        {{ $t("labels.postGroup") }}
      </v-card-title>

      <v-row
        class="mt-2"
        v-for="(data, rowIndex) in groupsSelectArray"
        :key="rowIndex"
      >
        <v-row align="center" justify="center">
          <v-col cols="11">
            <v-row v-if="!pageRules.isPublicGroups">
              <v-col
                cols="4"
                v-for="(element, colIndex) in data"
                :key="colIndex"
              >
                <ValidationProvider
                  :vid="'attribute' + rowIndex + colIndex"
                  name="Grup"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <div row wrap class="px-5 mt-3">
                    <v-select
                      v-model="groupArr[rowIndex][colIndex]"
                      :items="element.groups"
                      @click="
                        clickGroupFilterModal(element, colIndex, rowIndex)
                      "
                      @change="changeGroupInput(element, colIndex, rowIndex)"
                      item-text="name"
                      item-value="id"
                      :label="
                        element.name == 'Şirket' ? 'Kuruluş' : element.name
                      "
                      dense
                      required
                      :error-messages="errors[0]"
                    ></v-select>
                  </div>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row class="ml-5" v-if="pageRules.isPublicGroups">
              <v-card-title
                class="add-content-title"
                style="font-size: 12px !important;"
                >{{ $t("description.communityModule") }}</v-card-title
              >
            </v-row>
          </v-col>
          <v-col cols="1">
            <v-icon
              v-if="groupsSelectArray.length != 1"
              @click="deleteGroupRow(rowIndex)"
              >mdi-trash-can-outline</v-icon
            >
          </v-col>
        </v-row>
      </v-row>
      <v-row justify="center" align="center">
        <v-col cols="2" v-if="!pageRules.isPublicGroups">
          <v-btn
            small
            v-if="!groupLengthAlert"
            class="ma-1"
            outlined
            color="info"
            @click="createNewGroup()"
          >
            {{ $t("buttons.addNewGroup") }}
          </v-btn>
        </v-col>

        <v-col class="mt-2">
          <v-alert
            v-model="groupLengthAlert"
            border="left"
            close-text="Close Alert"
            type="info"
            color="#7443a0"
            dismissible
            outlined
            prominent
            class="font-weight-black"
            style="font-size: 1.2em"
          >
            {{ $t("description.groupLengthMessage") }}
          </v-alert>
        </v-col>
      </v-row>
    </v-banner>
  </v-card-text>
  <!-- Groups end -->
</template>

<script>
import GroupService from "../../services/GroupService";
import ContentService from "../../services/GroupService";

export default {
  data: () => ({
    groupArr: [[]],
    groupData: [],
    groupsSelectArray: [],
    //TODO body içine konulacak > body.GroupIds = this.groupModel.Groups,
    groupModel: {
      Groups: [],
    },
    selecItems: [],
    allSelect: [],
    corpGroup: [],
    locGroup: [],
    empGroup: [],
    groupLengthAlert: false,
  }),
  watch: {},
  mounted() {
    this.setup();
  },
  components: {},
  methods: {
    setup() {
      this.getGroupTypesModal();
      if (!this.pageRules.isPublicGroups) {
        this.fillGroups();
      }
      console.log("Page rules comp > ", this.pageRules);
      if (this.isUpdate) {
        console.log("Update işlemi yapılacak >");
      }
    },

    fillGroups() {
      GroupService.getGroups("sirket")
        .then((res) => {
          res.data.forEach((element) => {
            this.corpGroup.push(element.Id);
            let obj = {
              Id: element.Id,
              Name: element.Name,
            };
            this.allSelect.push(obj);
          });
        })
        .catch((err) => {
          window.showError(err);
        })
        .then(() => {});

      GroupService.getGroups("calisan_tipi")
        .then((res) => {
          res.data.forEach((element) => {
            this.empGroup.push(element.Id);
            let obj = {
              Id: element.Id,
              Name: element.Name,
            };
            this.allSelect.push(obj);
          });
        })
        .catch((err) => {
          window.showError(err);
        })
        .then(() => {});
      let all = {
        Name: "Tümü",
        Id: "",
      };
      this.allSelect.push(all);
    },
    //Change corparate group
    changeGroupInput(element, colIndex, dataIndex) {
      if (element.type == "sirket") {
        this.groupArr[dataIndex][colIndex + 1] = "";
      }
    },
    // Fill select box items
    clickGroupFilterModal(element, colIndex, dataIndex) {
      if (
        element.type == "lokasyon" &&
        this.groupArr[dataIndex][colIndex - 1] == undefined
      ) {
        this.groupsSelectArray[dataIndex][colIndex].groups = [...[]];
      }
      if (
        (element.type == "lokasyon" &&
          this.groupArr[dataIndex][colIndex - 1] != undefined) ||
        element.type == "calisan_tipi" ||
        element.type == "sirket"
      ) {
        GroupService.getGroups(
          element.type,
          element.type == "lokasyon"
            ? this.groupArr[dataIndex][colIndex - 1]
            : ""
        )
          .then((res) => {
            let arr = [];
            res.data.forEach((item) => {
              let obj = {
                id: item.Id,
                name: item.Name,
              };
              arr.push(obj);
            });
            if (
              element.type != "sirket" ||
              this.$store.getters.getPermissionIds
            )
              arr.unshift({ name: "Tümü", id: "All" });
            this.groupsSelectArray[dataIndex][colIndex].groups = arr;
            let listObj = {
              Type: element.type,
              Data: arr,
            };
            this.selecItems.push(listObj);
          })
          .catch((err) => {
            window.showError(err);
          })
          .then(() => {});
      }
    },
    //Group types
    getGroupTypesModal() {
      GroupService.getGroupTypes()
        .then((res) => {
          res.data.forEach((element) => {
            let obj = {
              type: element.Code,
              groups: [],
              name: element.Name,
            };

            this.groupData.push(obj);
          });
          this.groupsSelectArray.push(this.groupData);
        })
        .catch((err) => {
          window.showError(err);
        });
    },
    //To take GroupId
    createGroupIds() {
      for (let i = 0; i < this.groupArr.length; i++) {
        const element = this.groupArr[i];
        let array = this.groupArr[i].filter((x) => x == "All").length;
        if (array == 3) this.flag = true;
        this.groupModel.Groups[i] = new Array();
        for (let y = 0; y < element.length; y++) {
          if (element[y] === "All") {
            this.groupsSelectArray[i][y].groups.forEach((element) => {
              this.groupModel.Groups[i].push(element.id);
            });
          }

          this.groupModel.Groups[i].push(element[y]);
        }
        console.log("Group model > ", this.groupModel);
        this.groupModel.Groups[i] = this.groupModel.Groups[i].filter(
          (x) => x != "All"
        );
        return this.groupModel.Groups;
      }
    },
    //To add new row for new Group
    createNewGroup() {
      if (this.groupsSelectArray.length < 4) {
        this.groupLengthAlert = false;
        let arr = [];
        this.groupData.forEach((element) => {
          let obj = {
            type: element.type,
            groups: [],
            name: element.name,
          };

          arr.push(obj);
        });

        this.groupsSelectArray = [...this.groupsSelectArray, arr];
        this.groupArr.push([]);
      } else {
        this.groupLengthAlert = true;
      }
    },
    //To delete a row of Groups
    deleteGroupRow(rowIndex) {
      if (!(this.groupsSelectArray.length == 1)) {
        if (this.groupsSelectArray.length == 4) {
          this.groupLengthAlert = false;
        }

        this.groupsSelectArray.splice(rowIndex, 1);
        this.groupArr.splice(rowIndex, 1);
      } else {
        window.showInfo(
          this.$t("description.groupRowDelete"),
          "standart",
          "info"
        );
      }
    },
    returnFunc() {
      this.createGroupIds();
      this.$emit("returnFunc", this.groupArr);
    },
  },
  computed: {},
  props: {
    isUpdate: {
      type: Boolean,
      //default: () => this.$t('description.deleteModalDescription'),
    },
    pageRules: {
      type: Object,
    },
  },
};
</script>
