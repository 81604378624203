import store from '../store/store'
import axios from '../javascript/custom-axios'

export default {
  ifAuthenticated(to, from, next) {
    let isUserType = to.meta.userType.indexOf(store.getters.getUserType)
    if (store.getters.isAuthenticated && isUserType >= 0) {
      axios.defaults.headers = { 'Authorization': store.state.Token }
      next()
    } else {
      if(isUserType < 0){
        //tüm yetkilerin girebildiği sayfa olmalı
        next('/home/dashboard')
      }else{
        next('/login')
      }
    }
  },

  ifNotAuthenticated(to, from, next) {
    if (!store.getters.isAuthenticated) {
      axios.defaults.headers = { 'Authorization': '' }
      next()
      return
    }
    next('/home')
  },
}
