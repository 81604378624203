<template>
<v-fade-transition>
  <v-snackbar
    class="rounded-snackbar"
    elevation="0"
    :style="alertObject.y === 'top' ? 'top:85px;' : ''"
    v-model="show"
    :color="'transparent'"
    :bottom="alertObject.y === 'bottom'"
    :top="alertObject.y === 'top'"
    :left="alertObject.x === 'left'"
    :right="alertObject.x === 'right'"
    :vertical="alertObject.mode === 'vertical'"
    :timeout="timeout"
    >
    <v-alert
      dense
      min-height="65px"
      class="py-5"
      :type="alertObject.color"
    >
      {{alertObject.message}}
    </v-alert>
  </v-snackbar>
</v-fade-transition>
</template>

<script>

export default {
  props: {
    options: Object
  },
  data: () => ({
    show: false
  }),
  watch: {
    alertObject () {
      this.show = true
    }

  },
  computed: {
    timeout () {
      // Yetişkin bir kişi bir kelimeyi ortalama 0.35 saniyede okuyabilirmiş.
      // +800 milisaniye ise alerti farketme sürecindeki gecikme olabileceği için
      let _timeout = (this.alertObject.message.split(' ').length * 1000 * 0.35) + 800

      return _timeout < 3000 ? 3000 : _timeout // 3 Saniyeden daha kısa olmasın diye
    },

    computedClass () {
      return `alert ${this.options.type}`
    },

    alertObject () {
      return this.$store.state.alertObject
    }
  }
}
</script>

<style>

.rounded-snackbar .v-snack__content{
  min-height: 65px;
  /*border: 2px solid #fff;*/
  /*border-radius: 12px;*/
}
.rounded-snackbar .v-snack__wrapper{
  border-radius: 12px;
}
.alert{
    position: fixed;
    color:#eee;
    width: 500px;

    line-height: 50px;
    text-align: center;
    border-radius: 22px;
    left: 50%;
    margin-left: -250px;
    z-index: 100
}
</style>
