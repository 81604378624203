<template>
  <div class="text-center" v-if="$store.state.loadingStatus">
    <v-overlay>
      <v-row align="center" justify="center">
        <lottie-animation
          ref="anim"
          :loop="true"
          style="width:100px"
          :autoPlay="true"
          :speed="5"
          :animationData="require('@/assets/loading.json')"
        />
      </v-row>
      <v-row align="center" justify="center"
        ><p class="font-weight-bold">İşleminiz yapılıyor...</p>
      </v-row>
      <v-row>
        <p class="font-weight-bold">
          Lütfen pencereyi kapatmayın veya yenilemeyin.
        </p>
      </v-row>
    </v-overlay>
  </div>
</template>

<script>
import LottieAnimation from "lottie-web-vue";
export default {
  components: {
    LottieAnimation,
  },
  data: () => ({}),
  computed: {},
};
</script>

<style></style>
