import axios from '../javascript/custom-axios'
const RESOURCE_NAME = 'tags'
const RESOURCE_NAME2 = 'tags/tagList'

export default {
  getTags() {
    return axios.get(`${RESOURCE_NAME2}`)
  },
  createTags(body){
    return axios.post(`${RESOURCE_NAME}`,body)
 
  }
}