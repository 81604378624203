<template>
  <div>
    <!-- AskHR answer modal -->
    <v-dialog
      persistent
      v-model="questionAnswerDialog"
      max-width="1000"
      ref="questionAnswerPopupModal"
    >
      <v-card>
        <v-card-title class="headline indigo lighten-2">
          <div class="text-center white--text" style="width: 100%">
            {{ $t("labels.inspectQuestion") }}
          </div>
        </v-card-title>

        <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
          <form @submit.prevent="handleSubmit(sendForm)">
            <v-card-text>
              <v-layout>
                <v-flex md8 class="pr-10">
                  <!-- Inspected item title start -->
                  <v-row>
                    <v-col>
                      <v-textarea
                        v-model="inspectedItem.Title"
                        :label="$t('labels.titleHr')"
                        readonly
                        no-resize
                        rows="1"
                        auto-grow
                      ></v-textarea>
                    </v-col>
                  </v-row>
                  <!-- Inspected item title end -->
                  <!-- Inspected item question start -->
                  <v-row>
                    <v-col>
                      <v-textarea
                        v-model="inspectedItem.Question"
                        :label="$t('labels.questionHr')"
                        no-resize
                        rows="1"
                        auto-grow
                        readonly
                      ></v-textarea>
                    </v-col>
                  </v-row>
                  <!-- Inspected item question end -->
                </v-flex>

                <v-divider vertical></v-divider>

                <v-flex md4 class="pl-10">
                  <!-- Inspected item status start -->
                  <v-row class="justify-center">
                    <v-col cols="5">
                      <v-text-field
                        :label="$t('labels.tooltipAnswered')"
                        v-if="inspectedItem.Status == 20"
                        readonly
                        disabled
                      >
                      </v-text-field>
                      <v-text-field
                        :label="$t('labels.tooltipNoAnswered')"
                        v-if="inspectedItem.Status == 0"
                        readonly
                        disabled
                      >
                      </v-text-field>
                      <v-text-field
                        :label="$t('labels.tooltipInReview')"
                        v-if="inspectedItem.Status == 10"
                        readonly
                        disabled
                      >
                      </v-text-field>
                    </v-col>
                    <v-col v-if="inspectedItem.Status == 0">
                      <v-btn
                        small
                        class="ma-1 mt-5"
                        outlined
                        color="#7443a0"
                        @click="examineQuestion()"
                      >
                        {{ $t("buttons.inReviewOrder") }}
                      </v-btn>
                    </v-col>
                  </v-row>
                  <!-- Inspected item status end -->
                  <!-- Inspected item answer date start -->
                  <v-row class="justify-center">
                    <v-col cols="5">
                      <v-text-field
                        v-if="inspectedItem.IsAnswered"
                        v-model="inspectedItemCreatedAt"
                        :label="$t('labels.dateHr')"
                        readonly
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <!-- Inspected item answer date end -->
                </v-flex>
              </v-layout>
            </v-card-text>
            <v-card-text class="py-0">
              <v-row>
                <v-col>
                  <!-- Answer input start -->
                  <ValidationProvider
                    name="Soru Cevabı"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <v-textarea
                      :disabled="inspectedItem.IsAnswered"
                      v-model="questionAnswer"
                      :value="questionAnswer"
                      :label="$t('labels.questionAnswer')"
                      :error-messages="errors[0]"
                      solo
                    ></v-textarea>
                  </ValidationProvider>
                  <!-- Answer input end -->
                </v-col>
              </v-row>
            </v-card-text>
            <!-- Inspected item files start -->
            <v-card-text v-if="fileList.length > 0">
              <v-row>
                <v-col class="mx-4" style="font-size:12px;color:#7443a0">
                  {{ $t("labels.filesHr") }}
                </v-col>
              </v-row>
            </v-card-text>
            <v-banner
              rounded
              outlined
              class="ml-8 mr-8 mb-8"
              v-if="fileList.length > 0"
            >
              <v-col>
                <v-container class="mt-0" mx-0 px-0 fluid grid-list-xl>
                  <v-layout row wrap>
                    <v-flex xs4 v-for="(item, index) in fileList" :key="index">
                      <v-hover>
                        <v-img
                          style="border-radius: 8px"
                          width="100%"
                          position="center"
                          slot-scope="{ hover }"
                          max-height="100"
                          :aspect-ratio="1 / 1"
                          :src="
                            item.MimeType == 'image/jpeg' ||
                            item.MimeType == 'image/png'
                              ? item.LocalUrl
                              : item.MimeType == 'video/mp4'
                              ? require('@/../public/img/video-thumb.png')
                              : require('@/../public/img/article-thumb.png')
                          "
                        >
                          <v-expand-transition>
                            <div
                              v-if="hover"
                              class="
                                  transition-fast-in-fast-out
                                  image-hover
                                  white--text
                                "
                              style="height: 100%"
                            >
                              <v-layout
                                row
                                wrap
                                fill-height
                                align-center
                                justify-center
                                ma-0
                              >
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on }">
                                    <v-btn
                                      class="ma-0"
                                      icon
                                      v-on="on"
                                      style="z-index: 100"
                                      @click="openFile(item)"
                                    >
                                      <v-icon color="white"
                                        >mdi-download</v-icon
                                      >
                                    </v-btn>
                                  </template>
                                  <span>{{ item.Path }}</span>
                                </v-tooltip>
                              </v-layout>
                            </div>
                          </v-expand-transition>
                        </v-img>
                      </v-hover>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-col>
            </v-banner>
            <v-banner
              rounded
              outlined
              class="mx-4 mb-8 text-center add-content-title"
              v-if="fileList.length == 0"
            >
              <!-- :label="$t('labels.dateHr')" -->

              {{ $t("labels.noFileHr") }}
            </v-banner>
            <!-- Inspected item files end -->
            <v-card-actions
              style="
                background-color: white;
                position: sticky;
                bottom: 0px;
                border-top: solid grey 2px;
              "
            >
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                type="submit"
                :loading="loadings.send"
                v-if="
                  !inspectedItem.IsAnswered && inspectedItem.Answer === null
                "
              >
                {{ $t("buttons.save") }}
              </v-btn>
              <v-btn
                color="primary"
                text
                @click="
                  questionAnswerDialog = false;
                  clearModal();
                "
              >
                {{ $t("buttons.close") }}
              </v-btn>
            </v-card-actions>
          </form>
        </ValidationObserver>
      </v-card>
    </v-dialog>

    <!-- Page content -->
    <v-card style="border-radius: 10px">
      <v-card-title style="color: #595959">
        <v-row>
          <v-col class="py-2">{{ $t("title.askHr") }}</v-col>
          <v-spacer />
        </v-row>
      </v-card-title>

      <v-divider />

      <v-card-text class="pt-4">
        <v-row align="center" justify="space-between">
          <v-col cols="3">
            <v-select
              v-model="groupPartQuestionType"
              :value="groupPartQuestionType"
              :items="questionTypes.concat({ Value: 'Tümü', Key: '' })"
              item-text="Value"
              item-value="Value"
              :label="$t('labels.questionType')"
              @change="getTableList()"
              required
            ></v-select>
          </v-col>

          <!-- Filter Operation By Status -->
          <v-col cols="3">
            <v-select
              v-model="questionStatus"
              :value="groupPartQuestionType"
              :items="questionStatusItem.concat({ Name: 'Tümü', Code: '' })"
              item-text="Name"
              item-value="Code"
              :label="$t('labels.questionStatus')"
              @change="getTableList()"
              required
            ></v-select>
          </v-col>
          <v-col cols="4"></v-col>
          <!-- Filter Operation By Status End -->
          <!-- Pagination select box start -->
          <v-col cols="2">
            <v-select
              v-model="itemsPerPage"
              :items="perPageValues"
              :label="$t('labels.selectItemsPerPage')"
              item-value="value"
              item-text="name"
              @change="changeItemsPerPage()"
            >
            </v-select>
          </v-col>
          <!-- Pagination select box end -->

          <v-col align="end"></v-col>
        </v-row>
        <v-data-table
          :headers="headers"
          :items="tableData"
          :loading="loadings.table"
          :options.sync="options"
          :hide-default-footer="true"
          :footer-props="{ disablePagination: true, disableItemsPerPage: true }"
          :no-data-text="$t('description.noList')"
          ref="surveyList"
        >
          <!-- eslint-disable -->
          <template v-slot:item.Name="{ item }">
            <div style="font-weight: bold !important">{{ item.Name }}</div>
          </template>
          <template v-slot:item.User="{ item }">
            {{ item.User.FirstName + " " + item.User.LastName }}
          </template>
          <template v-slot:item.Type="{ item }">
            {{ item.Type }}
          </template>

          <template v-slot:item.CreatedAt="{ item }">
            {{ convertToLocal(item.CreatedAt) }}
          </template>

          <template v-slot:item.Status="{ item }">
            <v-chip
              v-if="item.Status == 20"
              class="ma-2"
              small
              color="green"
              text-color="white"
            >
              {{ $t("labels.tooltipAnswered") }}
            </v-chip>

            <v-chip
              v-if="item.Status == 0"
              class="ma-2 text--center"
              small
              color="grey"
              text-color="white"
            >
              {{ $t("labels.tooltipNoAnswered") }}
            </v-chip>

            <v-chip
              v-if="item.Status == 10"
              class="ma-2 text--center"
              small
              color="orange"
              text-color="white"
            >
              {{ $t("labels.tooltipInReview") }}
            </v-chip>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  class="mr-2"
                  @click="questionExamine(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-archive-eye-outline
                </v-icon>
              </template>
              <span>{{ $t("labels.tooltipAnalyse") }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
        <div class="text-xs-center pt-2 md4 lg4">
          <v-pagination
            @input="paginationChangeHandler"
            :value="pagination.page"
            :length="pages"
            :total-visible="10"
            circle
          ></v-pagination>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import AskHrService from "../services/AskHrService";
import DeleteModal from "../components/General/DeleteModal";
import { mapActions } from "vuex";

export default {
  data: () => ({
    // List options
    options: {
      itemsPerPage: 15,
    },
    itemsPerPage: 15,
    perPageValues: [
      {
        name: "10",
        value: 10,
      },
      {
        name: "15",
        value: 15,
      },
      {
        name: "30",
        value: 30,
      },
      {
        name: "50",
        value: 50,
      },
      {
        name: "100",
        value: 100,
      },
      {
        name: "Tümü",
        value: 99999999,
      },
    ],
    pagination: {},
    top: 15,
    tableData: [],
    headers: [],
    //Loading states
    loadings: {
      table: false,
      select: false,
      send: false,
    },
    //Initial models
    questionTypes: [],
    question: "",
    questionAnswerDialog: false,
    questionAnswer: "",
    questionId: "",
    questionStatus: "",
    questionStatusItem: [
      {
        Name: "Cevaplandı",
        Code: "20",
      },
      {
        Name: "Cevaplanmadı",
        Code: "0",
      },
      {
        Name: "İncelemede",
        Code: "10",
      },
    ],
    fileList: [],
    // Main page Question type selector
    groupPartQuestionType: "",
    inspectedItem: null,
    inspectedItemCreatedAt: null,
  }),

  watch: {},
  mounted() {
    this.setup();
    this.getTableList();
    this.getQuestionTypes();
  },
  components: {
    DeleteModal,
  },
  computed: {
    totalRecords() {
      return this.items.length;
    },
    pageCount() {
      return this.totalRecords / this.itemsPerPage;
    },
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      ) {
        return 0;
      }

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
  },
  methods: {
    //Page setup
    setup() {
      this.headers = [
        {
          text: "Başlık",
          value: "Title",
          sortable: false,
        },
        {
          text: this.$t("title.tableHeaderFullNameAskHR"),
          value: "Owner.FullName",
          sortable: false,
          align: "center",
        },
        {
          text: this.$t("title.tableHeaderCreateDate"),
          value: "CreatedAt",
          sortable: false,
          align: "center",
        },

        {
          text: this.$t("title.tableHeaderStatus"),
          value: "Status",
          sortable: false,
          align: "center",
        },
        {
          text: this.$t("title.tableHeaderActions"),
          value: "actions",
          sortable: false,
          align: "center",
        },
        (this.pagination = {
          totalItems: 200,
          rowsPerPage: this.itemsPerPage,
          page: 1,
        }),
      ];
    },
    questionExamine(item) {
      console.log("Ex item > ", item);
      this.inspectedItemCreatedAt = convertToLocal(item.UpdatedAt);
      this.inspectedItem = item;
      this.question = item.Question;
      this.questionAnswer = item.Answer;
      this.questionId = item.Id;
      this.fileList = [...item.Files];
      this.questionAnswerDialog = true;
    },
    examineQuestion() {
      let body = {
        Id: this.inspectedItem.Id,
        Status: 10,
      };
      this.$store.state.loadingStatus = true;
      AskHrService.updateQuestionStatus(body)
        .then((res) => {
          console.log("Res data item > ", res.data);
          this.getTableList();
          this.questionAnswerDialog = false;
        })
        .catch((err) => {
          window.handleServiceError(err);
        })
        .then(() => {
          this.$store.state.loadingStatus = false;
        });
    },
    getQuestionTypes() {
      this.$store.state.loadingStatus = true;

      AskHrService.getQuestionsType()
        .then((res) => {
          this.questionTypes = [...res.data];
        })
        .catch((err) => {
          window.handleServiceError(err);
        })
        .then(() => {
          // this.loadings.table = false;
          this.$store.state.loadingStatus = false;
        });
    },
    openFile(item) {
      window.open(item.LocalUrl, "_blank");
    },
    getTableList() {
      this.$store.state.loadingStatus = true;
      this.tableData = [];
      console.log("this.question status : ", this.questionStatus);
      AskHrService.getAskHr(
        this.groupPartQuestionType,
        this.questionStatus,
        this.pagination.rowsPerPage,
        this.pagination.page
      )
        .then((res) => {
          this.tableData = res.data.Result;
          this.pagination.totalItems = res.data.TotalItems;
        })
        .catch((err) => {
          window.handleServiceError(err);
        })
        .then(() => {
          // this.loadings.table = false;
          this.$store.state.loadingStatus = false;
        });
    },
    clearModal() {
      //Reset all the flags, modals and references
      this.questionAnswer = "";
      this.$refs.observer.reset();
    },
    //Submits the form whether it is an create operation
    sendForm() {
      let body = {
        Id: this.questionId,
        Answer: this.questionAnswer,
      };
      console.log("Gidecek  : ", body);

      this.$store.state.loadingStatus = true;
      0;
      AskHrService.createAnswerHr(body)
        .then((res) => {
          this.getTableList();
          this.clearModal();
          this.questionAnswerDialog = false;
        })
        .catch((err) => {
          window.handleServiceError(err);
        })
        .then(() => {
          this.$store.state.loadingStatus = false;
        });
    },
    //Change displayed items per page, page number one is default
    changeItemsPerPage() {
      this.pagination.rowsPerPage = this.itemsPerPage;
      this.pagination.page = 1;
      this.options.itemsPerPage = this.itemsPerPage;
      this.getTableList();
    },
    //Change list pagination page
    paginationChangeHandler(pageNumber) {
      this.pagination.page = pageNumber;
      this.getTableList();
    },

    //Gets sidebar data dynamically
    ...mapActions(["getMenuData"]),

  },
};
</script>
