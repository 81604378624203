// Örnek servis codiagno login
import axios from "../javascript/custom-axios";
const RESOURCE_NAME = "/users";

export default {
  getUsers(search = "",pageSize=15,page = 1,type="") {

    //Users v2 filter operation

    // if (GroupIds.length != 0) {
    //   let headerString = "";
    //   GroupIds.forEach((element) => {
    //     if (element != "") {
    //       headerString = headerString + "&GroupIds=" + element;
    //     }
    //   });
    //   return axios.get(`${RESOURCE_NAME}?${headerString}`);
    // } else {
    //   return axios.get(`${RESOURCE_NAME}`);
    // }

    //End of filter operation
    return axios.get(`${RESOURCE_NAME}`, {params:{Search:search,IsLight:false,pageSize:pageSize,page:page,Type:type}});
  },
  getUsersByType(type = "",pageSize=15,page=1,search=""){
    return axios.get(`${RESOURCE_NAME}`, {params:{Type:type,IsLight:false,pageSize:pageSize,page:page,Search:search}});
  },
  getUserDetails(id) {
    return axios.get(`${RESOURCE_NAME}?Id=${id}`,{params:{IsLight:false}});
  },
  createUser(body) {
    return axios.post(`${RESOURCE_NAME}/create`, body);
  },
  searchUser(search = ""){
    return axios.get(`${RESOURCE_NAME}`, {params:{Search:search,IsLight:false}});
  },

  getCategories(search) {
    return axios.get(`${RESOURCE_NAME}`, {params:{Name:search}})
  },
  getBlockedUsers(search="",pageSize=15,page=1) {
    return axios.get(`admin/blockedusers`,{params:{Search:search,pageSize:pageSize,page:page}});
  },
  removeBlockedUser(body) {
    return axios.post(`admin/removeuserblock`,body);
  },
  updatePassword(body){
    return axios.post(`${RESOURCE_NAME}/changepassword`, body);

  }

};
