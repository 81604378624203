<template>
  <div>
    <!-- FoodMenu modal -->
    <v-dialog persistent v-model="foodMenuDetailStatus" max-width="700">
      <v-card v-if="!isDetail">
        <v-card-title class="headline indigo lighten-2">
          <div class="text-center white--text" style="width: 100%">
            {{ this.modalHeader }}
          </div>
        </v-card-title>
        <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
          <form @submit.prevent="handleSubmit(sendForm)">
            <v-card-text class="py-3">
              <v-row>
                <v-col md="12" xs="12">
                  <!-- File input start -->
                  <v-banner rounded outlined class="mt-5">
                    <ValidationProvider
                      name="Dosya"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <v-file-input
                        v-model="excelFile"
                        accept=".xlsx, .xls"
                        ref="fileupload"
                        :rules="[
                          (value) =>
                            !value ||
                            value.size < 10000000 ||
                            'Dosya en fazla 10 MB olmalı.',
                        ]"
                        :placeholder="$t('labels.selectExcelFile')"
                        prepend-icon="mdi-file"
                        :label="$t('labels.fileUpload')"
                        @change="changeFile"
                        @input="changeFile"
                        :error-messages="errors[0]"
                        show-size
                      ></v-file-input>
                    </ValidationProvider>
                  </v-banner>
                  <!-- File input end -->
                  <!-- Groups start  -->
                  <v-banner rounded outlined class="mt-5">
                    <v-row
                      class="mt-2"
                      v-for="(data, rowIndex) in groupsSelectArray"
                      :key="rowIndex"
                    >
                      <v-row>
                        <v-col
                          cols="4"
                          v-for="(element, colIndex) in data"
                          :key="colIndex"
                        >
                          <ValidationProvider
                            :vid="'attribute' + rowIndex + colIndex"
                            name="Grup"
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <div row wrap class="px-5 mt-3">
                              <v-select
                                v-model="groupArr[rowIndex][colIndex]"
                                :items="element.groups"
                                @change="
                                  changeGroupFilterModal(
                                    element,
                                    colIndex,
                                    rowIndex,
                                    data
                                  )
                                "
                                @click="
                                  clickGroupFilterModal(
                                    element,
                                    colIndex,
                                    rowIndex
                                  )
                                "
                                item-text="name"
                                item-value="id"
                                :label="
                                  element.name == 'Şirket'
                                    ? 'Kuruluş'
                                    : element.name
                                "
                                :error-messages="errors[0]"
                                dense
                              ></v-select>
                            </div>
                          </ValidationProvider>
                        </v-col>
                      </v-row>
                    </v-row>
                    <v-row>
                      <v-col cols="2">
                        <v-btn
                          v-if="!groupLengthAlert"
                          small
                          class="ma-1"
                          outlined
                          color="info"
                          @click="createNewGroup()"
                        >
                          {{ $t("buttons.addNew") }}
                        </v-btn>
                      </v-col>

                      <v-col class="mt-2">
                        <v-alert
                          v-model="groupLengthAlert"
                          border="left"
                          close-text="Close Alert"
                          type="info"
                          color="#7443a0"
                          dismissible
                          outlined
                          prominent
                          class="font-weight-black"
                          style="font-size: 1.2em"
                        >
                          {{ $t("description.groupLengthMessage") }}
                        </v-alert>
                      </v-col>
                    </v-row>
                  </v-banner>
                  <!-- Groups end -->
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-actions
              style="
                background-color: white;
                position: sticky;
                bottom: 0px;
                border-top: solid grey 2px;
              "
            >
              <v-spacer></v-spacer>
              <v-btn color="primary" type="submit" :loading="loadings.send">
                {{ $t("buttons.save") }}
              </v-btn>
              <v-btn
                color="primary"
                text
                @click="
                  foodMenuDetailStatus = false;
                  clearModal();
                "
              >
                {{ $t("buttons.close") }}
              </v-btn>
            </v-card-actions>
          </form>
        </ValidationObserver>
      </v-card>
      <v-card v-if="isDetail" style="overflow-x: hidden;">
        <v-card-title class="headline indigo lighten-2">
          <div class="text-center white--text" style="width: 100%">
            {{ this.modalHeader }}
          </div>
        </v-card-title>
        <v-row align="center" justify="center">
          <v-card class="mx-auto my-12" width="400">
            <v-img
              height="150"
              src="https://cdn.vuetifyjs.com/images/cards/cooking.png"
            ></v-img>

            <v-card-title
              v-for="(item, index) in detailedItem"
              :key="index"
              style="border-bottom: 1px solid #d9d9d9"
              class="py-1 px-3"
            >
              <div class="text-h6 font-weight-black" style="width: 100%">
                {{ item.Type }}
                <v-list subheader two-line>
                  <v-list-item
                    v-for="(element, i) in item.FoodKind"
                    :key="i"
                    style="min-height: 30px"
                  >
                    <v-list-item-content class="ma-0 pa-0">
                      <v-list-item-title
                        class="font-weight-regular"
                        v-text="element.Name"
                      ></v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-action class="ma-0 text-center">
                      <v-chip
                        class="ma-1 caption text-center"
                        color="pink"
                        style="min-width: 100px"
                        label
                        text-color="white"
                      >
                        {{
                          element.Calory + " - " + $t("labels.foodMenuCalory")
                        }}
                      </v-chip>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </div>
            </v-card-title>
          </v-card>
        </v-row>
        <v-card-actions
          style="
            background-color: white;
            position: sticky;
            bottom: 0px;
            border-top: solid grey 2px;
          "
        >
          <v-spacer></v-spacer>
          <v-btn
            color="#7986CB"
            dark
            depressed
            @click="
              foodMenuDetailStatus = false;
              isDetail = false;
            "
          >
            {{ $t("buttons.close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- FoodMenu deletion modal -->
    <delete-modal :yesFunc="deleteItem" ref="deleteModalRef" />
    <!-- Main page content -->
    <v-card style="border-radius: 10px">
      <v-card-title style="color: #595959">
        <v-row align="center">
          <v-col align="start" class="py-2">{{
            $t("title.titleFoodMenu")
          }}</v-col>
          <!-- Buttons for example excel download, multiple delete, create -->
          <v-col cols="6" align="end">
            <v-btn
              small
              class="ma-2 mr-4"
              elevation="0"
              outlined
              color="info"
              :href="url + '/admin/downloads/YemekMenuTest.xlsx'"
              download
            >
              {{ $t("buttons.sampleFile") }}
            </v-btn>
            <v-btn
              small
              class="ma-2 mr-4"
              elevation="0"
              outlined
              color="info"
              @click="openDeleteModal()"
              v-if="selectedFoodMenus.length >= 1"
            >
              {{ $t("buttons.deleteSelected") }}
            </v-btn>
            <v-btn
              small
              class="ma-2 mr-4"
              elevation="0"
              outlined
              color="info"
              @click="
                foodMenuDetailStatus = true;
                modalHeader = $t('title.foodMenuModalCreate');
              "
            >
              {{ $t("buttons.addNew") }}
            </v-btn>
          </v-col>
          <!-- End buttons -->
        </v-row>
      </v-card-title>

      <v-divider />

      <v-card-text class="pt-4">
        <v-row align="center" justify="space-between">
          <!-- Range date picker start -->
          <v-col cols="3">
            <v-menu
              ref="datePickerRef"
              v-model="datePickerModel"
              :close-on-content-click="false"
              :return-value.sync="date"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateRangeText"
                  :label="$t('labels.selectDate')"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="dates" locale="tr-TR" range>
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="
                    datePickerModel = false;
                    dates = [];
                  "
                >
                  {{ $t("buttons.cancel") }}
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="
                    $refs.datePickerRef.save(date);
                    getTableList();
                  "
                >
                  {{ $t("buttons.ok") }}
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <!-- Range date picker end -->
          <!-- Group select boxes start -->
          <v-col cols="2">
            <v-select
              v-model="selectedCorpGroup"
              :items="corpGroups[0]"
              :label="$t('labels.selectCorp')"
              item-text="name"
              item-value="id"
              @change="changeFilter()"
            ></v-select>
          </v-col>
          <v-col cols="2">
            <v-select
              v-model="selectedLocGroup"
              :items="locGroups[0]"
              :label="$t('labels.selectLoc')"
              item-text="name"
              item-value="id"
              @change="changeFilter()"
            ></v-select>
          </v-col>
          <v-col cols="2">
            <v-select
              v-model="selectedEmpGroup"
              :items="empGroups[0]"
              :label="$t('labels.selectEmpType')"
              item-text="name"
              item-value="id"
              @change="changeFilter()"
            ></v-select>
          </v-col>
          <!-- Group select boxes end -->
          <!-- Pagination select box start -->
          <v-col cols="2">
            <v-select
              v-model="itemsPerPage"
              :items="perPageValues"
              :label="$t('labels.selectItemsPerPage')"
              item-value="value"
              item-text="name"
              @change="changeItemsPerPage()"
            >
            </v-select>
          </v-col>
          <!-- Pagination select box end -->
        </v-row>
        <v-data-table
          v-model="selectedFoodMenus"
          item-key="Id"
          show-select
          :headers="headers"
          :items="tableData"
          :loading="loadings.table"
          :options.sync="options"
          :hide-default-footer="true"
          :footer-props="{ disablePagination: true, disableItemsPerPage: true }"
          :no-data-text="$t('description.noList')"
        >
          <!-- eslint-disable -->
          <template v-slot:item.Date="{ item }">
            {{ convertToLocal(item.Date) }} tarihli yemek menüsü
          </template>

          <template v-slot:item.CreatedAt="{ item }">
            {{ convertToLocal(item.CreatedAt) }}
          </template>

          <template v-slot:item.User="{ item }">
            {{ item.User.FirstName + " " + item.User.LastName }}
          </template>

          <template v-slot:item.actions="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  class="mr-2"
                  @click="getFoodMenuDetails(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-file-eye
                </v-icon>
              </template>
              <span>{{ $t("labels.tooltipEdit") }}</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  class="mr-2"
                  @click="openDeleteModal(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-delete
                </v-icon>
              </template>
              <span>{{ $t("labels.tooltipDelete") }}</span>
            </v-tooltip>
          </template>
          <!-- Commented due to status of STATUS column -->
          <!-- <template v-slot:item.Status="{ item }">
            <v-chip v-if="item.Status == 1" class="ma-2" small color="primary">
              {{ $t("labels.tooltipActive") }}
            </v-chip>
            <v-chip v-if="item.Status == 0" class="ma-2" small color="primary">
              {{ $t("labels.tooltipPassive") }}
            </v-chip>
          </template> -->
        </v-data-table>
        <!-- Page number div start -->
        <div class="text-xs-center pt-2 md4 lg4">
          <v-pagination
            @input="paginationChangeHandler"
            :value="pagination.page"
            :length="pages"
            :total-visible="10"
            circle
          ></v-pagination>
        </div>
        <!-- Page number div end -->
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import FoodMenuService from "../services/FoodMenuService";
import GroupService from "../services/GroupService";
import DeleteModal from "../components/General/DeleteModal";
import { mapActions } from "vuex";
export default {
  data: () => ({
    url: "",
    // For table listing
    options: {
      itemsPerPage: 15,
    },
    top: 15,
    skip: 0,
    tableData: [],
    headers: [],
    selectedFoodMenus: [],
    expanded: [],
    singleExpand: false,

    //For filtering
    corpGroups: [],
    locGroups: [],
    empGroups: [],
    selectedCorpGroup: "",
    selectedLocGroup: "",
    selectedEmpGroup: "",
    selectedGroupIds: [],

    //Datepicker models
    dates: [],
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    datePickerModel: false,

    //Loading states
    loadings: {
      table: false,
      send: false,
    },
    //Initial models
    foodMenuDetailStatus: false,
    foodMenuModel: {
      File: null,
      GroupIds: [],
    },
    detailedItem: [],
    isDetail: false,
    deleteItemModel: {},
    modalHeader: "", //Global header for opened modal
    // Group variables
    groupLengthAlert: false,
    groupsSelectArray: [],
    groupArr: [[]],
    groupData: [],
    //All group handler for not calling service over and over
    selecItems: [],
    // Excel file model for upload bug
    excelFile: [],
    //Pagination models
    pagination: {},
    itemsPerPage: 15,
    // perPageValues: [10, 15, 30, 50, 100,"Tümü"],
    perPageValues: [
      {
        name: "10",
        value: 10,
      },
      {
        name: "15",
        value: 15,
      },
      {
        name: "30",
        value: 30,
      },
      {
        name: "50",
        value: 50,
      },
      {
        name: "100",
        value: 100,
      },
      //    {
      //   name:"Tümü",
      //   value:99999999
      // }
    ],
  }),
  computed: {
    // Computes dates that is displaying
    // dateRangeText() {
    //   return this.dates.join(" - ");
    // },
    dateRangeText() {
      let arr = this.dates.map((date) => {
        return convertToLocal(date);
      })
      arr = arr.join(" - ");
      return arr;
    },
    totalRecords() {
      return this.items.length;
    },
    pageCount() {
      return this.totalRecords / this.itemsPerPage;
    },
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      ) {
        return 0;
      }

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
  },
  watch: {},
  mounted() {
    this.url = process.env.VUE_APP_API_URL;
    this.setup();
    this.getTableList();
    this.getGroupTypesModal();
  },
  components: {
    DeleteModal,
  },
  methods: {
    //Page setup
    setup() {
      this.modalHeader = this.$t("title.foodMenuModalCreate");
      this.headers = [
        {
          text: this.$t("title.tableHeaderFoodMenuName"),
          value: "Date",
          sortable: false,
        },
        {
          text: this.$t("title.tableHeaderFoodMenuCreator"),
          value: "User",
          sortable: false,
        },
        {
          text: this.$t("title.tableHeaderFoodMenuDate"),
          value: "CreatedAt",
          align: "center",
          sortable: false,
        },
        {
          text: this.$t("title.tableHeaderLikeCount"),
          value: "LikeCount",
          align: "center",
          sortable: false,
        },
        {
          text: this.$t("title.tableHeaderActions"),
          value: "actions",
          sortable: false,
        },
        (this.pagination = {
          totalItems: 200,
          rowsPerPage: this.itemsPerPage,
          page: 1,
        }),
      ];
      //Gets all group types
      this.getGroups("sirket");
      this.getGroups("lokasyon");
      this.getGroups("calisan_tipi");
    },
    //Clears all modal's states
    clearModal() {
      this.foodMenuModel = {
        File: null,
        GroupIds: [],
      };
      this.modalHeader = "";
      //Reset opened modals
      this.$refs.observer.reset();
      this.$refs.fileupload.reset();
      this.groupsSelectArray = [];
      //Must add a one line of groups into modal
      this.foodMenuModel.GroupIds = [];
      this.groupArr = [[]];
      this.groupArr.push([]);
      this.createNewGroup();
      this.selectedFoodMenus = [];
      this.detailedItem = [];
    },
    //Change list pagination page
    paginationChangeHandler(pageNumber) {
      this.pagination.page = pageNumber;
      this.getTableList();
    },

    //Fill's the module list
    getTableList() {
      //Adjust dates
      let startDate = this.dates[0];
      let endDate = this.dates[1];

      if (this.dates.length > 0 && startDate >= endDate) {
        window.showInfo(
          this.$t("description.invalidDatePick"),
          "standart",
          "error"
        );
        //If only groups selected, empty date picker
        this.dates = [];
        this.dateRangeText = "";
      } else {
        this.$store.state.loadingStatus = true;

        // this.loadings.table = true;
        this.tableData = [];
        FoodMenuService.getMenus(
          startDate,
          endDate,
          this.selectedGroupIds,
          this.pagination.rowsPerPage,
          this.pagination.page
        )
          .then((res) => {
            res.data.Data.forEach((element) => {
              if (element.DeletedAt == null) {
                this.tableData.push(element);
              } else {
              }
            });
            this.pagination.totalItems = res.data.TotalData;
          })
          .catch((err) => {
            window.handleServiceError(res, err);
          })
          .then(() => {
            // this.loadings.table = false;
            this.$store.state.loadingStatus = false;
          });
      }
    },

    //Submits the create operation
    sendForm() {
      //Get selected group Ids
      // for (let i = 0; i < this.groupArr.length; i++) {
      //   const element = this.groupArr[i];
      //   this.foodMenuModel.GroupIds[i] = new Array();
      //   for (let y = 0; y < element.length; y++) {
      //     if (element[y] == "" || element[y] == undefined) {
      //       switch (y) {
      //         case 0:
      //           this.corpGroups[0].forEach((element) => {
      //             if (element.id == "") {
      //             } else {
      //               this.foodMenuModel.GroupIds[i].push(element.id);
      //             }
      //           });
      //           continue;
      //         case 1:
      //           this.locGroups[0].forEach((element) => {
      //             if (element.id == "") {
      //             } else {
      //               this.foodMenuModel.GroupIds[i].push(element.id);
      //             }
      //           });
      //           continue;
      //         case 2:
      //           this.empGroups[0].forEach((element) => {
      //             if (element.id == "") {
      //             } else {
      //               this.foodMenuModel.GroupIds[i].push(element.id);
      //             }
      //           });
      //           continue;

      //         default:
      //           break;
      //       }
      //     }
      //     this.foodMenuModel.GroupIds[i].push(element[y]);
      //   }
      // }

      for (let i = 0; i < this.groupArr.length; i++) {
        const element = this.groupArr[i];
        this.foodMenuModel.GroupIds[i] = new Array();
        for (let y = 0; y < element.length; y++) {
          if (element[y] === "All") {
            this.groupsSelectArray[i][y].groups.forEach((element) => {
              this.foodMenuModel.GroupIds[i].push(element.id);
            });
          }

          this.foodMenuModel.GroupIds[i].push(element[y]);
        }

        this.foodMenuModel.GroupIds[i] = this.foodMenuModel.GroupIds[i].filter(
          (x) => x != "All"
        );
      }
      //Load excel as a form data and append selected group ids
      let formData = new FormData();
      formData.append("File", this.foodMenuModel.File);
      for (let index = 0; index < this.foodMenuModel.GroupIds.length; index++) {
        const element = this.foodMenuModel.GroupIds[index];
        element.forEach((item) => {
          formData.append(`GroupIds[${index}]`, item);
        });
      }

      this.loadings.send = true;
      FoodMenuService.createNewFoodMenuExcel(formData)
        .then((res) => {
          this.getTableList();
          this.foodMenuDetailStatus = false;
          this.clearModal();
        })
        .catch((err) => {
          window.handleServiceError(res, err);
        })
        .then(() => {
          this.loadings.send = false;
        });
    },
    //Delete operation
    deleteItem() {
      //Singular delete operation
      if (this.selectedFoodMenus.length < 1 && this.deleteItemModel) {
        let body = {
          ids: [],
        };
        body.ids.push(this.deleteItemModel.Id);
        this.$store.state.loadingStatus = true;

        // this.loadings.table = true;
        FoodMenuService.deleteMenus(body)
          .then(() => {
            this.getTableList();
            this.$refs.deleteModalRef.modalStatus = false;
          })
          .catch((err) => {
            window.handleServiceError(res, err);
          })
          .then(() => {
            // this.loadings.table = false;
            this.$store.state.loadingStatus = false;
          });
      }
      //Multiple delete operation
      else {
        let body = {
          ids: [],
        };
        this.selectedFoodMenus.forEach((element) => {
          body.ids.push(element.Id);
        });

        // this.loadings.table = true;
        this.$store.state.loadingStatus = true;

        FoodMenuService.deleteMenus(body)
          .then(() => {
            this.getTableList();
            this.$refs.deleteModalRef.modalStatus = false;
            this.getMenuData();
          })
          .catch((err) => {
            window.handleServiceError(res, err);
          })
          .then(() => {
            // this.loadings.table = false;
            this.$store.state.loadingStatus = false;
          });
      }
      this.selectedFoodMenus = [];
    },
    //Opens modal for delete operation
    openDeleteModal(item) {
      //If only one line selected or more selected do delete operation
      if (
        this.selectedFoodMenus.length > 1 ||
        this.selectedFoodMenus.length == 1 ||
        item
      ) {
        this.deleteItemModel = item;
        this.$refs.deleteModalRef.modalStatus = true;
      }
      //None of lines or item selected
      else {
        window.showInfo(
          this.$t("description.invalidMenuSelect"),
          "standart",
          "error"
        );
      }
    },
    //On page load gets all group data by group types
    getGroups(type) {
      GroupService.getGroups(type)
        .then((res) => {
          let arr = [];
          res.data.forEach((item) => {
            let obj = {
              id: item.Id,
              name: item.Name,
            };
            arr.push(obj);
            arr.unshift({ name: "Tümü", id: "" });
          });
          switch (type) {
            case "sirket":
              this.corpGroups.push(arr);
              break;
            case "lokasyon":
              this.locGroups.push(arr);
              break;
            case "calisan_tipi":
              this.empGroups.push(arr);
              break;
            default:
              break;
          }
        })
        .catch((err) => {
          window.handleServiceError(res, err);
        })
        .then(() => {});
    },
    //Changes filter by selected group ids
    changeFilter() {
      let startDate = this.dates[0];
      let endDate = this.dates[1];
      this.selectedGroupIds = [];
      this.selectedGroupIds.push(this.selectedCorpGroup);
      this.selectedGroupIds.push(this.selectedLocGroup);
      this.selectedGroupIds.push(this.selectedEmpGroup);

      //Clear empty group ids
      this.selectedGroupIds = this.selectedGroupIds.filter(function(el) {
        return el;
      });
      // this.loadings.table = true;
      this.$store.state.loadingStatus = true;

      this.tableData = [];
      FoodMenuService.getMenus(
        startDate,
        endDate,
        this.selectedGroupIds,
        this.pagination.rowsPerPage,
        this.pagination.page
      )
        .then((res) => {
          res.data.Data.forEach((element) => {
            if (element.DeletedAt == null) {
              this.tableData.push(element);
            } else {
            }
          });
          this.pagination.totalItems = res.data.TotalData;
        })
        .catch((err) => {
          window.handleServiceError(res, err);
        })
        .then(() => {
          this.$store.state.loadingStatus = false;
          // this.loadings.table = false;
        });
    },
    //Creates new group line
    createNewGroup() {
      if (this.groupsSelectArray.length < 4) {
        this.groupLengthAlert = false;
        let arr = [];
        this.groupData.forEach((element) => {
          let obj = {
            type: element.type,
            groups: [],
            name: element.name,
          };

          arr.push(obj);
        });

        this.groupsSelectArray = [...this.groupsSelectArray, arr];
        this.groupArr.push([]);
      } else {
        this.groupLengthAlert = true;
      }
    },
    //Logs changed group in group selectboxes
    changeGroupFilterModal(element, index, dataIndex, data) {},
    //Loads data on each group type when selectbox is clicked
    clickGroupFilterModal(element, colIndex, dataIndex) {
      //If that group data loaded before, do not call service again
      // let data = this.selecItems.filter((x) => x.Type == element.type);
      // if (data.length > 0) {
      //   this.groupsSelectArray[dataIndex][colIndex].groups = data[0].Data;
      // } else {
      //   GroupService.getGroups(element.type)
      //     .then((res) => {
      //       let arr = [];
      //       res.data.forEach((item) => {
      //         let obj = {
      //           id: item.Id,
      //           name: item.Name,
      //         };
      //         arr.push(obj);
      //       });
      //       arr.unshift({ name: "Tümü", id: "" });
      //       this.groupsSelectArray[dataIndex][colIndex].groups = arr;
      //       let listObj = {
      //         Type: element.type,
      //         Data: arr,
      //       };
      //       this.selecItems.push(listObj);
      //     })
      //     .catch((err) => {
      //       window.showError(err);
      //     })
      //     .then(() => {});
      // }

      let data = this.selecItems.filter((x) => x.Type == element.type);
      // if (data.length > 0) {
      //   this.groupsSelectArray[dataIndex][colIndex].groups = data[0].Data;
      // } else {
      if (
        element.type == "lokasyon" &&
        this.groupArr[dataIndex][colIndex - 1] == undefined
      ) {
        this.groupsSelectArray[dataIndex][colIndex].groups = [...[]];
      }
      if (
        (element.type == "lokasyon" &&
          this.groupArr[dataIndex][colIndex - 1] != undefined) ||
        element.type == "calisan_tipi" ||
        element.type == "sirket"
      ) {
        GroupService.getGroups(
          element.type,
          element.type == "lokasyon"
            ? this.groupArr[dataIndex][colIndex - 1]
            : ""
        )
          .then((res) => {
            let arr = [];
            res.data.forEach((item) => {
              let obj = {
                id: item.Id,
                name: item.Name,
              };
              arr.push(obj);
            });
            if (
              element.type != "sirket" ||
              this.$store.getters.getPermissionIds
            )
              arr.unshift({ name: "Tümü", id: "All" });
            this.groupsSelectArray[dataIndex][colIndex].groups = arr;
            let listObj = {
              Type: element.type,
              Data: arr,
            };
            this.selecItems.push(listObj);
          })
          .catch((err) => {
            window.handleServiceError(res, err);
          })
          .then(() => {});
      }
    },
    //Fills all checkboxes group type on page load
    getGroupTypesModal() {
      GroupService.getGroupTypes()
        .then((res) => {
          res.data.forEach((element) => {
            let obj = {
              type: element.Code,
              groups: [],
              name: element.Name,
            };

            this.groupData.push(obj);
          });
          this.groupsSelectArray.push(this.groupData);
        })
        .catch((err) => {
          window.handleServiceError(res, err);
        });
    },
    //When input file is changed and inputted this function calls
    changeFile(file) {
      if (file != null) {
        if (file.size > 10000000) {
          alert("Lütfen 10 MB dan az dosya yükleyiniz.");
          this.$refs.fileupload.value = null;
        } else {
          this.foodMenuModel.File = file;
        }
      }
    },
    getFoodMenuDetails(item) {
      this.detailedItem = [];
      item.Food.forEach((element) => {
        this.detailedItem.push(element);
      });
      this.modalHeader = this.$t("title.foodMenuModalDetail");
      this.isDetail = true;
      this.foodMenuDetailStatus = true;
    },
    //Change displayed items per page, page number one is default
    changeItemsPerPage() {
      this.pagination.rowsPerPage = this.itemsPerPage;
      this.pagination.page = 1;
      this.options.itemsPerPage = this.itemsPerPage;
      this.getTableList();
    },
    //Calls sidebar dynamically
    ...mapActions(["getMenuData"]),
  },
};
</script>
