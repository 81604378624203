<template>
  <v-footer app bottom fixed padless>
    <v-col
      class="text-center"
      cols="12"
    >
      Powered by — <strong class="purple--text darken-1">Humanious</strong>
    </v-col>
  </v-footer>
</template>

<script>
export default {
  name: "BottomNavMenu",
  components:{
  }
};
</script>