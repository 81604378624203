// Örnek servis codiagno login
import axios from "../javascript/custom-axios";
const RESOURCE_NAME = "/admin";

export default {
  //   getProfile(id) {
  //     return axios.get(`${RESOURCE_NAME}/Id`, { params: { id: id } });
  //   },
  getStatistics(id) {
    return axios.get(`${RESOURCE_NAME}/statisticsQuery`);
  },
  getReport(key, startDate = "", endDate = "", type = "", pageSize = 15, page = 1, otherData = []) {
    var params = `/statistics?Key=${key}&Type=${type}&StartDate=${startDate}&EndDate=${endDate}&PageSize=${pageSize}&Page=${page}`;
    if (otherData.length > 0) {
      otherData.forEach(item => {
        params += `&${item.Key}=${item.Value}`;
      })
    }

    return axios.get(
      `${RESOURCE_NAME}${params}`
    );
  },
  //   updateMyProfile(body) {
  //     return axios.put(`${RESOURCE_NAME}/profile`, body);
  //   },
  //   createCurrentSellerAddress(body) {
  //     return axios.post(`/selleraddress`, body);
  //   },
};
