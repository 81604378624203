<template>
  <div>
    <!-- Page content -->
    <v-card style="border-radius: 10px">
      <v-card-title style="color: #595959">
        <v-row>
          <v-col class="py-2">{{ 'Hoşgeldiniz ' + this.$store.state.FullName}}</v-col>
        </v-row>
      </v-card-title>

      <v-divider />

      <v-card-text class="pt-4">
      </v-card-text>
    </v-card>
  </div>
</template>

<script>

export default {
  data: () => ({
   
  }),
  watch: {},
  mounted() {

  },
  components: {

  },
  methods: {

  },
};
</script>
