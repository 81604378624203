<template>
  <div style="width: 100%; height: 100%;" class="#F0F0F1 ">
    <NavigationDrawer />
    <NavMenu />
    <Container />
    <BottomNavMenu></BottomNavMenu>
  </div>
</template>

<script>
import NavMenu from "../components/NavMenu";
import NavigationDrawer from "../components/NavigationDrawer";
import Container from "../components/Container";
import BottomNavMenu from "../components/BottomNavMenu.vue";
export default {
  components: {
    NavMenu,
    NavigationDrawer,
    Container,
    BottomNavMenu,
  },
};
</script>
