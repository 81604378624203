<template>
  <div>
    <!--EndorsementTopic creation and edit modal -->
    <!-- It will be open when user clicks edit or add new button -->
    <v-dialog v-model="endorsementTopicDetailStatus" max-width="550" persistent>
      <v-card>
        <v-card-title class="headline indigo lighten-2">
          <div class="text-center white--text" style="width: 100%">
            {{ this.endorsementTopicHeader }}
          </div>
        </v-card-title>

        <v-container>
          <!-- In creation phase , picture can be seen only if it's uploaded -->
          <v-row justify="center" v-if="localUploadedImageUrl">
            <v-col cols="1">
              <v-icon
                v-show="endorsementTopicModel.Image"
                @click="clearImage()"
              >
                mdi-close-circle
              </v-icon>
            </v-col>
            <v-col align-content="center" cols="12">
              <v-row justify="center">
                <v-img
                  position="center"
                  max-height="100"
                  contain
                  :src="localUploadedImageUrl"
                >
                </v-img>
              </v-row>
            </v-col>
            <!-- The button which is right hand side of the picture  -->
          </v-row>
        </v-container>
        <!-- Text form to fill EndorsementTopic details  -->
        <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
          <form @submit.prevent="handleSubmit(sendForm)">
            <v-card-text class="py-3">
              <v-row justify="center">
                <input
                  type="file"
                  accept="image/*"
                  ref="fileInput"
                  style="display: none"
                  @change="selectImageHandle"
                  v-if="!localUploadedImageUrl"
                />
                <v-col md="12" xs="12">
                  <div v-if="!localUploadedImageUrl">
                    <ValidationProvider
                      name="Görsel"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <!-- Since the Image is selected from the local machine , it's selected as readonly textfield -->
                      <v-text-field
                        class="ma-2"
                        readonly
                        @click="browseFile()"
                        v-model="localUploadedImageName"
                        :value="localUploadedImageName"
                        :label="$t('labels.image')"
                        :error-messages="errors[0]"
                        append-icon="mdi-upload"
                        append-outer-icon="mdi-close"
                        @click:append-outer="clearImage()"
                        @click:append="browseFile()"
                      ></v-text-field>
                    </ValidationProvider>
                  </div>
                  <ValidationProvider
                    name="Başlık"
                    rules="required|max:50"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      class="ma-2"
                      v-model="endorsementTopicModel.Title"
                      :value="endorsementTopicModel.Title"
                      :label="$t('labels.header')"
                      :error-messages="errors[0]"
                      :counter="15"
                    ></v-text-field>
                  </ValidationProvider>

                  <ValidationProvider
                    name="Açıklama"
                    rules="required|max:250"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      class="ma-2"
                      :counter="250"
                      v-model="endorsementTopicModel.Description"
                      :value="endorsementTopicModel.Description"
                      :label="$t('labels.description')"
                      :error-messages="errors[0]"
                    ></v-text-field>
                  </ValidationProvider>

                  <ValidationProvider
                    name="Sıralama"
                    rules="required|integer"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      class="ma-2"
                      v-model="endorsementTopicModel.SortOrder"
                      :value="endorsementTopicModel.SortOrder"
                      :label="$t('labels.sortOrder')"
                      :error-messages="errors[0]"
                      type="number"
                    ></v-text-field>
                  </ValidationProvider>
                  <ValidationProvider
                    name="Type"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      class="ma-2"
                      v-model="endorsementTopicModel.Type"
                      :value="endorsementTopicModel.Type"
                      :label="$t('labels.type')"
                      :error-messages="errors[0]"
                      :disabled="endorsementTopicModel.Id != null"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-actions
              style="
                background-color: white;
                position: sticky;
                bottom: 0px;
                border-top: solid grey 2px;
              "
            >
              <v-spacer></v-spacer>
              <!-- Save button to sendForm to the server in order to create a new modal or edit existing modal -->
              <v-btn color="primary" type="submit" :loading="loadings.send">
                {{ $t("buttons.save") }}
              </v-btn>
              <!-- Dialog modal can be closed by using that button but the information you have entered will be lost -->
              <v-btn
                color="primary"
                text
                @click="
                  endorsementTopicDetailStatus = false;
                  clearEndorsementTopic();
                "
              >
                {{ $t("buttons.close") }}
              </v-btn>
            </v-card-actions>
          </form>
        </ValidationObserver>
      </v-card>
    </v-dialog>
    <!-- EndorsementTopic Deletion modal -->
    <delete-modal :yesFunc="deleteItem" ref="deleteModalRef" />
    <!-- EndorsementTopic Page Content -->
    <v-card style="border-radius: 10px">
      <v-card-title style="color: #595959">
        <v-row>
          <v-col class="py-2">{{ $t("title.endorsement") }}</v-col>
          <v-spacer />
          <v-btn
            small
            class="ma-2 mr-4"
            elevation="0"
            outlined
            color="info"
            @click="
              endorsementTopicDetailStatus = true;
              endorsementTopicHeader = $t('title.endorsementTopicModalCreate');
            "
          >
            {{ $t("buttons.addNew") }}
          </v-btn>
        </v-row>
      </v-card-title>

      <v-divider />
      <!-- Modal Create button above the table  -->
      <v-card-text class="pt-4">
        <!-- Tha main table to the screen to show EndorsementTopic attributes -->
        <v-data-table
          :headers="headers"
          :items="tableData"
          :loading="loadings.table"
          :options.sync="options"
          :footer-props="{ 'items-per-page-options': [5, 10, 15, 30] }"
          :no-data-text="$t('description.noList')"
        >
          <!-- eslint-disable -->
          <template v-slot:item.Title="{ item }">
            <div style="font-weight: bold !important;">{{ item.Title }}</div>
          </template>
          <!-- Edit Icon to make customization on the endorsement modals -->
          <template v-slot:item.actions="{ item }">
            <v-icon
              small
              class="mr-2"
              @click="getEndorsementTopicDetails(item)"
            >
              mdi-pencil</v-icon
            >
            <!-- Delete Icon to delete existing endorsement modal from the table -->
            <v-icon small @click="openDeleteEndorsementTopic(item)">
              mdi-delete</v-icon
            ></template
          >
          <!-- Since the EndorsementTopicModal has an Image , the table has changed -->
          <!-- to image from instead of text to show icon -->
          <template v-slot:item.ImgUrl="{ item }">
            <v-avatar size="40" class="my-2">
              <v-img
                :lazy-src="item.ImgUrl"
                max-height="40"
                max-width="40"
                :src="item.ImgUrl"
              ></v-img>
            </v-avatar>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import DeleteModal from "../components/General/DeleteModal";
import EndorsementTopicService from "../services/EndorsementTopicService";
import FileService from "../services/FileService";

export default {
  data: () => ({
    // Table content management data
    // It's used to determine how many item will be shown in table
    options: { itemsPerPage: 15 },
    // To fill table from the api when page is loaded
    tableData: [],
    //  Table headers array will be fill by using the setup() method when page is loaded
    headers: [],

    top: 15,
    skip: 0,

    // Loading states to show an animation to the user while data is not loaded yet
    loadings: {
      table: false,
      send: false,
    },

    // Inital models for endorsementTopic .
    endorsementTopicDetailStatus: false,
    // Main modal to store attributes of the endorsement item
    endorsementTopicModel: {
      Title: "",
      Description: "",
      Image: "",
      SortOrder: "",
      Type: "",
      Id: "",
      ImgUrl: "",
      ImgName: "",
    },
    // It's used to delete selected model
    deleteItemModel: {},
    // When user has uploaded an image , the details of it will be stored in this variable
    localUploadedImageName: "",
    localUploadedImageUrl: "",
    // The headers of the object will be stored here
    endorsementTopicHeader: "",
    // Image
    selectedFile: "",
  }),
  watch: {},
  // The methods that are initialized when page is loaded
  mounted() {
    this.setup();
    this.getTableList();
  },
  // DeleteModal is imported  from another part of the app
  components: { DeleteModal },
  methods: {
    // setup() is used to fill headers of the table and the title's of the page.
    setup() {
      this.endorsementTopicHeader = this.$t(
        "title.endorsementTopicModalCreate"
      );
      this.headers = [
        {
          text: this.$t("title.tableHeaderEndorsementTopicImage"),
          value: "ImgUrl",
          sortable: false,
        },

        {
          text: this.$t("title.tableHeaderEndorsementTopicHeader"),
          value: "Title",
          sortable: false,
        },
        {
          text: this.$t("title.tableHeaderEndorsementTopicDescription"),
          value: "Description",
          sortable: false,
        },
        {
          text: this.$t("title.tableHeaderEndorsementTopicSortOrder"),
          value: "SortOrder",
          sortable: true,
        },
        {
          text: this.$t("title.tableHeaderEndorsementTopicType"),
          value: "Type",
          sortable: false,
        },
        {
          text: this.$t("labels.actions"),
          value: "actions",
          sortable: false,
        },
      ];
    },
    // When the dialog is closed the image part will be deleted .
    clearImage() {
      this.endorsementTopicModel.Image = "";
      this.localUploadedImageUrl = null;
      this.localUploadedImageName = null;
    },
    // It's going to  open local storage of the computer to choose an image
    browseFile() {
      this.$refs.fileInput.value = "";
      this.$refs.fileInput.click();
    },
    // When user clicks an image from to the computer , input tag will be affected by using this method.
    selectImageHandle(e) {
      this.uploadImage(e.target.files[0]);
    },
    // When user clicks to the upload button on the dialog page ,
    // the image will be uploaded by using this method.
    uploadImage(media) {
      // Since the api requires formData object it's created here.
      let formData = new FormData();
      formData.append("Files", media);
      formData.append("Type", "media");
      FileService.uploadFile(formData)
        .then((res) => {
          // To show image in the page URL is assigned to the src's of the v-img tag.
          this.localUploadedImageUrl = res.data[0].LocalUrl;
          // Name of the image
          this.localUploadedImageName = media.name;
          // The image id which is responded from the database.
          this.endorsementTopicModel.Image = res.data[0].Id;
        })

        .catch((err) => {
          window.handleServiceError(res, err);
        });
    },
    // If user clicks close button , the textfields will be cleaned.
    clearEndorsementTopic() {
      this.endorsementTopicModel = {
        Title: "",
        Description: "",
        Image: "",
        SortOrder: "",
        Type: "",
        Id: null,
        ImageUrl: "",
        ImgName: "",
      };
      this.endorsementTopicHeader = "";
      this.$refs.observer.reset();
      this.selectedFile = "";
      this.localUploadedImageUrl = null;
      this.localUploadedImageName = null;
      this.clearImage();
    },
    // When user clicks edit button , the details of the item will be stored in the textfield's

    getEndorsementTopicDetails(item) {
      console.log("item : ", item);
      this.endorsementTopicHeader = this.$t(
        "title.endorsementTopicModalUpdate"
      );

      this.endorsementTopicModel = {
        Title: item.Title,
        Description: item.Description,
        Image: item.Image,
        SortOrder: item.SortOrder,
        Type: item.Type,
        Id: item.Id,
        ImgUrl: item.ImgUrl,
        ImgName: item.ImgName,
      };
      // To show image
      this.localUploadedImageUrl = item.ImgUrl;
      this.localUploadedImageName = item.ImgName;

      this.endorsementTopicDetailStatus = true;
    },
    // The data of the table will be filled by using this method when the page has loaded.
    getTableList() {
      this.loadings.table = true;
      EndorsementTopicService.getEndorsementTopics()
        .then((res) => {
          this.tableData = res.data;
        })
        .catch((err) => {
          window.handleServiceError(res, err);
        })
        .then(() => {
          this.loadings.table = false;
        });
    },
    // When user clicks save button , the post method will be activated to send our data to the user
    // A new item will be created if is not exists.

    sendForm() {
      // If Id exists , it should be an update operation
      if (this.endorsementTopicModel.Id) {
        this.updateEndorsementTopic();
      } else {
        this.createEndorsementTopic();
      }
    },
    //Create operation
    createEndorsementTopic() {
      this.loadings.send = true;
      EndorsementTopicService.createEndorsementTopic(this.endorsementTopicModel)
        .then((res) => {
          this.getTableList();
          this.endorsementTopicDetailStatus = false;
          this.clearEndorsementTopic();
        })
        .catch((err) => {
          window.handleServiceError(res, err);
        })
        .then(() => {
          this.loadings.send = false;
        });
    },
    // Update Operation
    updateEndorsementTopic() {
      this.loadings.table = true;
      // Since Type property is not allowed in the UPDATE operation it'is removed
      // from body before POST  operation.

      const { Type, ...updateModel } = this.endorsementTopicModel;

      EndorsementTopicService.updateEndorsementTopic(updateModel)
        .then((res) => {
          this.getTableList();
          this.endorsementTopicDetailStatus = false;
          this.clearEndorsementTopic();
        })
        .catch((err) => {
          window.handleServiceError(res, err);
        })
        .then(() => {
          this.loadings.table = false;
        });
    },
    // Delete Operation
    deleteItem() {
      this.loadings.table = true;
      EndorsementTopicService.deleteEndorsementTopic(this.deleteItemModel.Id)
        .then(() => {
          this.getTableList();
          this.$refs.deleteModalRef.modalStatus = false;
        })
        .catch((err) => {
          window.handleServiceError(res, err);
        })
        .then(() => {
          this.loadings.table = false;
        });
    },
    // Sub method to use in delete operation
    openDeleteEndorsementTopic(item) {
      this.deleteItemModel = item;
      this.$refs.deleteModalRef.modalStatus = true;
    },
  },
};
</script>
