var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"width":"100%","height":"100%","background":"radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(192,171,210,1) 29%, rgba(116,67,160,1) 100%)"},attrs:{"aspect-ratio":"2"}},[_c('v-layout',{attrs:{"wrap":"","align-center":"","justify-center":"","fill-height":""}},[_c('v-container',[_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.ForgotPassword),callback:function ($$v) {_vm.ForgotPassword=$$v},expression:"ForgotPassword"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline font-weight-medium text-center orange--text"},[_vm._v(" "+_vm._s(_vm.$t("description.forgotPassword"))+" ")]),_c('v-divider'),_c('v-card-text',[_c('v-container',[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.sendOneStep.apply(null, arguments)}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('labels.email'),"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('labels.sellerCode'),"error-messages":errors[0]},model:{value:(_vm.forgot.email),callback:function ($$v) {_vm.$set(_vm.forgot, "email", $$v)},expression:"forgot.email"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('labels.bayiEmail'),"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('labels.bayiEmail'),"error-messages":errors[0]},model:{value:(_vm.forgot.bayiEmail),callback:function ($$v) {_vm.$set(_vm.forgot, "bayiEmail", $$v)},expression:"forgot.bayiEmail"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-row',[_c('v-spacer'),_c('v-btn',{staticClass:"white--text mr-3",attrs:{"color":"blue darken-1","loading":_vm.loadings.editedButton,"disabled":invalid},on:{"click":function($event){return _vm.ForgotPasswordSend()}}},[_vm._v(" "+_vm._s(_vm.$t("buttons.send"))+" ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","dark":""},on:{"click":function($event){_vm.ForgotPassword = false}}},[_vm._v(" "+_vm._s(_vm.$t("buttons.cancel"))+" ")])],1)],1)],1)],1)]}}])})],1)],1),_c('v-card-actions',{staticClass:"pb-4 pr-5"})],1)],1),_c('v-layout',{attrs:{"wrap":"","align-center":"","justify-center":"","fill-height":""}},[_c('v-card',{staticStyle:{"border-radius":"11px"},attrs:{"max-width":"900","min-width":"550"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pa-0",staticStyle:{"text-align":"center"},attrs:{"md":"4","xs":"12"}}),_c('v-col',{staticClass:"px-3 pb-5",attrs:{"md":"12","xs":"12"}},[_c('div',{staticStyle:{"margin":"40px 0px 60px 0px"}},[_c('v-img',{staticStyle:{"margin-left":"auto","margin-right":"auto"},attrs:{"max-height":"70","src":require('@/../public/img/BSP-Banner-Transparent.png'),"contain":""}})],1),_c('div',{staticStyle:{"max-width":"300px","margin":"30px auto 20px auto"}},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.sendLogin)}}},[_c('ValidationProvider',{attrs:{"name":"Sicil Numarası","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('labels.sicilNumber'),"error-messages":errors[0]},model:{value:(_vm.Username),callback:function ($$v) {_vm.Username=$$v},expression:"Username"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Şifre","rules":"required|min:4|max:24"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('labels.password'),"error-messages":errors[0],"counter":24,"append-icon":_vm.showPassword ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.showPassword ? 'text' : 'password'},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.Password),callback:function ($$v) {_vm.Password=$$v},expression:"Password"}})]}}],null,true)}),_c('v-layout',{staticStyle:{"margin-top":"40px"},attrs:{"justify-center":"","column":"","wrap":"","align-center":""}},[(_vm.remainingLogin > 0)?_c('v-row',[_c('p',{staticClass:"font-weight-black",staticStyle:{"color":"#7443a0 !important"}},[_vm._v(" "+_vm._s(_vm.remainingLogin)+" "+_vm._s(_vm.$t("labels.userRemainingLogin"))+" ")])]):_vm._e(),(_vm.remainingLogin == 0)?_c('v-row',[_c('p',{staticClass:"font-weight-black",staticStyle:{"color":"#7443a0 !important"}},[_vm._v(" "+_vm._s(_vm.$t("labels.userBlocked"))+" ")])]):_vm._e(),_c('v-row',[_c('v-btn',{staticClass:"purple darken-1 px-5",staticStyle:{"width":"160px"},attrs:{"elevation":"0","rounded":"","dark":"","large":"","type":"submit","loading":_vm.pageLoading}},[_vm._v(" "+_vm._s(_vm.$t("buttons.login"))+" ")])],1)],1)],1)]}}])})],1)])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }