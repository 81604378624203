var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"persistent":"","max-width":"400"},model:{value:(_vm.modulDetailStatus),callback:function ($$v) {_vm.modulDetailStatus=$$v},expression:"modulDetailStatus"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline indigo lighten-2"},[_c('div',{staticClass:"text-center white--text",staticStyle:{"width":"100%"}},[_vm._v(" "+_vm._s(this.modalHeader)+" ")])]),_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.sendForm)}}},[_c('v-card-text',{staticClass:"py-3"},[_c('v-row',[_c('v-col',{attrs:{"md":"12","xs":"12"}},[_c('v-checkbox',{attrs:{"label":_vm.$t('labels.modulIsStatic')},model:{value:(_vm.isStatic),callback:function ($$v) {_vm.isStatic=$$v},expression:"isStatic"}}),_c('ValidationProvider',{attrs:{"name":"İsim","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"value":_vm.moduleModel.Name,"label":_vm.$t('labels.modulName'),"error-messages":errors[0]},model:{value:(_vm.moduleModel.Name),callback:function ($$v) {_vm.$set(_vm.moduleModel, "Name", $$v)},expression:"moduleModel.Name"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Yol Dizini","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"value":_vm.moduleModel.Route,"label":_vm.$t('labels.modulRoutue'),"error-messages":errors[0]},model:{value:(_vm.moduleModel.Route),callback:function ($$v) {_vm.$set(_vm.moduleModel, "Route", $$v)},expression:"moduleModel.Route"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Anahtar","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"disabled":!!_vm.editItemId,"value":_vm.moduleModel.Key,"label":_vm.$t('labels.modulKey'),"error-messages":errors[0]},on:{"keypress":function($event){return _vm.controlKeyChar($event)}},model:{value:(_vm.moduleModel.Key),callback:function ($$v) {_vm.$set(_vm.moduleModel, "Key", $$v)},expression:"moduleModel.Key"}})]}}],null,true)}),_c('v-autocomplete',{staticClass:"mt-2",attrs:{"items":_vm.iconItems,"clearable":"","item-text":"text","item-value":"value","label":"Icon"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-3 mb-1",attrs:{"color":"grey darken-2"}},[_vm._v(_vm._s(item.value))]),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.text)}})],1)]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-avatar',{staticClass:"text-h5 font-weight-light white--text",attrs:{"color":"grey darken-2"}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(_vm._s(item.value))])],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.text)}})],1)]}}],null,true),model:{value:(_vm.moduleModel.Icon),callback:function ($$v) {_vm.$set(_vm.moduleModel, "Icon", $$v)},expression:"moduleModel.Icon"}}),_c('v-textarea',{attrs:{"outlined":"","value":_vm.moduleModel.LongDescription,"label":_vm.$t('labels.modulDescription')},model:{value:(_vm.moduleModel.LongDescription),callback:function ($$v) {_vm.$set(_vm.moduleModel, "LongDescription", $$v)},expression:"moduleModel.LongDescription"}})],1)],1)],1),_c('v-card-actions',{staticStyle:{"background-color":"white","position":"sticky","bottom":"0px","border-top":"solid grey 2px"}},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","type":"submit","loading":_vm.loadings.send}},[_vm._v(" "+_vm._s(_vm.$t("buttons.save"))+" ")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.modulDetailStatus = false;
                _vm.clearModal();}}},[_vm._v(" "+_vm._s(_vm.$t("buttons.close"))+" ")])],1)],1)]}}])})],1)],1),_c('delete-modal',{ref:"deleteModalRef",attrs:{"yesFunc":_vm.deleteItem}}),_c('v-card',{staticStyle:{"border-radius":"10px"}},[_c('v-card-title',{staticStyle:{"color":"#595959"}},[_c('v-row',[_c('v-col',{staticClass:"py-2"},[_vm._v(_vm._s(_vm.$t("title.modulManagement")))]),_c('v-spacer'),_c('v-btn',{staticClass:"ma-2 mr-4",attrs:{"small":"","elevation":"0","outlined":"","color":"info"},on:{"click":function($event){_vm.modulDetailStatus = true;
            _vm.modalHeader = _vm.$t('title.moduleModalCreate');}}},[_vm._v(" "+_vm._s(_vm.$t("buttons.addNew"))+" ")])],1)],1),_c('v-divider'),_c('v-card-text',{staticClass:"pt-4"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.tableData,"loading":_vm.loadings.table,"options":_vm.options,"footer-props":{ 'items-per-page-options': [5, 10, 15, 30] },"no-data-text":_vm.$t('description.noList')},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.CreatedAt",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.convertToLocal(item.CreatedAt))+" ")]}},{key:"item.Icon",fn:function(ref){
            var item = ref.item;
return [_c('v-icon',[_vm._v(_vm._s(item.Icon))])]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.routeToCustom(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-star ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("labels.tooltipCustomFiels")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.getModuleDetails(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("labels.tooltipEdit")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.openDeleteModal(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("labels.tooltipDelete")))])])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }