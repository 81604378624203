<template>
  <div>
    <!-- Prohibited Word modal -->
    <v-dialog
      persistent
      v-model="prohibitedWordDetailStatus"
      max-width="500"
      ref="prohibitedWordPopupModal"
    >
      <v-card v-if="!isDetails">
        <v-card-title class="headline indigo lighten-2">
          <div class="text-center white--text" style="width: 100%">
            {{ this.modalHeader }}
          </div>
        </v-card-title>
        <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
          <form @submit.prevent="handleSubmit(sendForm)">
            <v-card-text class="pt-10 px-10">
              <!-- Division select start -->
              <!-- <v-banner rounded outlined class="mt-5">
                <v-card-title class="add-content-title">
                  {{ $t("labels.postDivision") }}
                </v-card-title>
                <v-select
                  :items="divisionItems.concat({ Name: 'Tümü', Id: -1 })"
                  item-text="Name"
                  item-value="Id"
                  v-model="selectedDivision"
                  dense
                ></v-select>
              </v-banner> -->
              <!-- Division select end -->
              <ValidationProvider
                name="Kelime İçeriği"
                rules="required"
                v-slot="{ errors }"
              >
                <v-text-field
                  v-model="prohibitedWordModel.Word"
                  :value="prohibitedWordModel.Word"
                  :label="$t('labels.prohibitedWord')"
                  :error-messages="errors[0]"
                  @keypress.enter.prevent
                  @keydown.space.prevent
                  v-on:keypress="isLetter($event)"
                ></v-text-field>
              </ValidationProvider>
              <v-card-text class="py-3"> </v-card-text>
            </v-card-text>
            <v-card-actions
              style="
                background-color: white;
                position: sticky;
                bottom: 0px;
                border-top: solid grey 2px;
              "
            >
              <v-spacer></v-spacer>
              <v-btn color="primary" type="submit" :loading="loadings.send">
                {{ $t("buttons.save") }}
              </v-btn>
              <v-btn
                color="primary"
                text
                @click="
                  prohibitedWordDetailStatus = false;
                  clearModal();
                "
              >
                {{ $t("buttons.close") }}
              </v-btn>
            </v-card-actions>
          </form>
        </ValidationObserver>
      </v-card>
      <v-card v-if="isDetails">
        <v-card-title class="headline indigo lighten-2">
          <div class="text-center white--text" style="width: 100%">
            {{ this.modalHeader }}
          </div>
        </v-card-title>
        <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
          <form @submit.prevent="handleSubmit(sendForm)">
            <v-card-text class="pt-10 px-10">
              <ValidationProvider
                name="Kelime İçeriği"
                rules="required"
                v-slot="{ errors }"
              >
                <v-text-field
                  v-model="inspectedModel.Word"
                  :value="inspectedModel.Word"
                  :label="$t('labels.prohibitedWord')"
                  :error-messages="errors[0]"
                  @keypress.enter.prevent
                  @keydown.space.prevent
                  v-on:keypress="isLetter($event)"
                ></v-text-field>
              </ValidationProvider>
            </v-card-text>
            <v-card-actions
              style="
                background-color: white;
                position: sticky;
                bottom: 0px;
                border-top: solid grey 2px;
              "
            >
              <v-spacer></v-spacer>
              <v-btn color="primary" type="submit" :loading="loadings.send">
                {{ $t("buttons.save") }}
              </v-btn>
              <v-btn
                color="primary"
                text
                @click="
                  prohibitedWordDetailStatus = false;
                  clearModal();
                "
              >
                {{ $t("buttons.close") }}
              </v-btn>
            </v-card-actions>
          </form>
        </ValidationObserver>
      </v-card>
    </v-dialog>
    <!-- Prohibited Word deletion modal -->
    <delete-modal :yesFunc="deleteItem" ref="deleteModalRef" />
    <!-- Page content -->
    <v-card style="border-radius: 10px">
      <v-card-title style="color: #595959">
        <v-row>
          <v-col class="py-2">{{ $t("title.prohibitedWords") }}</v-col>
          <v-spacer />
          <v-btn
            small
            class="ma-2 mr-4"
            elevation="0"
            outlined
            color="info"
            @click="
              prohibitedWordDetailStatus = true;
              isDetails = false;
              modalHeader = $t('title.createProhibitedWord');
            "
          >
            {{ $t("buttons.addNew") }}
          </v-btn>
        </v-row>
      </v-card-title>

      <v-divider />

      <v-card-text class="pt-4">
        <v-row align="center" justify="space-between">
          <!-- <v-col cols="3">
            <v-select
              v-model="groupPartSurveyType"
              :value="groupPartSurveyType"
              :items="surveyTypes.concat({ Name: 'Tümü', Code: '' })"
              item-text="Name"
              item-value="Code"
              :label="$t('labels.surveyType')"
              @change="filterSurveyByType()"
              required
            ></v-select>
          </v-col> -->

          <v-col cols="2">
            <v-select
              v-model="itemsPerPage"
              :items="perPageValues"
              :label="$t('labels.selectItemsPerPage')"
              item-value="value"
              item-text="name"
              @change="changeItemsPerPage()"
            >
            </v-select>
          </v-col>
        </v-row>

        <v-data-table
          :headers="headers"
          :items="tableData"
          :loading="loadings.table"
          :options.sync="options"
          :hide-default-footer="true"
          :footer-props="{
            disablePagination: true,
            disableItemsPerPage: true,
          }"
          :no-data-text="$t('description.noList')"
          ref="wordList"
        >
          <!-- eslint-disable -->
          <template v-slot:item.Name="{ item }">
            <div style="font-weight: bold !important">{{ item.Name }}</div>
          </template>
          <template v-slot:item.User="{ item }">
            <!-- {{ item.User.FirstName + " " + item.User.LastName }} -->
            {{ getFullName(item) }}
          </template>

          <template v-slot:item.AnswerCount="{ item }">
            {{ item.AnswerCount == 0 ? "Cevaplanmadı" : item.AnswerCount }}
          </template>

          <template v-slot:item.CreatedAt="{ item }">
            {{ convertToLocal(item.CreatedAt) }}
          </template>

          <template v-slot:item.UpdatedAt="{ item }">
            {{ item.UpdatedAt ? convertToLocal(item.UpdatedAt) : "-" }}
          </template>

          <!-- eslint-disable -->
          <template v-slot:item.actions="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  class="mr-2"
                  @click="getWordDetails(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-pencil
                </v-icon>
              </template>
              <span>{{ $t("labels.tooltipEdit") }}</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  class="mr-2"
                  @click="openDeleteModal(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-delete
                </v-icon>
              </template>
              <span>{{ $t("labels.tooltipDelete") }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
        <div class="text-xs-center pt-2 md4 lg4">
          <v-pagination
            @input="paginationChangeHandler"
            :value="pagination.page"
            :length="pages"
            :total-visible="10"
            circle
          ></v-pagination>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import ProhibitedWordService from "../services/ProhibitedWordService";
import DeleteModal from "../components/General/DeleteModal";
import GroupService from "../services/GroupService";
import { mapActions } from "vuex";
import { VEmojiPicker } from "v-emoji-picker";
export default {
  data: () => ({
    // List options
    options: {
      itemsPerPage: 15,
    },
    top: 15,
    skip: 0,
    tableData: [],
    headers: [],
    //Loading states
    loadings: {
      table: false,
      select: false,
      send: false,
    },
    //Initial models
    questionRules: [(v) => v.length <= 150 || "Maksimum 150 karakter giriniz."],
    answerRules: [(v) => v.length <= 50 || "Maksimum 50 karakter giriniz."],
    prohibitedWordDetailStatus: false,
    // To add new model
    prohibitedWordModel: {
      Word: "",
    },
    // To inspect a model
    inspectedModel: {
      Word: "",
      AddedUser: "",
      CreatedAt: "",
      UpdatedAt: "",
    },
    deleteItemModel: {},
    isDetails: false,
    editItemId: null, //Inspected item ID
    modalHeader: "", //Global header for opened modal

    //Division select modals
    selectedDivision: null,
    divisionItems: [],

    pagination: {},
    itemsPerPage: 15,
    perPageValues: [
      {
        name: "10",
        value: 10,
      },
      {
        name: "15",
        value: 15,
      },
      {
        name: "30",
        value: 30,
      },
      {
        name: "50",
        value: 50,
      },
      {
        name: "100",
        value: 100,
      },
    ],
  }),

  watch: {},
  mounted() {
    this.setup();
    this.getTableList();
  },
  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      ) {
        return 0;
      }

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
    totalRecords() {
      return this.items.length;
    },
    pageCount() {
      return this.totalRecords / this.itemsPerPage;
    },
  },
  components: {
    DeleteModal,
    VEmojiPicker,
  },

  methods: {
    getDivisions() {
      GroupService.getDivisions()
        .then((res) => {
          this.divisionItems = res.data;
          this.divisionItems.unshift({ Id: -1, Name: "Tümü" });
        })
        .catch((err) => {
          window.showError(err);
        })
        .then(() => {});
    },
    changeItemsPerPage() {
      this.pagination.rowsPerPage = this.itemsPerPage;
      this.pagination.page = 1;
      this.options.itemsPerPage = this.itemsPerPage;
      this.getTableList();
    },
    paginationChangeHandler(pageNumber) {
      this.pagination.page = pageNumber;
      this.rowsPerPage = this.itemsPerPage;
      this.getTableList();
    },

    //Page setup
    setup() {
      this.modalHeader = this.$t("title.moduleModalCreate");
      this.headers = [
        {
          text: this.$t("title.tableHeaderProhibitedWord"),
          value: "Word",
          sortable: false,
        },
        {
          text: this.$t("title.tableHeaderProhibitedWordCreator"),
          value: "AddedUser",
          sortable: false,
          align: "center",
        },
        {
          text: this.$t("title.tableHeaderCreateDate"),
          value: "CreatedAt",
          sortable: false,
          align: "center",
        },
        {
          text: this.$t("title.tableHeaderEndDate"),
          value: "UpdatedAt",
          sortable: false,
          align: "center",
        },
        {
          text: this.$t("title.tableHeaderActions"),
          value: "actions",
          sortable: false,
        },
        (this.pagination = {
          totalItems: 200,
          rowsPerPage: this.itemsPerPage,
          page: 1,
        }),
      ];
      this.pagination = {
        totalItems: 100,
        rowsPerPage: 15,
        page: 1,
      };

      // this.fillGroups();
      this.getDivisions();
      this.selectedDivision = -1;
    },

    getFullName(item) {
      if (!!item.User) {
        return item.User.FirstName + " " + item.User.LastName;
      } else {
        return "-";
      }
    },

    //Fill's the word list
    getTableList() {
      this.tableData = [];
      this.$store.state.loadingStatus = true;

      ProhibitedWordService.getBadWords(
        this.pagination.rowsPerPage,
        this.pagination.page
      )
        .then((res) => {
          console.log("Table data > ", this.tableData);
          this.tableData = res.data.Result;
          this.pagination.totalItems = res.data.TotalItems;
        })
        .catch((err) => {
          window.handleServiceError(err);
        })
        .then(() => {
          this.$store.state.loadingStatus = false;
        });
    },
    //Clears all modal's states and item ID
    clearModal() {
      this.prohibitedWordModel = {
        Word: "",
      };
      this.inspectedModel = {
        Word: "",
        AddedUser: "",
        CreatedAt: "",
        UpdatedAt: "",
      };
      //Reset all the flags, modals and references
      this.modalHeader = "";
      this.$refs.observer.reset();
      this.createdModelGroupIds = {};
      this.editItemId = null;

      this.selectedDivision = -1;
    },

    //Submits the form whether it is an update operation or create operation
    sendForm() {
      if (this.editItemId) {
        this.updateWord();
      } else {
        this.createWord();
      }
    },
    //Create operation
    createWord() {
      this.$store.state.loadingStatus = true;
      this.prohibitedWordDetailStatus = false;
      ProhibitedWordService.addProhibitedWord(this.prohibitedWordModel)
        .then((res) => {
          this.getTableList();
          this.clearModal();
        })
        .catch((err) => {
          window.handleServiceError(res, err);
        })
        .then(() => {
          // this.loadings.table = false;
          this.$store.state.loadingStatus = false;
        });
    },
    //Inspect an item in list
    getWordDetails(item) {
      this.modalHeader = this.$t("title.prohibitedWordDetails");
      this.editItemId = item.Word;
      //Use item data to fill other parts of detail modal
      this.inspectedModel = {
        Word: item.Word,
        AddedUser: item.AddedUser,
        CreatedAt: item.CreatedAt,
        UpdatedAt: item.UpdatedAt,
      };

      this.isDetails = true;
      this.prohibitedWordDetailStatus = true;
    },

    //Update operation
    updateWord() {
      this.$store.state.loadingStatus = true;
      this.prohibitedWordDetailStatus = false;
      let body = {
        UpdatedWord: this.inspectedModel.Word,
        Word: this.editItemId,
      };
      console.log("body=>", body);
      ProhibitedWordService.updateProhibitedWord(body)
        .then((res) => {
          this.getTableList();
          this.clearModal();
        })
        .catch((err) => {
          window.handleServiceError(res, err);
        })
        .then(() => {
          // this.loadings.table = false;
          this.$store.state.loadingStatus = false;
        });
    },
    //Opens modal for deletion operation
    openDeleteModal(item) {
      this.deleteItemModel = item;
      this.$refs.deleteModalRef.modalStatus = true;
    },
    //Delete operation
    deleteItem() {
      // this.loadings.table = true;
      this.$refs.deleteModalRef.modalStatus = false;
      this.$store.state.loadingStatus = true;
      console.log("deleted item >> ", this.deleteItemModel);
      ProhibitedWordService.deleteWord(this.deleteItemModel.Word)
        .then(() => {
          this.getTableList();
        })
        .catch((err) => {
          window.handleServiceError(res, err);
        })
        .then(() => {
          // this.loadings.table = false;
          this.$store.state.loadingStatus = false;
        });
    },

    //Gets sidebar data dynamically
    ...mapActions(["getMenuData"]),
  },
};
</script>
