// Örnek servis codiagno login
import axios from "../javascript/custom-axios";
const RESOURCE_NAME = "/foodmenu";

export default {
  getMenus(
    startDate = "",
    endDate = "",
    GroupIds = [],
    pageSize = 15,
    page = 1
  ) {
     if(pageSize=="Tümü"){
       pageSize=" ";
     }

    if (GroupIds.length < 1) {
      return axios.get(
        `/admin/foodmenu?StartDate=${startDate}&EndDate=${endDate}&PageSize=${pageSize}&Page=${page}`
      );
    }
    let tempString = "";
    GroupIds.forEach((element) => {
      tempString += `&GroupIds=${element}`;
    });
    return axios.get(
      `/admin/foodmenu?StartDate=${startDate}&EndDate=${endDate}${tempString}&PageSize=${pageSize}&Page=${page}`
    );
  },
  //Axios specifically looks for word > "data"
  deleteMenus(data) {
    return axios.delete(`${RESOURCE_NAME}/delete/menus`, { data });
  },
  createNewFoodMenuExcel(formData) {
    return axios.post(`${RESOURCE_NAME}/excel`, formData, {
      contentType: "multipart/form-data",
    });
  },
};
