<template>
  <div>
    <!-- Content status modal -->
    <delete-modal
      :yesFunc="selectedContentFunc"
      :description="this.modalDescription"
      :title="this.modalTitle"
      ref="contentModalRef"
    />
    <!-- Page content -->
    <v-card style="border-radius: 10px">
      <v-card-title style="color: #595959">
        <v-row>
          <v-col class="py-2">{{ $t("title.suspensefulContents") }}</v-col>
        </v-row>
      </v-card-title>

      <v-divider />

      <v-card-text class="pt-4">
        <v-row align="center" justify="start">
          <v-col cols="3">
            <v-select
              v-model="selectedContentType"
              :value="selectedContentType"
              :items="contentTypes.concat({ Name: 'Tümü', Code: '' })"
              item-text="Name"
              item-value="Code"
              :label="$t('labels.contentType')"
              @change="filterContent()"
              required
            ></v-select>
          </v-col>
          <v-col cols="3">
            <v-select
              v-model="selectedContentStatus"
              :value="selectedContentStatus"
              :items="contentStatusTypes.concat({ Name: 'Tümü', Value: '' })"
              item-text="Name"
              item-value="Value"
              :label="$t('labels.contentStatus')"
              @change="filterContent()"
              required
            ></v-select>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="2">
            <v-select
              v-model="itemsPerPage"
              :items="perPageValues"
              :label="$t('labels.selectItemsPerPage')"
              item-value="value"
              item-text="name"
              @change="changeItemsPerPage()"
            >
            </v-select>
          </v-col>
        </v-row>

        <v-data-table
          :headers="headers"
          :items="tableData"
          :loading="loadings.table"
          :options.sync="options"
          :hide-default-footer="true"
          :footer-props="{
            disablePagination: true,
            disableItemsPerPage: true,
          }"
          :no-data-text="$t('description.noList')"
          ref="surveyList"
        >
          <!-- eslint-disable -->
          <template v-slot:item.Content="{ item }">
            {{ strippedContent(item.Content) }}
          </template>
          <!-- eslint-disable -->
          <template v-slot:item.ContentOwner="{ item }">
            {{ getFullName(item) }}
          </template>

          <template v-slot:item.CreatedAt="{ item }">
            {{ convertToLocal(item) }}
          </template>

          <template v-slot:item.Reason="{ item }">
            {{
              item.Reason == "0" ? "Otomatik Algılama" : "Kullanıcı Şikayeti"
            }}
          </template>

          <template v-slot:item.Status="{ item }">
            <v-chip
              v-if="item.Status == 20"
              class="ma-2"
              small
              color="green"
              text-color="white"
            >
              {{ $t("labels.tooltipPublished") }}
            </v-chip>

            <v-chip
              v-if="item.Status == 0"
              class="ma-2 text--center"
              small
              color="grey"
              text-color="white"
            >
              {{ $t("labels.tooltipDraft") }}
            </v-chip>

            <v-chip
              v-if="item.Status == -10"
              class="ma-2"
              small
              color="#F44336"
              text-color="white"
            >
              {{ $t("labels.tooltipReject") }}
            </v-chip>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  class="mr-2"
                  @click="decideContentStatus(1, item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-checkbox-marked-circle-outline
                </v-icon>
              </template>
              <span>{{ $t("labels.tooltipAccept") }}</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  class="mr-2"
                  @click="decideContentStatus(0, item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-cancel
                </v-icon>
              </template>
              <span>{{ $t("labels.tooltipDeny") }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
        <div class="text-xs-center pt-2 md4 lg4">
          <v-pagination
            @input="paginationChangeHandler"
            :value="pagination.page"
            :length="pages"
            :total-visible="10"
            circle
          ></v-pagination>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import SuspensefulContentService from "../services/SuspensefulContentService";
import DeleteModal from "../components/General/DeleteModal";
import GroupService from "../services/GroupService";
import { mapActions } from "vuex";
import { VEmojiPicker } from "v-emoji-picker";
export default {
  data: () => ({
    // List options
    options: {
      itemsPerPage: 15,
    },
    top: 15,
    skip: 0,
    tableData: [],
    headers: [],
    //Loading states
    loadings: {
      table: false,
      select: false,
      send: false,
    },
    //Initial models
    questionRules: [(v) => v.length <= 150 || "Maksimum 150 karakter giriniz."],
    answerRules: [(v) => v.length <= 50 || "Maksimum 50 karakter giriniz."],
    suspensefulContentDetailStatus: false,
    // To add new model
    prohibitedWordModel: {},
    // To inspect a model
    inspectedModel: {},
    selectedContentModel: {},
    isDetails: false,
    editItemId: null, //Inspected item ID
    modalHeader: "", //Global header for opened modal
    modalDescription: "",
    modalTitle: "",
    selectedStatus: -1,

    selectedContentType: "",
    selectedContentStatus: "",
    contentTypes: [],
    contentStatusTypes: [],

    //Division select modals
    selectedDivision: null,
    divisionItems: [],

    pagination: {},
    itemsPerPage: 15,
    perPageValues: [
      {
        name: "10",
        value: 10,
      },
      {
        name: "15",
        value: 15,
      },
      {
        name: "30",
        value: 30,
      },
      {
        name: "50",
        value: 50,
      },
      {
        name: "100",
        value: 100,
      },
    ],
  }),

  watch: {},
  mounted() {
    this.setup();
    this.getTableList();
  },
  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      ) {
        return 0;
      }

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
    totalRecords() {
      return this.items.length;
    },
    pageCount() {
      return this.totalRecords / this.itemsPerPage;
    },
  },
  components: {
    DeleteModal,
    VEmojiPicker,
  },

  methods: {
    getDivisions() {
      GroupService.getDivisions()
        .then((res) => {
          this.divisionItems = res.data;
          this.divisionItems.unshift({ Id: -1, Name: "Tümü" });
        })
        .catch((err) => {
          window.showError(err);
        })
        .then(() => {});
    },
    changeItemsPerPage() {
      this.pagination.rowsPerPage = this.itemsPerPage;
      this.pagination.page = 1;
      this.options.itemsPerPage = this.itemsPerPage;
      this.getTableList();
    },
    paginationChangeHandler(pageNumber) {
      this.pagination.page = pageNumber;
      this.rowsPerPage = this.itemsPerPage;
      this.getTableList();
    },

    //Page setup
    setup() {
      this.modalHeader = this.$t("title.moduleModalCreate");
      this.surveyQuestionIndex = 1;
      this.answerBoundIsReached = false;
      this.headers = [
        {
          text: this.$t("title.tableHeaderSuspensefulContent"),
          value: "Content",
          sortable: false,
        },
        {
          text: this.$t("title.tableHeaderContentOwner"),
          value: "ContentOwner",
          sortable: false,
          align: "center",
        },
        {
          text: this.$t("title.tableHeaderContentType"),
          value: "Type",
          sortable: false,
          align: "center",
        },
        {
          text: this.$t("title.tableHeaderContentReason"),
          value: "Reason",
          sortable: false,
          align: "center",
        },

        {
          text: this.$t("title.tableHeaderStatus"),
          value: "Status",
          sortable: false,
          align: "center",
        },
        {
          text: this.$t("title.tableHeaderActions"),
          value: "actions",
          sortable: false,
        },
        (this.pagination = {
          totalItems: 200,
          rowsPerPage: this.itemsPerPage,
          page: 1,
        }),
      ];

      this.pagination = {
        totalItems: 100,
        rowsPerPage: 15,
        page: 1,
      };

      this.contentTypes = [
        { Name: "Gönderi", Code: "0" },
        { Name: "İlan", Code: "2" },
        { Name: "Yorum", Code: "1" },
      ];
      this.contentStatusTypes = [
        {
          Name: "Yayında",
          Code: "Published",
          Value: 20,
        },
        {
          Name: "Red",
          Code: "Rejected",
          Value: -10,
        },
        {
          Name: "Beklemede",
          Code: "Pending",
          Value: 0,
        },
      ];

      // this.fillGroups();
      this.getDivisions();
      this.selectedDivision = -1;
    },

    getFullName(item) {
      if (!!item.ContentOwner) {
        return item.ContentOwner.FirstName + " " + item.ContentOwner.LastName;
      } else {
        return "-";
      }
    },

    //Fill's the content list
    getTableList() {
      this.tableData = [];
      this.$store.state.loadingStatus = true;

      SuspensefulContentService.getBadContents(
        this.pagination.rowsPerPage,
        this.pagination.page,
        this.selectedContentType,
        this.selectedContentStatus
      )
        .then((res) => {
          this.tableData = res.data.Result;
          console.log("Table data > ", this.tableData);
          this.pagination.totalItems = res.data.TotalItems;
        })
        .catch((err) => {
          window.handleServiceError(err);
        })
        .then(() => {
          this.$store.state.loadingStatus = false;
        });
    },
    //Clears all modal's states and item ID
    clearModal() {
      this.prohibitedWordModel = {};
      this.inspectedModel = {};
      //Reset all the flags, modals and references
      this.modalHeader = "";
      this.$refs.observer.reset();
      this.createdModelGroupIds = {};
      this.editItemId = null;

      this.selectedDivision = -1;
    },

    //Handle content
    decideContentStatus(status, item) {
      if (status === 0) {
        this.modalTitle = this.$t("description.contentDenyTitle");
        this.modalDescription = this.$t("description.deleteModalDescription");
        this.selectedStatus = 0;
      } else if (status === 1) {
        this.modalTitle = this.$t("description.contentApproveTitle");
        this.modalDescription = this.$t("description.contentModalDescription");
        this.selectedStatus = 1;
      }
      this.openDeleteModal(item);
    },
    //Opens modal for deletion operation
    openDeleteModal(item) {
      this.selectedContentModel = item;
      this.$refs.contentModalRef.modalStatus = true;
    },
    //Delete operation
    selectedContentFunc() {
      this.$store.state.loadingStatus = true;
      this.$refs.contentModalRef.modalStatus = false;
      if (this.selectedStatus === 1) {
        let body = {
          Id: this.selectedContentModel.Id,
        };
        SuspensefulContentService.approveContent(body)
          .then(() => {
            this.getTableList();
          })
          .catch((err) => {
            window.handleServiceError(res, err);
          })
          .then(() => {
            this.$store.state.loadingStatus = false;
          });
      } else if (this.selectedStatus === 0) {
        SuspensefulContentService.denyContent(
          this.selectedContentModel.Type,
          this.selectedContentModel.Id
        )
          .then(() => {
            this.getTableList();
          })
          .catch((err) => {
            window.handleServiceError(res, err);
          })
          .then(() => {
            this.$store.state.loadingStatus = false;
          });
      }
    },

    filterContent() {
      this.$store.state.loadingStatus = true;

      this.tableData = [];

      this.pagination.rowsPerPage = 15;
      this.itemsPerPage = 15;
      this.pagination.page = 1;
      this.getTableList();
    },
    //Gets sidebar data dynamically
    ...mapActions(["getMenuData"]),
  },
};
</script>
