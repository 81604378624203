//import fields from '../attributes/tr' // validasyon için tanımlı fieldları genel dil dosyasına ekleme
export default {
  /* Buttons */
  "buttons.login": "GİRİŞ",
  "buttons.forgotPass": "Şifremi Unuttum",
  "buttons.close": "KAPAT",
  "buttons.select": "SEÇ",
  "buttons.back": "GERİ",
  "buttons.resume": "DEVAM",
  "buttons.assign": "ATAMA YAP",
  "buttons.send": "GÖNDER",
  "buttons.update": "GÜNCELLE",
  "buttons.cancel": "İPTAL",
  "buttons.profile": "Profil",
  "buttons.logout": "Çıkış Yap",
  "buttons.yes": "EVET",
  "buttons.no": "HAYIR",
  "buttons.ok": "TAMAM",
  "buttons.submit": "Göster",
  "buttons.clearFilter": "FİLTRELERİ TEMİZLE",
  "buttons.excelExport": "EXCEL OLARAK İNDİR",
  "buttons.download": "İNDİR",
  "buttons.clear": "TEMİZLE",
  "buttons.save": "KAYDET",
  "buttons.inReviewOrder": "İncelemeye Al",
  "buttons.publish": "Yayımla",
  "buttons.saveAsDraft": "Taslak Olarak Kaydet",
  "buttons.uploadNew": "YENİ YÜKLE",
  "buttons.uploadfromLibrary": "KÜTÜPHANEDEN EKLE",
  "buttons.addNew": "YENİ EKLE",
  "buttons.addNewGroup": "YENİ GRUP EKLE",
  "buttons.makeAdmin": "ADMİN YAP",
  "buttons.removeAdminPermissions": "Adminden Çıkar",
  "buttons.userPermissions": "YETKİLENDİR",
  "buttons.userDetails": "DETAY",
  "buttons.deleteSelected": "SEÇİLENLERİ SİL",
  "buttons.deleteSelectedCategory": "SİL",
  "buttons.sampleFile": "ÖRNEK DOSYAYI İNDİR",
  "buttons.surveyAddAnswer": "YENİ ŞIK EKLE",
  "buttons.allPermissionSelect": "TÜMÜNÜ SEÇ",
  "buttons.allPermissionDeselect": "TÜMÜNÜ KALDIR",

  /* Labels */
  "labels.email": "E-Mail",
  "labels.sicilNumber": "Sicil Numarası",
  "labels.password": "Parola",
  "labels.search": "Arama",
  "labels.groups": "Gruplar",
  "labels.nameSurname": "Ad Soyad",
  "labels.userName": "Kullanıcı Adı",
  "labels.sellerCode": "Satıcı Kodu",
  "labels.bayiEmail": "Bayi E-mail",
  "labels.modulName": "Modül İsmi",
  "labels.modulRoutue": "Modül Yol Dizini",
  "labels.modulIsStatic": "Statik bir modül yarat.",
  "labels.modulKey": "Modül Key",
  "labels.modulDescription": "Modül Açıklaması",
  "labels.groupType": "Grup Tipi",
  "labels.groupName": "Grup İsmi",
  "labels.allGroupTypes": "Tümü",
  "labels.userBlocked": "Kullanıcınız Bloklanmıştır.",
  "labels.userRemainingLogin": "Hakkınız Kaldı.",
  "labels.userUsername": "Kullanıcı Adı (Sicil)",
  "labels.userPassword": "Şifre",
  "labels.userFirstName": "İsim",
  "labels.userLastname": "Soyisim",
  "labels.userEmail": "E-posta",
  "labels.userPhone": "Telefon",
  "labels.userStatus": "Kullanıcı Durumu",
  "labels.userType": "Kullanıcı Tipi",
  "labels.userAbout": "Kullanıcı Hakkında",
  "labels.header": "Başlık",
  "labels.chooseKeyFirst": "Birincil Yap",
  "labels.deleteKey": "Sil",
  "labels.category": "Kategori",
  "labels.tags": "Etiketler",
  "labels.tags.desc": "Yeni bir etiket eklemek için enter tuşuna basınız.",
  "labels.categoryNameTR": "Kategori Türkçe İsmi",
  "labels.categoryNameEN": "Kategori İngilizce İsmi",
  "labels.mainPicture": "Ana Resim",
  "labels.max5": "Maksimum 5 Adet",
  "labels.max15": "Maksimum 15 Adet",
  "labels.userSearch": "İsim",
  "labels.tooltipCustomFiels": "Özel Alana Git",
  "labels.tooltipEdit": "Düzenle",
  "labels.tooltipDocumentAssign": "Döküman Ata",
  "labels.tooltipDocumentAssignUpdate": "Döküman Atamasını Güncelle",
  "labels.tooltipDeny": "Reddet",
  "labels.tooltipAccept": "Onayla",
  "labels.tooltipAnswer": "Cevapla",
  "labels.tooltipAnalyse": "İncele",
  "labels.tooltipAnswered": "Cevaplandı",
  "labels.tooltipDelete": "Sil",
  "labels.tooltipDeleteAssignment": "Atamayı Sil",
  "labels.surveyType": "Anket Tipi",
  "labels.documentType": "Döküman Tipi",
  "labels.assignmentType": "Atama Tipi",
  "labels.documentTypeAssigned": "Atanmış Dökümanlar",
  "labels.documentTypeUnassigned": "Atanmamış Dökümanlar",
  "labels.documentTypePrivate": "Gizli Dökümanlar",
  "labels.documentTypePublic": "Görünür Dökümanlar",
  "labels.contentType": "İçerik Tipi",
  "labels.contentStatus": "İçerik Durumu",
  "labels.questionType": "Soru Tipi",
  "labels.questionStatus": "Soru Durumu",
  "labels.makeAdmin": "Admin Yap",
  "labels.authorizeUser": "Yetkilendir",
  "labels.unblockUser": "Bloğu Kaldır",
  "labels.fileUpload": "Dosya veya Medya Ekle",
  "labels.fileUploadCompulsory": "Dosya veya Medya Ekle (Zorunlu)",
  "labels.storyFileUpload": "Hikaye Görseli Ekle",
  "labels.storyFileUploadDescription":
    "En fazla 50 MB, dikey formatta, önerilen çözünürlük 900x1200",
  "labels.fileUploadDescription":
    "En fazla 20 MB, 4:3 oranında, önerilen çözünürlük 1200x900 olmalıdır.",
  "labels.photoUpload": "Fotoğraf Ekle (Zorunlu)",
  "labels.storyPhotoUpload": "İkon Fotoğrafı Ekle (Zorunlu)",
  "labels.storyphotoUploadDescription":
    "En fazla 20 MB, kare formatta, önerilen çözünürlük 900x900 olmalıdır.",
  "labels.photoUploadDescription":
    "En fazla 1 MB, 4:3 oranında, önerilen çözünürlük 1200x900 olmalıdır.",
  "labels.photoUploadNotifications": "Fotoğraf Yükle",
  "labels.price": "Fiyat",
  "labels.description": "Açıklama",
  "labels.contact": "İletişim",
  "labels.image": "Görsel",
  "labels.sortOrder": "Sıralama",
  "labels.actions": "İşlem",
  "labels.type": "Anahtar Kelime",
  "labels.postType": "İçerik Tipi",
  "labels.content": "İçerik",
  "labels.postCategory": "Kategori",
  "labels.postCategoryRequired":
    " (Her ana kategoriden bir tane seçilmesi zorunlu)",
  "labels.postTag": "Etiket",
  "labels.postGroup": "Grup",
  "labels.postDivision": "Bölge",
  "labels.postCustomField": "Özel Alan",
  "labels.selectExcelFile": "Yemek menüsü seçiniz",
  "labels.tooltipActive": "Aktif",
  "labels.tooltipPassive": "Pasif",
  "labels.foodMenuCalory": "Kalori",
  "labels.selectCorp": "Kuruluş seçiniz",
  "labels.selectLoc": "Lokasyon seçiniz",
  "labels.selectEmpType": "Çalışan Tipi seçiniz",
  "labels.selectItemsPerPage": "Sayfa başına veri adedi seçiniz",
  "labels.selectDate": "Tarih Seçiniz",
  "labels.selectTime": "Saat Seçiniz",
  "labels.selectDateRange": "Tarih Aralığı Seçin",
  "labels.tooltipPublished": "Yayında",
  "labels.tooltipDraft": "Beklemede",
  "labels.tooltipReview": "İncelemede",
  "labels.tooltipUnpublished": "Kaldırıldı",
  "labels.tooltipReject": "Reddedildi",
  "labels.tooltipActive": "Aktif",
  "labels.tooltipPassive": "Pasif",
  "labels.answerQuestion": "Soruyu Cevapla",
  "labels.inspectQuestion": "Soruyu İncele",
  "labels.questionAnswer": "Soru Cevabı",
  "labels.questionHr": "Soru",
  "labels.titleHr": "Başlık",
  "labels.filesHr": "Dosyalar",
  "labels.dateHr": "Cevaplanma Tarihi",
  "labels.noFileHr": "Dosya Yok",
  "labels.statusHr": "Soru Durumu",
  "labels.tooltipAnswered": "Cevaplandı",
  "labels.tooltipNoAnswered": "Cevaplanmadı",
  "labels.tooltipInReview": "İncelemede",
  "labels.selectboxType": "Lütfen veri tipini seçiniz",
  "labels.inputName": "Name (İsim alanı özgün olmalı)",
  "labels.parentCategory": "Ebeveyn Kategori",
  "labels.surveyType": "Anket Tipi",
  "labels.surveyName": "Anket İsmi",
  "labels.prohibitedWord": "Yasaklı Kelime",
  "labels.surveyStatus": "Anket Durumu",
  "labels.surveyPublishDate": "Yayınlanma Tarihi",
  "labels.surveyEndDate": "Yayından Kaldırılma Tarihi",
  "labels.surveyQuestion": "Anket Sorusu",
  "labels.surveyAnswer": "Şık",
  "labels.surveyLink": "Link",
  "labels.selectPage": "Sayfa Seçiniz",
  "labels.selectStatistic": "Rapor Seçiniz",
  "labels.selectCountPostType": "Gönderi Tipi Seçiniz",
  "labels.inputTagId": "Tag Id",
  "labels.selectInnerPage": "Yönlendirilen sayfayı seçiniz",
  "labels.modulChoose": "Yönlendirilecek Sayfayı Seç",
  "labels.dateAndLinks": "Tarih ve Link",
  "labels.dateAndPin": "Tarih ve Sabitle",
  "labels.link": "Link",
  "labels.notificationUsers": "Kullanıcılar",
  "labels.documentName": "Döküman İsmi",
  "labels.documentContent": "Döküman İçeriği",
  "labels.notificationTargetTypes": "İletilecek Kişi(ler)",
  "labels.testNotification": "Test Bildirimi",
  "labels.notificationDateTime": "Gönderim Tarihi ve Saati",
  "labels.notificationAllTargets": "Tüm kişilere ve gruplara gönder.",
  "labels.notificationTestMessage": "Test bildirimi olarak gönder.",
  "labels.notificationSendMessage": "Seçilenlere bildirim gönder.",
  "labels.notificationUserSelect": "Kullanıcıları seçiniz",
  "labels.notificationLink": "Uygulama içinde açılacak bağlantıyı giriniz.",
  "labels.notificationExternalLink":
    "Uygulama dışında açılacak bağlantıyı giriniz.",
  "labels.notificationMessage": "Mesaj",
  "labels.notificationTitleDescription": "İçerik ve Mesaj",
  "labels.storyTitle": "Hikaye Başlığı",
  "labels.surveyDownload": "Anketi Sonuçlarını İndir",
  "labels.currentPassword": "Mevcut Şifre",
  "labels.newPassword": "Yeni Şifre",
  "labels.reNewPassword": "Yeni Şifre (Tekrar)",
  "labels.changePassword": "Şifre Değiştir",
  "labels.hardReload": "Sayfayı Yenile",
  "labels.search": "Aramak için metin giriniz",
  "labels.useGuide": "Kullanım Kılavuzu",
  "labels.searchInTitle": "Başlıkta Ara",
  "labels.noCategory": "Kategori Yok",
  "labels.searchCategory": "Görüntülemek İstediğiniz Kategoriyi Arayın",
  "labels.noCategorySelected": "Kategori seçimi yapılmadı",
  "labels.unPublish": "Yayından Kaldır",
  "labels.publishItem": "Yayına Al",
  "labels.batnews": "Battan Haberler",
  "labels.bspPlus": "BSP+",
  "labels.regions": "Bölgeler",

  /* Menu */

  "menu.groups": "Gruplar",
  "menu.users": "Kullanıcılar",
  "menu.modulManagement": "Modül Yönetimi",

  /* Titles */

  "title.modulManagement": "Modül Yönetimi",
  "title.groups": "Gruplar",
  "title.users": "Kullanıcılar",
  "title.endorsement": "Takdir Teşekkür",
  "title.moduleModalCreate": "Yeni Modül Ekle",
  "title.moduleModalUpdate": "Modülü Güncelle",
  "title.groupModalCreate": "Yeni Grup Ekle",
  "title.groupModalUpdate": "Grubu Güncelle",
  "title.userModalUpdate": "Kullanıcı Güncelle",
  "title.userModalCreate": "Yeni Kullanıcı Ekle",
  "title.logout": "Çıkış Yap",
  "title.userDetails": "Kullanıcı Detay",
  "title.category": "Kategori",
  "title.categoryModalUpdate": "Kategori Güncelle",
  "title.categoryCreate": "Yeni Kategori Ekle",
  "title.makeAdmin": "Admin Yap",
  "title.removeAdmin": "Adminden Çıkar",
  "title.addContent": "İçerik Ekle",
  "title.updateContent": "İçerik Güncelle",
  "title.tableHeaderTrName": "Türkçe İsim",
  "title.tableHeaderEnName": "İngilizce İsim",
  "title.surveys": "Anketler",
  "title.suspensefulContents": "Şüpheli İçerikler",
  "title.prohibitedWords": "Yasaklı Kelimeler",
  "title.askHr": "IK" + "'ya" + " Sorum Var",
  "title.createSurvey": "Anket Ekle",
  "title.createProhibitedWord": "Yasaklı Kelime Ekle",
  "title.surveyDetails": "Anket Detay",
  "title.prohibitedWordDetails": "Yasaklı Kelime Detay",
  "title.tableHeaderIcon": "İkon",
  "title.tableHeaderName": "İsmi",
  "title.tableHeaderUsername": "Kullanıcı Adı",
  "title.tableHeaderProfilePic": "Profil Resmi",
  "title.tableHeaderLastName": "Soyismi",
  "title.tableHeaderEmail": "Eposta",
  "title.tableHeaderKey": "Modül Anahtarı",
  "title.tableHeaderCreateDate": "Oluşturulma Tarihi",
  "title.tableHeaderEndDate": "Bitiş Tarihi",
  "title.tableHeaderActions": "İşlemler",
  "title.tableHeaderSurveyQuestion": "Anket Sorusu",
  "title.tableHeaderSurveyType": "Anket Tipi",
  "title.tableHeaderUserType": "Kullanıcı Tipi",
  "title.tableHeaderStatus": "Durum",
  "title.tableHeaderSurveyAnswerCount": "Cevaplanma Sayısı",
  "title.tableHeaderModules": "Modül İsmi",
  "title.tableHeaderWrite": "Yazma",
  "title.tableHeaderRead": "Okuma",
  "title.tableHeaderApprove": "Yayına Alma",
  "title.deleteContentTitle": "İçeriği Sil",
  "title.titleCustomFields": "Özel Alanlar",
  "title.customFieldCreate": "Özel Alan Ekle",
  "title.customFieldEdit": "Özel Alan Düzenle",
  "title.tableHeaderDisplayName": "Görüntülenecek Ad",
  "title.tableHeaderDataType": "Veri Tipi",
  "title.tableHeaderCustomFieldName": "Özel Alan İsmi",
  "title.tableHeaderCustomFieldModuleName": "Ait Olduğu Modül İsmi",
  "title.foodMenuModalCreate": "Dosya Yükle",
  "title.titleFoodMenu": "Yemek Menüsü",
  "title.titleReport": "Raporlar",
  "title.tableHeaderFoodMenuDate": "Sisteme Ekleniş Tarihi",
  "title.tableHeaderLikeCount": "Beğeni Sayısı",
  "title.tableHeaderFoodMenuName": "Yemek Menü Adı",
  "title.tableHeaderFoodMenuCreator": "Menüyü Oluşturan Kişi",
  "title.foodMenuModalDetail": "Yemek Menüsü Detayı",
  "title.parentName": "Ebeveyn İsmi",
  "title.modulManagement": "Modül Yönetimi",
  "title.groups": "Gruplar",
  "title.users": "Kullanıcılar",
  "title.moduleModalCreate": "Yeni Modül Ekle",
  "title.documentAssign": "Döküman Ata",
  "title.documentUpdate": "Döküman Atama Güncelle",
  "title.moduleModalUpdate": "Modülü Güncelle",
  "title.groupModalCreate": "Yeni Grup Ekle",
  "title.groupModalUpdate": "Grup Düzenle",
  "title.groupModalUpdate": "Grubu Güncelle",
  "title.userModalUpdate": "Kullanıcı Güncelle",
  "title.userModalCreate": "Yeni Kullanıcı Ekle",
  "title.logout": "Çıkış Yap",
  "title.ProfileInfo": "Profil Bilgileri",
  "title.userDetails": "Kullanıcı Detay",
  "title.category": "Kategori",
  "title.categoryDetails": "Detayı",
  "title.categoryCreate": "Yeni Kategori Ekle",
  "title.subCategoryCreate": "Alt Kategori Ekle",
  "title.makeAdmin": "Admin Yap",
  "title.removeAdmin": "Adminden Çıkar",
  "title.addContent": "İçerik Ekle",
  "title.surveys": "Anketler",
  "title.documentAssign": "Döküman Atama",
  "title.createSurvey": "Anket Ekle",
  "title.surveyDetails": "Anket Detay",
  "title.tableHeaderIcon": "İkon",
  "title.tableHeaderName": "İsmi",
  "title.tableHeaderUsername": "Kullanıcı Adı",
  "title.tableHeaderProfilePic": "Profil Resmi",
  "title.tableHeaderLastName": "Soyismi",
  "title.tableHeaderEmail": "Eposta",
  "title.tableHeaderKey": "Modül Anahtarı",
  "title.tableHeaderCreateDate": "Oluşturulma Tarihi",
  "title.tableHeaderEndDate": "Bitiş Tarihi",
  "title.tableHeaderActions": "İşlemler",
  "title.tableHeaderSurveyQuestion": "Anket Sorusu",
  "title.tableHeaderSurveyType": "Anket Tipi",
  "title.tableHeaderUserType": "Kullanıcı Tipi",
  "title.tableHeaderModules": "Modül İsmi",
  "title.tableHeaderWrite": "Yazma",
  "title.tableHeaderRead": "Okuma",
  "title.tableHeaderApprove": "Yayına Alma",
  "title.tableHeaderFullNameSurvey": "Anketi Oluşturan",
  "title.tableHeaderFullNameAskHR": "Soruyu Oluşturan",
  "title.tableHeaderContentOwner": "İçerik Sahibi",
  "title.tableHeaderProhibitedWord": "Yasaklı Kelime",
  "title.tableHeaderProhibitedWordCreator": "Yasaklı Kelimeyi Oluşturan",
  "title.forceUpdate": "Güncelleme JSON",
  "title.forceUpdateAdd": "Güncelleme JSON Ekleme",
  "title.forceUpdateLabel": "Güncelleme JSON Dosyası",
  "title.tableHeaderContentType": "İçerik Tipi",
  "title.tableHeaderContentReason": "İçerik Veri Türü",
  "title.tableHeaderSuspensefulContent": "İçerik",
  "title.stories": "Hikayeler",
  "title.tableHeaderTitle": "Başlık",
  "title.tableHeaderThumbNail": "Görsel",
  "title.addStory": "Hikaye Ekle",
  "title.notificationTitle": "Bildirim Başlığı",
  "title.notificationType": "Bildirim Hedefi",
  "title.notificationData": "Bildirim İçeriği",
  "title.storyData": "Hikaye İçeriği",
  "title.storyLinkType": "Hikaye Link Türü",
  "title.notificationRedirectPage": "Yönlendirilecek Sayfa",
  "title.contentPageHeaderAdd": "Ekle",
  "title.contentPageHeaderUpdate": "Güncelle",
  "title.moduleDescription": "Modül Açıklaması",
  "title.blockedUsers": "Bloklanmış Kullanıcılar",

  "title.subCategoryCreate": "Alt Kategori Ekle",
  "title.advertModalUpdate": "Advert Güncelle",
  "title.advertModalCreate": "Advert Ekle",
  "title.endorsementTopicModalCreate": "Takdir Teşekkür Ekle",
  "title.endorsementTopicModalUpdate": "Takdir Teşekkür Güncelle",
  "title.tableHeaderEndorsementTopicImage": "Görsel",
  "title.tableHeaderEndorsementTopicDescription": "Açıklama",
  "title.tableHeaderEndorsementTopicSortOrder": "Sıralama",
  "title.tableHeaderEndorsementTopicType": "Anahtar Tipi",
  "title.tableHeaderEndorsementTopicHeader": "Başlık",
  "title.notifications": "Bildirimler",

  /* Description */
  "description.forgotPassword": "Şifremi Gönder",
  "description.noList": "Liste boş.",
  "description.deleteModalTitle": "Silme İşlemi",
  "description.contentDenyTitle": "İçerik Engelleme İşlemi",
  "description.contentApproveTitle": "İçerik Onaylama İşlemi",
  "description.deleteModalDescription":
    "Seçilen veriyi silmek istediğinize emin misiniz?",
  "description.contentModalDescription":
    "Seçilen içeriği onaylamak istediğinize emin misiniz?",
  "description.invalidKey":
    "Lütfen anahtar alanında tükçe karakter ve _ dışında karakter kullanmayınız.",
  "description.logout": "Çıkış yapmak istediğinize emin misiniz?",
  "description.groupLengthMessage": "Not : En fazla 4 satır ekleyebilirsiniz.",
  "description.tableLoadingError":
    "Tablo yüklenirken bir hata oluştu, lütfen tekrar deneyiniz.",
  "description.makeAdminDescription":
    "kişisini admin yapmak istediğinize emin misiniz?",
  "description.removeAdminDescription":
    "kişisini admin yetkisinden çıkarmak istediğinize emin misiniz?",
  "description.authorizationUserText1":
    "kullanıcısının yetkilerini tanımlayınız, daha sonra aşağıda yer alan",
  "description.authorizationUserText2": "butonuna tıklayınız.",
  "description.deleteContent":
    "Seçilen içeriği silmek istediğinize emin misiniz?",
  "description.invalidDatePick": "Lütfen geçerli bir tarih aralığı girin.",
  "description.invalidUserSelection": "Lütfen atamak için kullanıcı veya bölge seçin.",
  "description.excelDataError":
    "Lütfen dışarı aktaracak verinin olduğu tablo seçiniz.",
  "description.excelColumnError":
    "Lütfen dışarı aktaracak verinin başlığı olan tablo seçiniz.",
  "description.invalidMenuSelect": "Lütfen en az bir adet menü seçin.",
  "description.selectModule": "Lütfen bir modül seçiniz",
  "description.fileSizeError":
    "Lütfen dosya boyut limitine uygun dosya seçiniz.",
  "description.notificationTargetError":
    "Lütfen bildirim göndermeden önce en az bir adet hedef seçiniz.",
  "description.unsupportedMediaType":
    "Desteklenmeyen dosya tipi, lütfen farklı bir dosya tipi seçiniz.",
  "description.surveyAnswerBoundReached": "Cevap ekleme sınırına ulaşılmıştır.",
  "description.surveyEmptyAnswer": "Boş cevap eklenemez",
  "description.wrongDateInput":
    "Başlangıç tarihi Bitiş tarihinden ileride olamaz.",
  "description.selectPage": "Lütfen Bir Sayfa Seçiniz",
  "description.selectInnerPage": "Lütfen Bir  İç Sayfa Seçiniz",
  "description.notificationSendError":
    "Bildirim Gönderilemedi, Lütfen Tekrar Deneyiniz.",
  "description.storyPhotoError": "Lütfen en az bir adet fotoğraf yükleyiniz.",
  "description.tagCountReached":
    "Maksimum 15 adet etiket ekleyebilirsiniz, sıfırlamak için sayfayı yenileyiniz.",
  "description.propertyEmptySicil":
    "Boş sicil numarası eklenemez, lütfen sicil numarası ekleyiniz.",
  "description.propertyEmptyPublishDate":
    "Özel alanlar bölümündeki bilgiler zorunludur, lütfen eksiksiz doldurunuz",
  "description.contentPhotoUpload":
    "Lütfen zorunlu fotoğraf alanını doldurunuz.",
  "description.groupRowDelete":
    "Bu satırı silemezsiniz. En az bir grup satırı girilmelidir.",
  "description.emptyCategorySelect":
    "Kategori seçimi zorunludur, lütfen en az bir adet kategori seçiniz.",
  "description.draftNotificationInfo":
    "Taslak olarak yayınlanıyor, bildirim gönderilmeyecektir.",
  "description.contentFileUpload": "Lütfen zorunlu medya alanını doldurunuz.",
  "description.contentFileUploadOnlyPdf":
    "Lütfen medya alanına sadece pdf uzantılı dosyaları ekleyiniz.",
  "description.errorOnCreate":
    "Lütfen zorunlu alanları doldurup tekrar deneyin.",
  "description.errorOnCreateContent":
    "Lütfen içerik kurallarına uygun yayınlama yapınız.",
  "description.communityModule":
    "Bu modül içerikleri tüm topluluk tarafınca görüntülenebilir.",
  "description.invalidFileType":
    "Lütfen içeriği yayınlamak için geçerli bir dosya türü seçin.",
  "description.authorizationError":
    "Yetkilendirme problemi yaşanıyor, lütfen tekrar giriş yapınız.",
  "description.serverError":
    "Sunucularda bir problem yaşanıyor, lütfen daha sonra tekrar deneyiniz.",
    "description.addTagError":
    "Etiket eklenemedi, lütfen daha sonra tekrar deneyiniz.",
};
