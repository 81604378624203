<template>
  <div>
    <!-- Category Module modal -->
    <v-dialog persistent v-model="pageDetailStatus" max-width="400">
      <v-card>
        <v-card-title class="headline indigo lighten-2">
          <div class="text-center white--text" style="width: 100%">
            {{ this.modalHeader }}
          </div>
        </v-card-title>

        <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
          <form @submit.prevent="handleSubmit(sendForm)">
            <v-card-text class="py-3">
              <v-row>
                <v-col md="12" xs="12" class="px-0 py-0 pl-2 pr-2">
                  <ValidationProvider
                    name="trName"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      v-model="categoryModel.trName"
                      :value="categoryModel.trName"
                      :label="$t('labels.categoryNameTR')"
                      :error-messages="errors[0]"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col md="12" xs="12" class="px-0 py-0 pl-2 pr-2">
                  <ValidationProvider
                    name="enName"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      v-model="categoryModel.enName"
                      :value="categoryModel.enName"
                      :label="$t('labels.categoryNameEN')"
                      :error-messages="errors[0]"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col md="12" xs="4" class="px-0 pl-2 pr-2">
                  <ValidationProvider name="Type" v-slot="{ errors }">
                    <!-- <v-select
                      v-model="categoryModel.ParentId"
                      :items="selecBoxCategories"
                      item-text="Culture"
                      item-value="Id"
                      :label="$t('labels.parentCategory')"
                      :error-messages="errors[0]"
                      :disabled="categoryModel.ParentId != null"
                    ></v-select> -->

                    <v-autocomplete
                      v-model="categoryModel.ParentId"
                      :items="selecBoxCategories"
                      :error-messages="errors[0]"
                      item-text="Culture"
                      item-value="Id"
                      dense
                      :disabled="selectedCategoriesModal.length == 1"
                      :label="$t('labels.parentCategory')"
                    ></v-autocomplete>
                  </ValidationProvider>
                </v-col>
                <!--
                    <v-treeview
                    v-model="categoryModel.ParentId"
                    :items="selecBoxCategories"
                    selection-type="independent"
                    item-text="Culture"
                    item-value="Id"
                    item-children="ChildItems"
                    selectable
                    return-object
                    open-all
                  ></v-treeview>
                  -->
              </v-row>
            </v-card-text>

            <v-card-actions
              style="
                background-color: white;
                position: sticky;
                bottom: 0px;
                border-top: solid grey 2px;
              "
            >
              <v-spacer></v-spacer>
              <v-btn color="primary" type="submit" :loading="loadings.send">
                {{ $t("buttons.save") }}
              </v-btn>
              <v-btn
                color="primary"
                text
                @click="
                  pageDetailStatus = false;
                  clearModal();
                "
              >
                {{ $t("buttons.close") }}
              </v-btn>
            </v-card-actions>
          </form>
        </ValidationObserver>
      </v-card>
    </v-dialog>

    <!-- Category Module deletion modal -->
    <delete-modal :yesFunc="deleteItem" ref="deleteModalRef" />

    <!-- Page content -->
    <v-card style="border-radius: 10px">
      <!-- <v-card-title style="color: #595959">
        <v-row>
          <v-col class="py-2">{{ $t("title.category") }}</v-col>
          <v-spacer />
        </v-row>
      </v-card-title>

      <v-divider /> -->

      <v-card-text class="pt-4">
        <v-row align="center" justify="space-between" class="mb-2">
          <!-- <v-col cols="3">
            <v-select
              v-model="selectedFilterParentId"
              :items="
                selecBoxCategories.concat([{ Culture: 'Tümü', Id: 'All' }])
              "
              item-text="Culture"
              item-value="Id"
              :label="$t('labels.parentCategory')"
              @change="filteredCategories()"
              hide-details
            ></v-select>
          </v-col> -->
          <v-col cols="3">
            <!-- v-on:keyup.enter="searchTable"
              @click:clear="searchTable('clear')"
              @click:append="searchTable" -->
            <v-text-field
              v-model="search"
              :label="$t('labels.search')"
              clear-icon="mdi-close-circle"
              clearable
              single-line
              hide-details
              class="mb-5"
            ></v-text-field>
          </v-col>
          <v-col cols="6" align="end">
            <v-btn
              small
              class="ma-2 mr-4"
              elevation="0"
              outlined
              color="info"
              v-if="
                selectedCategoriesModal.length == 1 &&
                  controlUpdateButton == true
              "
              @click="getPageDetails(selectedCategoriesModal)"
            >
              {{ $t("labels.tooltipEdit") }}
            </v-btn>
            <v-btn
              small
              class="ma-2 mr-4"
              elevation="0"
              outlined
              color="info"
              v-if="selectedCategoriesModal.length == 1"
              @click="openDeleteModal(selectedCategoriesModal)"
            >
              {{ $t("buttons.deleteSelectedCategory") }}
            </v-btn>
            <v-btn
              small
              class="ma-2 mr-4"
              elevation="0"
              outlined
              color="info"
              @click="
                pageDetailStatus = true;
                selectedCategoriesModal=[]
                modalHeader = $t('title.categoryCreate');
              "
            >
              {{ $t("buttons.addNew") }}
            </v-btn>
          </v-col>
        </v-row>

        <!-- <v-divider /> -->

        <v-card>
          <v-card-title class="add-content-card-body text-h5">
            Tüm Kategoriler
          </v-card-title>
          <v-card-text class="px-0 py-0 add-content-card-body">
            <!-- <v-data-table
              :headers="headers"
              :items="tableData"
              :loading="loadings.table"
              :footer-props="{ 'items-per-page-options': [5, 10, 15, 30] }"
              :no-data-text="$t('description.noList')"
            >
              eslint-disable
              <template v-slot:item.Translation="{ item }">
                <div
                  style="font-weight: bold !important;"
                  :class="!item.ParentId ? 'primary--text' : null"
                >
                  {{ getCategoriNameWithCulture(item.Translation, "tr") }}
                </div>
              </template>
              <template v-slot:item.CreatedAt="{ item }">
                <div :class="!item.ParentId ? 'primary--text' : null">
                  {{ convertToLocal(item.CreatedAt) }}
                </div>
              </template>
              <template v-slot:item.ParentId="{ item }">
                <v-tooltip bottom v-if="item.ParentId">
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip
                      outlined
                      color="blue"
                      label
                      v-if="item.ParentId"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <div
                        class="text-truncate text-center"
                        style="width:150px;"
                      >
                        {{ item.ParentId ? getParentName(item) : "-" }}
                      </div>
                    </v-chip>
                  </template>
                  <span>{{ item.ParentId ? getParentName(item) : "-" }}</span>
                </v-tooltip>

                <v-tooltip bottom v-if="!item.ParentId">
                  <template v-slot:activator="{ on, attrs }"> </template>
                  <span>{{ "Ebeveyn Kategori" }}</span>
                </v-tooltip>
              </template>

              <template v-slot:item.actions="{ item }">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      small
                      class="mr-2"
                      @click="getPageDetails(item)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-pencil
                    </v-icon>
                  </template>
                  <span>{{ $t("labels.tooltipEdit") }}</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      small
                      class="mr-2"
                      @click="openDeleteModal(item)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                  <span>{{ $t("labels.tooltipDelete") }}</span>
                </v-tooltip>
              </template>
            </v-data-table> -->
            <v-banner rounded outlined>
              <v-treeview
                v-if="cData != null"
                v-model="selectedCategoriesModal"
                :items="cData"
                :search="search"
                :filter="filter"
                selection-type="independent"
                item-children="Children"
                item-value="Id"
                item-text="CategoryNameTR"
                item-key="Id"
                selectable
                rounded
              >
              </v-treeview>
            </v-banner>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import CategoryService from "../services/CategoryService";
import DeleteModal from "../components/General/DeleteModal.vue";

import _ from "lodash";

export default {
  data: () => ({
    //List options
    options: {
      itemsPerPage: 15,
    },
    top: 15,
    skip: 0,
    tableData: [],
    //Table contents
    headers: [],
    //Page loaders
    loadings: {
      table: false,
      send: false,
    },
    //Initial models
    pageDetailStatus: false,
    pageDetailUpdate: false,
    modalHeader: "",
    modalHeaderUpdate: "",
    deleteItemModel: {},
    categoryModel: {
      trName: "",
      enName: "",
      Id: "",
    },
    search: "",
    parentId: "All",
    selecBoxCategories: [],
    selectedFilterParentId: "All",
    cData: null,
    selectedCategoriesModal: [],
    searchCategoryModel: "",
    caseSensitive: false,
    controlUpdateButton: false,
    key:null
  }),
  watch: {
    selectedCategoriesModal: function(val) {
      let obj = this.cData.find((o) => o.Id === val[0]);
      if (obj == undefined) {
        this.controlUpdateButton = true;
      } else {
        this.controlUpdateButton = false;
      }
    },
  },
  computed: {
    filter() {
      return this.caseSensitive
        ? (item, search, textKey) => item[textKey].indexOf(search) > -1
        : undefined;
    },
  },
  mounted() {
     this.key = "all";
    this.setup();
    this.getCategories();
    this.getTableList(this.search, this.parentId, true);
  },
  components: {
    DeleteModal,
  },
  methods: {
    //Sets up the page on mount
    setup() {
      this.modalHeader = this.$t("title.groupModalCreate");
      this.modalHeaderUpdate = this.$t("title.groupModalUpdate");
      this.headers = [
        {
          text: this.$t("title.tableHeaderTrName"),
          value: "Translation",
          sortable: false,
        },
        {
          text: this.$t("title.tableHeaderCreateDate"),
          value: "CreatedAt",
          sortable: false,
        },
        {
          text: this.$t("title.parentName"),
          value: "ParentId",
          sortable: false,
          align: "center",
        },
        {
          text: this.$t("title.tableHeaderActions"),
          value: "actions",
          sortable: false,
        },
      ];
    },

    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 40;
    },
    //Get all categories
    getCategories() {
      // this.$store.state.loadingStatus = true;

      // let params = {
      //   ParentId: "all",
      //   PageSize: 1000,
      // };
      // CategoryService.getCategories2(params)
      //   .then((res) => {
      //     console.log("res data =>", res.data);
      //     this.cData = setCategoryTreeviewDataNew(res.data);
      //     //TODO kategorilerde treeview için yapıldı. Geçici yapıldı daha sonra  daha stabil ve düzgün yazılmalı
      //     if (this.cData && this.cData.length) {
      //       this.cData = this.cData[0].ChildItems;
      //     } else {
      //       this.cData = [];
      //     }

      //     // this.cData = setFilterData(this.cData, "kategoriler", res.data);
      //     console.log("cData > ", this.cData);
      //   })
      //   .catch((err) => {
      //     window.showError(err);
      //   })
      //   .then(() => {
      //     this.$store.state.loadingStatus = false;
      //   });


      //Added new treeview service
         this.$store.state.loadingStatus = true;


      CategoryService.getCategoriesInTreeView2(this.key)
        .then((res) => {
          this.cData = res.data.Children
          console.log("cData yeni > ", this.cData);
        })
        .catch((err) => {
          window.showError(err);
        })
        .then(() => {
          this.$store.state.loadingStatus = false;
        });
    },

    //Loads page list
    getTableList(search = "", parentId = "All", selectBoxItems = false) {
      this.$store.state.loadingStatus = true;

      CategoryService.getCategories(search, parentId)
        .then((res) => {
          console.log("res : ", res.data);
          this.tableData = res.data;

          //set select box items
          selectBoxItems ? this.setSelectBoxItems(res.data) : null;
        })
        .catch((err) => {
          window.showError(err);
        })
        .then(() => {
          this.$store.state.loadingStatus = false;
        });
    },

    // get parent name fields
    getParentName(item) {
      let filterArray = [];
      let data = this.selectedFilterParentId
        ? this.selecBoxCategories
        : this.tableData;

      filterArray = _.filter(data, ["Id", item.ParentId]);
      //console.log("filtered arra : ", filterArray);
      if (filterArray.length > 0)
        return this.selectedFilterParentId
          ? filterArray[0].Culture
          : getCategoriName(filterArray[0].Translation);
      else return "a";
    },

    // set category select boc items
    setSelectBoxItems(data) {
      let array = [];
      data.forEach((x) => {
        array.push({
          Id: x.Id,
          ParentId: x.ParentId,
          Culture: getCategoriName(x.Translation),
        });
      });
      this.selecBoxCategories = array;
    },

    //Inspects an item
    getPageDetails(item) {
      item = this.tableData.find((x) => {
        return x.Id == item[0];
      });

      this.modalHeader = this.$t("title.categoryModalUpdate");
      var categoriNameTr = item.Translation.filter(
        (item) => item.Culture == "tr"
      );
      var categoriNameEn = item.Translation.filter(
        (item) => item.Culture == "en"
      );
      this.categoryModel = {
        trName: categoriNameTr.length > 0 ? categoriNameTr[0].Name : "",
        enName: categoriNameEn.length > 0 ? categoriNameEn[0].Name : "",
        Id: item.Id,
        ParentId: item.ParentId,
      };
      this.pageDetailStatus = true;
    },

    //Clear modal and its content
    clearModal() {
      this.categoryModel = {
        trName: "",
        enName: "",
        Id: "",
      };
      this.selectedCategoriesModal = [];
      this.$refs.observer.reset();
    },

    //Delete operation
    deleteItem() {
      console.log("DELETED ITEMS2 > ", this.deleteItemModel);
      this.$store.state.loadingStatus = true;

      CategoryService.deleteCategory(this.deleteItemModel[0])
        .then((res) => {
          this.getTableList();
          this.getCategories();
          this.$refs.deleteModalRef.modalStatus = false;
        })
        .catch((err) => {
          window.showError(err);
        })
        .then(() => {
          this.$store.state.loadingStatus = false;
          window.showInfo("Kategori Silindi", "standart", "success");
        });
    },

    //Submits form whether it's update or create operation
    sendForm() {
      if (this.categoryModel.Id) {
        this.updateCategory();
      } else {
        this.createCategory();
      }
    },

    //Create operation
    createCategory() {
      this.$store.state.loadingStatus = true;

      let body = {
        parentId: this.categoryModel.ParentId
          ? this.categoryModel.ParentId
          : null,
        categoryType: "general",
        translation: [
          {
            culture: "tr",
            name: this.categoryModel.trName,
          },
          {
            culture: "en",
            name: this.categoryModel.enName,
          },
        ],
      };
      CategoryService.createCategory(body)
        .then((res) => {
          this.getTableList(this.search, this.parentId, true);
          this.getCategories();
          this.pageDetailStatus = false;
        })
        .catch((err) => {
          window.showError(err);
        })
        .then(() => {
          this.clearModal();
          this.$store.state.loadingStatus = false;
          window.showInfo("Kategori Eklendi", "standart", "success");
        });
    },

    //Update operation
    updateCategory() {
      this.$store.state.loadingStatus = true;

      let body = {
        Id: this.categoryModel.Id,
        // parentId: this.categoryModel.ParentId
        //   ? this.categoryModel.ParentId
        //   : null,
        translation: [
          {
            culture: "tr",
            name: this.categoryModel.trName,
          },
          {
            culture: "en",
            name: this.categoryModel.enName,
          },
        ],
      };
      CategoryService.updateCategoryTranslations(body)
        .then((res) => {
          this.getTableList(this.search, this.parentId, true);
          this.getCategories();
          this.pageDetailStatus = false;
          this.clearModal();
        })
        .catch((err) => {
          window.showError(err);
        })
        .then(() => {
          this.$store.state.loadingStatus = false;
          window.showInfo("Kategori Güncellendi", "standart", "success");
        });
    },

    //Creates delete modal
    openDeleteModal(item) {
      console.log("Item > ", item);
      this.deleteItemModel = [];
      item.map((item) => {
        console.log("ID > ", item);
        this.deleteItemModel.push(item);
      });
      console.log("DELETE ITEM MODEL > ", this.deleteItemModel);
      // this.deleteItemModel = item;
      this.$refs.deleteModalRef.modalStatus = true;
    },

    //Delete operation
    // deleteItem() {
    //   this.$store.state.loadingStatus = true;

    //   CategoryService.deleteCategory(this.deleteItemModel.Id)
    //     .then((res) => {
    //       this.getTableList();
    //       this.$refs.deleteModalRef.modalStatus = false;
    //     })
    //     .catch((err) => {
    //       window.showError(err);
    //     })
    //     .then(() => {
    //       this.$store.state.loadingStatus = false;
    //     });
    // },

    //Search Operation
    searchTable(type = "search") {
      type == "clear" ? (this.search = "") : null;
      this.getTableList(this.search, this.selectedFilterParentId);
    },

    filteredCategories() {
      console.log("parent ıd ", this.selectedFilterParentId);
      this.getTableList(this.search, this.selectedFilterParentId, false);
    },
  },
};
</script>
