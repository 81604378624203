var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('delete-modal',{ref:"contentModalRef",attrs:{"yesFunc":_vm.selectedContentFunc,"description":this.modalDescription,"title":this.modalTitle}}),_c('v-card',{staticStyle:{"border-radius":"10px"}},[_c('v-card-title',{staticStyle:{"color":"#595959"}},[_c('v-row',[_c('v-col',{staticClass:"py-2"},[_vm._v(_vm._s(_vm.$t("title.suspensefulContents")))])],1)],1),_c('v-divider'),_c('v-card-text',{staticClass:"pt-4"},[_c('v-row',{attrs:{"align":"center","justify":"start"}},[_c('v-col',{attrs:{"cols":"3"}},[_c('v-select',{attrs:{"value":_vm.selectedContentType,"items":_vm.contentTypes.concat({ Name: 'Tümü', Code: '' }),"item-text":"Name","item-value":"Code","label":_vm.$t('labels.contentType'),"required":""},on:{"change":function($event){return _vm.filterContent()}},model:{value:(_vm.selectedContentType),callback:function ($$v) {_vm.selectedContentType=$$v},expression:"selectedContentType"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-select',{attrs:{"value":_vm.selectedContentStatus,"items":_vm.contentStatusTypes.concat({ Name: 'Tümü', Value: '' }),"item-text":"Name","item-value":"Value","label":_vm.$t('labels.contentStatus'),"required":""},on:{"change":function($event){return _vm.filterContent()}},model:{value:(_vm.selectedContentStatus),callback:function ($$v) {_vm.selectedContentStatus=$$v},expression:"selectedContentStatus"}})],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"2"}},[_c('v-select',{attrs:{"items":_vm.perPageValues,"label":_vm.$t('labels.selectItemsPerPage'),"item-value":"value","item-text":"name"},on:{"change":function($event){return _vm.changeItemsPerPage()}},model:{value:(_vm.itemsPerPage),callback:function ($$v) {_vm.itemsPerPage=$$v},expression:"itemsPerPage"}})],1)],1),_c('v-data-table',{ref:"surveyList",attrs:{"headers":_vm.headers,"items":_vm.tableData,"loading":_vm.loadings.table,"options":_vm.options,"hide-default-footer":true,"footer-props":{
          disablePagination: true,
          disableItemsPerPage: true,
        },"no-data-text":_vm.$t('description.noList')},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.Content",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.strippedContent(item.Content))+" ")]}},{key:"item.ContentOwner",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getFullName(item))+" ")]}},{key:"item.CreatedAt",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.convertToLocal(item))+" ")]}},{key:"item.Reason",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.Reason == "0" ? "Otomatik Algılama" : "Kullanıcı Şikayeti")+" ")]}},{key:"item.Status",fn:function(ref){
        var item = ref.item;
return [(item.Status == 20)?_c('v-chip',{staticClass:"ma-2",attrs:{"small":"","color":"green","text-color":"white"}},[_vm._v(" "+_vm._s(_vm.$t("labels.tooltipPublished"))+" ")]):_vm._e(),(item.Status == 0)?_c('v-chip',{staticClass:"ma-2 text--center",attrs:{"small":"","color":"grey","text-color":"white"}},[_vm._v(" "+_vm._s(_vm.$t("labels.tooltipDraft"))+" ")]):_vm._e(),(item.Status == -10)?_c('v-chip',{staticClass:"ma-2",attrs:{"small":"","color":"#F44336","text-color":"white"}},[_vm._v(" "+_vm._s(_vm.$t("labels.tooltipReject"))+" ")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.decideContentStatus(1, item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-checkbox-marked-circle-outline ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("labels.tooltipAccept")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.decideContentStatus(0, item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-cancel ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("labels.tooltipDeny")))])])]}}])}),_c('div',{staticClass:"text-xs-center pt-2 md4 lg4"},[_c('v-pagination',{attrs:{"value":_vm.pagination.page,"length":_vm.pages,"total-visible":10,"circle":""},on:{"input":_vm.paginationChangeHandler}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }