var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"persistent":"","max-width":"500"},model:{value:(_vm.customFieldDetailStatus),callback:function ($$v) {_vm.customFieldDetailStatus=$$v},expression:"customFieldDetailStatus"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline indigo lighten-2"},[_c('div',{staticClass:"text-center white--text",staticStyle:{"width":"100%"}},[_vm._v(" "+_vm._s(this.modalHeader)+" ")])]),_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.sendForm)}}},[_c('v-card-text',{staticClass:"py-3"},[_c('v-row',[_c('v-col',{attrs:{"md":"12","xs":"12"}},[_c('v-select',{attrs:{"items":_vm.allModules,"label":_vm.$t('description.selectModule'),"item-text":"Name","item-value":"Key","disabled":_vm.editItemId != null},model:{value:(_vm.selectedModuleModal),callback:function ($$v) {_vm.selectedModuleModal=$$v},expression:"selectedModuleModal"}}),_c('v-select',{attrs:{"items":_vm.dataTypes,"label":_vm.$t('labels.selectboxType'),"item-text":"Name","item-value":"Code","disabled":_vm.editItemId != null},model:{value:(_vm.selectedDataType),callback:function ($$v) {_vm.selectedDataType=$$v},expression:"selectedDataType"}}),_c('ValidationProvider',{attrs:{"name":"Görüntülenecek Ad","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"value":_vm.customFieldModel.DisplayName,"label":_vm.$t('title.tableHeaderDisplayName'),"error-messages":errors[0]},model:{value:(_vm.customFieldModel.DisplayName),callback:function ($$v) {_vm.$set(_vm.customFieldModel, "DisplayName", $$v)},expression:"customFieldModel.DisplayName"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Alan Adı","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"value":_vm.customFieldModel.Name,"disabled":_vm.editItemId != null,"label":_vm.$t('labels.inputName'),"error-messages":errors[0]},model:{value:(_vm.customFieldModel.Name),callback:function ($$v) {_vm.$set(_vm.customFieldModel, "Name", $$v)},expression:"customFieldModel.Name"}})]}}],null,true)})],1)],1)],1),_c('v-card-actions',{staticStyle:{"background-color":"white","position":"sticky","bottom":"0px","border-top":"solid grey 2px"}},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","type":"submit","loading":_vm.loadings.send}},[_vm._v(" "+_vm._s(_vm.$t("buttons.save"))+" ")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.customFieldDetailStatus = false;
                _vm.clearModal();}}},[_vm._v(" "+_vm._s(_vm.$t("buttons.close"))+" ")])],1)],1)]}}])})],1)],1),_c('delete-modal',{ref:"deleteModalRef",attrs:{"yesFunc":_vm.deleteItem}}),_c('v-card',{staticStyle:{"border-radius":"10px"}},[_c('v-card-title',{staticStyle:{"color":"#595959"}},[_c('v-row',[_c('v-col',{staticClass:"py-2"},[_vm._v(_vm._s(_vm.$t("title.titleCustomFields")))]),_c('v-spacer'),_c('v-btn',{staticClass:"ma-2 mr-4",attrs:{"small":"","elevation":"0","outlined":"","color":"info"},on:{"click":function($event){_vm.customFieldDetailStatus = true;
            _vm.modalHeader = _vm.$t('title.customFieldCreate');}}},[_vm._v(" "+_vm._s(_vm.$t("buttons.addNew"))+" ")])],1)],1),_c('v-divider'),_c('v-card-text',{staticClass:"pt-4"},[_c('v-row',{attrs:{"align":"center","justify":"space-between"}},[_c('v-col',{attrs:{"cols":"3"}},[_c('v-select',{attrs:{"items":_vm.allModules,"label":_vm.$t('description.selectModule'),"item-text":"Name","item-value":"Key"},on:{"change":function($event){return _vm.filterCustomField()}},model:{value:(_vm.selectedModule),callback:function ($$v) {_vm.selectedModule=$$v},expression:"selectedModule"}})],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.tableData,"loading":_vm.loadings.table,"options":_vm.options,"footer-props":{ 'items-per-page-options': [5, 10, 15, 30] },"no-data-text":_vm.$t('description.selectModule')},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.CreatedAt",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.convertToLocal(item.CreatedAt))+" ")]}},{key:"item.Icon",fn:function(ref){
            var item = ref.item;
return [_c('v-icon',[_vm._v(_vm._s(item.Icon))])]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.getCustomFieldDetails(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("labels.tooltipEdit")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.openDeleteModal(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("labels.tooltipDelete")))])])]}},{key:"item.Status",fn:function(ref){
            var item = ref.item;
return [(item.Status == 1)?_c('v-chip',{staticClass:"ma-2",attrs:{"small":"","color":"primary"}},[_vm._v(" "+_vm._s(_vm.$t("labels.tooltipActive"))+" ")]):_vm._e(),(item.Status == 0)?_c('v-chip',{staticClass:"ma-2",attrs:{"small":"","color":"primary"}},[_vm._v(" "+_vm._s(_vm.$t("labels.tooltipPassive"))+" ")]):_vm._e(),(item.Status == 10)?_c('v-chip',{staticClass:"ma-2",attrs:{"small":"","color":"primary"}},[_vm._v(" "+_vm._s(_vm.$t("labels.tooltipReview"))+" ")]):_vm._e(),(item.Status == 20)?_c('v-chip',{staticClass:"ma-2",attrs:{"small":"","color":"primary"}},[_vm._v(" "+_vm._s(_vm.$t("labels.tooltipPublished"))+" ")]):_vm._e(),(item.Status == -20)?_c('v-chip',{staticClass:"ma-2",attrs:{"small":"","color":"primary"}},[_vm._v(" "+_vm._s(_vm.$t("labels.tooltipReject"))+" ")]):_vm._e()]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }