// Dil ayarlarını yapan modül
import VueI18n from 'vue-i18n'

// çevirilerin bulunduğu dosyalar
import tr from '../assets/i18n/languages/tr'
import en from '../assets/i18n/languages/en'
// formlarda kullanılan validasyon mesajlarının çevirisi
/*import messagesEn from '../assets/i18n/messages/en'
import messagesTr from '../assets/i18n/messages/tr'
// formlarda kullanılan fieldların çevirisi
import attributesEn from '../assets/i18n/attributes/en'
import attributesTr from '../assets/i18n/attributes/tr'*/

//import VeeValidate from 'vee-validate'

const messages = { en, tr }
export default {
  setLanguageSettings (Vue) {
    Vue.use(VueI18n)
   
   /* Vue.use(VeeValidate, {
      locale: localStorage.getItem('language') || 'tr',
      dictionary: {
        en: { messages: messagesEn, attributes: attributesEn },
        tr: { messages: messagesTr, attributes: attributesTr }
      }
    })*/

    let i18n = new VueI18n({
      //TODO sayfa dili değiştirildiğinde burada işlem yapılacak
      //locale: localStorage.getItem('language') || 'tr', // set locale
      locale:'tr', // set locale
      messages // local mesajlar
    })

    return i18n
  }
}
