import axios from "../javascript/custom-axios";
const RESOURCE_NAME = "admin/posts";

export default {
  getPosts(params) {
    return axios.get(`${RESOURCE_NAME}`, { params });
  },
  uploadFile(body) {
    return axios.post(`/upload`, body);
  },
  getCategories(params) {
    return axios.get(`/categories`, { params: { params } });
  },
  getContentDetail(pageId) {
    return axios.get(`/posts/Detail`, { params: { Id: pageId } });
  },
  changeContentStatus(body) {
    return axios.put(`${RESOURCE_NAME}/changestatus`, body);
  },
};
