import Vue from "vue";
import { extend, localize } from "vee-validate";
import { required, email, min, max, integer } from "vee-validate/dist/rules";
import { ValidationProvider } from 'vee-validate';
import { ValidationObserver } from 'vee-validate';
import ar from "vee-validate/dist/locale/ar.json";
import en from "vee-validate/dist/locale/en.json";
import tr from "vee-validate/dist/locale/tr.json";
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
// Install required rule.
extend("required", required);
// Install email rule.
extend("email", email);
// Install min rule.
extend("min", min);
// Install max rule.
extend("max", max);
// Install integer rule.
extend("integer", integer);
// Install English and Arabic localizations.
localize({
  en: {
    messages: en.messages,
    names: {
      email: "E-mail Address",
      password: "Password"
    },
    fields: {
      password: {
        min: "{_field_} is too short, you want to get hacked?"
      }
    }
  },
  tr: {
    messages: tr.messages,
    // names: {
    //   email: "البريد الاليكتروني",
    //   password: "كلمة السر"
    // },
    // fields: {
    //   password: {
    //     min: "كلمة السر قصيرة جداً سيتم اختراقك"
    //   }
    // }
  }
});
let LOCALE = "tr";
localize(LOCALE);
// A simple get/set interface to manage our locale in components.
// This is not reactive, so don't create any computed properties/watchers off it.
Object.defineProperty(Vue.prototype, "locale", {
  get() {
    return LOCALE;
  },
  set(val) {
    LOCALE = val;
    localize(val);
  }
});