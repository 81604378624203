<template>
  <div>
    <!-- Survey modal -->
    <v-dialog
      persistent
      v-model="surveyDetailStatus"
      max-width="1000"
      ref="surveyPopupModal"
    >
      <v-card v-if="!isDetails">
        <v-card-title class="headline indigo lighten-2">
          <div class="text-center white--text" style="width: 100%">
            {{ this.modalHeader }}
          </div>
        </v-card-title>
        <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
          <form @submit.prevent="handleSubmit(sendForm)">
            <v-card-text class="pt-10 px-10">
              <!-- Division select start -->
              <v-banner rounded outlined class="mt-5">
                <v-card-title class="add-content-title">
                  {{ $t("labels.postDivision") }}
                </v-card-title>
                <v-col cols="6">
                  <v-select
                    :items="divisionItems.concat({ Name: 'Tümü', Id: -1 })"
                    item-text="Name"
                    item-value="Id"
                    v-model="selectedDivision"
                    dense
                  ></v-select>
                </v-col>
              </v-banner>
              <!-- Division select end -->

              <v-card-text class="py-3">
                <!-- Survey creation modal input part -->
                <v-row>
                  <v-col md="6" xs="6">
                    <!-- Name input start -->
                    <ValidationProvider
                      name="Anket ismi"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <v-text-field
                        v-model="surveyModel.Name"
                        :value="surveyModel.Name"
                        :label="$t('labels.surveyName')"
                        :error-messages="errors[0]"
                        @keypress.enter.prevent
                      ></v-text-field>
                    </ValidationProvider>
                    <!-- Name input end -->
                    <!-- Type input start -->
                    <ValidationProvider
                      name="Anket Tipi"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <v-select
                        v-model="surveyModel.Type"
                        :value="surveyModel.Type"
                        :items="surveyTypes"
                        item-text="Name"
                        @change="changeSurveyType()"
                        item-value="Code"
                        :label="$t('labels.surveyType')"
                        required
                        :error-messages="errors[0]"
                      ></v-select>
                    </ValidationProvider>
                    <!-- Type input end -->
                    <!-- Status input start -->
                    <v-select
                      v-model="surveyModel.Status"
                      :value="surveyModel.Status"
                      :items="surveyStatus"
                      item-text="Name"
                      item-value="Value"
                      :label="$t('labels.surveyStatus')"
                    ></v-select>
                    <!-- Status input end -->
                  </v-col>
                  <v-col md="6" xs="6">
                    <!-- start publish date picker -->
                    <v-dialog
                      ref="publishDateDialog"
                      v-model="publishDateModal"
                      :return-value.sync="surveyModel.StartDate"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <ValidationProvider
                          name="Baslangıç Tarihi"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <v-text-field
                            v-model="surveyModel.StartDate"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            clearable
                            :label="$t('labels.surveyPublishDate')"
                            :error-messages="errors[0]"
                          ></v-text-field>
                        </ValidationProvider>
                      </template>
                      <v-date-picker
                        v-model="surveyModel.StartDate"
                        locale="tr-TR"
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="publishDateModal = false"
                        >
                          {{ $t("buttons.close") }}
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="
                            $refs.publishDateDialog.save(surveyModel.StartDate)
                          "
                        >
                          {{ $t("buttons.select") }}
                        </v-btn>
                      </v-date-picker>
                    </v-dialog>
                    <!-- end publish date picker -->
                    <!-- start end date picker -->
                    <v-dialog
                      ref="unPublishDateDialog"
                      v-model="unPublishDateModal"
                      :return-value.sync="surveyModel.EndDate"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <ValidationProvider
                          name="Bitiş Tarihi"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <v-text-field
                            v-model="surveyModel.EndDate"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            clearable
                            :label="$t('labels.surveyEndDate')"
                            :error-messages="errors[0]"
                          ></v-text-field>
                        </ValidationProvider>
                      </template>
                      <v-date-picker
                        v-model="surveyModel.EndDate"
                        locale="tr-TR"
                        scrollable
                        :allowed-dates="disableBeforeStartDateSurveyModel"
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="unPublishDateModal = false"
                        >
                          {{ $t("buttons.close") }}
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="
                            $refs.unPublishDateDialog.save(surveyModel.EndDate)
                          "
                        >
                          {{ $t("buttons.select") }}
                        </v-btn>
                      </v-date-picker>
                    </v-dialog>
                    <!-- end end date picker -->
                    <!--  anonymous checkbox start -->
                    <v-row>
                      <v-col cols="4">
                        <v-checkbox
                          v-model="surveyModel.IsAnonymous"
                          :label="'Anonim Yanıtlar'"
                          hide-details
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="6">
                        <v-checkbox
                          v-model="surveyModel.SendNotification"
                          :label="'Bildirim Olarak Gitsin'"
                          hide-details
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                    <!--  anonymous checkbox end -->
                  </v-col>
                </v-row>
                <!-- Survey creation modal input part end -->
                <!-- Survey creation modal question part start -->
                <v-row align="center" justify="center">
                  <!-- if survey type is single -->
                  <v-col md="12" xs="12" v-if="singleSurveyStatus">
                    <!-- Question start -->
                    <v-container class="px-0">
                      <v-row>
                        <v-textarea
                          v-model="surveyModel.Question"
                          :value="surveyModel.Question"
                          :label="$t('labels.surveyQuestion')"
                          outlined
                          clearable
                          counter
                          :rules="questionRules"
                          rows="1"
                          no-resize
                          @keypress.enter.prevent
                        ></v-textarea>
                      </v-row>
                    </v-container>
                    <!-- Question end -->
                    <!-- Options start -->
                    <v-container
                      v-if="surveyQuestionIndex - 1 > 0"
                      class="px-0"
                    >
                      <v-row
                        v-for="(item, index) in surveyModel.Options"
                        :key="index"
                        align="center"
                        justify="start"
                        :v-if="item.length < 1"
                        class="mb-3 mt-1 px-5 pb-0 pt-3"
                        style="border: 1px solid grey; border-radius: 5px"
                      >
                        <v-col cols="12" class="pb-0">
                          <v-row justify="center" align="center">
                            <p
                              class="font-weight-black"
                              style="
                                font-size: 1.2em;
                                margin-bottom: 30px !important;
                              "
                            >
                              {{ $t("labels.surveyAnswer") }}
                              {{ index + 1 }} :&nbsp;
                            </p>
                            <!--   @blur="item.optionFlat =!item.optionFlat"  v-text field-->
                            <v-text-field
                              v-model="item.Text"
                              solo
                              :flat="!item.optionFlat"
                              :readonly="!item.optionFlat"
                              :value="item.Text"
                              style="font-size: 1.2em"
                            >
                              {{ item.Text }}
                              <template slot="append">
                                <v-btn
                                  class="ma-2"
                                  fab
                                  dark
                                  x-small
                                  color="red"
                                  @click="deleteAnswer(index)"
                                >
                                  <v-icon dark> mdi-minus </v-icon>
                                </v-btn>
                              </template>
                              <template slot="append">
                                <v-btn
                                  class="ma-2"
                                  fab
                                  dark
                                  x-small
                                  :color="!item.optionFlat ? 'blue' : 'green'"
                                >
                                  <v-icon
                                    v-if="!item.optionFlat"
                                    @click="confirmText(item.Text, index)"
                                    >mdi-pencil</v-icon
                                  >
                                  <v-icon
                                    v-if="item.optionFlat"
                                    @click="confirmText2(item.Text, index)"
                                    >mdi-check</v-icon
                                  >
                                </v-btn>
                              </template>
                            </v-text-field>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-container>
                    <!-- Options end -->
                    <!-- Option answers start -->
                    <v-container class="px-0">
                      <!-- Input answer options start -->
                      <v-row
                        align="center"
                        justify="start"
                        class="mt-2"
                        v-if="!answerBoundIsReached"
                      >
                        <v-textarea
                          v-model="questionText"
                          :value="questionText"
                          item-text="Text"
                          item-value="Text"
                          :label="$t('labels.surveyAnswer')"
                          outlined
                          clearable
                          clear-icon="mdi-close-circle"
                          append-outer-icon="mdi-emoticon-happy"
                          @click:append-outer="openEmojiPicker()"
                          counter
                          :rules="answerRules"
                          rows="1"
                          no-resize
                          @keypress.enter.prevent
                        ></v-textarea>
                      </v-row>
                      <!-- Emoji Picker Start-->
                      <v-dialog
                        v-model="openEmojiPickerDialog"
                        max-width="325"
                        style="border-radius: 0px"
                        hide-overlay
                        shaped
                        persistent
                      >
                        <template>
                          <div id="app">
                            <VEmojiPicker @select="selectEmoji" />
                          </div>
                        </template>

                        <v-spacer></v-spacer>
                        <v-card
                          style="border-radius: 0px"
                          align="center"
                          justify="center"
                        >
                          <v-btn
                            color="primary"
                            text
                            @click="openEmojiPickerDialog = false"
                            style="border-radius: 0px"
                          >
                            {{ $t("buttons.ok") }}
                          </v-btn>
                        </v-card>
                      </v-dialog>
                      <!-- Emoji Picker end-->
                      <!-- Input answer options end -->
                      <!-- Enter answer options start -->
                      <v-row align="center" justify="center">
                        <v-btn
                          small
                          class="ma-2 mr-4"
                          elevation="0"
                          outlined
                          color="info"
                          v-if="!answerBoundIsReached"
                          @click="addAnswer()"
                        >
                          {{ $t("buttons.surveyAddAnswer") }}
                        </v-btn>
                      </v-row>
                      <!-- Enter answer options end -->
                      <!-- Empty answer alert start -->
                      <v-row align="center" justify="center" class="mt-7">
                        <v-alert
                          v-if="emptyAnswerFlag"
                          type="warning"
                          style="font-size: 1em"
                          dismissible
                          color="#7443a0"
                        >
                          {{ $t("description.surveyEmptyAnswer") }}
                        </v-alert>
                        <!-- Empty answer alert end -->
                      </v-row>
                    </v-container>
                    <!-- Option answers end -->
                    <!-- Answer bound alert start -->
                    <v-container
                      v-if="answerBoundIsReached"
                      justify="center"
                      align="center"
                      class="px-0"
                    >
                      <div style="height: 30px"></div>
                      <v-alert
                        outlined
                        type="info"
                        color="#7443a0"
                        prominent
                        border="left"
                        class="font-weight-black"
                        style="font-size: 1.2em"
                        dismissible
                      >
                        {{ $t("description.surveyAnswerBoundReached") }}
                      </v-alert>
                    </v-container>
                    <!-- Answer bound alert end -->
                  </v-col>
                  <!-- end of single answer type survey -->
                  <!-- if survey type is multi -->
                  <v-col md="12" xs="12" v-if="multiSurveyStatus">
                    <!-- Question start -->
                    <v-container class="px-0">
                      <v-row>
                        <v-textarea
                          v-model="surveyModel.Question"
                          :value="surveyModel.Question"
                          :label="$t('labels.surveyQuestion')"
                          outlined
                          clearable
                          clear-icon="mdi-close-circle"
                          counter
                          :rules="questionRules"
                          rows="1"
                          no-resize
                          @keypress.enter.prevent
                        ></v-textarea>
                      </v-row>
                    </v-container>
                    <!-- Question end -->
                    <!-- Options start -->
                    <v-container
                      v-if="surveyQuestionIndex - 1 > 0"
                      class="px-0"
                    >
                      <v-row
                        v-for="(item, index) in surveyModel.Options"
                        :key="index"
                        align="center"
                        justify="start"
                        :v-if="item.length < 1"
                        class="mb-3 mt-1 px-5 pb-0 pt-3"
                        style="border: 1px solid grey; border-radius: 5px"
                      >
                        <v-col cols="12" class="pb-0">
                          <v-row justify="center" align="center">
                            <p
                              class="font-weight-black"
                              style="
                                font-size: 1.2em;
                                margin-bottom: 30px !important;
                              "
                            >
                              {{ $t("labels.surveyAnswer") }}
                              {{ index + 1 }} :&nbsp;
                            </p>
                            <v-text-field
                              v-model="item.Text"
                              solo
                              :flat="!item.optionFlat"
                              :readonly="!item.optionFlat"
                              :value="item.Text"
                              style="font-size: 1.2em"
                            >
                              {{ item.Text }}
                              <template slot="append">
                                <v-btn
                                  class="ma-2"
                                  fab
                                  dark
                                  x-small
                                  color="red"
                                  @click="deleteAnswer(index)"
                                >
                                  <v-icon dark> mdi-minus </v-icon>
                                </v-btn>
                              </template>
                              <template slot="append">
                                <v-btn
                                  class="ma-2"
                                  fab
                                  dark
                                  x-small
                                  :color="!item.optionFlat ? 'blue' : 'green'"
                                >
                                  <v-icon
                                    v-if="!item.optionFlat"
                                    @click="
                                      confirmSingleChoiceTextMultiple(
                                        item.Text,
                                        index
                                      )
                                    "
                                    >mdi-pencil</v-icon
                                  >
                                  <v-icon
                                    v-if="item.optionFlat"
                                    @click="
                                      confirmSingleChoiceTextMultiple2(
                                        item.Text,
                                        index
                                      )
                                    "
                                    >mdi-check</v-icon
                                  >
                                </v-btn>
                              </template>
                            </v-text-field>
                          </v-row>
                        </v-col>
                        <!-- <v-col cols="2" align="end">
                          <v-btn
                            class="mb-4"
                            fab
                            dark
                            x-small
                            color="red"
                            @click="deleteAnswer(index)"
                          >
                            <v-icon dark> mdi-minus </v-icon>
                          </v-btn>
                        </v-col> -->
                      </v-row>
                    </v-container>
                    <!-- Options end -->
                    <!-- Option answers start -->
                    <v-container class="px-0">
                      <!-- Input answer options start -->
                      <v-row
                        align="center"
                        justify="start"
                        class="mt-2"
                        v-if="!answerBoundIsReached"
                      >
                        <v-textarea
                          v-model="questionText"
                          :value="questionText"
                          item-text="Text"
                          item-value="Text"
                          :label="$t('labels.surveyAnswer')"
                          outlined
                          clearable
                          clear-icon="mdi-close-circle"
                          append-outer-icon="mdi-emoticon-happy"
                          @click:append-outer="openEmojiPicker()"
                          counter
                          :rules="answerRules"
                          rows="1"
                          no-resize
                          @keypress.enter.prevent
                        ></v-textarea>
                      </v-row>
                      <v-dialog
                        v-model="openEmojiPickerDialog"
                        max-width="325"
                        style="border-radius: 0px"
                        hide-overlay
                        shaped
                        persistent
                      >
                        <!-- Emoji Picker Start-->
                        <template>
                          <div id="app">
                            <VEmojiPicker @select="selectEmoji" />
                          </div>
                        </template>
                        <v-spacer></v-spacer>
                        <v-card align="center" justify="center">
                          <v-btn
                            color="primary"
                            text
                            @click="openEmojiPickerDialog = false"
                            style="border-radius: 0px"
                          >
                            {{ $t("buttons.ok") }}
                          </v-btn>
                        </v-card>
                      </v-dialog>
                      <!-- Emoji Picker End-->
                      <!-- Input answer options end -->
                      <!-- Enter answer options start -->
                      <v-row align="center" justify="center">
                        <v-btn
                          small
                          class="ma-2 mr-4"
                          elevation="0"
                          outlined
                          color="info"
                          v-if="!answerBoundIsReached"
                          @click="addAnswer()"
                        >
                          {{ $t("buttons.surveyAddAnswer") }}
                        </v-btn>
                      </v-row>
                      <!-- Enter answer options end -->
                      <!-- Empty answer alert start -->
                      <!-- Empty answer alert start -->
                      <v-row align="center" justify="center" class="mt-7">
                        <v-alert
                          v-if="emptyAnswerFlag"
                          type="warning"
                          color="#7443a0"
                          style="font-size: 1em"
                          dismissible
                        >
                          {{ $t("description.surveyEmptyAnswer") }}
                        </v-alert>
                        <!-- Empty answer alert end -->
                      </v-row>
                    </v-container>
                    <!-- Option answers end -->
                    <!-- Answer bound alert start -->
                    <v-container
                      v-if="answerBoundIsReached"
                      justify="center"
                      align="center"
                      class="px-0"
                    >
                      <div style="height: 30px"></div>
                      <v-alert
                        outlined
                        type="info"
                        color="#7443a0"
                        prominent
                        border="left"
                        class="font-weight-black"
                        style="font-size: 1.2em"
                        dismissible
                      >
                        {{ $t("description.surveyAnswerBoundReached") }}
                      </v-alert>
                    </v-container>
                    <!-- Answer bound alert end -->
                  </v-col>
                  <!-- end of multi answer type survey -->
                  <!-- if survey type is open-ended -->
                  <v-col md="12" xs="12" v-if="openSurveyStatus">
                    <v-text-field
                      v-model="surveyModel.Question"
                      :value="surveyModel.Question"
                      :label="$t('labels.surveyQuestion')"
                      @keypress.enter.prevent
                    ></v-text-field>
                  </v-col>
                  <!-- end of open-ended survey type -->
                  <!-- if survey type is link -->
                  <v-col md="12" xs="12" v-if="linkSurveyStatus">
                    <v-text-field
                      v-model="surveyModel.Question"
                      :value="surveyModel.Question"
                      :label="$t('labels.surveyLink')"
                      @keypress.enter.prevent
                    ></v-text-field>
                  </v-col>
                  <!-- end of link type survey -->
                </v-row>
                <!-- Survey creation modal question part end -->
              </v-card-text>
            </v-card-text>
            <v-card-actions
              style="
                background-color: white;
                position: sticky;
                bottom: 0px;
                border-top: solid grey 2px;
              "
            >
              <v-spacer></v-spacer>
              <v-btn color="primary" type="submit" :loading="loadings.send">
                {{ $t("buttons.save") }}
              </v-btn>
              <v-btn
                color="primary"
                text
                @click="
                  surveyDetailStatus = false;
                  clearModal();
                "
              >
                {{ $t("buttons.close") }}
              </v-btn>
            </v-card-actions>
          </form>
        </ValidationObserver>
      </v-card>
      <v-card v-if="isDetails">
        <v-card-title class="headline indigo lighten-2">
          <div class="text-center white--text" style="width: 100%">
            {{ this.modalHeader }}
          </div>
        </v-card-title>
        <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
          <form @submit.prevent="handleSubmit(sendForm)">
            <v-card-text class="pt-10 px-10">
              <v-banner rounded outlined class="mt-5">
                <v-card-title class="add-content-title">
                  {{ $t("labels.postDivision") }}
                </v-card-title>
                <v-col cols="6">
                  <v-select
                    :items="divisionItems.concat({ Name: 'Tümü', Id: -1 })"
                    item-text="Name"
                    item-value="Id"
                    v-model="selectedDivision"
                    dense
                    disabled
                  ></v-select>
                </v-col>
              </v-banner>
              <v-card-text class="py-3">
                <!-- Survey creation modal input part start -->
                <v-row>
                  <v-col md="6" xs="6">
                    <!-- Name input start -->
                    <ValidationProvider
                      name="Anket ismi"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <v-text-field
                        v-model="inspectedModel.Name"
                        :value="inspectedModel.Name"
                        :label="$t('labels.surveyName')"
                        :error-messages="errors[0]"
                        disabled
                      ></v-text-field>
                    </ValidationProvider>
                    <!-- Name input end -->
                    <!-- Type input start -->
                    <v-text-field
                      v-model="inspectedModel.LocalType"
                      :value="inspectedModel.LocalType"
                      item-text="Name"
                      item-value="Code"
                      :label="$t('labels.surveyType')"
                      disabled
                    ></v-text-field>
                    <!-- Type input end -->

                    <!-- Status input start -->
                    <v-select
                      v-model="inspectedModel.Status"
                      :value="inspectedModel.Status"
                      :items="surveyStatus"
                      item-text="Name"
                      item-value="Value"
                      :label="$t('labels.surveyStatus')"
                    ></v-select>
                    <!-- Status input end -->
                  </v-col>
                  <v-col md="6" xs="6">
                    <!-- start publish date picker -->
                    <v-text-field
                      v-model="inspectedModel.StartDate"
                      prepend-icon="mdi-calendar"
                      :label="$t('labels.surveyPublishDate')"
                      readonly
                      disabled
                    ></v-text-field>
                    <!-- end publish date picker -->
                    <!-- start end date picker -->
                    <div>
                      <v-dialog
                        ref="unPublishDateDialogInspect"
                        v-model="inspectedEndDateModal"
                        :return-value.sync="inspectedModel.EndDate"
                        persistent
                        width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <ValidationProvider
                            name="Bitiş Tarihi"
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <v-text-field
                              v-model="inspectedModel.EndDate"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              clearable
                              :label="$t('labels.surveyEndDate')"
                              :error-messages="errors[0]"
                            ></v-text-field>
                          </ValidationProvider>
                        </template>
                        <v-date-picker
                          v-model="inspectedModel.EndDate"
                          locale="tr-TR"
                          scrollable
                          :allowed-dates="disableBeforeStartDateInspectedModel"
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="primary"
                            @click="inspectedEndDateModal = false"
                          >
                            {{ $t("buttons.close") }}
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="
                              $refs.unPublishDateDialogInspect.save(
                                inspectedModel.EndDate
                              )
                            "
                          >
                            {{ $t("buttons.select") }}
                          </v-btn>
                        </v-date-picker>
                      </v-dialog>
                    </div>
                    <!-- end end date picker -->
                    <!--  anonymous checkbox start -->
                    <v-row>
                      <v-col cols="4">
                        <v-checkbox
                          v-model="inspectedModel.IsAnonymous"
                          :label="'Anonim Yanıtlar'"
                          hide-details
                          disabled
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="6">
                        <v-checkbox
                          v-model="inspectedModel.SendNotification"
                          :label="'Bildirim Olarak Gitsin'"
                          hide-details
                          disabled
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                    <!--  anonymous checkbox end -->
                  </v-col>
                </v-row>
                <!-- Survey creation modal input part end -->

                <!-- Survey creation modal question part start -->
                <v-row align="center" justify="center">
                  <!-- if survey type is single -->
                  <v-col md="12" xs="12" v-if="singleSurveyStatus">
                    <!-- Question display start -->
                    <v-container class="px-0">
                      <v-row>
                        <v-textarea
                          v-model="inspectedModel.Question"
                          :value="inspectedModel.Question"
                          :label="$t('labels.surveyQuestion')"
                          outlined
                          counter
                          rows="1"
                          no-resize
                          disabled
                          @keypress.enter.prevent
                        ></v-textarea>
                      </v-row>
                    </v-container>
                    <!-- Question display end -->
                    <!-- Option display start -->
                    <v-container class="px-0">
                      <v-row
                        v-for="(item, index) in inspectedModel.Options"
                        :key="index"
                        align="center"
                        justify="start"
                        class="mb-3 mt-1 px-5 pb-0 pt-3"
                        style="border: 1px solid grey; border-radius: 5px"
                      >
                        <p
                          class="font-weight-black"
                          style="
                                font-size: 1.2em;
                                margin-bottom: 30px !important;
                              "
                        >
                          {{ $t("labels.surveyAnswer") }}
                          {{ index + 1 }} :&nbsp;
                        </p>
                        <v-text-field
                          v-model="item.Text"
                          solo
                          :value="item.Text"
                          style="font-size: 1.2em"
                        >
                          {{ item.Text }}
                          <template slot="append">
                            <v-btn
                              class="ma-2"
                              fab
                              dark
                              x-small
                              color="red"
                              @click="clearInspectedOption(index)"
                            >
                              <v-icon dark> mdi-minus </v-icon>
                            </v-btn>
                          </template>
                        </v-text-field>
                      </v-row>
                    </v-container>
                    <!-- Option display end -->

                    <v-container class="px-0">
                      <!-- Input answer options start -->
                      <v-row
                        align="center"
                        justify="start"
                        class="mt-2"
                        v-if="!answerBoundIsReached"
                      >
                        <v-textarea
                          v-model="questionText"
                          :value="questionText"
                          item-text="Text"
                          item-value="Text"
                          :label="$t('labels.surveyAnswer')"
                          outlined
                          clearable
                          clear-icon="mdi-close-circle"
                          append-outer-icon="mdi-emoticon-happy"
                          @click:append-outer="openEmojiPicker()"
                          counter
                          :rules="answerRules"
                          rows="1"
                          no-resize
                          @keypress.enter.prevent
                        ></v-textarea>
                      </v-row>
                      <!-- Emoji Picker Start-->
                      <v-dialog
                        v-model="openEmojiPickerDialog"
                        max-width="325"
                        style="border-radius: 0px"
                        hide-overlay
                        shaped
                        persistent
                      >
                        <template>
                          <div id="app">
                            <VEmojiPicker @select="selectEmoji" />
                          </div>
                        </template>

                        <v-spacer></v-spacer>
                        <v-card
                          style="border-radius: 0px"
                          align="center"
                          justify="center"
                        >
                          <v-btn
                            color="primary"
                            text
                            @click="openEmojiPickerDialog = false"
                            style="border-radius: 0px"
                          >
                            {{ $t("buttons.ok") }}
                          </v-btn>
                        </v-card>
                      </v-dialog>
                      <!-- Emoji Picker end-->
                      <!-- Input answer options end -->
                      <!-- Enter answer options start -->
                      <v-row align="center" justify="center">
                        <v-btn
                          small
                          class="ma-2 mr-4"
                          elevation="0"
                          outlined
                          color="info"
                          v-if="!answerBoundIsReached"
                          @click="updateAnswer()"
                        >
                          {{ $t("buttons.surveyAddAnswer") }}
                        </v-btn>
                      </v-row>
                      <!-- Enter answer options end -->
                      <!-- Empty answer alert start -->
                      <v-row align="center" justify="center" class="mt-7">
                        <v-alert
                          v-if="emptyAnswerFlag"
                          type="warning"
                          style="font-size: 1em"
                          dismissible
                          color="#7443a0"
                        >
                          {{ $t("description.surveyEmptyAnswer") }}
                        </v-alert>
                        <!-- Empty answer alert end -->
                      </v-row>
                    </v-container>
                    <!-- Option answers end -->
                    <!-- Answer bound alert start -->
                    <v-container
                      v-if="answerBoundIsReached"
                      justify="center"
                      align="center"
                      class="px-0"
                    >
                      <div style="height: 30px"></div>
                      <v-alert
                        outlined
                        type="info"
                        color="#7443a0"
                        prominent
                        border="left"
                        class="font-weight-black"
                        style="font-size: 1.2em"
                        dismissible
                      >
                        <template slot="close">
                          <v-btn
                            class="ma-2"
                            fab
                            dark
                            x-small
                            color="purple"
                            @click="closeAlertUpdateSingle()"
                          >
                            <v-icon dark> mdi-close </v-icon>
                          </v-btn>
                        </template>

                        {{ $t("description.surveyAnswerBoundReached") }}
                      </v-alert>
                    </v-container>
                  </v-col>
                  <!-- end of single answer type survey -->
                  <!-- if survey type is multi -->
                  <v-col md="12" xs="12" v-if="multiSurveyStatus">
                    <!-- Question display start -->
                    <v-container class="px-0">
                      <v-text-field
                        v-model="inspectedModel.Question"
                        :value="inspectedModel.Question"
                        :label="$t('labels.surveyQuestion')"
                        disabled
                      ></v-text-field>
                    </v-container>
                    <!-- Question display end -->
                    <!-- Answer display start -->
                    <v-container class="px-0">
                      <v-row
                        v-for="(item, index) in inspectedModel.Options"
                        :key="index"
                        align="center"
                        justify="start"
                        disabled
                        class="mb-3 mt-1 px-5 pb-0 pt-3"
                        style="
                          border: 1px solid;
                          border-radius: 5px;
                          border-color: #bdbdbd;
                        "
                      >
                        <p
                          class="font-weight-black"
                          style="font-size: 1.2em; color: #bdbdbd"
                        >
                          {{ $t("labels.surveyAnswer") }}
                          {{ index + 1 }} :&nbsp;
                        </p>
                        <v-text-field
                          v-model="item.Text"
                          solo
                          :value="item.Text"
                          style="font-size: 1.2em"
                        >
                          {{ item.Text }}
                          <template slot="append">
                            <v-btn
                              class="ma-2"
                              fab
                              dark
                              x-small
                              color="red"
                              @click="deleteAnswerMultiple(index)"
                            >
                              <v-icon dark> mdi-minus </v-icon>
                            </v-btn>
                          </template>
                        </v-text-field>
                      </v-row>
                    </v-container>

                    <!-- Answer display end -->
                    <!-- Answer display start -->
                    <v-container class="px-0">
                      <!-- Input answer options start -->
                      <v-row
                        align="center"
                        justify="start"
                        class="mt-2"
                        v-if="!answerBoundIsReached"
                      >
                        <v-textarea
                          v-model="questionText"
                          :value="questionText"
                          item-text="Text"
                          item-value="Text"
                          :label="$t('labels.surveyAnswer')"
                          outlined
                          clearable
                          clear-icon="mdi-close-circle"
                          append-outer-icon="mdi-emoticon-happy"
                          @click:append-outer="openEmojiPicker()"
                          counter
                          :rules="answerRules"
                          rows="1"
                          no-resize
                          @keypress.enter.prevent
                        ></v-textarea>
                      </v-row>
                      <!-- Emoji Picker Start-->
                      <v-dialog
                        v-model="openEmojiPickerDialog"
                        max-width="325"
                        style="border-radius: 0px"
                        hide-overlay
                        shaped
                        persistent
                      >
                        <template>
                          <div id="app">
                            <VEmojiPicker @select="selectEmoji" />
                          </div>
                        </template>

                        <v-spacer></v-spacer>
                        <v-card
                          style="border-radius: 0px"
                          align="center"
                          justify="center"
                        >
                          <v-btn
                            color="primary"
                            text
                            @click="openEmojiPickerDialog = false"
                            style="border-radius: 0px"
                          >
                            {{ $t("buttons.ok") }}
                          </v-btn>
                        </v-card>
                      </v-dialog>
                      <!-- Emoji Picker end-->
                      <!-- Input answer options end -->
                      <!-- Enter answer options start -->
                      <v-row align="center" justify="center">
                        <v-btn
                          small
                          class="ma-2 mr-4"
                          elevation="0"
                          outlined
                          color="info"
                          v-if="!answerBoundIsReached"
                          @click="updateAnswer()"
                        >
                          {{ $t("buttons.surveyAddAnswer") }}
                        </v-btn>
                      </v-row>
                      <!-- Enter answer options end -->
                      <!-- Empty answer alert start -->
                      <v-row align="center" justify="center" class="mt-7">
                        <v-alert
                          v-if="emptyAnswerFlag"
                          type="warning"
                          style="font-size: 1em"
                          dismissible
                          color="#7443a0"
                        >
                          {{ $t("description.surveyEmptyAnswer") }}
                        </v-alert>
                        <!-- Empty answer alert end -->
                      </v-row>
                    </v-container>
                    <!-- Option answers end -->
                    <!-- Answer bound alert start -->
                    <v-container
                      v-if="answerBoundIsReached"
                      justify="center"
                      align="center"
                      class="px-0"
                    >
                      <div style="height: 30px"></div>
                      <v-alert
                        outlined
                        type="info"
                        color="#7443a0"
                        prominent
                        border="left"
                        class="font-weight-black"
                        style="font-size: 1.2em"
                        dismissible
                      >
                        <template slot="close">
                          <v-btn
                            class="ma-2"
                            fab
                            dark
                            x-small
                            color="purple"
                            @click="closeAlertUpdateSingle()"
                          >
                            <v-icon dark> mdi-close </v-icon>
                          </v-btn>
                        </template>

                        {{ $t("description.surveyAnswerBoundReached") }}
                      </v-alert>
                    </v-container>
                    <!-- Answer display end -->
                  </v-col>
                  <!-- end of multi answer type survey -->
                  <!-- if survey type is open-ended -->
                  <v-col md="12" xs="12" v-if="openSurveyStatus">
                    <v-text-field
                      v-model="inspectedModel.Question"
                      :value="inspectedModel.Question"
                      label="Soru"
                      disabled
                    ></v-text-field>
                  </v-col>
                  <!-- end of open-ended survey type -->
                  <!-- if survey type is link -->
                  <v-col md="12" xs="12" v-if="linkSurveyStatus">
                    <v-text-field
                      v-model="inspectedModel.Question"
                      :value="inspectedModel.Question"
                      label="Link"
                      disabled
                    ></v-text-field>
                  </v-col>
                  <!-- end of link type survey -->
                </v-row>
                <!-- Survey creation modal question part end -->
              </v-card-text>
            </v-card-text>
            <v-card-actions
              style="
                background-color: white;
                position: sticky;
                bottom: 0px;
                border-top: solid grey 2px;
              "
            >
              <v-spacer></v-spacer>
              <v-btn color="primary" type="submit" :loading="loadings.send">
                {{ $t("buttons.save") }}
              </v-btn>
              <v-btn
                color="primary"
                text
                @click="
                  surveyDetailStatus = false;
                  clearModal();
                "
              >
                {{ $t("buttons.close") }}
              </v-btn>
            </v-card-actions>
          </form>
        </ValidationObserver>
      </v-card>
    </v-dialog>
    <!-- Survey deletion modal -->
    <delete-modal :yesFunc="deleteItem" ref="deleteModalRef" />
    <!-- Page content -->
    <v-card style="border-radius: 10px">
      <v-card-title style="color: #595959">
        <v-row>
          <v-col class="py-2">{{ $t("title.surveys") }}</v-col>
          <v-spacer />
          <v-btn
            small
            class="ma-2 mr-4"
            elevation="0"
            outlined
            color="info"
            @click="
              surveyDetailStatus = true;
              isDetails = false;
              surveyModel.Type = '';
              modalHeader = $t('title.createSurvey');
            "
          >
            {{ $t("buttons.addNew") }}
          </v-btn>
        </v-row>
      </v-card-title>

      <v-divider />

      <v-card-text class="pt-4">
        <v-row align="center" justify="space-between">
          <v-col cols="3">
            <v-select
              v-model="groupPartSurveyType"
              :value="groupPartSurveyType"
              :items="surveyTypes.concat({ Name: 'Tümü', Code: '' })"
              item-text="Name"
              item-value="Code"
              :label="$t('labels.surveyType')"
              @change="filterSurveyByType()"
              required
            ></v-select>
          </v-col>

          <v-col cols="2">
            <v-select
              v-model="itemsPerPage"
              :items="perPageValues"
              :label="$t('labels.selectItemsPerPage')"
              item-value="value"
              item-text="name"
              @change="changeItemsPerPage()"
            >
            </v-select>
          </v-col>
        </v-row>

        <v-data-table
          :headers="headers"
          :items="tableData"
          :loading="loadings.table"
          :options.sync="options"
          :hide-default-footer="true"
          :footer-props="{
            disablePagination: true,
            disableItemsPerPage: true,
          }"
          :no-data-text="$t('description.noList')"
          ref="surveyList"
        >
          <!-- eslint-disable -->
          <template v-slot:item.Name="{ item }">
            <div style="font-weight: bold !important">{{ item.Name }}</div>
          </template>
          <template v-slot:item.User="{ item }">
            <!-- {{ item.User.FirstName + " " + item.User.LastName }} -->
            {{ getFullName(item) }}
          </template>

          <template v-slot:item.AnswerCount="{ item }">
            {{ item.AnswerCount == 0 ? "Cevaplanmadı" : item.AnswerCount }}
          </template>

          <template v-slot:item.CreatedAt="{ item }">
            {{ convertToLocal(item.CreatedAt) }}
          </template>

          <!-- eslint-disable -->
          <template v-slot:item.EndDate="{ item }">
            {{ convertToLocal(item.EndDate) }}
          </template>

          <template v-slot:item.Type="{ item }">
            {{ convertToLocalType(item.Type) }}
          </template>

          <template v-slot:item.Status="{ item }">
            <v-chip
              v-if="item.Status == 1"
              class="ma-2"
              small
              color="green"
              text-color="white"
            >
              {{ $t("labels.tooltipPublished") }}
            </v-chip>

            <v-chip
              v-if="item.Status == 0"
              class="ma-2 text--center"
              small
              color="grey"
              text-color="white"
            >
              {{ $t("labels.tooltipDraft") }}
            </v-chip>

            <v-chip
              v-if="item.Status == 10"
              class="ma-2"
              small
              color="#f51b6b"
              text-color="white"
            >
              {{ $t("labels.tooltipUnpublished") }}
            </v-chip>

            <v-chip
              v-if="item.Status == 20"
              class="ma-2"
              small
              color="green"
              text-color="white"
            >
              {{ $t("labels.tooltipPublished") }}
            </v-chip>

            <v-chip
              v-if="item.Status == -20"
              class="ma-2"
              small
              color="#F44336"
              text-color="white"
            >
              {{ $t("labels.tooltipReject") }}
            </v-chip>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  class="mr-2"
                  @click="getSurveyDetails(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-pencil
                </v-icon>
              </template>
              <span>{{ $t("labels.tooltipEdit") }}</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  class="mr-2"
                  @click="openDeleteModal(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-delete
                </v-icon>
              </template>
              <span>{{ $t("labels.tooltipDelete") }}</span>
            </v-tooltip>
            <v-tooltip top v-if="item.AnswerCount">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  class="mr-2"
                  @click="downloadSurvey(item.Id, item.Name)"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-download
                </v-icon>
              </template>
              <span>{{ $t("labels.surveyDownload") }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
        <div class="text-xs-center pt-2 md4 lg4">
          <v-pagination
            @input="paginationChangeHandler"
            :value="pagination.page"
            :length="pages"
            :total-visible="10"
            circle
          ></v-pagination>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import SurveyService from "../services/SurveyService";
import DeleteModal from "../components/General/DeleteModal";
import GroupService from "../services/GroupService";
import { mapActions } from "vuex";
import { VEmojiPicker } from "v-emoji-picker";
export default {
  data: () => ({
    // List options
    options: {
      itemsPerPage: 15,
    },
    top: 15,
    skip: 0,
    tableData: [],
    headers: [],
    //Loading states
    loadings: {
      table: false,
      select: false,
      send: false,
    },
    //Initial models
    questionRules: [(v) => v.length <= 150 || "Maksimum 150 karakter giriniz."],
    answerRules: [(v) => v.length <= 50 || "Maksimum 50 karakter giriniz."],
    surveyDetailStatus: false,
    // To add new model
    surveyModel: {
      Name: "",
      Type: "",
      StartDate: "",
      EndDate: "",
      Status: "",
      Question: "",
      Options: [],
      Groups: [],
      Anonymous: false,
      SendNotification: false,
    },
    // To inspect a model
    inspectedModel: {
      Name: "",
      Type: "",
      StartDate: "",
      EndDate: "",
      Status: "",
      Question: "",
      Options: [],
      Groups: [],
    },
    deleteItemModel: {},
    isDetails: false,
    editItemId: null, //Inspected item ID
    modalHeader: "", //Global header for opened modal

    //Group modals for detail
    filteredDataSelect: [],
    createdModelGroupIds: {},
    filteredModel: {},

    //Survey filters
    surveyTypes: [],
    surveyStatus: [],

    //Date picker modals
    publishDateModal: false,
    unPublishDateModal: false,
    inspectedEndDateModal: false,

    // Emoji picker
    openEmojiPickerDialog: false,
    //Question type modal flags
    singleSurveyStatus: false,
    multiSurveyStatus: false,
    openSurveyStatus: false,
    linkSurveyStatus: false,

    //Controllers for question inputs
    surveyQuestionIndex: null,
    answerBoundIsReached: false,
    emptyAnswerFlag: false,
    questionText: "",
    inputLengthFlag: false,

    //All group handler for not calling service over and over
    selectItems: [],

    //Full name object
    fullName: "",
    // Group row flag
    isGroupCreated: false,
    // Main page group type selector
    groupPartSurveyType: "",
    test: false,

    //Division select modals
    selectedDivision: null,
    divisionItems: [],

    pagination: {},
    itemsPerPage: 15,
    perPageValues: [
      {
        name: "10",
        value: 10,
      },
      {
        name: "15",
        value: 15,
      },
      {
        name: "30",
        value: 30,
      },
      {
        name: "50",
        value: 50,
      },
      {
        name: "100",
        value: 100,
      },
    ],
  }),

  watch: {},
  mounted() {
    this.setup();
    this.getTableList();
  },
  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      ) {
        return 0;
      }

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
    totalRecords() {
      return this.items.length;
    },
    pageCount() {
      return this.totalRecords / this.itemsPerPage;
    },
  },
  components: {
    DeleteModal,
    VEmojiPicker,
  },

  methods: {
    getDivisions() {
      GroupService.getDivisions()
        .then((res) => {
          this.divisionItems = res.data;
          this.divisionItems.unshift({ Id: -1, Name: "Tümü" });
        })
        .catch((err) => {
          window.showError(err);
        })
        .then(() => {});
    },
    changeItemsPerPage() {
      this.pagination.rowsPerPage = this.itemsPerPage;
      this.pagination.page = 1;
      this.options.itemsPerPage = this.itemsPerPage;
      this.getTableList();
    },
    paginationChangeHandler(pageNumber) {
      this.pagination.page = pageNumber;
      this.rowsPerPage = this.itemsPerPage;
      this.getTableList();
    },
    clearInspectedOption(index) {
      this.surveyQuestionIndex = this.surveyQuestionIndex - 1;
      this.inspectedModel.Options.splice(index, 1);
      this.answerBoundIsReached = false;
      this.questionText = "";
    },
    confirmText(val, index) {
      this.surveyModel.Options[index].optionFlat = true;
    },
    confirmText2(val, index) {
      this.surveyModel.Options[index].optionFlat = false;
    },
    confirmSingleChoiceTextMultiple(val, index) {
      this.surveyModel.Options[index].optionFlat = true;
    },
    confirmSingleChoiceTextMultiple2(val, index) {
      this.surveyModel.Options[index].optionFlat = false;
    },
    closeAlertUpdateSingle() {
      this.answerBoundIsReached = false;
      this.questionText = "";
    },

    //Page setup
    setup() {
      this.modalHeader = this.$t("title.moduleModalCreate");
      this.surveyQuestionIndex = 1;
      this.answerBoundIsReached = false;
      this.headers = [
        {
          text: "Anket İsmi",
          value: "Name",
          sortable: false,
        },
        {
          text: this.$t("title.tableHeaderFullNameSurvey"),
          value: "User",
          sortable: false,
          align: "center",
        },
        {
          text: this.$t("title.tableHeaderSurveyType"),
          value: "Type",
          sortable: false,
        },
        {
          text: this.$t("title.tableHeaderCreateDate"),
          value: "CreatedAt",
          sortable: false,
          align: "center",
        },
        {
          text: this.$t("title.tableHeaderEndDate"),
          value: "EndDate",
          sortable: false,
          align: "center",
        },
        {
          text: this.$t("title.tableHeaderSurveyAnswerCount"),
          value: "AnswerCount",
          sortable: false,
          align: "center",
        },
        {
          text: this.$t("title.tableHeaderStatus"),
          value: "Status",
          sortable: false,
          align: "center",
        },
        {
          text: this.$t("title.tableHeaderActions"),
          value: "actions",
          sortable: false,
        },(this.pagination = {
          totalItems: 200,
          rowsPerPage: this.itemsPerPage,
          page: 1,
        }),
      ];
      this.surveyTypes = [
        {
          Name: "Çoktan Seçmeli - Tek Cevaplı",
          Code: "Single Choice",
        },
        {
          Name: "Çoktan Seçmeli - Birden Fazla Cevaplı",
          Code: "Multi Choice",
        },
        {
          Name: "Açık Uçlu",
          Code: "Open-ended",
        },
        {
          Name: "Link",
          Code: "Link",
        },
      ];
      this.surveyStatus = [
        {
          Name: "Yayında",
          Code: "Published",
          Value: 20,
        },
        {
          Name: "Red",
          Code: "Rejected",
          Value: -20,
        },
        {
          Name: "Yayından Kaldırıldı",
          Code: "UnPublished ",
          Value: 10,
        },
        {
          Name: "Taslak",
          Code: "Draft",
          Value: 0,
        },
      ];

      this.pagination = {
        totalItems: 100,
        rowsPerPage: 15,
        page: 1,
      };

      // this.fillGroups();
      this.getDivisions();
      this.selectedDivision = -1;
    },

    // Validation to disable previous dates before the start date in the date-picker dialog
    disableBeforeStartDateSurveyModel(val) {
      return val > this.surveyModel.StartDate;
    },
    disableBeforeStartDateInspectedModel(val) {
      return val > this.inspectedModel.StartDate;
    },

    getFullName(item) {
      if (!!item.User) {
        return item.User.FirstName + " " + item.User.LastName;
      } else {
        return "-";
      }
    },

    //Fill's the survey list
    getTableList() {
      this.tableData = [];
      this.$store.state.loadingStatus = true;
      SurveyService.getSurveys(
        this.pagination.rowsPerPage,
        this.pagination.page,
        this.groupPartSurveyType
      )
        .then((res) => {
          this.tableData = res.data.Result;
          console.log("Table data > ", this.tableData);
          this.pagination.totalItems = res.data.TotalItems;
        })
        .catch((err) => {
          window.handleServiceError(err);
        })
        .then(() => {
          this.$store.state.loadingStatus = false;
        });
    },
    //Clears all modal's states and item ID
    clearModal() {
      this.surveyModel = {
        Name: "",
        Type: "",
        StartDate: "",
        EndDate: "",
        Question: "",
        Status: "",
        Options: [],
        Groups: [],
        Anonymous: false,
        SendNotification: false,
      };
      this.inspectedModel = {
        Name: "",
        Type: "",
        StartDate: "",
        EndDate: "",
        Question: "",
        Status: "",
        Options: [],
        Groups: [],
      };
      //Reset all the flags, modals and references
      this.modalHeader = "";
      this.$refs.observer.reset();
      this.createdModelGroupIds = {};
      this.editItemId = null;
      this.answerBoundIsReached = false;
      this.singleSurveyStatus = false;
      this.multiSurveyStatus = false;
      this.openSurveyStatus = false;
      this.surveyQuestionIndex = 1;
      this.questionText = "";

      //Must add a one line of groups into modal
      this.isGroupCreated = false;
      this.openEmojiPickerDialog = false;
      this.emptyAnswerFlag = false;
      this.selectedDivision = -1;
    },

    // Emoji picker
    selectEmoji(emoji) {
      if (this.questionText !== null) {
        this.questionText = `${this.questionText} ${emoji.data}`;
      } else {
        this.questionText = emoji.data;
      }
    },
    openEmojiPicker() {
      this.openEmojiPickerDialog = true;
    },

    //List survey table by filter survey type
    filterSurveyByType() {
      // this.loadings.table = true;
      this.$store.state.loadingStatus = true;

      this.tableData = [];

      this.pagination.rowsPerPage = 15;
      this.itemsPerPage = 15;
      this.pagination.page = 1;
      this.getTableList();
    },

    //Changes survey type on modal.
    changeSurveyType() {
      this.surveyModel.Options = [];
      switch (this.surveyModel.Type || this.inspectedModel.Type) {
        case "Single Choice":
          this.multiSurveyStatus = false;
          this.openSurveyStatus = false;
          this.singleSurveyStatus = true;
          this.answerBoundIsReached = false;
          this.emptyAnswerFlag = false;
          this.linkSurveyStatus = false;
          this.surveyModel.Question = "";
          break;
        case "Multi Choice":
          this.multiSurveyStatus = true;
          this.openSurveyStatus = false;
          this.singleSurveyStatus = false;
          this.answerBoundIsReached = false;
          this.emptyAnswerFlag = false;
          this.linkSurveyStatus = false;
          this.surveyModel.Question = "";
          break;
        case "Open-ended":
          this.multiSurveyStatus = false;
          this.openSurveyStatus = true;
          this.singleSurveyStatus = false;
          this.emptyAnswerFlag = false;
          this.answerBoundIsReached = false;
          this.linkSurveyStatus = false;
          this.surveyModel.Question = "";
          break;
        case "Link":
          this.multiSurveyStatus = false;
          this.openSurveyStatus = false;
          this.singleSurveyStatus = false;
          this.emptyAnswerFlag = false;
          this.answerBoundIsReached = false;
          this.linkSurveyStatus = true;
          this.surveyModel.Question = "";
          break;

        default:
          break;
      }
      this.surveyQuestionIndex = 1;
    },
    //Adds answer option for selected survey type
    addAnswer() {
      if (this.questionText != "") {
        this.emptyAnswerFlag = false;

        if (this.surveyQuestionIndex == 5) {
          this.answerBoundIsReached = true;
        }

        if (this.surveyQuestionIndex > 5) {
          this.answerBoundIsReached = true;
        } else {
          let body = {
            OptionId: this.surveyQuestionIndex,
            Text: this.questionText,
            Type: "",
            optionFlat: false,
          };
          this.surveyQuestionIndex++;

          this.surveyModel.Options.push(body);
        }
        this.questionText = "";
      } else {
        this.emptyAnswerFlag = true;
      }
    },
    updateAnswer() {
      if (this.inspectedModel.Options.length == 5) {
        this.answerBoundIsReached = true;
      } else {
        if (this.questionText != "") {
          this.emptyAnswerFlag = false;

          if (this.surveyQuestionIndex == 5) {
            this.answerBoundIsReached = true;
          }

          if (this.surveyQuestionIndex > 5) {
            this.answerBoundIsReached = true;
          } else {
            let body = {
              OptionId: this.surveyQuestionIndex,
              Text: this.questionText,
              Type: "",
              optionFlat: false,
            };
            this.surveyQuestionIndex++;

            this.inspectedModel.Options.push(body);
          }
          this.questionText = "";
        } else {
          this.emptyAnswerFlag = true;
        }
      }
    },
    //Submits the form whether it is an update operation or create operation
    sendForm() {
      // One answered control
      if (this.questionText !== null && !this.editItemId) {
        this.addAnswer();
      }
      if (this.editItemId) {
        this.updateSurvey();
      } else {
        this.createSurvey();
      }
    },
    //Create operation
    createSurvey() {
      this.surveyModel.Options.forEach((x) => {
        delete x.optionFlat;
      });

      this.emptyAnswerFlag = false;
      if (
        this.surveyModel.EndDate > this.surveyModel.StartDate ||
        this.surveyModel.EndDate == this.surveyModel.StartDate
      ) {
        this.surveyModel.Groups = [];
        let divArr = [[this.selectedDivision]];
        this.surveyModel.GroupIds = this.selectedDivision == -1 ? [[]] : divArr; // -1 : Tümü

        console.log("Survey model >> ", this.surveyModel);

        this.$store.state.loadingStatus = true;
        this.loadings.send = true;
        SurveyService.createSurvey(this.surveyModel)
          .then((res) => {
            this.getTableList();
            this.clearModal();
            this.surveyDetailStatus = false;
          })
          .catch((err) => {
            window.handleServiceError(err);
          })
          .then(() => {
            this.loadings.send = false;
            this.$store.state.loadingStatus = false;
          });
      } else {
        window.showInfo(
          this.$t("description.wrongDateInput"),
          "standart",
          "error"
        );
      }
    },
    //Inspect an item in list
    getSurveyDetails(item) {
      this.modalHeader = this.$t("title.surveyDetails");
      this.editItemId = item.Id;
      console.log("ITEM >> ", item);

      this.selectedDivision = item.GroupIds[0].filter((x) => x != null).pop();
      //Use item data to fill other parts of detail modal
      this.inspectedModel = {
        Id: item.Id,
        Name: item.Name,
        Type: item.Type,
        StartDate: convertToDatepicker(convertToLocal(item.StartDate)),
        EndDate: convertToDatepicker(convertToLocal(item.EndDate)),
        Status: item.Status,
        Question: item.Question,
        Options: item.Options,
        LocalType: this.convertToLocalType(item.Type),
        IsAnonymous: item.IsAnonymous,
        SendNotification: item.SendNotification,
      };

      this.changeSurveyType();
      if (item.Options.length >= 4) {
        this.surveyQuestionIndex = 5;
        this.answerBoundIsReached = true;
      }
      this.isDetails = true;
      this.surveyDetailStatus = true;
      // this.inspectedModel.Options.forEach((x) => {
      //   x.optionFlat = false;
      // });
      // console.log("inspected model>", this.inspectedModel);
    },

    //Update operation
    updateSurvey() {
      this.inspectedModel.Options.forEach((x) => {
        delete x.optionFlat;
      });
      this.$store.state.loadingStatus = true;
      this.emptyAnswerFlag = false;
      let body = {
        Id: this.inspectedModel.Id,
        Status: this.inspectedModel.Status,
        EndDate: this.inspectedModel.EndDate,
        Options: this.inspectedModel.Options,
        VoteCount: 0,
      };
      console.log("body=>", body);
      // this.loadings.table = true;
      SurveyService.updateSurveyDetails(body)
        .then((res) => {
          this.getTableList();
          this.surveyDetailStatus = false;
          this.clearModal();
        })
        .catch((err) => {
          window.handleServiceError(res, err);
        })
        .then(() => {
          // this.loadings.table = false;
          this.$store.state.loadingStatus = false;
        });
    },
    //Opens modal for deletion operation
    openDeleteModal(item) {
      this.deleteItemModel = item;
      this.$refs.deleteModalRef.modalStatus = true;
    },
    //Delete operation
    deleteItem() {
      // this.loadings.table = true;
      this.$store.state.loadingStatus = true;

      SurveyService.deleteSurvey(this.deleteItemModel.Id)
        .then(() => {
          this.getTableList();
          this.$refs.deleteModalRef.modalStatus = false;
        })
        .catch((err) => {
          window.handleServiceError(res, err);
        })
        .then(() => {
          // this.loadings.table = false;
          this.$store.state.loadingStatus = false;
        });
    },

    //Deletes an multi answered question answer
    deleteAnswer(index) {
      //If answer bound is reached
      if (this.answerBoundIsReached) {
        this.answerBoundIsReached = false;
        //Minus two with respect to index starting to 0
      }
      this.surveyQuestionIndex = this.surveyQuestionIndex - 1;

      //Remove the index that is given in the array
      this.surveyModel.Options.splice(index, 1);
    },
    deleteAnswerMultiple(index) {
      //If answer bound is reached
      if (this.answerBoundIsReached) {
        this.answerBoundIsReached = false;
        //Minus two with respect to index starting to 0
      }
      this.surveyQuestionIndex = this.surveyQuestionIndex - 1;

      //Remove the index that is given in the array
      this.inspectedModel.Options.splice(index, 1);
    },
    //Dowload the survey by survey ID

    downloadSurvey(id, fileName) {
      // this.loadings.table = true;
      this.$store.state.loadingStatus = true;
      SurveyService.downloadSurveyAnswers(id, fileName)
        .then((res) => {})

        .catch((err) => {
          window.handleServiceError(res, err);
        })
        .then(() => {
          // this.loadings.table = false;
          this.$store.state.loadingStatus = false;
        });
    },
    // Survey Type Name Localization
    convertToLocalType(type) {
      if (
        type === this.surveyTypes[0].Code ||
        type === this.surveyTypes[0].Name
      )
        return this.surveyTypes[0].Name;
      else if (
        type === this.surveyTypes[1].Code ||
        type === this.surveyTypes[1].Name
      )
        return this.surveyTypes[1].Name;
      else if (
        type === this.surveyTypes[2].Code ||
        type === this.surveyTypes[2].Name
      )
        return this.surveyTypes[2].Name;
      else if (
        type === this.surveyTypes[3].Code ||
        type === this.surveyTypes[3].Name
      )
        return this.surveyTypes[3].Name;
    },

    //Gets sidebar data dynamically
    ...mapActions(["getMenuData"]),
  },
};
</script>
