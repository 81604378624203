<template>
  <div>
    <!-- Document modal -->
    <v-dialog
      persistent
      v-model="documentDetailStatus"
      max-width="1000"
      ref="documentPopupModal"
    >
      <v-card v-if="isDetails">
        <v-card-title class="headline indigo lighten-2">
          <div class="text-center white--text" style="width: 100%">
            {{ this.modalHeader }}
          </div>
        </v-card-title>
        <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
          <form @submit.prevent="handleSubmit(sendForm)">
            <v-card-text class="pt-10 px-10">
              <v-row class="ml-1">
                <v-card-title class="add-content-document-title ">
                  {{ $t("labels.documentName") + " : " }}
                </v-card-title>
                <v-card-title
                  class="add-content-document-sub-title text-uppercase"
                >
                  {{ inspectedModel.Title }}
                </v-card-title>
              </v-row>
              <!-- <v-row class="ml-1 mt-6 mb-6">
                <v-card-title class="add-content-document-title ">
                  {{ $t("labels.documentContent") + " : " }}
                </v-card-title>
                <v-card-title class="add-content-document-sub-title">
                  {{ strippedContent(inspectedModel.Content) }}
                </v-card-title>
              </v-row> -->
              <v-card-text class="px-0 py-0 add-content-card-body">
                <v-checkbox
                  class="mt-6 ml-0 font-weight-medium"
                  v-model="sendNotification"
                  color="indigo"
                  :label="$t('labels.notificationSendMessage')"
                ></v-checkbox>
              </v-card-text>

              <!-- Date selection start -->
              <v-col cols="5">
                <v-card-text class="px-0 py-0 add-content-card-body">
                  <v-menu
                    ref="datePickerRef"
                    v-model="datePickerModel"
                    :close-on-content-click="false"
                    :return-value.sync="date"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="dateRangeText"
                        :label="$t('labels.selectDate')"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="dates"
                      locale="tr-TR"
                      range
                      :min="date"
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="
                          datePickerModel = false;
                          dates = [];
                        "
                      >
                        {{ $t("buttons.cancel") }}
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.datePickerRef.save(date)"
                      >
                        {{ $t("buttons.ok") }}
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-card-text>
              </v-col>
              <!-- Date selection end -->

              <!-- Users & Groups start  -->
              <v-card-text class="px-0 py-0 add-content-card-body">
                <v-banner rounded outlined class="mt-1">
                  <v-card-title
                    v-if="selectedDivision == -1 || selectedDivision == null"
                    class="add-content-title"
                  >
                    {{ $t("labels.notificationUsers") }}
                  </v-card-title>

                  <v-row
                    class="mt-2"
                    v-if="selectedDivision == -1 || selectedDivision == null"
                  >
                    <v-col cols="7">
                      <v-combobox
                        v-model="comboSelect"
                        :items="comboItems"
                        item-text="FullName"
                        item-value="Id"
                        :label="$t('labels.notificationUserSelect')"
                        deletable-chips
                        class="ml-5"
                        multiple
                        small-chips
                        solo
                        hide-selected
                        :search-input.sync="search"
                        :loading="isLoading"
                        clear-icon="mdi-close-circle"
                        clearable
                        v-on:keyup.enter="searchCallNewTest"
                      >
                        <template v-slot:append-outer>
                          <v-btn
                            small
                            elevation="0"
                            outlined
                            color="info"
                            @click="searchCallNewTest()"
                          >
                            Ara
                          </v-btn>
                        </template>
                        <template v-slot:item="{ item }">
                          {{ item.FullName + " (" + item.Username + ")" }}
                        </template>
                        <template v-slot:selection="{ attrs, item, parent }">
                          <v-chip
                            v-if="item === Object(item)"
                            v-bind="attrs"
                            :color="`grey lighten-3`"
                            label
                            small
                          >
                            <span class="pr-2">
                              {{ item.FullName + " (" + item.Username + ")" }}
                            </span>
                            <v-icon small @click="parent.selectItem(item)">
                              $delete
                            </v-icon>
                          </v-chip>
                        </template>
                        <template v-slot:no-data>
                          <p class="ml-3 mt-3">
                            {{
                              searchResult == ""
                                ? $t("labels.search")
                                : searchResult
                            }}
                          </p>
                        </template>
                      </v-combobox>
                    </v-col>
                  </v-row>

                  <v-divider
                    class="mb-5 mt-5"
                    v-if="
                      comboSelect.length == 0 &&
                        (selectedDivision == -1 || selectedDivision == null)
                    "
                  />

                  <v-card-title
                    v-if="comboSelect.length == 0"
                    class="add-content-title"
                  >
                    {{ $t("labels.postDivision") }}
                  </v-card-title>

                  <v-row class="mt-2" v-if="comboSelect.length == 0">
                    <v-col cols="3">
                      <v-select
                        :items="divisionItems"
                        item-text="Name"
                        item-value="Id"
                        v-model="selectedDivision"
                        dense
                        clearable
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-banner>
              </v-card-text>
              <!-- Users & Groups end -->
            </v-card-text>
            <v-card-actions
              style="
                background-color: white;
                position: sticky;
                bottom: 0px;
                border-top: solid grey 2px;
              "
            >
              <v-spacer></v-spacer>
              <v-btn color="primary" type="submit" :loading="loadings.send">
                {{ $t("buttons.assign") }}
              </v-btn>
              <v-btn
                color="primary"
                text
                @click="
                  documentDetailStatus = false;
                  clearModal();
                "
              >
                {{ $t("buttons.close") }}
              </v-btn>
            </v-card-actions>
          </form>
        </ValidationObserver>
      </v-card>
    </v-dialog>
    <!-- Document deletion modal -->
    <delete-modal
      :yesFunc="deleteItem"
      ref="deleteModalRef"
      title="Atamayı Silme İşlemi"
      description="Seçilen atamayı silmek istediğinize emin misiniz?"
    />
    <!-- Page content -->
    <v-card style="border-radius: 10px">
      <v-card-title style="color: #595959">
        <v-row>
          <v-col class="py-2">{{ $t("title.documentAssign") }}</v-col>
          <!-- <v-spacer />
          <v-btn
            small
            class="ma-2 mr-4"
            elevation="0"
            outlined
            color="info"
            @click="
              documentDetailStatus = true;
              isDetails = false;
              modalHeader = $t('title.documentAssign');
            "
          >
            {{ $t("title.documentAssign") }}
          </v-btn> -->
        </v-row>
      </v-card-title>

      <v-divider />

      <v-card-text class="pt-4">
        <v-row align="center">
          <v-col cols="3">
            <v-select
              v-model="selectedDocumentType"
              :value="selectedDocumentType"
              :items="documentTypes.concat({ Name: 'Tümü', Code: '' })"
              item-text="Name"
              item-value="Code"
              :label="$t('labels.documentType')"
              @change="filterDocumentByType()"
              required
            ></v-select>
          </v-col>
          <v-col cols="3">
            <v-select
              v-model="selectedAssignmentType"
              :value="selectedAssignmentType"
              :items="assignmentTypes.concat({ Name: 'Tümü', Code: '' })"
              item-text="Name"
              item-value="Code"
              :label="$t('labels.assignmentType')"
              @change="filterDocumentByType()"
              required
            ></v-select>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="2">
            <v-select
              v-model="itemsPerPage"
              :items="perPageValues"
              :label="$t('labels.selectItemsPerPage')"
              item-value="value"
              item-text="name"
              @change="changeItemsPerPage()"
            >
            </v-select>
          </v-col>
        </v-row>

        <v-data-table
          :headers="headers"
          :items="tableData"
          :loading="loadings.table"
          :options.sync="options"
          :hide-default-footer="true"
          :footer-props="{
            disablePagination: true,
            disableItemsPerPage: true,
          }"
          :no-data-text="$t('description.noList')"
          ref="surveyList"
        >
          <!-- eslint-disable -->
          <template v-slot:item.Content="{ item }">
            {{ strippedContent(item.Content) }}
          </template>
          <template v-slot:item.User="{ item }">
            <!-- {{ item.User.FirstName + " " + item.User.LastName }} -->
            {{ getFullName(item) }}
          </template>

          <template v-slot:item.CreatedAt="{ item }">
            {{ convertToLocal(item.CreatedAt) }}
          </template>

          <!-- eslint-disable -->
          <template v-slot:item.actions="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  class="mr-2"
                  @click="assignDocument(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-file-document
                </v-icon>
              </template>
              <span>{{ defineDocumentSpan(item) }}</span>
            </v-tooltip>
            <v-tooltip
              v-if="
                item.PrivateOptions.UserList && item.PrivateOptions.GroupList
              "
              top
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  class="mr-2"
                  @click="openDeleteModal(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-delete
                </v-icon>
              </template>
              <span>{{ $t("labels.tooltipDeleteAssignment") }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
        <div class="text-xs-center pt-2 md4 lg4">
          <v-pagination
            @input="paginationChangeHandler"
            :value="pagination.page"
            :length="pages"
            :total-visible="10"
            circle
          ></v-pagination>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import LearningManagementService from "../services/LearningManagementService";
import DeleteModal from "../components/General/DeleteModal";
import UserService from "../services/UserService";
import GroupService from "../services/GroupService";
import { mapActions } from "vuex";
import { VEmojiPicker } from "v-emoji-picker";
export default {
  data: () => ({
    // List options
    options: {
      itemsPerPage: 15,
    },
    top: 15,
    skip: 0,
    tableData: [],
    headers: [],
    //Loading states
    loadings: {
      table: false,
      select: false,
      send: false,
    },
    //Initial models
    documentDetailStatus: false,
    // To inspect a model
    inspectedModel: {},
    deleteItemModel: {},
    isDetails: false,
    editItemId: null, //Inspected item ID
    modalHeader: "", //Global header for opened modal

    //Document filters
    documentTypes: [],
    assignmentTypes: [],

    //Division select modals
    selectedDivision: null,
    divisionItems: [],
    comboSelect: [],
    searchResult: "",
    comboItems: [],
    isLoading: false,
    searchText: "",
    lastSearchText: "",
    lastEditTime: null,
    intervalTemp: null,
    search: null,
    search2: null,
    selectedDocumentType: "",
    selectedAssignmentType: "",
    sendNotification: false,

    //Datepicker models
    dates: [],
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    datePickerModel: false,

    pagination: {},
    itemsPerPage: 15,
    perPageValues: [
      {
        name: "10",
        value: 10,
      },
      {
        name: "15",
        value: 15,
      },
      {
        name: "30",
        value: 30,
      },
      {
        name: "50",
        value: 50,
      },
      {
        name: "100",
        value: 100,
      },
    ],
  }),

  watch: {
    model(val) {
      if (val.length > 5) {
        this.$nextTick(() => this.model.pop());
      }
    },
    //Every input character is searched
    search(val) {
      val && val !== this.select && this.searchCallNew(val);
      if (val !== "" && val != null) {
      } else {
        this.comboItems = [];
        this.searchResult = "";
      }
    },
    search2(val) {
      val && val !== this.select;
    },
  },
  computed: {
    dateRangeText() {
      let arr = this.dates.map((date) => {
        return convertToLocal(date);
      });
      arr = arr.join(" - ");
      return arr;
    },
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      ) {
        return 0;
      }

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
    totalRecords() {
      return this.items.length;
    },
    pageCount() {
      return this.totalRecords / this.itemsPerPage;
    },
  },
  beforeDestroy() {
    if (this.intervalTemp) {
      clearInterval(this.intervalTemp);
      this.intervalTemp = null;
    }
  },
  components: {
    DeleteModal,
  },
  mounted() {
    this.setup();
    this.getTableList();
  },

  methods: {
    getDivisions() {
      GroupService.getDivisions()
        .then((res) => {
          this.divisionItems = res.data;
        })
        .catch((err) => {
          window.showError(err);
        })
        .then(() => {});
    },
    changeItemsPerPage() {
      this.pagination.rowsPerPage = this.itemsPerPage;
      this.pagination.page = 1;
      this.options.itemsPerPage = this.itemsPerPage;
      this.getTableList();
    },
    paginationChangeHandler(pageNumber) {
      this.pagination.page = pageNumber;
      this.rowsPerPage = this.itemsPerPage;
      this.getTableList();
    },
    //Page setup
    setup() {
      this.modalHeader = this.$t("title.moduleModalCreate");
      this.headers = [
        {
          text: this.$t("title.tableHeaderTitle"),
          value: "Title",
          sortable: false,
        },
        {
          text: this.$t("title.tableHeaderSuspensefulContent"),
          value: "Content",
          sortable: false,
        },
        {
          text: this.$t("title.tableHeaderContentOwner"),
          value: "User",
          sortable: false,
          align: "center",
        },
        {
          text: this.$t("title.tableHeaderCreateDate"),
          value: "CreatedAt",
          sortable: false,
          align: "center",
        },
        {
          text: this.$t("title.tableHeaderActions"),
          value: "actions",
          sortable: false,
        },
        (this.pagination = {
          totalItems: 200,
          rowsPerPage: this.itemsPerPage,
          page: 1,
        }),
      ];
      this.documentTypes = [
        {
          Name: this.$t("labels.documentTypePrivate"),
          Code: true,
        },
        {
          Name: this.$t("labels.documentTypePublic"),
          Code: false,
        },
      ];
      this.assignmentTypes = [
        {
          Name: this.$t("labels.documentTypeAssigned"),
          Code: true,
        },
        {
          Name: this.$t("labels.documentTypeUnassigned"),
          Code: false,
        },
      ];
      this.pagination = {
        totalItems: 100,
        rowsPerPage: 15,
        page: 1,
      };

      // this.fillGroups();
      this.getDivisions();
      this.selectedDivision = -1;
    },

    getFullName(item) {
      if (!!item.User) {
        return item.User.FirstName + " " + item.User.LastName;
      } else {
        return "-";
      }
    },
    defineDocumentSpan(item) {
      //TODO backend private options null dönmemeli ??
      if (item.PrivateOptions != null) {
        return item.PrivateOptions.UserList != null
          ? item.PrivateOptions.UserList.length > 0 &&
            item.PrivateOptions.GroupList.length > 0
            ? this.$t("labels.tooltipDocumentAssignUpdate")
            : this.$t("labels.tooltipDocumentAssign")
          : this.$t("labels.tooltipDocumentAssign");
      } else {
        return "";
      }
    },
    //Fill's the document list
    getTableList() {
      this.tableData = [];
      this.$store.state.loadingStatus = true;
      LearningManagementService.getPosts(
        this.selectedDocumentType,
        this.selectedAssignmentType,
        "akademi",
        this.pagination.rowsPerPage,
        this.pagination.page
      )
        .then((res) => {
          this.tableData = res.data.Data;
          console.log("Table data > ", this.tableData);
          this.pagination.totalItems = res.data.TotalData;
        })
        .catch((err) => {
          window.handleServiceError(err);
        })
        .then(() => {
          this.$store.state.loadingStatus = false;
        });
    },
    //Clears all modal's states and item ID
    clearModal() {
      this.inspectedModel = {};
      //Reset all the flags, modals and references
      this.modalHeader = "";
      this.$refs.observer.reset();
      this.selectedDivision = -1;
      this.comboSelect = [];
    },

    //Submits the form whether it is an update operation or create operation
    sendForm() {
      //Adjust dates
      let startDate = this.dates[0];
      let endDate = this.dates[1];
      let body = {
        StartDate: "",
        EndDate: "",
        UserList: [],
        GroupList: [],
      };
      if (this.dates.length == 0 || startDate >= endDate) {
        window.showInfo(
          this.$t("description.invalidDatePick"),
          "standart",
          "error"
        );
        //empty date picker
        this.dates = [];
      } else if (
        this.comboSelect.length == 0 &&
        (this.selectedDivision == -1 || this.selectedDivision == null)
      ) {
        window.showInfo(
          this.$t("description.invalidUserSelection"),
          "standart",
          "error"
        );
      } else {
        if (this.comboSelect.length > 0) {
          this.comboSelect.map((item) => {
            if (item.Id) {
              let tempObj = { Id: item.Id };
              body.UserList.push(tempObj);
            }
          });
        } else if (this.selectedDivision != -1) {
          body.GroupList = [{ Id: this.selectedDivision }];
        }
        body.StartDate = startDate;
        body.EndDate = endDate;
        this.assignmentFunction(body);
      }
    },
    //Create operation
    assignmentFunction(body) {
      this.inspectedModel.SendNotification = this.sendNotification;

      this.inspectedModel.PrivateOptions = body;
      this.inspectedModel.IsPrivate = true;
      this.$store.state.loadingStatus = true;
      this.documentDetailStatus = false;
      LearningManagementService.assignDocument(this.inspectedModel)
        .then((res) => {
          this.getTableList();
          this.clearModal();
        })
        .catch((err) => {
          window.handleServiceError(err);
          this.documentDetailStatus = false;
          this.clearModal();
        })
        .then(() => {
          this.loadings.send = false;
          this.$store.state.loadingStatus = false;
        });
    },
    //Inspect an item in list
    assignDocument(item) {
      this.dates = [];
      this.modalHeader = this.$t("title.documentAssign");
      this.editItemId = item.Id;
      this.inspectedModel = item;

      if (
        this.inspectedModel.PrivateOptions &&
        this.inspectedModel.PrivateOptions.StartDate &&
        this.inspectedModel.PrivateOptions.EndDate
      ) {
        this.dates[0] = this.inspectedModel.PrivateOptions.StartDate;
        this.dates[1] = this.inspectedModel.PrivateOptions.EndDate;
      }

      if (
        this.inspectedModel.PrivateOptions.UserList &&
        this.inspectedModel.PrivateOptions.UserList.length > 0
      )
        this.comboSelect = this.inspectedModel.PrivateOptions.UserList;
      if (
        this.inspectedModel.PrivateOptions.GroupList &&
        this.inspectedModel.PrivateOptions.GroupList.length > 0
      )
        this.selectedDivision = this.inspectedModel.PrivateOptions.GroupList[0].Id;

      this.isDetails = true;
      this.documentDetailStatus = true;
    },

    //Opens modal for deletion operation
    openDeleteModal(item) {
      this.deleteItemModel = item;
      this.$refs.deleteModalRef.modalStatus = true;
    },
    //Delete operation
    deleteItem() {
      console.log("DELETE ITEM MODEL >> ", this.deleteItemModel);
      Object.keys(this.deleteItemModel.PrivateOptions).forEach((key) => {
        this.deleteItemModel.PrivateOptions[key] = null;
      });
      // this.deleteItemModel.IsPrivate = false;
      this.$store.state.loadingStatus = true;
      this.$refs.deleteModalRef.modalStatus = false;
      LearningManagementService.assignDocument(this.deleteItemModel)
        .then(() => {
          this.getTableList();
        })
        .catch((err) => {
          window.handleServiceError(err);
          this.$refs.deleteModalRef.modalStatus = false;
        })
        .then(() => {
          this.$store.state.loadingStatus = false;
        });
    },

    searchCallNewTest() {
      let pageSize = 99999;
      let page = 1;
      this.isLoading = true;
      UserService.getUsers(this.searchText, pageSize, page)
        .then((res) => {
          this.comboItems = res.data.Data;
          if (this.comboItems.length <= 0) {
            this.searchResult =
              "Aradığınız kriterde kullanıcı bulunamadı.Yeniden deneyiniz.";
          }
        })
        .catch((err) => {
          window.handleServiceError(err);
        })
        .then(() => {
          // this.loadings.table = false;
          // this.$store.state.loadingStatus = false;
          this.isLoading = false;
        });
    },
    //Search operation new
    searchCallNew(val) {
      this.searchText = val;
    },
    //Search operation new end
    //Search operation
    searchCall() {
      if (
        this.lastEditTime !== null &&
        this.lastSearchText !== this.searchText &&
        new Date().getTime() - this.lastEditTime > 1000 &&
        this.searchText !== ""
      ) {
        let pageSize = 99999;
        let page = 1;
        this.isLoading = true;
        if (this.searchText == "" || this.searchText == null) {
          UserService.getUsers("", pageSize, page)
            .then((res) => {
              this.comboItems = res.data.Data;
            })
            .catch((err) => {
              window.handleServiceError(err);
            })
            .then(() => {
              // this.loadings.table = false;
              // this.$store.state.loadingStatus = false;
              this.isLoading = false;
            });
        } else {
          this.isLoading = true;
          UserService.getUsers(this.searchText, pageSize, page)
            .then((res) => {
              this.comboItems = res.data.Data;
              if (this.comboItems.length <= 0) {
                this.searchResult =
                  "Aradığınız kriterde kullanıcı bulunamadı.Yeniden deneyiniz.";
              }
            })
            .catch((err) => {
              window.handleServiceError(err);
            })
            .then(() => {
              // this.loadings.table = false;
              // this.$store.state.loadingStatus = false;
              this.isLoading = false;
            });
        }
        //Clear search props
        this.lastSearchText = this.searchText;
        clearInterval(this.intervalTemp);
        this.intervalTemp = null;
      }
    },

    querySelections(v) {
      this.lastEditTime = new Date().getTime();
      this.searchText = v;
      if (!this.intervalTemp) {
        this.intervalTemp = setInterval(() => {
          this.searchCall();
        }, 2000);
      }
    },

    //List document table by filter Document type
    filterDocumentByType() {
      // this.loadings.table = true;
      this.$store.state.loadingStatus = true;

      this.tableData = [];

      this.pagination.rowsPerPage = 15;
      this.itemsPerPage = 15;
      this.pagination.page = 1;
      this.getTableList();
    },

    //Gets sidebar data dynamically
    ...mapActions(["getMenuData"]),
  },
};
</script>
