import axios from "../javascript/custom-axios";
const RESOURCE_NAME = "/notification";

export default {
  getNotifications(PageSize=15,Page = 1) {

    return axios.get(`/admin/notifications`, { params: { NotificationType: 'Corporate' ,PageSize:PageSize,Page:Page} });
  },
  createNotification(body) {
    return axios.post(`${RESOURCE_NAME}`, body);
  },
  deleteNotification(id) {
    return axios.delete(`${RESOURCE_NAME}/${id}`);
  },
};
