import axios from "../javascript/custom-axios";
const RESOURCE_NAME = "admin/posts";

export default {
  createPost(body) {
    return axios.post(`${RESOURCE_NAME}/create`, body);
  },

  deletePost(id){
    return axios.delete(`/posts/delete/${id}`);   
  },

  updatePost(body) {
    return axios.put(`/posts/update`, body);
  },
};
