import Vue from "vue";
import Router from "vue-router";
import guard from "./javascript/navigation-guard";

import dashboard from "./views/Dashboard.vue";

import Login from "./views/Login.vue";
import Home from "./views/Home.vue";

import Groups from "./views/Groups.vue";
import Users from "./views/Users.vue";
import ModulManagement from "./views/ModulManagement.vue";
import Moduls from "./views/Moduls.vue";
import UpdateContent from "./views/UpdateContent.vue";
import EndorsementTopic from "./views/EndorsementTopic.vue";
//import Category from './views/Category.vue'
import Category from "./views/CategoryTreeview.vue";
import CustomField from "./views/CustomField.vue";
import Survey from "./views/Survey.vue";
import AskHr from "./views/AskHr.vue";
import FoodMenu from "./views/FoodMenu.vue";
import AddNewContentAdd from "./views/AddNewContent.vue";
import Stories from "./views/Stories.vue";
import Notifications from "./views/Notifications.vue";
import CreateStory from "./views/CreateStory.vue";
import BlockedUsers from "./views/BlockedUsers.vue";
import Report from "./views/Report.vue";
import ProhibitedWords from "./views/ProhibitedWords.vue";
import SuspensefulContents from "./views/SuspensefulContents.vue";
import LearningManagementSystem from "./views/LearningManagementSystem.vue";
import ForceUpdate from "./views/ForceUpdate.vue";

Vue.use(Router);

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/login",
      name: "login",
      component: Login,
      beforeEnter: guard.ifNotAuthenticated,
    },
    {
      path: "/",
      redirect: "/login",
    },
    {
      path: "/loading",
      redirect: "/AuthLoading",
    },
    {
      path: "/home",
      name: "home",
      redirect: "/home/dashboard",
      component: Home,
      children: [
        {
          name: "dashboard",
          path: "dashboard",
          component: dashboard,
          beforeEnter: guard.ifAuthenticated,
          meta: { userType: ["SuperAdmin", "Admin"] },
        },
        {
          name: "groups",
          path: "groups",
          component: Groups,
          beforeEnter: guard.ifAuthenticated,
          meta: { userType: ["SuperAdmin", "Admin"] },
        },
        {
          name: "users",
          path: "users",
          component: Users,
          beforeEnter: guard.ifAuthenticated,
          meta: { userType: ["SuperAdmin", "Admin"] },
        },
        {
          name: "moduleManagement",
          path: "moduleManagement",
          component: ModulManagement,
          beforeEnter: guard.ifAuthenticated,
          meta: { userType: ["SuperAdmin"] },
        },
        {
          name: "moduls",
          path: "moduls/:id",
          props: { default: true, sidebar: false },
          component: Moduls,
          beforeEnter: guard.ifAuthenticated,
          meta: { userType: ["SuperAdmin", "Admin"] },
        },
        {
          name: "updateContent",
          path: "updateContent/:id",
          props: { default: true, sidebar: false },
          component: UpdateContent,
          beforeEnter: guard.ifAuthenticated,
          meta: { userType: ["SuperAdmin", "Admin"] },
        },
        {
          name: "addContentAdd",
          path: "addContentAdd/:id",
          props: { default: true, sidebar: false },
          component: AddNewContentAdd,
          beforeEnter: guard.ifAuthenticated,
          meta: { userType: ["SuperAdmin", "Admin"] },
        },
        {
          name: "endorsement_topic",
          path: "endorsement_topic",
          component: EndorsementTopic,
          beforeEnter: guard.ifAuthenticated,
          meta: { userType: ["SuperAdmin", "Admin"] },
        },
        {
          name: "categories",
          path: "categories",
          component: Category,
          beforeEnter: guard.ifAuthenticated,
          meta: { userType: ["SuperAdmin", "Admin"] },
        },
        {
          name: "customfields",
          path: "custom_fields",
          props: (route) => ({
            query: route.query.id,
            default: true,
            sidebar: false,
          }),
          component: CustomField,
          beforeEnter: guard.ifAuthenticated,
          meta: { userType: ["SuperAdmin", "Admin"] },
        },
        {
          name: "survey",
          path: "survey",
          component: Survey,
          beforeEnter: guard.ifAuthenticated,
          meta: { userType: ["SuperAdmin", "Admin"] },
        },
        {
          name: "askhr",
          path: "askhr",
          component: AskHr,
          beforeEnter: guard.ifAuthenticated,
          meta: { userType: ["SuperAdmin", "Admin"] },
        },
        {
          name: "food_menus",
          path: "food_menus",
          component: FoodMenu,
          beforeEnter: guard.ifAuthenticated,
          meta: { userType: ["SuperAdmin", "Admin"] },
        },
        {
          name: "stories",
          path: "stories",
          component: Stories,
          beforeEnter: guard.ifAuthenticated,
          meta: { userType: ["SuperAdmin", "Admin"] },
        },
        {
          name: "notifications",
          path: "notifications",
          component: Notifications,
          beforeEnter: guard.ifAuthenticated,
          meta: { userType: ["SuperAdmin", "Admin"] },
        },
        {
          name: "blockedusers",
          path: "blockedusers",
          component: BlockedUsers,
          beforeEnter: guard.ifAuthenticated,
          meta: { userType: ["SuperAdmin", "Admin"] },
        },
        {
          name: "statistics",
          path: "statistics",
          component: Report,
          beforeEnter: guard.ifAuthenticated,
          meta: { userType: ["SuperAdmin", "Admin"] },
        },
        {
          name: "prohibitedWords",
          path: "prohibitedWords",
          component: ProhibitedWords,
          beforeEnter: guard.ifAuthenticated,
          meta: { userType: ["SuperAdmin", "Admin"] },
        },
        {
          name: "suspensefulContents",
          path: "suspensefulContents",
          component: SuspensefulContents,
          beforeEnter: guard.ifAuthenticated,
          meta: { userType: ["SuperAdmin", "Admin"] },
        },
        {
          name: "learningManagementSystem",
          path: "learningManagementSystem",
          component: LearningManagementSystem,
          beforeEnter: guard.ifAuthenticated,
          meta: { userType: ["SuperAdmin", "Admin"] },
        },
        {
          name: "forceUpdate",
          path: "forceUpdate",
          component: ForceUpdate,
          beforeEnter: guard.ifAuthenticated,
          meta: { userType: ["SuperAdmin", "Admin"] },
        },
        
        {
          name: "createStory",
          path: "createStory",
          component: CreateStory,
          beforeEnter: guard.ifAuthenticated,
          meta: { userType: ["SuperAdmin", "Admin"] },
        },
      ],
      //beforeEnter: guard.ifAuthenticated,
      props: true,
    },
  ],
});
