<template>
  <v-container grid-list-xl class="word-wrap mb-3">
    <div>
      <!-- Sticky update button start -->
      <v-btn
        v-scroll="onScroll"
        v-show="fab"
        dark
        fixed
        bottom
        style="z-index: 9999"
        right
        big
        class="ma-2 mr-4"
        elevation="0"
        color="primary"
        @click="stickyUpdateOperation"
      >
        {{ $t('buttons.update') }}
      </v-btn>
      <!-- Sticky update button end -->
      <!-- Info dialog start -->
      <v-dialog max-width="500" v-model="infoDialog">
        <v-card>
          <v-card-title class="text-h4 text-center justify-center mb-5">
            {{ $t('title.moduleDescription') }}
          </v-card-title>
          <v-card-text
            class="font-weight-bold pl-8 body-1"
            style="line-height: 1.8; color: #7443a0;"
            ><p v-html="moduleDescription"></p
          ></v-card-text>
          <v-card-actions
            style="
          background-color: white;
          position: sticky;
          bottom: 0px;
        "
          >
            <v-spacer></v-spacer>

            <v-btn
              color="deep-purple lighten-2"
              text
              @click="infoDialog = false"
            >
              {{ $t('buttons.close') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Info dialog end -->
      <!-- Info dialog start -->
      <v-dialog max-width="500" v-model="notificationDialog" persistent>
        <v-card>
          <v-card-title class="text-h4 text-center justify-center mb-5">
            Bilgilendirme
          </v-card-title>
          <v-card-text
            class="font-weight-bold pl-8 body-1"
            style="line-height: 1.8; color: #7443a0;"
            ><p>
              Bu güncelleme işleminde bildirim gönderilecektir. Devam etmek
              istiyor musunuz?
            </p></v-card-text
          >
          <v-card-actions
            style="
          background-color: white;
          position: sticky;
          bottom: 0px;
        "
          >
            <v-spacer></v-spacer>

            <v-btn
              color="deep-purple lighten-2"
              text
              @click="
                modalDialogInfo = true;
                notificationDialog = false;
                update();
              "
            >
              EVET
            </v-btn>
            <v-btn
              color="deep-purple lighten-2"
              text
              @click="
                notificationDialog = false;
                $store.state.loadingStatus = false;
              "
            >
              HAYIR
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Info dialog end -->

      <v-card-title style="color: #595959">
        <v-row>
          <v-col class="py-2 pl-0"
            >{{ pageHeader }} {{ $t('title.contentPageHeaderUpdate') }}</v-col
          >
          <v-spacer />
          <v-btn
            small
            class="ma-2 mr-4"
            elevation="0"
            outlined
            color="info"
            dark
            @click="informationPopUp"
          >
            <v-icon>
              mdi-information-outline
            </v-icon>
          </v-btn>
          <v-btn
            small
            v-if="updateContentDetail.Status == 20"
            class="ma-2 mr-4"
            elevation="0"
            outlined
            color="info"
            dark
            @click="updateCall(true)"
          >
            TASLAĞA KAYDET
          </v-btn>
          <v-btn
            small
            v-if="updateContentDetail.Status == 0"
            class="ma-2 mr-4"
            elevation="0"
            outlined
            color="info"
            dark
            @click="updateCall()"
          >
            YAYINA AL
          </v-btn>
          <v-btn
            small
            class="ma-2 mr-4"
            elevation="0"
            outlined
            color="info"
            @click="update()"
          >
            {{ $t('buttons.update') }}
          </v-btn>
        </v-row>
      </v-card-title>
      <!-- Form buttons end -->
      <v-divider />

      <v-layout row wrap class="px-0 py-10">
        <v-flex xs12 md8>
          <!-- Groups start  -->
          <!-- <group-modal
            ref="groups"
            :pageRules="pageRules"
            :isUpdate="true"
            @returnFunc="tempFunc($event)"
          ></group-modal> -->
          <!-- <v-card-text class="px-0 py-0 add-content-card-body">
            <v-banner rounded outlined class="mt-5">
              <v-card-title class="add-content-title">
                {{ $t("labels.postGroup") }}
              </v-card-title>
              <v-row
                class="mt-2"
                v-for="(data, rowIndex) in vSelectData"
                :key="rowIndex"
              >
                <v-row align="center" justify="center">
                  <v-col cols="11">
                    <v-row v-if="!pageRules.isPublicGroups">
                      <v-col
                        cols="4"
                        v-for="(element, colIndex) in data"
                        :key="colIndex"
                      >
                        <ValidationProvider
                          :vid="'attribute' + rowIndex + colIndex"
                          name="Grup"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <div row wrap class="px-5 mt-3">
                            <v-select
                              :key="(rowIndex + colIndex) * 20"
                              v-model="selectedItems[rowIndex][colIndex]"
                              :items="element.Data"
                              :label="
                                element.Name == 'Şirket'
                                  ? 'Kuruluş'
                                  : element.Name
                              "
                              :disabled="disableLoc[rowIndex] && colIndex == 1"
                              item-text="Name"
                              item-value="Id"
                              :error-messages="errors[0]"
                              dense
                              @change="
                                changeGroupInput(element, colIndex, rowIndex)
                              "
                              @click="
                                clickGroupFilterModal(
                                  element,
                                  colIndex,
                                  rowIndex
                                )
                              "
                            ></v-select>
                          </div>
                        </ValidationProvider>
                      </v-col>
                    </v-row>
                    <v-row class="ml-5" v-if="pageRules.isPublicGroups">
                      <v-card-title
                        class="add-content-title"
                        style="font-size: 12px !important;"
                        >{{ $t("description.communityModule") }}</v-card-title
                      >
                    </v-row>
                  </v-col>
                  <v-col cols="1">
                    <v-icon
                      v-if="vSelectData.length != 1"
                      @click="deleteGroupRow(rowIndex)"
                      >mdi-trash-can-outline</v-icon
                    >
                  </v-col>
                </v-row>
              </v-row>
              <v-row>
                <v-col cols="2" v-if="!pageRules.isPublicGroups">
                  <v-btn
                    small
                    v-if="!groupLengthAlert"
                    class="ma-1"
                    outlined
                    color="info"
                    @click="createNewGroup()"
                  >
                    {{ $t("buttons.addNewGroup") }}
                  </v-btn>
                </v-col>

                <v-col class="mt-2">
                  <v-alert
                    v-model="groupLengthAlert"
                    border="left"
                    close-text="Close Alert"
                    type="info"
                    color="#7443a0"
                    dismissible
                    outlined
                    prominent
                    class="font-weight-black"
                    style="font-size: 1.2em"
                  >
                    {{ $t("description.groupLengthMessage") }}
                  </v-alert>
                </v-col>
              </v-row>
            </v-banner>
          </v-card-text> -->
          <!-- Groups end -->
          <!-- Division select start  -->
          <!-- <v-card-text class="px-0 py-0 add-content-card-body">
            <v-banner rounded outlined class="mt-5">
              <v-card-title class="add-content-title">
                {{ $t("labels.postDivision") }}
              </v-card-title>

              <v-col cols="6">
                <v-select
                  v-if="!pageRules.isPublicGroups"
                  :items="divisionItems"
                  item-text="Name"
                  item-value="Id"
                  v-model="selectedDivision"
                  dense
                  return-object
                  single-line
                ></v-select>
              </v-col>
              <v-card-title
                class="add-content-title"
                style="font-size: 12px !important;"
                v-if="pageRules.isPublicGroups"
                >{{ $t("description.communityModule") }}</v-card-title
              >
            </v-banner>
          </v-card-text> -->

          <v-card-text class="px-0 py-0 add-content-card-body">
            <v-banner rounded outlined class="mt-5">
              <v-card-title class="add-content-title">
                {{ $t('labels.postType') }}
              </v-card-title>

              <v-layout v-if="!pageRules.isPublicGroups">
                <v-col cols="12">
                  <v-layout class="mx-2">
                    <v-radio-group v-model="contentType" row color="red">
                      <v-radio
                        :label="$t('labels.batnews')"
                        :value="1"
                        color="purple"
                      ></v-radio>
                      <v-radio
                        :label="$t('labels.bspPlus')"
                        :value="2"
                        color="purple"
                      ></v-radio>
                    </v-radio-group>
                  </v-layout>
                  <v-layout v-if="contentType == 2">
                    <v-col cols="6">
                      <v-select
                        :items="divisionItems"
                        item-text="Name"
                        item-value="Id"
                        v-model="selectedDivision"
                        dense
                        single-line
                      ></v-select>
                    </v-col>
                  </v-layout>
                </v-col>
              </v-layout>

              <v-card-title
                class="add-content-title"
                style="font-size: 12px !important;"
                v-if="pageRules.isPublicGroups"
                >{{ $t('description.communityModule') }}</v-card-title
              >
            </v-banner>
          </v-card-text>
          <!-- Division select end  -->
          <!-- Content start -->
          <!-- Title -->
          <v-card-text
            class="px-0 py-0 add-content-card-body"
            v-if="pageRules.title.isShow"
          >
            <v-banner rounded outlined class="mt-5 my-2 mb-5">
              <v-text-field
                :label="pageRules.title.defaultLabel"
                v-model="updateContentDetail.Title"
                :disabled="pageRules.title.isDisabled"
              ></v-text-field>
              <v-card-title
                class="add-content-title"
                style="font-size: 12px !important;"
                >{{ pageRules.title.description }}</v-card-title
              >
            </v-banner>
          </v-card-text>
          <!-- Title end -->

          <!-- İçerik Bölümü -->
          <v-card-text class="px-0 py-0" v-if="pageRules.content.isShow">
            <ckeditor
              :name="pageRules.content.defaultLabel"
              :editor="editor"
              v-model="updateContentDetail.Content"
              :config="editorConfig"
            ></ckeditor>
          </v-card-text>
          <v-card-text
            class="px-0 py-2 add-content-card-body"
            v-if="pageRules.link.isShow"
          >
            <v-banner rounded outlined class="mt-5 my-2">
              <ValidationProvider
                :name="pageRules.link.defaultLabel"
                :rules="pageRules.link.isRequired ? 'required' : null"
                v-slot="{ errors }"
              >
                <v-text-field
                  :label="pageRules.link.defaultLabel"
                  v-model="updateContentDetail.Content"
                  :error-messages="errors[0]"
                ></v-text-field>
              </ValidationProvider>
            </v-banner>
          </v-card-text>
          <!-- İçerik end -->
          <!-- Content end -->

          <!-- Custom Field Start -->
          <v-card-text
            class="px-0 py-0 add-content-card-body"
            v-if="propertiesFlag"
          >
            <v-banner outlined rounded class="mt-5">
              <v-card-title class="add-content-title">
                {{ $t('labels.postCustomField') }}
              </v-card-title>
              <v-row class="mt-5 mx-2">
                <v-col
                  cols="4"
                  v-for="(element, index) in updateContentDetail.Properties"
                  :key="index"
                >
                  <v-text-field
                    v-if="element.DataType == 'string'"
                    v-model="element.Value"
                    :label="element.Name"
                  ></v-text-field>
                  <!-- If data is integer -->
                  <!-- type="number" -->
                  <v-text-field
                    v-if="element.DataType == 'number'"
                    v-model="element.Value"
                    :label="element.Name"
                    @keypress="controlIntChar($event)"
                    hide-details
                  ></v-text-field>
                  <!-- If data is date-time -->
                  <v-menu
                    ref="datePickerRef"
                    v-model="menu"
                    v-if="element.DataType == 'date_time'"
                    :close-on-content-click="false"
                    :return-value.sync="date"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="element.Value"
                        :label="element.Name"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <!-- no-title
                  scrollable -->
                    <v-date-picker v-model="date" locale="tr-TR">
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menu = false">
                        {{ $t('buttons.cancel') }}
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="
                          $refs.datePickerRef[0].save(date);
                          element.Value = convertToLocal(date);
                        "
                      >
                        {{ $t('buttons.ok') }}
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-banner>
          </v-card-text>
          <!-- Custom Field End -->

          <!-- Users -->
          <v-card-text v-if="pageRules.user && pageRules.user.isShow" class="px-0 py-0 add-content-card-body">
            <v-banner rounded outlined class="mt-5">
              <v-card-title class="add-content-title">{{
                pageRules.user.defaultLabel
              }}</v-card-title>
              <v-card-title
                class="add-content-title"
                style="font-size: 12px !important;"
                >{{ pageRules.user.description }}</v-card-title
              >
              <v-combobox
                class="mt-4"
                clearable
                solo
                persistent-hint
                return-object
                ref="combobox"
                v-on:keyup.enter="searchUser"
                :search-input.sync="searchUserText"
                v-model="selectedUser"
                item-value="Id"
                item-text="userFullValue"
                :items="users"
                no-data-text="Arama yapmak için lütfen için bir kullanıcı adı veya sicil numarası giriniz."
              >
                <template v-slot:append>
                  <v-btn color="purple" dark @click="searchUser(null)">
                    <v-icon>mdi-magnify</v-icon>
                  </v-btn>
                </template>
              </v-combobox>
            </v-banner>
          </v-card-text>
          <!-- Users END -->

          <!-- Photo Upload start -->
          <v-card-text
            class="px-0 py-0 add-content-card-body"
            v-if="pageRules.photo.isShow"
          >
            <v-banner rounded outlined class="mt-5">
              <v-card-title class="add-content-title">{{
                pageRules.photo.defaultLabel
              }}</v-card-title>
              <v-card-title
                class="add-content-title"
                style="font-size: 12px !important;"
                >{{ pageRules.photo.description }}</v-card-title
              >

              <v-row>
                <v-col>
                  <input
                    type="file"
                    accept="image/*"
                    ref="uploader"
                    class="d-none"
                    @change="onFileChange"
                  />

                  <v-btn
                    small
                    class="ma-4 mr-8"
                    elevation="0"
                    outlined
                    color="info"
                    :disabled="photoLength == 1"
                    @click="browsePhoto()"
                  >
                    {{ $t('buttons.uploadNew') }}
                  </v-btn>
                  <!-- <v-btn
                small
                class="ma-4 mr-4"
                elevation="0"
                outlined
                :disabled="photoLength == 1"
                color="info"
              >
                {{ $t("buttons.uploadfromLibrary") }}
              </v-btn> -->
                </v-col>
                <v-col
                  align-self="end"
                  class="mr-5"
                  style="text-align: end"
                  v-if="selectedPhotoModel"
                >
                  {{ photoLength == null ? ' ' : photoLength + ' /' + ' 1' }}
                </v-col>
              </v-row>

              <v-banner rounded outlined class="mt-5" v-if="selectedPhotoModel">
                <v-hover>
                  <v-img
                    style="border-radius: 8px"
                    max-height="250px"
                    :aspect-ratio="4 / 3"
                    contain
                    :src="selectedPhotoModel.LocalUrl"
                    slot-scope="{ hover }"
                  >
                    <v-expand-transition>
                      <div
                        v-if="hover"
                        class="
                          transition-fast-in-fast-out
                          image-hover
                          white--text
                        "
                        style="height: 100%"
                      >
                        <v-layout
                          row
                          wrap
                          fill-height
                          align-center
                          justify-center
                          ma-0
                        >
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                @click="deletePhoto(selectedPhotoModel, 0)"
                                class="ma-0"
                                icon
                                v-on="on"
                                style="z-index: 100"
                              >
                                <v-icon color="white">mdi-close</v-icon>
                              </v-btn>
                            </template>
                            <span>{{ $t('labels.deleteKey') }}</span>
                          </v-tooltip>

                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                class="ma-0"
                                icon
                                v-on="on"
                                style="z-index: 100"
                              >
                                <v-icon color="white">mdi-folder</v-icon>
                              </v-btn>
                            </template>
                            <span>{{ selectedPhotoModel.Path }}</span>
                          </v-tooltip>
                        </v-layout>
                      </div>
                    </v-expand-transition>
                  </v-img>
                </v-hover>
              </v-banner>
            </v-banner>
          </v-card-text>
          <!-- Photo upload end -->

          <!-- File Upload -->
          <v-card-text
            class="px-0 py-0 add-content-card-body"
            v-if="pageRules.media.isShow"
          >
            <v-banner rounded outlined class="mt-5">
              <v-card-title class="add-content-title">
                {{ pageRules.media.defaultLabel }}
              </v-card-title>
              <v-card-title
                class="add-content-title"
                style="font-size: 12px !important;"
                >{{ pageRules.media.description }}</v-card-title
              >

              <v-row>
                <v-col>
                  <input
                    v-if="isRequiredMedia == false"
                    type="file"
                    accept="application/pdf"
                    ref="fileInput"
                    style="display: none"
                    @change="selectFileHandle"
                  />
                  <input
                    v-else
                    type="file"
                    accept="/*"
                    ref="fileInput"
                    style="display: none"
                    @change="selectFileHandle"
                  />
                  <v-btn
                    small
                    class="ma-4 mr-8"
                    elevation="0"
                    outlined
                    color="info"
                    :disabled="fileLength == 5"
                    @click="
                      browseFile();
                      ('');
                    "
                  >
                    {{ $t('buttons.uploadNew') }}
                  </v-btn>
                  <!-- <v-btn
                small
                class="ma-4 mr-4"
                elevation="0"
                outlined
                :disabled="fileLength == 5"
                color="info"
              >
                {{ $t("buttons.uploadfromLibrary") }}
              </v-btn> -->
                </v-col>
                <v-col
                  align-self="end"
                  class="mr-5"
                  style="text-align: end"
                  v-if="fileLength > 0"
                >
                  {{ fileLength == null ? ' ' : fileLength + ' /' + ' 5' }}
                </v-col>
              </v-row>

              <v-banner rounded outlined class="mt-5" v-if="fileLength > 0">
                <v-col>
                  <v-container class="mt-5" mx-0 px-0 fluid grid-list-xl>
                    <v-layout row wrap>
                      <v-flex
                        xs2
                        v-for="(item, index) in selectedFiles"
                        :key="index"
                      >
                        <v-hover v-if="!item.isUploaded">
                          <v-img
                            style="border-radius: 8px"
                            width="100%"
                            :aspect-ratio="1 / 1"
                            slot-scope="{ hover }"
                            contain
                            :src="
                              item.MimeType == 'image/jpeg' ||
                              item.MimeType == 'image/png'
                                ? item.LocalUrl
                                : item.MimeType == 'video/mp4'
                                ? require('@/../public/img/video-thumb.png')
                                : require('@/../public/img/article-thumb.png')
                            "
                          >
                            <v-expand-transition>
                              <div
                                v-if="hover"
                                class="
                                  transition-fast-in-fast-out
                                  image-hover
                                  white--text
                                "
                                style="height: 100%"
                              >
                                <v-layout
                                  row
                                  wrap
                                  fill-height
                                  align-center
                                  justify-center
                                  ma-0
                                >
                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                      <v-btn
                                        @click="deleteFile(item.id, index)"
                                        class="ma-0"
                                        icon
                                        v-on="on"
                                        style="z-index: 100"
                                      >
                                        <v-icon color="white">mdi-close</v-icon>
                                      </v-btn>
                                    </template>
                                    <span>{{ $t('labels.deleteKey') }}</span>
                                  </v-tooltip>

                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                      <v-btn
                                        class="ma-0"
                                        icon
                                        v-on="on"
                                        style="z-index: 100"
                                      >
                                        <v-icon color="white"
                                          >mdi-folder</v-icon
                                        >
                                      </v-btn>
                                    </template>
                                    <span>{{ item.Path }}</span>
                                  </v-tooltip>
                                </v-layout>
                              </div>
                            </v-expand-transition>
                          </v-img>
                        </v-hover>
                        <v-hover v-if="item.isUploaded">
                          <v-img
                            style="border-radius: 8px"
                            width="100%"
                            :aspect-ratio="1 / 1"
                            slot-scope="{ hover }"
                            contain
                            :src="
                              item.media.type == 'image/jpeg' ||
                              item.media.type == 'image/png'
                                ? computedUrl(item.media)
                                : item.media.type == 'video/mp4'
                                ? require('@/../public/img/video-thumb.png')
                                : require('@/../public/img/article-thumb.png')
                            "
                          >
                            <v-expand-transition>
                              <div
                                v-if="hover"
                                class="
                                  transition-fast-in-fast-out
                                  image-hover
                                  white--text
                                "
                                style="height: 100%"
                              >
                                <v-layout
                                  row
                                  wrap
                                  fill-height
                                  align-center
                                  justify-center
                                  ma-0
                                >
                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                      <v-btn
                                        @click="deleteFile(item.id, index)"
                                        class="ma-0"
                                        icon
                                        v-on="on"
                                        style="z-index: 100"
                                      >
                                        <v-icon color="white">mdi-close</v-icon>
                                      </v-btn>
                                    </template>
                                    <span>{{ $t('labels.deleteKey') }}</span>
                                  </v-tooltip>

                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                      <v-btn
                                        class="ma-0"
                                        icon
                                        v-on="on"
                                        style="z-index: 100"
                                      >
                                        <v-icon color="white"
                                          >mdi-folder</v-icon
                                        >
                                      </v-btn>
                                    </template>
                                    <span>{{ item.Path }}</span>
                                  </v-tooltip>
                                </v-layout>
                              </div>
                            </v-expand-transition>
                          </v-img>
                        </v-hover>
                      </v-flex>
                    </v-layout>
                  </v-container>
                </v-col>
              </v-banner>
            </v-banner>
          </v-card-text>
          <!-- File upload end -->

          <!-- Tag start -->
          <v-card-text
            class="px-0 py-0 add-content-card-body"
            v-if="pageRules.tag.isShow"
          >
            <v-banner rounded outlined class="mt-5">
              <v-card-title class="add-content-title">
                {{ pageRules.tag.defaultLabel }}
              </v-card-title>
              <v-card-title
                class="add-content-title"
                style="font-size: 12px !important;"
                >{{ pageRules.tag.description }}</v-card-title
              >
              <v-col>
                <v-flex xs12>
                  <v-combobox
                    v-model="updateContentDetail.Tags"
                    :items="tags"
                    :search-input.sync="search"
                    hide-selected
                    :hint="$t('labels.max15')"
                    :label="$t('labels.tags')"
                    multiple
                    persistent-hint
                    deletable-chips
                    small-chips
                    item-text="TagName"
                    item-value="Id"
                    @input="selectTag(updateContentDetail.Tags)"
                    @keyup.enter="addTag(updateContentDetail.Tags)"
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            "<strong>{{ search }}</strong
                            >" için bir eşleşme bulunamadı. Yeni bir tane
                            oluşturmak için <kbd>enter</kbd> tuşuna basın.
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-combobox>
                </v-flex>
              </v-col>
            </v-banner>
          </v-card-text>
          <!-- Tag end -->

          <!-- Date and time picker start -->
          <v-card-text
            class="px-0 py-0 add-content-card-body"
            v-if="pageRules.datetime.isShow"
          >
            <v-banner rounded outlined class="mt-5">
              <v-card-title class="add-content-title">
                {{ pageRules.datetime.defaultLabel }}
              </v-card-title>
              <v-card-title
                class="add-content-title"
                style="font-size: 12px !important;"
                >{{ pageRules.datetime.description }}</v-card-title
              >
              <v-row align="center" justify="space-between" class="pr-5">
                <v-col cols="5">
                  <v-text-field
                    v-model="dateValueDisplay"
                    :label="$t('labels.selectDate')"
                    readonly
                  >
                    <template v-slot:prepend>
                      <date-picker v-model="dateValue" />
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="5">
                  <v-text-field
                    v-model="timeValueDisplay"
                    :label="$t('labels.selectDate')"
                    readonly
                  >
                    <template v-slot:prepend>
                      <time-picker format="24hr" v-model="timeValue" />
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
            </v-banner>
          </v-card-text>
          <!-- Date and time picker end -->
        </v-flex>

        <v-flex md4>
          <!-- Test or pin -->
          <v-card-text
            class="px-0 py-0 add-content-card-body"
            v-if="
              pageRules.showTest ||
                pageRules.showPin ||
                pageRules.showNotification
            "
          >
            <v-banner rounded outlined class="mt-5">
              <v-card-title class="add-content-title">
                {{ $t('labels.postType') }}
              </v-card-title>
              <v-row class="mx-2">
                <v-col cols="12" sm="6" md="6">
                  <v-checkbox
                    v-if="pageRules.showTest"
                    v-model="updateContentDetail.IsTest"
                    :label="'Test'"
                    hide-details
                  ></v-checkbox>
                  <v-checkbox
                    v-if="pageRules.showPin"
                    v-model="updateContentDetail.IsPinned"
                    :label="'Üste Sabitle'"
                    hide-details
                  ></v-checkbox>
                  <v-checkbox
                    v-if="pageRules.showNotification"
                    v-model="updateContentDetail.SendNotification"
                    :label="'Bildirim Gönder'"
                    hide-details
                  ></v-checkbox>
                  <v-checkbox
                    v-if="pageRules.showDocumentType"
                    v-model="updateContentDetail.IsPrivate"
                    :label="'Gizli Paylaş'"
                    hide-details
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-banner>
          </v-card-text>
          <!-- Test or pin end -->

          <!-- Category start  -->
          <v-card-text
            class="px-0 py-0 add-content-card-body"
            v-if="categoryData != null && categoryData.length > 0"
          >
            <v-banner rounded outlined class="mt-5">
              <v-card-title class="add-content-title">
                {{
                  $t('labels.postCategory') + $t('labels.postCategoryRequired')
                }}
              </v-card-title>
              <v-treeview
                v-model="updateContentDetail.CategoryIds"
                :items="categoryData"
                selection-type="leaf"
                item-children="Children"
                item-value="Id"
                item-text="CategoryNameTR"
                item-key="Id"
                selectable
                open-all
                style="overflow: auto; height: 300px"
              ></v-treeview>
            </v-banner>
          </v-card-text>
          <!-- Category end -->
        </v-flex>
      </v-layout>
    </div>
  </v-container>
</template>

<script>
import GroupService from '../services/GroupService';
import ContentService from '../services/ContentService';
import FileService from '../services/FileService';
import CategoryService from '../services/CategoryService';
import TagService from '../services/TagService';
import ModuleService from '../services/ModuleService';
import PostService from '../services/PostService';

import GroupModal from '../components/General/GroupModal.vue';

import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials';
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold';
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic';
import Link from '@ckeditor/ckeditor5-link/src/link';
import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import List from '@ckeditor/ckeditor5-list/src/list.js';
import Heading from '@ckeditor/ckeditor5-heading/src/heading.js';
import Indent from '@ckeditor/ckeditor5-indent/src/indent.js';
import IndentBlock from '@ckeditor/ckeditor5-indent/src/indentblock';
import Font from '@ckeditor/ckeditor5-font/src/font';

import UserService from '../services/UserService';

export default {
  data: () => ({
    moduleDetail: {},
    groupArr: [],

    groupIds: [],
    groupTypes: [],

    vSelectData: [],

    corpGroup: [],
    locGroup: [],
    empGroup: [],
    allSelect: [],

    selectedItems: [],
    categories: [],
    tempFiles: [],
    fileControlArr: [],
    selectedFiles: [],
    fileIds: [],
    //Content Field start
    updateContentDetail: {},
    editor: ClassicEditor,
    editorConfig: {
      plugins: [
        Essentials,
        Bold,
        Italic,
        Link,
        Paragraph,
        List,
        Heading,
        Indent,
        IndentBlock,
        Font,
      ],

      toolbar: {
        items: [
          'heading',
          '|',
          'bold',
          'italic',
          'link',
          'bulletedList',
          'numberedList',
          '|',
          'outdent',
          'indent',
          '|',
          'undo',
          'redo',
          '|',
          'fontSize',
          'fontColor',
          'fontBackgroundColor',
        ],
      },
      fontSize: {
        options: [9, 11, 13, 15, 17, 19, 21, 23, 25, 27, 29],
      },
    },
    selectedRootIndex: 0,
    mySetChangeTracker: 0,
    categoryIds: [],
    search: null,
    tags: [],
    groupLengthAlert: false,

    //mandatory photo upload models
    selectedPhotoModel: null,
    selectedPhotoModelRes: null,
    selectedPhotoModelResId: null,
    photoLength: null,
    fileLength: null,
    selectedPhoto: [],
    selectedPhotoUrl: [],
    cData: null,
    fab: false,
    postId: '',

    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    dateValue: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    timeValue: '',
    menu: false,

    //Necessary variables for page differentiation
    key: '',
    propertiesFlag: true,
    pageKey: '',
    pageHeader: '',
    infoDialog: false,
    notificationDialog: false,
    mediaFlag: true,
    moduleDescription: '',
    disableLoc: [],
    modalDialogInfo: false,
    selecItems: [],
    filterLocationItem: [],
    temp: [],
    categoryData: [],
    requiredCategoryLength: -1,
    gecici: [],

    //Division select modals
    selectedDivision: null,
    divisionItems: [],

    contentType: -1, //1: Battan haberler 2:BSP+

    selectedUser: null,
    users: [],
    searchUserText: '',
  }),
  watch: {},
  beforeMount() {
    // this.pageRules = getPageRulesByName(this.$route.query.pageName);
    this.pageRules = getPageRulesById(this.$route.query.pageId);
  },
  mounted() {
    this.setup();
  },
  components: {
    GroupModal,
    DatePicker: () => import('../components/General/DatePicker.vue'),
    TimePicker: () => import('../components/General/TimePicker.vue'),
  },
  methods: {
    searchUser(event) {
      this.$store.state.loadingStatus = true;
      UserService.getUsers(this.searchUserText)
        .then((response) => {
          this.users = response.data.Data;
          this.users.map(
            (item) =>
              (item.userFullValue =
                item.FirstName +
                ' ' +
                item.LastName +
                '(' +
                item.Username +
                ')')
          );
        })
        .catch(window.showError)
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },

    //Sets up the page on page mount
    async setup() {
      await this.getCategoriesInTreeView();
      this.getDetailPage();
      if (!this.pageRules.isPublicGroups) {
        // this.fillGroups();
        this.getDivisions();
      }
      this.getTags();
      this.key = this.$route.params.id;
      this.pageHeader = this.$route.query.pageName;
      this.moduleDescription = this.$route.query.moduleDescription;
      this.notificationDialog = false;
      this.modalDialogInfo = false;
    },

    getDivisions() {
      GroupService.getDivisions()
        .then((res) => {
          this.divisionItems = res.data;
          this.divisionItems.unshift({ Id: -1, Name: 'Bölge Seçiniz' });
        })
        .catch((err) => {
          window.showError(err);
        })
        .then(() => {});
    },

    async getCategoriesInTreeView() {
      this.$store.state.loadingStatus = true;
      return new Promise(async (resolve, reject) => {
        try {
          if (this.pageRules.categoryIds.length > 0) {
            await Promise.all(
              this.pageRules.categoryIds.map(async (element, index) => {
                await CategoryService.getCategoriesInTreeView(element.id)
                  .then((res) => {
                    this.categoryData = this.categoryData.concat(res.data);
                    // this.categoryData = { ...this.categoryData, ...res.data };
                  })
                  .catch((err) => {
                    window.showError(err);
                  })
                  .then(() => {
                    this.categoryData.map((element, index) => {
                      this.categoryData[
                        index
                      ].IsRequired = this.pageRules.categoryIds[
                        index
                      ].isRequired;
                    });
                    let reqArray = this.categoryData.filter((x) => x.IsRequired)
                      .length;
                    this.requiredCategoryLength = reqArray;
                  });
              })
            );
          }
          resolve(true);
        } catch (error) {
          reject(error);
        }
      });
    },
    //Opens or closes information popup
    informationPopUp() {
      this.infoDialog = !this.infoDialog;
    },
    //Watch scroll movement
    onScroll(e) {
      if (typeof window === 'undefined') return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 40;
    },
    stickyUpdateOperation() {
      this.update();
    },
    //Loads groups when page loaded
    fillGroups() {
      GroupService.getGroups('sirket')
        .then((res) => {
          res.data.forEach((element) => {
            this.corpGroup.push(element.Id);
            let obj = {
              Id: element.Id,
              Name: element.Name,
            };
            this.allSelect.push(obj);
          });
        })
        .catch((err) => {
          window.showError(err);
        })
        .then(() => {});
      GroupService.getGroups('lokasyon')
        .then((res) => {
          res.data.forEach((element) => {
            this.locGroup.push(element.Id);
            let obj = {
              Id: element.Id,
              Name: element.Name,
            };
            this.allSelect.push(obj);
          });
        })
        .catch((err) => {
          window.showError(err);
        })
        .then(() => {});
      GroupService.getGroups('calisan_tipi')
        .then((res) => {
          res.data.forEach((element) => {
            this.empGroup.push(element.Id);
            let obj = {
              Id: element.Id,
              Name: element.Name,
            };
            this.allSelect.push(obj);
          });
        })
        .catch((err) => {
          window.showError(err);
        })
        .then(() => {});
      let all = {
        Name: 'Tümü',
        Id: '',
      };
      this.allSelect.push(all);
    },
    //Sayfa detayını getirir
    getDetailPage() {
      this.postId = this.$route.params.id;

      ContentService.getContentDetail(this.postId)
        .then(async (res) => {
          this.key = res.data.Key;
          this.pageKey = res.data.Key;

          const user = res.data.User;
          user.userFullValue =
            user.FirstName + ' ' + user.LastName + '(' + user.Username + ')';
          this.users.push(user);
          this.selectedUser = user;

          this.updateContentDetail = res.data;
          if (this.pageRules.photo.isRequired || this.pageRules.photo.isShow) {
            //Get photo first
            this.selectedPhotoModelId = this.updateContentDetail.FileIds[0];
            this.selectedPhotoModel = this.updateContentDetail.Files.find(
              (x) => x.Id == this.selectedPhotoModelId
            );
            if (this.selectedPhotoModel) {
              this.photoLength = 1;
            }
          }

          //Seperate the photo with the others
          this.selectedFiles = this.updateContentDetail.Files.filter(
            (x) => x.Id != this.selectedPhotoModelId
          );
          if (this.$route.query.pageName == 'Güncel') {
            this.selectedFiles = this.updateContentDetail.Files;
          }
          this.fileLength = this.selectedFiles.length;

          //Adjust file upload array
          if (this.selectedPhotoModel) {
            this.tempFiles.unshift(this.selectedPhotoModel);
            this.fileControlArr.unshift('photo');
          }
          this.tempFiles = this.tempFiles.concat(this.selectedFiles);
          if (this.selectedFiles.length > 0) {
            this.selectedFiles.forEach((element) => {
              this.fileControlArr.push('document');
            });
          }

          if (this.updateContentDetail.Properties.length <= 0) {
            this.propertiesFlag = false;
          }

          // this.groupIds = res.data.GroupIds;
          this.selectedDivision = res.data.GroupIds.filter(
            (x) => x != null
          ).pop();
          if (res.data.GroupIds == 0) this.selectedDivision = -1;

          let tempSendedAt = res.data.SendedAt.split(' ');
          this.dateValue = tempSendedAt[0];
          this.timeValue = tempSendedAt[1];
          console.log("Geçerlilik Tarihi : ", this.dateValue, this.timeValue);
          this.contentType = res.data.Type == 'corporate' ? 1 : 2;

          // this.getGroupTypes();
          this.getCustomFields(this.updateContentDetail.Key);
        })
        .catch((err) => {
          window.showError(err);
        })
        .then(() => {
          this.$store.state.loadingStatus = false;
          // this.getCategories();
        });
    },
    //Grup Tiplerini getirir
    getGroupTypes() {
      GroupService.getGroupTypes()
        .then((res) => {
          this.groupTypes = res.data;
          this.setGroupsData();
        })
        .catch((err) => {
          window.showError(err);
        });
    },
    //Sütunlar ayarlanıyor
    async setGroupsData() {
      await Promise.all(
        this.groupTypes.map(async (element, i) => {
          await this.getGroupData(element.Code, i);
        })
      );
      await this.setRow();
      await this.setSelectedItems();
    },
    //Grup tipinin dataları(grupları) getirir
    async getGroupData(type, index) {
      await GroupService.getGroups(type)
        .then((res) => {
          let arr = res.data;
          arr.unshift({ Id: type, Name: 'Tümü' });
          this.groupTypes[index].Data = res.data;
        })
        .catch((err) => {
          window.showError(err);
        });
    },
    //Satırlar ayarlanıyor
    async setRow() {
      await this.groupIds.forEach(async (element, index) => {
        await this.groupArr.push(this.groupTypes);
      });
    },
    async setSelectedItems() {
      this.groupIds.forEach((element, index) => {
        let arr = [];

        this.groupArr[index].forEach((elm) => {
          let arrtemp = '';
          arrtemp = elm.Data.filter((x) => this.groupIds[index].includes(x.Id));
          let sonuc = '';
          arrtemp.length > 0 ? (sonuc = arrtemp[0].Id) : (sonuc = elm);
          arr.push(sonuc);
          this.disableLoc.push(true);
        });

        // this.groupIds[index].forEach((elm, i) => {
        //   let bca = this.groupArr[index][i].Data.filter((x) => x.Id == elm);
        //   let sonuc = "";
        //   bca.length > 0 ? (sonuc = bca[0].Id) : (sonuc = elm);
        //   arr.push(sonuc);
        // });
        this.selectedItems.push(arr);
      });

      //Tüm işlemler bitince select box lar için atama yapılıyor
      this.vSelectData = this.groupArr;
      this.gecici.push(this.vSelectData[0][1].Data);
    },
    changeGroupInput(element, colIndex, dataIndex) {
      if (element.Code == 'sirket') {
        this.selectedItems[dataIndex][colIndex + 1] = '';
        this.disableLoc[dataIndex] = false;
      }
    },
    clickGroupFilterModal(element, colIndex, dataIndex) {
      if (
        element.Code == 'lokasyon' &&
        this.selectedItems[dataIndex][colIndex - 1] == undefined
      ) {
        this.vSelectData[dataIndex][colIndex].Data = [...[]];
      }
      if (
        (element.Code == 'lokasyon' &&
          this.selectedItems[dataIndex][colIndex - 1] != undefined) ||
        element.Code == 'calisan_tipi' ||
        element.Code == 'sirket'
      ) {
        GroupService.getGroups(
          element.Code,
          element.Code == 'lokasyon'
            ? this.selectedItems[dataIndex][colIndex - 1]
            : ''
        )
          .then((res) => {
            let arr = [];
            res.data.forEach((item) => {
              let obj = {
                Id: item.Id,
                Name: item.Name,
              };
              arr.push(obj);
            });

            if (
              element.Code != 'sirket' ||
              this.$store.getters.getPermissionIds
            )
              if (element.Code == 'lokasyon') {
                arr.unshift({ Id: 'lokasyon', Name: 'Tümü' });
              } else if (element.Code == 'calisan_tipi') {
                arr.unshift({ Id: 'calisan_tipi', Name: 'Tümü' });
              } else {
                arr.unshift({ Id: 'sirket', Name: 'Tümü' });
              }

            this.vSelectData[dataIndex][colIndex].Data = arr;

            let listObj = {
              Type: element.Code,
              Data: this.vSelectData[dataIndex][colIndex].Data,
            };
            this.selectedItems.push(listObj);

            let index = null;
            this.selectedItems.forEach((x) => {
              if (Array.isArray(x) == false) {
                index = this.selectedItems.findIndex((y) => {
                  return y == x;
                });
              }
            });

            this.selectedItems.splice(index, this.selectedItems.length - 1);
          })
          .catch((err) => {
            window.showError(err);
          })
          .then(() => {
            for (let index = 0; index < this.vSelectData.length; index++) {
              this.vSelectData[index][1].Data = this.gecici[0];
            }
          });
      }
    },
    //file operation
    selectFileHandle(e) {
      if (e.target.files[0] === undefined) {
      } else {
        if (e.target.files[0].size < 20000000) {
          this.uploadFile(e.target.files[0]);
        } else {
          window.showInfo(
            this.$t('description.fileSizeError'),
            'standart',
            'error'
          );
        }
      }
    },
    //Upload file
    uploadFile(media) {
      if (media === undefined) {
        console.log('Undefined media');
      } else if (media.type == '') {
        window.showInfo(
          this.$t('description.unsupportedMediaType'),
          'standart',
          'error'
        );
      } else {
        if (media.size > 50000000) {
          window.showInfo(
            this.$t('description.fileSizeError'),
            'standart',
            'error'
          );
        } else {
          let obj = {
            media: media,
            isUploaded: true,
          };
          this.selectedFiles.push(obj);

          this.tempFiles.push(obj);
          this.fileControlArr.push('document');
          this.fileLength++;
        }
      }
    },
    //Browse all file types
    browseFile() {
      this.$refs.fileInput.value = '';
      this.$refs.fileInput.click();
    },
    //Delete an uploaded file
    deleteFile(fileId, index) {
      this.updateContentDetail.FileIds.splice(index, 1);
      this.updateContentDetail.Files.splice(index, 1);
      this.selectedFiles.splice(index, 1);
      this.tempFiles.splice(index, 1);
      this.fileControlArr.splice(index, 1);
      this.fileLength--;
    },
    //Browse mandatory photo
    onFileChange(e) {
      if (e.target.files[0] === undefined) {
      } else {
        if (e.target.files[0].size < 1000000) {
          this.selectedPhotoModel = e.target.files[0];
          this.uploadPhoto(this.selectedPhotoModel);
        } else {
          window.showInfo(
            this.$t('description.fileSizeError'),
            'standart',
            'error'
          );
        }
      }
    },
    //Open photo browse popup
    browsePhoto() {
      this.$refs.uploader.click();
    },
    //Delete selected photo
    deletePhoto(photo, index) {
      this.updateContentDetail.FileIds.splice(index, 1);

      this.selectedPhoto.splice(index, 1);
      this.selectedPhotoUrl.splice(index, 1);
      this.selectedPhotoModel = null;
      this.photoLength--;
      this.selectedPhotoModelRes = null;
      this.tempFiles.splice(0, 1);
      this.fileControlArr.splice(0, 1);
    },
    //Return file url to send service
    computedUrl(file) {
      return URL.createObjectURL(file);
    },
    //Upload selected photo
    uploadPhoto(media) {
      if (media === undefined) {
        console.log('Undefined media');
      } else {
        if (media.size > 1000000) {
          window.showInfo(
            this.$t('description.fileSizeError'),
            'standart',
            'error'
          );
        } else {
          this.selectedPhotoUrl.push(this.computedUrl(media));
          this.selectedPhotoModel.LocalUrl = this.selectedPhotoUrl[0];
          let obj = {
            media: media,
            isUploaded: true,
            isFirst: true,
          };
          this.tempFiles.unshift(obj);
          this.fileControlArr.unshift('photo');
          this.photoLength = 1;
        }
      }
    },
    //file operation end

    //Category items
    getCategories() {
      let params = {
        ParentId: 'all',
        PageSize: 1000,
      };
      CategoryService.getCategories2(params)
        .then((res) => {
          this.cData = setCategoryTreeviewData(res.data);
          this.cData = setFilterData(this.cData, this.pageKey, res.data);
        })
        .catch((err) => {
          window.showError(err);
        })
        .then(() => {});
    },
    //Tag operations
    getTags() {
      TagService.getTags()
        .then((res) => {
          this.tags = res.data.Result;
        })
        .catch((err) => {
          window.showError(err);
        })
        .then(() => {});
    },
    //Select a tag
    selectTag(tag) {
      // if (tag != null) {
      //   this.tagIds = [];
      //   tag.forEach((x) => this.updateContentDetail.TagIds.push(x.Id));
      // }
      // tag = null;

      if (
        tag &&
        this.updateContentDetail.TagIds.length <= 15 &&
        tag.length <= 15
      ) {
        // this.tagIds = [];
        tag.forEach((x) => this.updateContentDetail.TagIds.push(x.Id));
      }
      this.updateContentDetail.TagIds = [
        ...new Set(this.updateContentDetail.TagIds),
      ];
      this.updateContentDetail.TagIds = this.updateContentDetail.TagIds.filter(
        (x) => x != undefined
      );
    },
    //Create and add a new tag
    addTag(tag) {
      tag.forEach((x, index) => {
        if (x.Id == undefined) {
          let body = {
            TagName: x,
          };
          if (index == tag.length - 1) {
            TagService.createTags(body)
              .then((res) => {
                //this.tags=  res.data
                // this.tags.push(res.data);
                // this.updateContentDetail.TagIds.push(res.data.Id);

                // this.tags[index] = res.data;
                this.updateContentDetail.Tags.push(res.data);
                this.updateContentDetail.Tags = this.updateContentDetail.Tags.filter(
                  (item) => typeof item !== 'string'
                );
                this.updateContentDetail.TagIds.push(res.data.Id);
              })
              .catch((err) => {
                window.showError(err);
              })
              .then(() => {});
          }
        }
      });
      // this.updateContentDetail.TagIds.forEach((x) => {
      //   if (x == undefined) {
      //     this.updateContentDetail.TagIds.splice(
      //       this.updateContentDetail.TagIds.indexOf(x),
      //       1
      //     );
      //   }
      // });

      this.updateContentDetail.TagIds = this.updateContentDetail.TagIds.filter(
        (x) => x != undefined
      );
    },
    //Delete a group row
    deleteGroupRow(rowIndex) {
      if (!(this.selectedItems.length == 1)) {
        if (this.selectedItems.length == 4) {
          this.groupLengthAlert = false;
        }
        this.selectedItems.splice(rowIndex, 1);
        this.vSelectData.splice(rowIndex, 1);
      } else {
        window.showInfo(
          this.$t('description.groupRowDelete'),
          'standart',
          'info'
        );
      }
    },
    //Group Modals Operation
    createNewGroup() {
      if (this.selectedItems.length < 4) {
        this.groupLengthAlert = false;
        let temp = [];
        this.selectedItems = [...this.selectedItems, temp];
        this.vSelectData = [...this.vSelectData, this.groupArr[0]];
      } else {
        this.groupLengthAlert = true;
      }
    },
    //Custom Field Operation
    getCustomFields(key) {
      let params = {
        key: key,
      };
      ModuleService.getCustomFields(params)
        .then((res) => {
          //Find new added custom field
          res.data.forEach((element) => {
            let tempObj = this.updateContentDetail.Properties.find(
              (x) => x.Key === element.Name
            );
            if (tempObj === undefined) {
              let obj = {
                DataType: element.DataType,
                Key: element.Name,
                Value: '',
                Name: element.DisplayName,
              };
              this.updateContentDetail.Properties.push(obj);
            } else {
              tempObj.Name = element.DisplayName;
            }
          });
          let temparr = this.updateContentDetail.Properties;
          let temparr2 = res.data;

          temparr = temparr.filter((x) => x.Name != temparr2.DisplayName);
          this.updateContentDetail.Properties = temparr;
        })
        .catch((err) => {
          window.showError(err);
        })
        .then(() => {});
    },
    //Upload all selected files
    async uploadFiles(index) {
      if (index < this.tempFiles.length) {
        this.uploadFileCall(this.tempFiles[index], index);
      } else {
        this.updateContentDetail.FileIds = [];
        this.fileIds.forEach((element) => {
          this.updateContentDetail.FileIds.push(element);
        });
        this.updateCall();
      }
    },
    //Send uploaded file to service call if it's updated
    async uploadFileCall(item, index) {
      //If item doesn't exists
      if (!item) {
        this.updateCall();
        return;
      }
      if (item.isUploaded) {
        let media = item.media;
        if (media === undefined) {
          console.log('Undefined media');
        }
        let formData = new FormData();
        if (
          media.type == 'image/jpeg' ||
          media.type == 'image/png' ||
          media.type == 'video/mp4'
        ) {
          formData.append('Files', media);
          formData.append('Type', 'media');
        } else {
          formData.append('Files', media);
          formData.append('Type', 'document');
        }
        await Promise.resolve(
          FileService.uploadFile(formData)
            .then((res) => {
              if (item.isFirst) {
                this.fileIds.unshift(res.data[0].Id);
              } else {
                this.fileIds.push(res.data[0].Id);
              }
            })
            .catch((err) => {
              window.showError(err);
            })
            .then(() => {
              this.uploadFiles(index + 1);
            })
        );
      } else {
        this.fileIds.push(item.Id);

        this.uploadFiles(index + 1);
      }
    },
    //Start update operation
    async update() {
      this.$store.state.loadingStatus = true;
      if (!this.isRequiredMedia && this.fileLength == 0) {
        window.showInfo(
          this.$t('description.contentFileUpload'),
          'standart',
          'info'
        );
        this.$store.state.loadingStatus = false;

        return;
      }

      if (
        this.selectedPhotoModel == null &&
        this.isRequiredPhoto &&
        this.isRequiredForAdvancement
      ) {
        window.showInfo(
          this.$t('description.storyPhotoError'),
          'standart',
          'info'
        );
        this.$store.state.loadingStatus = false;
      } else {
        if (
          this.updateContentDetail.SendNotification &&
          !this.modalDialogInfo
        ) {
          this.notificationDialog = true;

          return;
        }
        this.tempFiles = this.tempFiles.filter((element) => element);

        if (this.tempFiles.length > 0) {
          let control = await this.uploadControl();
          if (control) {
            await this.uploadFiles(0);
          } else {
            window.showInfo(
              this.$t('description.errorOnCreate'),
              'standart',
              'info'
            );
            this.$store.state.loadingStatus = false;
          }
        } else if (this.isRequiredPhoto && this.tempFiles.length > 0) {
          window.showInfo(
            this.$t('description.contentPhotoUpload'),
            'standart',
            'info'
          );
          this.$store.state.loadingStatus = false;
        } else {
          this.updateCall();
        }
      }
    },
    async uploadControl() {
      if (
        this.pageRules.photo.isRequired &&
        !this.fileControlArr.includes('photo')
      ) {
        return false;
      }
      if (
        this.pageRules.media.isRequired &&
        !this.fileControlArr.includes('document')
      ) {
        return false;
      }
      if (
        this.pageRules.tag.isRequired &&
        this.updateContentDetail.TagIds.length < 1
      ) {
        return false;
      }

      if (
        this.pageKey == 'aramiza_katilanlar' ||
        this.pageKey == 'yeni_doganlar'
      ) {
        let tempArr = this.updateContentDetail.Properties.filter(
          (x) => x.Key == 'sicilNumber'
        );
        if ((tempArr[0].Value == '') | (tempArr[0].Value == '00000000')) {
          console.log('Empty required field in properties section');
          window.showInfo(
            this.$t('description.propertyEmptySicil'),
            'standart',
            'info'
          );
          return false;
        }
      }
      // this.isRequiredLink
      if (this.pageRules.link.isRequired) {
        let tempArr = this.updateContentDetail.Properties.filter(
          (x) =>
            x.Key == 'publish_date' ||
            x.Key == 'mail_to' ||
            x.Key == 'IK_sicil_number'
        );
        if (
          tempArr[0].Value == '' ||
          tempArr[1].Value == '' ||
          tempArr[2].Value == ''
        ) {
          console.log('Empty required field in properties section');
          window.showInfo(
            this.$t('description.propertyEmptyPublishDate'),
            'standart',
            'info'
          );
          return false;
        }
      }
      if (
        this.categoryData.length > 0 &&
        this.updateContentDetail.CategoryIds.length < 1
      ) {
        console.log('Category input mandatory');
        window.showInfo(
          this.$t('description.emptyCategorySelect'),
          'standart',
          'info'
        );
        this.$store.state.loadingStatus = false;
        return;
      }

      if (
        this.categoryData &&
        this.updateContentDetail.CategoryIds.length > 0
      ) {
        let index = 0;
        this.updateContentDetail.CategoryIds.forEach((i) => {
          this.categoryData.forEach((element) => {
            var result = this.searchTree(element, i, false);
            if (result) {
              index++;
            }
          });
        });
        if (index < this.requiredCategoryLength) {
          window.showInfo(
            this.$t('description.emptyCategorySelect'),
            'standart',
            'info'
          );
          return false;
        }
      }

      return true;
    },

    searchTree(element, id) {
      if (element.Id == id) {
        return element;
      } else if (element.Children != null) {
        var result = null;
        element.Children.forEach((child) => {
          if (result == null) {
            result = this.searchTree(child, id);
          }
        });
        return result;
      }
      return null;
    },

    //Final step of update operation
    async updateCall(isDraft = false) {
      if (isDraft) {
        window.showInfo(
          this.$t('description.draftNotificationInfo'),
          'standart',
          'info'
        );
        setTimeout(() => {
          console.log('Draft timeout');
        }, 2000);
      }
      this.$store.state.loadingStatus = true;
      //Check the content and make sure sicilNumber field is a required input
      let sendedAt = this.dateValue + ' ' + this.timeValue;

      this.updateContentDetail.Content = this.updateContentDetail.Content?.replaceAll('</p>', '</p>\n')
        .replaceAll('</h1>', '</h1>\n')
        .replaceAll('</h2>', '</h2>\n')
        .replaceAll('</h3>', '</h3>\n')
        .replaceAll('</h4>', '</h4>\n');

      let updateContentBody = {
        Title: this.updateContentDetail.Title,
        Content: this.updateContentDetail.Content,
        Privacy: 'public',
        Id: this.postId,
        FileIds: this.updateContentDetail.FileIds,
        IsTest: this.updateContentDetail.IsTest,
        IsPinned: this.updateContentDetail.IsPinned,
        SendNotification: this.updateContentDetail.SendNotification,
        IsPrivate: this.updateContentDetail.IsPrivate,
        GroupIds: this.contentType == 1 ? [] : [this.selectedDivision],
        TagIds:
          this.updateContentDetail.Tags?.length > 0
            ? this.updateContentDetail.Tags.flatMap((item) => item.Id)
            : [],
        Properties: this.updateContentDetail.Properties,
        Status: isDraft ? 0 : 20,
        SendedAt: sendedAt,
      };

      if (this.selectedUser) {
        updateContentBody.PostCreatorUserId = this.selectedUser.Id;
      }

      //Category controls
      if (this.updateContentDetail.CategoryIds.length > 0) {
        updateContentBody.CategoryIds = this.updateContentDetail.CategoryIds;
      }

      // if (isDraft) {
      //   updateContentBody.Status = 0;
      // }

      //If all group selected group selection
      // for (let index = 0; index < this.selectedItems.length; index++) {
      //   const element = this.selectedItems[index];
      //   let tempArr = [];
      //   for (let y = 0; y < this.groupTypes.length; y++) {
      //     let temp = element[y];
      //     if (element[y] === undefined) {
      //       temp = y;
      //     }
      //     switch (temp) {
      //       case "sirket":
      //       case 0:
      //         this.corpGroup.forEach((item) => {
      //           tempArr.push(item);
      //         });
      //         break;

      //       case "lokasyon":
      //       case 1:
      //         this.locGroup.forEach((item) => {
      //           tempArr.push(item);
      //         });

      //         break;

      //       case "calisan_tipi":
      //       case 2:
      //         this.empGroup.forEach((item) => {
      //           tempArr.push(item);
      //         });

      //         break;

      //       default:
      //         tempArr.push(element[y]);
      //         break;
      //     }
      //   }
      //   this.selectedItems[index] = tempArr;
      // }
      // updateContentBody.GroupIds = this.selectedItems;

      // let typeControl = updateContentBody.GroupIds.filter(
      //   (x) => x.length == this.allSelect.length - 1
      // );

      // if (typeControl.length < 1) {
      //   updateContentBody.Type = "corporate";
      // } else {
      //   updateContentBody.Type = "individual";
      // }
      //updateContentBody.Type = "corporate";
      //updateContentBody.Type = this.pageRules.isPublicGroups ? "corporate" : "individual"; //bölge seçebiliyorsa individual seçemiyorsa corporate
      updateContentBody.Type =
        this.contentType == 1 ? 'corporate' : 'individual'; //bölge seçebiliyorsa individual seçemiyorsa corporate:battan haberler
      if (isDraft) {
        updateContentBody.SendNotification = false;
      }

      let control = await this.uploadControl();
      if (control) {
        let props = this.updateContentDetail.Properties.find(
          (x) => x.Key == 'sicilNumber' || x.Key == 'IK_sicil_number'
        );
        //Temp value is used to do not show the user zero addition
        // if (props) {
        //   let tempVal = validateRegistryNumber(props.Value);
        //   props.Value = tempVal;
        // }

        updateContentBody.GroupIds =
          this.contentType == 1
            ? []
            : this.selectedDivision != -1
            ? [this.selectedDivision]
            : [];
        if (this.pageRules.isPublicGroups) {
          updateContentBody.GroupIds = [];
          updateContentBody.IsAllGroups = true;
        }
        PostService.updatePost(updateContentBody)
          .then((res) => {
            this.$router.go(-1);
            //     this.$router.push({ name: "moduls" });
          })
          .catch((err) => {
            this.$store.state.loadingStatus = false;
            window.showError(err);
          })
          .then(() => {
            this.$store.state.loadingStatus = false;
          });
      } else {
        window.showInfo(
          this.$t('description.errorOnCreate'),
          'standart',
          'info'
        );
        this.$store.state.loadingStatus = false;
      }
    },
    notificationCall() {},
    //Input control for text fields
    //For only letters and numbers
    controlKeyChar(e) {
      window.controlKeyChar(e);
    },
    //For only numbers
    controlIntChar(e) {
      window.controlIntChar(e);
    },
  },
  computed: {
    dateValueDisplay() {
      return convertToLocal(this.dateValue);
    },
    timeValueDisplay() {
      return this.timeValue;
    },
    isRequiredTitle() {
      switch (this.key) {
        case 'yeni_doganlar':
          return false;
        case 'vefat_edenler':
          return false;

        default:
          return true;
      }
    },
    isRequiredTag() {
      switch (this.key) {
        case 'aramiza_katilanlar':
          return false;

        default:
          return true;
      }
    },
    isRequiredForAdvancement() {
      switch (this.key) {
        case 'atamalar':
          return false;
        default:
          return true;
      }
    },

    isRequiredMedia() {
      switch (this.key) {
        case 'el_kitap_pdf':
          return false;
        case 'dergilik':
          return false;

        default:
          return true;
      }
    },

    //Determine whether the photo and file sections is necessary or not
    isRequiredPhoto() {
      switch (this.key) {
        case 'phones':
          return false;
        case 'ik_el_kitabi':
          return false;
        case 'personnel_shuttle':
          return false;
        case 'job_notices':
          return false;
        case 'vefat_edenler':
          return false;
        case 'yeni_doganlar':
          return false;
        case 'akademi':
          return false;
        case 'kurumsal_haberler':
          return false;
        case 'aramiza_katilanlar':
          return false;
        case 'ilanlar':
          return false;
        default:
          return true;
      }
    },
    //Determine whether is the content field or link filed
    isRequiredLink() {
      switch (this.key) {
        case 'job_notices':
          return true;
        default:
          return false;
      }
    },
    isRequiredStory() {
      switch (this.key) {
        case 'job_notices':
          return true;
        case 'yeni_doganlar':
          return true;
        case 'indirimler':
          return true;
        case 'aramiza_katilanlar':
          return true;
        default:
          return false;
      }
    },
    isRequiredContent() {
      switch (this.key) {
        case 'phones':
          return false;
        default:
          return true;
      }
    },
  },
};
</script>
