export default {
  Token : '',
  RefreshToken : '',
  Avatar : '',
  Email : '',
  FirstName : '',
  FullName : '',
  Id : '',
  IsBlocked : '',
  LastName : '',
  Type : '',
  Username : '',
  i18n: {},
  router: '',
  validator: {},
  alertObject: {
    color: 'info',
    message: '',
    position: 'top'
  },
  GroupPermessionIds: [],
  menuItems: [],
  loadingStatus: false,
  Password: '',
}
