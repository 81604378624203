export default {
  isAuthenticated(state) {
    return (!!state.Token && !!state.Id)
  },
  getId(state){
     return(state.Id)
  },


  getToken(state) {
    return (state.Token)
  },

  getUserType(state) {
    return (state.Type)
  },

  getUserFullName(state) {
    return (state.FullName)
  },

  userPasswordControl(state) {
    if (state.Password == 'ecz654321'){
      return true
    }
    return false
  },

  isAdmin(state) {
    return state.Type == 'Admin' ? true : false
  },

  isSuperAdmin(state) {
    return state.Type == 'SuperAdmin' ? true : false
  },

  getPermissionIds(state) {
    //TODO 61bbd45a6e3d3071a18462d6: Topluluk idsi, Daha sonradan bu backendten gelicek.
    return state.GroupPermessionIds.includes('61bbd45a6e3d3071a18462d6') //Eczacıbaşı için
    //TODO 61bbd45a6e3d3071a18462d6: Topluluk idsi, Daha sonradan bu backendten gelicek.
    //return state.GroupPermessionIds.includes('61bbd45a6e3d3071a18462d6') //Demo için
  },
}