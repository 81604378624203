<template>
  <div
    style="width: 100%; height: 100%; background: rgb(255,255,255);
background: radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(192,171,210,1) 29%, rgba(116,67,160,1) 100%);"
    aspect-ratio="2"
  >
    <v-layout wrap align-center justify-center fill-height>
      <v-container>
        <v-dialog v-model="ForgotPassword" max-width="500px">
          <v-card>
            <v-card-title
              class="headline font-weight-medium text-center orange--text"
            >
              <!-- description.forgotPassword -->
              {{ $t("description.forgotPassword") }}
            </v-card-title>

            <v-divider />

            <v-card-text>
              <v-container>
                <ValidationObserver ref="observer" v-slot="{ invalid }">
                  <form @submit.prevent="sendOneStep">
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <ValidationProvider
                          :name="$t('labels.email')"
                          rules="required|email"
                          v-slot="{ errors }"
                        >
                          <v-text-field
                            v-model="forgot.email"
                            :label="$t('labels.sellerCode')"
                            :error-messages="errors[0]"
                          ></v-text-field>
                        </ValidationProvider>
                      </v-col>
                      <!-- BayiEmail field  burası eklendi-->
                      <v-col cols="12" sm="12" md="12">
                        <ValidationProvider
                          :name="$t('labels.bayiEmail')"
                          rules="required|email"
                          v-slot="{ errors }"
                        >
                          <v-text-field
                            v-model="forgot.bayiEmail"
                            :label="$t('labels.bayiEmail')"
                            :error-messages="errors[0]"
                          ></v-text-field>
                        </ValidationProvider>
                      </v-col>

                      <v-col cols="12" sm="12" md="12">
                        <v-row>
                          <v-spacer />
                          <v-btn
                            color="blue darken-1"
                            class="white--text mr-3"
                            @click="ForgotPasswordSend()"
                            :loading="loadings.editedButton"
                            :disabled="invalid"
                          >
                            {{ $t("buttons.send") }}
                          </v-btn>
                          <v-btn
                            color="blue darken-1"
                            text
                            dark
                            @click="ForgotPassword = false"
                          >
                            {{ $t("buttons.cancel") }}
                          </v-btn>
                        </v-row>
                      </v-col>
                    </v-row>
                  </form>
                </ValidationObserver>
              </v-container>
            </v-card-text>

            <v-card-actions class="pb-4 pr-5"> </v-card-actions>
          </v-card>
        </v-dialog>
        <v-layout wrap align-center justify-center fill-height>
          <v-card style="border-radius: 11px" max-width="900" min-width="550">
            <v-row no-gutters>
              <v-col md="4" xs="12" class="pa-0" style="text-align:center">
                <!-- change logo color by filter -->
                <!-- <v-img
                  height="200"
                  style="height:260px; filter: invert(52%) sepia(13%) saturate(3207%) hue-rotate(130deg) brightness(95%) contrast(80%); margin-top:20%; margin-left:15%"
                  :src="require('@/../public/img/humanious-logo.png')"
                ></v-img> -->
                <!-- <img
                  style="height:260px; filter: invert(52%) sepia(13%) saturate(3207%) hue-rotate(130deg) brightness(95%) contrast(80%); margin-top:20%; margin-left:15%"
                  :src="require('@/../public/img/humanious-logo.png')"
                /> -->
              </v-col>
              <v-col md="12" xs="12" class="px-3 pb-5">
                <div style="margin:40px 0px 60px 0px">
                  <!-- TODO: change image -->
                  <v-img
                    max-height="70"
                    :src="require('@/../public/img/BSP-Banner-Transparent.png')"
                    style="margin-left: auto; margin-right: auto"
                    contain
                  ></v-img>
                </div>
                <!-- <div style="margin:40px 0px 60px 0px">
                  <v-img
                    max-height="70"
                    :src="
                      require('@/../public/img/emo_horizontal.png')
                    "
                    style="margin-left: auto; margin-right: auto"
                    contain
                  ></v-img>
                </div> -->
                <div style="max-width: 300px; margin: 30px auto 20px auto">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form @submit.prevent="handleSubmit(sendLogin)">
                      <ValidationProvider
                        name="Sicil Numarası"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <v-text-field
                          v-model="Username"
                          :label="$t('labels.sicilNumber')"
                          :error-messages="errors[0]"
                        ></v-text-field>
                      </ValidationProvider>

                      <ValidationProvider
                        name="Şifre"
                        rules="required|min:4|max:24"
                        v-slot="{ errors }"
                      >
                        <v-text-field
                          v-model="Password"
                          :label="$t('labels.password')"
                          :error-messages="errors[0]"
                          :counter="24"
                          :append-icon="
                            showPassword ? 'mdi-eye' : 'mdi-eye-off'
                          "
                          :type="showPassword ? 'text' : 'password'"
                          @click:append="showPassword = !showPassword"
                        ></v-text-field>
                      </ValidationProvider>
                      <!-- Şifremi unuttum  burda -->
                      <v-layout
                        style="margin-top: 40px"
                        justify-center
                        column
                        wrap
                        align-center
                      >
                        <!--
                        <v-btn
                          text
                          class="px-5 text-capitalize body-2 font-weight-light"
                          color="red lighten-1"
                          rounded
                          @click="ForgotPassword = true"
                          >{{ $t("buttons.forgotPass") }}</v-btn
                        >
                        -->
                        <v-row v-if="remainingLogin > 0">
                          <p
                            class="font-weight-black"
                            style="color: #7443a0 !important"
                          >
                            {{ remainingLogin }}
                            {{ $t("labels.userRemainingLogin") }}
                          </p>
                        </v-row>
                        <v-row v-if="remainingLogin == 0">
                          <p
                            class="font-weight-black"
                            style="color: #7443a0 !important"
                          >
                            {{ $t("labels.userBlocked") }}
                          </p>
                        </v-row>
                        <v-row>
                          <v-btn
                            elevation="0"
                            class="purple darken-1 px-5"
                            rounded
                            dark
                            large
                            style="width:160px"
                            type="submit"
                            :loading="pageLoading"
                          >
                            {{ $t("buttons.login") }}
                          </v-btn>
                        </v-row>
                      </v-layout>
                    </form>
                  </ValidationObserver>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-layout>
      </v-container>
    </v-layout>
  </div>
</template>
<script>
import AccountService from "../services/AccountService";
import { mapActions } from "vuex";
export default {
  mounted() {},
  data() {
    return {
      showPassword: false,
      pageLoading: false,
      ForgotPassword: false,
      Username: "",
      Password: "",
      bayiEmail: "",
      loadings: {
        editedButton: false,
      },
      forgot: {
        email: "",
        id: "",
      },
      remainingLogin: null,
    };
  },
  components: {},
  methods: {
    sendLogin() {
      this.pageLoading = true;
      let data = {
        Username: this.Username,
        Password: this.Password,
      };
      this.login(data)
        .then((res) => {
          this.pageLoading = false;
          this.$store.state.Password = data.Password;
          this.$store.state.Id = res.data.Id;
          this.$router.push({ name: "home" });
        })
        .catch((err) => {
          this.remainingLogin = err.response.data.LeftLoginCount;
          console.log("err ", err);
          this.pageLoading = false;
        });
    },

    ForgotPasswordSend() {
      let data2 = {
        Email: this.forgot.email,
        BayiEmail: this.forgot.bayiEmail,
      };
      this.loadings.editedButton = false;
      AccountService.forgotPassword(data2)
        .then((res) => {
          window.showSuccess(res.data.message);
          this.dialogForgotPassword = true;
          this.ForgotPassword = false;
        })
        .catch((err) => {
          //console.log(err.response.data);
          //console.log(err);
          window.showError(err);
        })
        .then(() => {
          this.loadings.editedButton = false;
        });
    },

    ...mapActions(["login"]),
  },
};
</script>
