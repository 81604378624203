// Örnek servis codiagno login
import axios from "../javascript/custom-axios";
const RESOURCE_NAME = "/admin/badWord";

export default {
  getBadWords(pageSize = 15, page = 1, Type = "") {
    return axios.get(`${RESOURCE_NAME}/getBadWords`, {
      params: { page, pageSize },
    });
  },
  addProhibitedWord(body) {
    return axios.post(`${RESOURCE_NAME}/addBadWord`, body);
  },
  updateProhibitedWord(body) {
    return axios.put(`${RESOURCE_NAME}/changeBadWord`, body);
  },
  deleteWord(word) {
    return axios.delete(`${RESOURCE_NAME}/deleteBadWord?word=${word}`);
  },
};
