var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[(_vm.isLoading)?_c('div',{staticClass:"text-center"},[_c('v-overlay',[_c('v-progress-circular',{attrs:{"size":70,"width":10,"indeterminate":"","color":"#E15200"}})],1)],1):_vm._e(),_c('div',[_c('v-btn',{directives:[{name:"scroll",rawName:"v-scroll",value:(_vm.onScroll),expression:"onScroll"},{name:"show",rawName:"v-show",value:(_vm.fab),expression:"fab"}],staticClass:"ma-2 mr-4",attrs:{"dark":"","fixed":"","bottom":"","right":"","big":"","elevation":"0","color":"primary"},on:{"click":_vm.stickyPublishOperation}},[_vm._v(" "+_vm._s(_vm.$t("buttons.publish"))+" ")]),_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.sendForm)}}},[_c('v-card-title',{staticStyle:{"color":"#595959"}},[_c('v-row',[_c('v-col',{staticClass:"py-2 pl-0"},[_vm._v(_vm._s(_vm.$t("title.addStory")))]),_c('v-spacer'),_c('v-btn',{staticClass:"ma-2 mr-4",attrs:{"small":"","type":"submit","elevation":"0","outlined":"","color":"info"}},[_vm._v(" "+_vm._s(_vm.$t("buttons.publish"))+" ")])],1)],1),_c('v-divider'),_c('v-card-text',{staticClass:"px-0 py-10"},[_c('v-card-text',{staticClass:"px-0 py-0 add-content-card-body"},[_c('v-banner',{staticClass:"mt-5",attrs:{"rounded":"","outlined":""}},[_c('v-card-title',{staticClass:"add-content-title"},[_vm._v(_vm._s(_vm.$t("labels.storyTitle")))]),_c('ValidationProvider',{attrs:{"name":"Başlık","rules":"required|max:150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mx-5",attrs:{"label":_vm.$t('labels.header'),"clearable":"","error-messages":errors[0]},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}})]}}],null,true)})],1)],1),_c('v-card-text',{staticClass:"px-0 py-0 add-content-card-body"},[_c('v-banner',{staticClass:"mt-5",attrs:{"rounded":"","outlined":""}},[_c('v-card-title',{staticClass:"add-content-title"},[_vm._v(_vm._s(_vm.$t("labels.storyPhotoUpload")))]),_c('v-card-title',{staticClass:"add-content-title",staticStyle:{"font-size":"12px !important"}},[_vm._v(_vm._s(_vm.$t("labels.storyphotoUploadDescription")))]),_c('v-row',[_c('v-col',[_c('input',{ref:"uploader",staticClass:"d-none",attrs:{"type":"file","accept":"image/*"},on:{"change":_vm.onFileChange}}),_c('v-btn',{staticClass:"ma-4 mr-8",attrs:{"small":"","elevation":"0","outlined":"","color":"info","disabled":_vm.photoLength == 1},on:{"click":function($event){return _vm.browsePhoto()}}},[_vm._v(" "+_vm._s(_vm.$t("buttons.uploadNew"))+" ")])],1),(_vm.selectedPhotoModel)?_c('v-col',{staticClass:"mr-5",staticStyle:{"text-align":"end"},attrs:{"align-self":"end"}},[_vm._v(" "+_vm._s(_vm.photoLength == null ? " " : _vm.photoLength + " /" + " 1")+" ")]):_vm._e()],1),(_vm.selectedPhotoModel)?_c('v-banner',{staticClass:"mt-5",attrs:{"rounded":"","outlined":""}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return _c('v-img',{staticStyle:{"border-radius":"8px"},attrs:{"max-height":"250px","aspect-ratio":4 / 3,"contain":"","src":_vm.computedUrl(_vm.selectedPhotoModel)}},[_c('v-expand-transition',[(hover)?_c('div',{staticClass:"\n                          transition-fast-in-fast-out\n                          image-hover\n                          white--text\n                        ",staticStyle:{"height":"100%"}},[_c('v-layout',{attrs:{"row":"","wrap":"","fill-height":"","align-center":"","justify-center":"","ma-0":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ma-0",staticStyle:{"z-index":"100"},attrs:{"icon":""},on:{"click":function($event){return _vm.deletePhoto(_vm.selectedPhotoModel, 0)}}},on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-close")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("labels.deleteKey")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ma-0",staticStyle:{"z-index":"100"},attrs:{"icon":""}},on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-folder")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.selectedPhotoModel.name))])])],1)],1):_vm._e()])],1)}}],null,true)})],1):_vm._e()],1)],1),_c('v-card-text',{staticClass:"px-0 py-0 add-content-card-body"},[_c('v-banner',{staticClass:"mt-5",attrs:{"rounded":"","outlined":""}},[_c('v-card-title',{staticClass:"add-content-title"},[_vm._v(_vm._s(_vm.$t("labels.storyFileUpload")))]),_c('v-card-title',{staticClass:"add-content-title",staticStyle:{"font-size":"12px !important"}},[_vm._v(_vm._s(_vm.$t("labels.storyFileUploadDescription")))]),_c('v-row',[_c('v-col',[_c('input',{ref:"fileInput",staticStyle:{"display":"none"},attrs:{"type":"file","accept":"/*"},on:{"change":_vm.selectFileHandle}}),_c('v-btn',{staticClass:"ma-4 mr-8",attrs:{"small":"","elevation":"0","outlined":"","color":"info","disabled":_vm.fileLength == 1},on:{"click":function($event){_vm.browseFile();
                      ('');}}},[_vm._v(" "+_vm._s(_vm.$t("buttons.uploadNew"))+" ")])],1),(_vm.selectedFiles.length > 0)?_c('v-col',{staticClass:"mr-5",staticStyle:{"text-align":"end"},attrs:{"align-self":"end"}},[_vm._v(" "+_vm._s(_vm.fileLength == null ? " " : _vm.fileLength + " /" + " 1")+" ")]):_vm._e()],1),(_vm.selectedFiles.length > 0)?_c('v-banner',{staticClass:"mt-5",attrs:{"rounded":"","outlined":""}},[_c('v-col',[_c('v-container',{staticClass:"mt-5",attrs:{"mx-0":"","px-0":"","fluid":"","grid-list-xl":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},_vm._l((_vm.selectedFiles),function(item,index){return _c('v-flex',{key:index,attrs:{"xs2":""}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var hover = ref.hover;
return _c('v-img',{staticStyle:{"border-radius":"8px"},attrs:{"width":"100%","aspect-ratio":1 / 1,"contain":"","src":item.type == 'image/jpeg' ||
                              item.type == 'image/png'
                                ? _vm.selectedFilesUrl[index]
                                : item.type == 'video/mp4'
                                ? require('@/../public/img/video-thumb.png')
                                : require('@/../public/img/article-thumb.png')}},[_c('v-expand-transition',[(hover)?_c('div',{staticClass:"\n                                  transition-fast-in-fast-out\n                                  image-hover\n                                  white--text\n                                ",staticStyle:{"height":"100%"}},[_c('v-layout',{attrs:{"row":"","wrap":"","fill-height":"","align-center":"","justify-center":"","ma-0":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ma-0",staticStyle:{"z-index":"100"},attrs:{"icon":""},on:{"click":function($event){return _vm.deleteFile(item.id, index)}}},on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-close")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("labels.deleteKey")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ma-0",staticStyle:{"z-index":"100"},attrs:{"icon":""}},on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-folder")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.name))])])],1)],1):_vm._e()])],1)}}],null,true)})],1)}),1)],1)],1)],1):_vm._e()],1)],1),_c('v-card-text',{staticClass:"px-0 py-0 add-content-card-body"},[_c('v-banner',{staticClass:"mt-5",attrs:{"rounded":"","outlined":""}},[_c('v-card-title',{staticClass:"add-content-title"},[_vm._v(" "+_vm._s(_vm.$t("labels.modulChoose"))+" ")]),_c('v-row',{staticClass:"ma-2 mr-4"},[_c('v-col',{attrs:{"cols":"4"}},[_c('ValidationProvider',{attrs:{"name":"Page","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.pageList,"label":_vm.$t('description.selectPage'),"item-text":"Value","return-object":"","item-value":"Key","disabled":_vm.editItemId != null,"error-messages":errors[0]},on:{"change":function($event){return _vm.fillInnerSelectPage(_vm.selectedModuleModal)}},model:{value:(_vm.selectedModuleModal),callback:function ($$v) {_vm.selectedModuleModal=$$v},expression:"selectedModuleModal"}})]}}],null,true)})],1),(_vm.selectedModuleModal.IsDetail)?_c('v-col',{attrs:{"cols":"4"}},[_c('v-select',{attrs:{"items":_vm.allInnerModules,"label":_vm.$t('description.selectInnerPage'),"item-text":"Value","item-value":"Key","disabled":_vm.editItemId != null},model:{value:(_vm.selectedInnerModuleModal),callback:function ($$v) {_vm.selectedInnerModuleModal=$$v},expression:"selectedInnerModuleModal"}})],1):_vm._e()],1)],1)],1),_c('v-card-text',{staticClass:"px-0 py-0 add-content-card-body"},[_c('v-banner',{staticClass:"mt-5",attrs:{"rounded":"","outlined":""}},[_c('v-card-title',{staticClass:"add-content-title"},[_vm._v(" "+_vm._s(_vm.$t("labels.dateAndPin"))+" ")]),_c('v-row',{staticClass:"ma-2 mr-4"},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.date,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
                                var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Bitiş Tarihi","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menu.save(_vm.date)}}},[_vm._v(" OK ")])],1)],1)],1),(_vm.selectedModuleModal.IsLink)?_c('v-col',{attrs:{"cols":"5"}},[_c('v-text-field',{attrs:{"label":_vm.$t('labels.link'),"clearable":""},model:{value:(_vm.link),callback:function ($$v) {_vm.link=$$v},expression:"link"}})],1):_vm._e()],1),_c('v-row',{staticClass:"ma-2 mr-4"},[_c('v-col',{attrs:{"cols":"2"}},[_c('v-checkbox',{attrs:{"label":'Üste Sabitle'},model:{value:(_vm.checkboxPin),callback:function ($$v) {_vm.checkboxPin=$$v},expression:"checkboxPin"}})],1)],1)],1)],1),_c('v-card-text',{staticClass:"px-0 py-0 add-content-card-body"},[_c('v-banner',{staticClass:"mt-5",attrs:{"rounded":"","outlined":""}},[_c('v-card-title',{staticClass:"add-content-title"},[_vm._v(" "+_vm._s(_vm.$t("labels.postGroup"))+" ")]),_vm._l((_vm.groupsSelectArray),function(data,rowIndex){return _c('v-row',{key:rowIndex,staticClass:"mt-2"},[_c('v-row',_vm._l((data),function(element,colIndex){return _c('v-col',{key:colIndex,attrs:{"cols":"4"}},[_c('ValidationProvider',{attrs:{"vid":'attribute' + rowIndex + colIndex,"name":"Grup","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('div',{staticClass:"px-5 mt-3",attrs:{"row":"","wrap":""}},[_c('v-select',{attrs:{"items":element.groups,"item-text":"name","item-value":"id","label":element.name == 'Şirket'
                              ? 'Kuruluş'
                              : element.name,"dense":"","required":"","error-messages":errors[0]},on:{"click":function($event){return _vm.clickGroupFilterModal(element, colIndex, rowIndex)}},model:{value:(_vm.groupArr[rowIndex][colIndex]),callback:function ($$v) {_vm.$set(_vm.groupArr[rowIndex], colIndex, $$v)},expression:"groupArr[rowIndex][colIndex]"}})],1)]}}],null,true)})],1)}),1)],1)}),_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[(!_vm.groupLengthAlert)?_c('v-btn',{staticClass:"ma-1",attrs:{"small":"","outlined":"","color":"info"},on:{"click":function($event){return _vm.createNewGroup()}}},[_vm._v(" "+_vm._s(_vm.$t("buttons.addNew"))+" ")]):_vm._e()],1),_c('v-col',{staticClass:"mt-2"},[_c('v-alert',{staticClass:"font-weight-black",staticStyle:{"font-size":"1.2em"},attrs:{"border":"left","close-text":"Close Alert","type":"info","color":"#7443a0","dismissible":"","outlined":"","prominent":""},model:{value:(_vm.groupLengthAlert),callback:function ($$v) {_vm.groupLengthAlert=$$v},expression:"groupLengthAlert"}},[_vm._v(" "+_vm._s(_vm.$t("description.groupLengthMessage"))+" ")])],1)],1)],2)],1)],1)],1)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }