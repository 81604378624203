//import fields from '../attributes/en' // validasyon için tanımlı fieldları genel dil dosyasına ekleme
export default {
  /* Buttons */

  "buttons.login": "LOGIN",
  "buttons.forgotPass": "Forgot Password",
  "buttons.close": "CLOSE",
  "buttons.select": "SELECT",
  "buttons.back": "BACK",
  "buttons.resume": "RESUME",
  "buttons.send": "SEND",
  "buttons.update": "UPDATE",
  "buttons.cancel": "CANCEL",
  "buttons.profile": "Profile",
  "buttons.logout": "Logout",
  "buttons.yes": "YES",
  "buttons.no": "NO",
  "buttons.ok": "OK",
  "buttons.submit": "Submit",
  "buttons.clearFilter": "CLEAR FILTERS",
  "buttons.excelExport": "EXCEL EXPORT",
  "buttons.download": "DOWNLOAD",
  "buttons.clear": "CLEAR",
  "buttons.save": "SAVE",
  "buttons.inReviewOrder": "Review Order",
  "buttons.publish": "Publish",
  "buttons.saveAsDraft": "Save as Draft",
  "buttons.uploadNew": "UPLOAD NEW",
  "buttons.uploadfromLibrary": "ADD FROM LIBRARY",
  "buttons.addNew": "ADD NEW",
  "buttons.addNewGroup": "ADD NEW GROUP",
  "buttons.makeAdmin": "MAKE ADMIN",
  "buttons.removeAdminPermissions": "Remove from Admin",
  "buttons.userPermissions": "AUTHENTICATE",
  "buttons.userDetails": "DETAILS",
  "buttons.deleteSelected": "DELETE SELECTED",
  "buttons.deleteSelectedCategory": "DELETE SELECTED",

  "buttons.sampleFile": "DOWNLOAD SAMPLE FILE",
  "buttons.surveyAddAnswer": "ADD NEW ANSWER",
  "buttons.allPermissionSelect": "SELECT ALL",
  "buttons.allPermissionDeselect": "DESELECT ALL",

  /* Labels */

  "labels.email": "E-Mail",
  "labels.sicilNumber": "Registration Number",
  "labels.password": "Password",
  "labels.search": "Search",
  "labels.groups": "Groups",
  "labels.nameSurname": "Name Surname",
  "labels.userName": "Username",
  "labels.sellerCode": "Vendor Code",
  "labels.bayiEmail": "Vendor E-mail",
  "labels.modulName": "Module Name",
  "labels.modulKey": "Module Key",
  "labels.modulDescription": "Module Description",
  "labels.groupType": "Group Type",
  "labels.groupName": "Group Name",
  "labels.allGroupTypes": "All",
  "labels.userBlocked": "Your user has been blocked.",
  "labels.userRemainingLogin": "Rights remaining",
  "labels.userUsername": "Username (Registration)",
  "labels.userPassword": "Password",
  "labels.userFirstName": "First Name",
  "labels.userLastname": "Last Name",
  "labels.userEmail": "Email",
  "labels.userPhone": "Phone",
  "labels.userStatus": "User Status",
  "labels.userType": "User Type",
  "labels.userAbout": "About User",
  "labels.header": "Header",
  "labels.chooseKeyFirst": "Make Primary",
  "labels.deleteKey": "Delete",
  "labels.category": "Category",
  "labels.tags": "Tags",
  "labels.tags.desc": "Press enter key to add a new tag.",
  "labels.categoryNameTR": "Category Turkish Name",
  "labels.categoryNameEN": "Category English Name",
  "labels.mainPicture": "Main Image",
  "labels.max5": "Maximum 5 Items",
  "labels.max15": "Maximum 15 Items",
  "labels.userSearch": "Name",
  "labels.tooltipCustomFiels": "Go to Custom Field",
  "labels.tooltipEdit": "Edit",
  "labels.answerQuestion": "Answer Question",
  "labels.inspectQuestion": "Inspect Question",
  "labels.questionAnswer": "Question Answer",
  "labels.questionHr": "Question",
  "labels.titleHr": "Title",
  "labels.filesHr": "Files",
  "labels.dateHr": "Reply Date",
  "labels.noFileHr": "No File",
  "labels.statusHr": "Question Status",
  "labels.tooltipAnswer": "Answer",
  "labels.tooltipAnalyse": "examine",
  "labels.tooltipAnswered": "Answered",
  "labels.tooltipDelete": "Delete",
  "labels.surveyType": "Survey Type",
  "labels.questionType": "Question Type",
  "labels.questionStatus": "Question Status",
  "labels.makeAdmin": "Make Admin",
  "labels.authorizeUser": "Authorize",
  "labels.fileUpload": "Add Files or Media",
  "labels.fileUploadCompulsory": "Add Files or Media (Compulsory)",
  "labels.storyFileUpload": "Add Story Image",
  "labels.storyFileUploadDescription":
    "Up to 50 MB, portrait format, recommended resolution 900x1200",
  "labels.fileUploadDescription":
    "Maximum 20 MB, 4:3 ratio, recommended resolution 1200x900.",
  "labels.photoUpload": "Add Photo (Required)",
  "labels.storyPhotoUpload": "Add Icon Photo (Required)",
  "labels.storyphotoUploadDescription":
    "Maximum 20 MB, square format, recommended resolution 900x900.",
  "labels.photoUploadDescription":
    "Maximum 1 MB, 4:3 ratio, recommended resolution 1200x900.",
  "labels.photoUploadNotifications": "Upload Photo",
  "labels.price": "Price",
  "labels.description": "Description",
  "labels.contact": "Contact",
  "labels.image": "Image",
  "labels.sortOrder": "Sort",
  "labels.actions": "Action",
  "labels.type": "Keyword",
  "labels.postType": "Content Type",
  "labels.content": "Content",
  "labels.postCategory": "Category",
  "labels.postCategoryRequired":
    " (It is mandatory to select one from each main category)",
  "labels.postTag": "Label",
  "labels.postGroup": "Group",
  "labels.postDivision": "Division",
  "labels.postCustomField": "Custom Field",
  "labels.selectExcelFile": "Choose a food menu",
  "labels.tooltipActive": "Active",
  "labels.tooltipPassive": "Passive",
  "labels.tooltipAnswered": "Answered",
  "labels.tooltipNoAnswered": "Not Answered",
  "labels.tooltipInReview": "In Review",
  "labels.foodMenuCalory": "Calories",
  "labels.selectCorp": "Choose company",
  "labels.selectLoc": "Select location",
  "labels.selectEmpType": "Select Employee Type",
  "labels.selectItemsPerPage": "Choose number of data per page",
  "labels.selectDate": "Select Date",
  "labels.selectTime": "Select Time",
  "labels.selectDateRange": "Select Date Range",
  "labels.tooltipPublished": "Live",
  "labels.tooltipDraft": "Pending",
  "labels.tooltipReview": "In review",
  "labels.tooltipUnpublished": "Unpublished",
  "labels.tooltipReject": "Rejected",
  "labels.tooltipActive": "Active",
  "labels.tooltipPassive": "Passive",
  "labels.selectboxType": "Please select data type",
  "labels.inputName": "Name (Name field must be unique)",
  "labels.parentCategory": "Parent Category",
  "labels.surveyType": "Survey Type",
  "labels.surveyName": "Survey Name",
  "labels.surveyStatus": "Survey Status",
  "labels.surveyPublishDate": "Publish Date",
  "labels.surveyEndDate": "End Date",
  "labels.surveyQuestion": "Survey Question",
  "labels.surveyAnswer": "Answer",
  "labels.surveyLink": "Link",
  "labels.selectPage": "Select Page",
  "labels.selectStatistic": "Select Statistic",
  "labels.selectCountPostType": "Select Post Type",
  "labels.inputTagId": "Tag Id",
  "labels.selectInnerPage": "Select the redirected page",
  "labels.modulChoose": "Choose Page to Redirect",
  "labels.dateAndLinks": "Date and Link",
  "labels.dateAndPin": "Date and Pin",
  "labels.link": "Link",
  "labels.notificationUsers": "Users",
  "labels.notificationTargetTypes": "Person(s) and Group(s) to be forwarded",
  "labels.notificationDateTime": "Submission Date and Time",
  "labels.notificationAllTargets": "Send to all contacts and groups.",
  "labels.notificationUserSelect": "Select users",
  "labels.notificationLink": "Enter the link to open in the application.",
  "labels.notificationExternalLink":
    "Enter the link to open outside the application.",
  "labels.notificationMessage": "Message",
  "labels.notificationTitleDescription": "Content and Message",
  "labels.storyTitle": "Story Title",
  "labels.surveyDownload": "Download Survey Results",
  "labels.currentPassword": "Current Password",
  "labels.newPassword": "New Password",
  "labels.reNewPassword": "Re-type New Password",
  "labels.changePassword": "Change Password",
  "labels.hardReload": "Reload Page",
  "labels.search": "Enter Text To Search",
  "labels.useGuide": "User Guide",
  "labels.searchInTitle": "Search to Title",
  "labels.noCategory": "No Category",
  "labels.searchCategory": "Type for Searching Category",
  "labels.noCategorySelected": "Category not selected",
  "labels.unPublish": "Unpublish",
  "labels.publishItem": "Publish",
  "labels.regions": "Regions",

  /* Menu */

  "menu.groups": "Groups",
  "menu.users": "Users",
  "menu.modulManagement": "Module Management",

  /* Titles */

  "title.modulManagement": "Module Management",
  "title.groups": "Groups",
  "title.users": "Users",
  "title.endorsement": "Appreciation Thanks",
  "title.moduleModalCreate": "Add New Module",
  "title.moduleModalUpdate": "Update Module",
  "title.groupModalCreate": "Add New Group",
  "title.groupModalUpdate": "Update Group",
  "title.userModalUpdate": "Update User",
  "title.userModalCreate": "Add New User",
  "title.logout": "Logout",
  "title.ProfileInfo": "Profile Information",
  "title.userDetails": "User Detail",
  "title.category": "Category",
  "title.categoryModalUpdate": "Category Update",
  "title.categoryCreate": "Add New Category",
  "title.makeAdmin": "Make Admin",
  "title.removeAdmin": "Remove from Admin",
  "title.addContent": "Add Content",
  "title.updateContent": "Update Content",
  "title.tableHeaderTrName": "Turkish Name",
  "title.tableHeaderEnName": "English Name",
  "title.surveys": "Surveys",
  "title.askHr": "Ask HR",
  "title.createSurvey": "Add Survey",
  "title.surveyDetails": "Survey Detail",
  "title.tableHeaderIcon": "Icon",
  "title.tableHeaderName": "Name",
  "title.tableHeaderProfilePic": "Profile Picture",
  "title.tableHeaderLastName": "Lastname",
  "title.tableHeaderEmail": "Email",
  "title.tableHeaderKey": "Module Key",
  "title.tableHeaderCreateDate": "Creation Date",
  "title.tableHeaderEndDate": "End Date",
  "title.tableHeaderActions": "Actions",
  "title.tableHeaderSurveyQuestion": "Survey Question",
  "title.tableHeaderSurveyType": "Survey Type",
  "title.tableHeaderUserType": "UserType",
  "title.tableHeaderStatus": "Status",
  "title.tableHeaderSurveyAnswerCount": "Number of Answers",
  "title.tableHeaderModules": "Module Name",
  "title.tableHeaderWrite": "Don't Write",
  "title.tableHeaderRead": "Read",
  "title.tableHeaderApprove": "Publishing",
  "title.deleteContentTitle": "Delete Content",
  "title.titleCustomFields": "Custom Fields",
  "title.customFieldCreate": "Add Custom Field",
  "title.customFieldEdit": "Edit Custom Field",
  "title.tableHeaderDisplayName": "Name to Display",
  "title.tableHeaderDataType": "DataType",
  "title.tableHeaderCustomFieldName": "Custom Field Name",
  "title.tableHeaderCustomFieldModuleName": "Module Name It Belongs To",
  "title.foodMenuModalCreate": "Upload File",
  "title.titleFoodMenu": "Food Menu",
  "title.titleReport": "Reports",
  "title.tableHeaderFoodMenuDate": "Date Added to System",
  "title.tableHeaderLikeCount": "Like Count",
  "title.tableHeaderFoodMenuName": "Food Menu Name",
  "title.tableHeaderFoodMenuCreator": "Menu Creator",
  "title.foodMenuModalDetail": "Food Menu Detail",
  "title.parentName": "Parent Name",
  "title.modulManagement": "Module Management",
  "title.groups": "Groups",
  "title.users": "Users",
  "title.moduleModalCreate": "Add New Module",
  "title.moduleModalUpdate": "Update Module",
  "title.groupModalCreate": "Add New Group",
  "title.groupModalUpdate": "Update Group",
  "title.userModalUpdate": "Update User",
  "title.userModalCreate": "Add New User",
  "title.logout": "Logout",
  "title.userDetails": "User Detail",
  "title.category": "Category",
  "title.categoryDetails": "Detail",
  "title.categoryCreate": "Add New Category",
  "title.subCategoryCreate": "Add SubCategory",
  "title.makeAdmin": "Make Admin",
  "title.removeAdmin": "Remove from Admin",
  "title.addContent": "Add Content",
  "title.surveys": "Surveys",
  "title.createSurvey": "Add Survey",
  "title.surveyDetails": "Survey Detail",
  "title.tableHeaderIcon": "Icon",
  "title.tableHeaderName": "Name",
  "title.tableHeaderUsername": "Username",
  "title.tableHeaderProfilePic": "Profile Picture",
  "title.tableHeaderLastName": "Lastname",
  "title.tableHeaderEmail": "Email",
  "title.tableHeaderKey": "Module Key",
  "title.tableHeaderCreateDate": "Creation Date",
  "title.tableHeaderEndDate": "End Date",
  "title.tableHeaderActions": "Actions",
  "title.tableHeaderSurveyQuestion": "Survey Question",
  "title.tableHeaderSurveyType": "Survey Type",
  "title.tableHeaderUserType": "UserType",
  "title.tableHeaderModules": "Module Name",
  "title.tableHeaderWrite": "Write",
  "title.tableHeaderRead": "Read",
  "title.tableHeaderApprove": "Publishing",
  "title.tableHeaderFullNameSurvey": "Survey Creator",
  "title.tableHeaderFullNameAskHR": "Question Creator",
  "title.stories": "Stories",
  "title.tableHeaderTitle": "Title",
  "title.tableHeaderThumbNail": "Image",
  "title.addStory": "Add Story",
  "title.notificationTitle": "Notification Title",
  "title.notificationType": "Notification Target",
  "title.notificationData": "Notification Content",
  "title.storyData": "Story Content",
  "title.storyLinkType": "Story Link Type",
  "title.notificationRedirectPage": "Page to Redirect",
  "title.contentPageHeaderAdd": "Add",
  "title.contentPageHeaderUpdate": "Update",
  "title.moduleDescription": "Module Description",
  "title.subCategoryCreate": "Add SubCategory",
  "title.advertModalUpdate": "Update Advert",
  "title.advertModalCreate": "Add Advert",
  "title.endorsementTopicModalCreate": "Add Appreciation & Thanks",
  "title.endorsementTopicModalUpdate": "Update Appreciation & Thanks",
  "title.tableHeaderEndorsementTopicImage": "Image",
  "title.tableHeaderEndorsementTopicDescription": "Description",
  "title.tableHeaderEndorsementTopicSortOrder": "Sort",
  "title.tableHeaderEndorsementTopicType": "Key Type",
  "title.tableHeaderEndorsementTopicHeader": "Title",
  "title.notifications": "Notifications",

  /* Description */
  "description.forgotPassword": "Send My Password",
  "description.noList": "List is empty.",
  "description.deleteModalTitle": "Deletion Action",
  "description.deleteModalDescription":
    "Are you sure you want to delete the selected data?",
  "description.invalidKey":
    "Please only use English letters and the underscore sign ( _ ) in the key field.",
  "description.logout": "Are you sure you want to log out?",
  "description.groupLengthMessage": "Note: You can add up to 4 lines.",
  "description.tableLoadingError":
    "An error occurred while loading the table, please try again.",
  "description.makeAdminDescription":
    "Are you sure you want to make this person admin?",
  "description.removeAdminDescription":
    "Are you sure you want to remove this person from admin?",
  "description.authorizationUserText1": "Define the user's privileges, then",
  "description.authorizationUserText2": "click the following button.",
  "description.deleteContent":
    "Are you sure you want to delete the selected content?",
  "description.invalidDatePick": "Please enter a valid date range.",
  "description.excelDataError": "Please select report and data to export.",
  "description.excelColumnError":
    "Please select report and data with column to export.",
  "description.invalidMenuSelect": "Please select at least one menu.",
  "description.selectModule": "Please select a module",
  "description.fileSizeError":
    "Please select a file that complies with the file size limit.",
  "description.notificationTargetError":
    "Please select at least one target before sending notification.",
  "description.unsupportedMediaType":
    "Unsupported file type, please select a different file type.",
  "description.surveyAnswerBoundReached": "Reply adding limit reached.",
  "description.surveyEmptyAnswer": "Cannot add empty answer",
  "description.wrongDateInput": "Start date cannot be later than End date.",
  "description.selectPage": "Please Select a Page",
  "description.selectInnerPage": "Please Select an Inner Page",
  "description.notificationSendError":
    "Notification could not be sent, please try again.",
  "description.storyPhotoError": "Please upload at least one photo.",
  "description.tagCountReached":
    "You can add a maximum of 15 tags, please refresh the page to reset.",
  "description.propertyEmptySicil":
    "Empty registration number cannot be added, please add registration number.",
  "description.propertyEmptyPublishDate":
    "The information in the custom fields section is required, please fill in completely",
  "description.contentPhotoUpload": "Please fill in the required photo field.",
  "description.groupRowDelete":
    "You cannot delete this row. At least one group line must be entered.",
  "description.emptyCategorySelect":
    "Category selection is required, please select at least one category.",
  "description.draftNotificationInfo":
    "Publishing as draft, notification will not be sent.",
  "description.contentFileUpload": "Please upload at least one file.",
  "description.contentFileUploadOnlyPdf":
    "Please upload at least one file only pdf .",
  "description.errorOnCreate":
    "Please fill the mandatory inputs and try again.",
  "description.errorOnCreateContent":
    "Please post according to the content rules.",
  "description.communityModule":
    "This module contents can be accessed by the entire community.",
  "description.invalidFileType":
    "Please select a valid file type to publish the content.",
  "description.authorizationError":
    "There is an authorization problem, please login again.",
  "description.serverError":
    "There is a problem with the servers, please try again later.",
    "description.addTagError":
    "Failed to add a tag, please try again later.",
};
