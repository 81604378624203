<template>
  <v-container grid-list-xl class="word-wrap mb-3">
    <div>
      <!-- Sticky publish button start -->
      <v-btn
        v-scroll="onScroll"
        v-show="fab"
        dark
        fixed
        bottom
        style="z-index: 9999"
        right
        big
        class="ma-2 mr-4"
        elevation="0"
        color="primary"
        @click="stickyPublishOperation"
      >
        {{ $t('buttons.publish') }}
      </v-btn>
      <!-- Sticky publish button end -->
      <!-- Info dialog start -->
      <v-dialog max-width="500" v-model="infoDialog">
        <v-card>
          <v-card-title class="text-h4 text-center justify-center mb-5">
            {{ $t('title.moduleDescription') }}
          </v-card-title>
          <v-card-text
            class="font-weight-bold pl-8 body-1"
            style="line-height: 1.8; color: #7443a0;"
          >
            <p v-html="moduleDescription"></p>
          </v-card-text>
          <v-card-actions
            style="
          background-color: white;
          position: sticky;
          bottom: 0px;
        "
          >
            <v-spacer></v-spacer>

            <v-btn
              color="deep-purple lighten-2"
              text
              @click="infoDialog = false"
            >
              {{ $t('buttons.close') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Info dialog end -->
      <!-- Content -->
      <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
        <form @submit.prevent="handleSubmit(sendForm)">
          <!-- Form buttons start -->
          <v-card-title style="color: #595959">
            <v-row>
              <v-col class="py-2 pl-0"
                >{{ pageHeader }} {{ $t('title.contentPageHeaderAdd') }}</v-col
              >
              <v-spacer />
              <v-btn
                small
                class="ma-2 mr-4"
                elevation="0"
                outlined
                color="info"
                dark
                @click="informationPopUp"
              >
                <v-icon>
                  mdi-information-outline
                </v-icon>
              </v-btn>
              <!-- <v-btn class="mx-2" fab dark small color="primary">
                <v-icon dark>
                  mdi-minus
                </v-icon>
              </v-btn> -->
              <v-btn
                small
                type="submit"
                class="ma-2 mr-4"
                elevation="0"
                outlined
                color="info"
              >
                {{ $t('buttons.publish') }}
              </v-btn>
            </v-row>
          </v-card-title>
          <!-- Form buttons end -->

          <v-divider />

          <v-layout row wrap class="px-0 py-10">
            <v-flex xs12 md8>
              <!-- Groups start  -->
              <!-- <v-card-text class="px-0 py-0 add-content-card-body">
                <v-banner rounded outlined class="mt-5">
                  <v-card-title class="add-content-title">
                    {{ $t("labels.postGroup") }}
                  </v-card-title>

                  <v-row
                    class="mt-2"
                    v-for="(data, rowIndex) in groupsSelectArray"
                    :key="rowIndex"
                  >
                    <v-row align="center" justify="center">
                      <v-col cols="11">
                        <v-row v-if="!pageRules.isPublicGroups">
                          <v-col
                            cols="4"
                            v-for="(element, colIndex) in data"
                            :key="colIndex"
                          >
                            <ValidationProvider
                              :vid="'attribute' + rowIndex + colIndex"
                              name="Grup"
                              rules="required"
                              v-slot="{ errors }"
                            >
                              <div row wrap class="px-5 mt-3">
                                <v-select
                                  v-model="groupArr[rowIndex][colIndex]"
                                  :items="element.groups"
                                  @click="
                                    clickGroupFilterModal(
                                      element,
                                      colIndex,
                                      rowIndex
                                    )
                                  "
                                  @change="
                                    changeGroupInput(
                                      element,
                                      colIndex,
                                      rowIndex
                                    )
                                  "
                                  item-text="name"
                                  item-value="id"
                                  :label="
                                    element.name == 'Şirket'
                                      ? 'Kuruluş'
                                      : element.name
                                  "
                                  dense
                                  required
                                  :error-messages="errors[0]"
                                ></v-select>
                              </div>
                            </ValidationProvider>
                          </v-col>
                        </v-row>
                        <v-row class="ml-5" v-if="pageRules.isPublicGroups">
                          <v-card-title
                            class="add-content-title"
                            style="font-size: 12px !important;"
                            >{{
                              $t("description.communityModule")
                            }}</v-card-title
                          >
                        </v-row>
                      </v-col>
                      <v-col cols="1">
                        <v-icon
                          v-if="groupsSelectArray.length != 1"
                          @click="deleteGroupRow(rowIndex)"
                          >mdi-trash-can-outline</v-icon
                        >
                      </v-col>
                    </v-row>
                  </v-row>
                  <v-row justify="center" align="center">
                    <v-col cols="2" v-if="!pageRules.isPublicGroups">
                      <v-btn
                        small
                        v-if="!groupLengthAlert"
                        class="ma-1"
                        outlined
                        color="info"
                        @click="createNewGroup()"
                      >
                        {{ $t("buttons.addNewGroup") }}
                      </v-btn>
                    </v-col>

                    <v-col class="mt-2">
                      <v-alert
                        v-model="groupLengthAlert"
                        border="left"
                        close-text="Close Alert"
                        type="info"
                        color="#7443a0"
                        dismissible
                        outlined
                        prominent
                        class="font-weight-black"
                        style="font-size: 1.2em"
                      >
                        {{ $t("description.groupLengthMessage") }}
                      </v-alert>
                    </v-col>
                  </v-row>
                </v-banner>
              </v-card-text> -->
              <!-- Groups end -->

              <!-- Division select start  -->
              <v-card-text class="px-0 py-0 add-content-card-body">
                <v-banner rounded outlined class="mt-5">
                  <v-card-title class="add-content-title">
                    {{ $t('labels.postType') }}
                  </v-card-title>

                  <v-layout v-if="!pageRules.isPublicGroups">
                    <v-col cols="12">
                      <v-layout class="mx-2">
                        <v-radio-group v-model="contentType" row color="red">
                          <v-radio
                            :label="$t('labels.batnews')"
                            :value="1"
                            color="purple"
                          ></v-radio>
                          <v-radio
                            :label="$t('labels.bspPlus')"
                            :value="2"
                            color="purple"
                          ></v-radio>
                        </v-radio-group>
                      </v-layout>
                      <v-layout v-if="contentType == 2">
                        <v-col cols="6">
                          <v-select
                            :items="
                              divisionItems.concat({
                                Name: 'Bölge Seçiniz',
                                Id: -1,
                              })
                            "
                            item-text="Name"
                            item-value="Id"
                            v-model="selectedDivision"
                            dense
                          ></v-select>
                        </v-col>
                      </v-layout>
                    </v-col>
                  </v-layout>

                  <v-card-title
                    class="add-content-title"
                    style="font-size: 12px !important;"
                    v-if="pageRules.isPublicGroups"
                    >{{ $t('description.communityModule') }}</v-card-title
                  >
                </v-banner>
              </v-card-text>
              <!-- Division select end  -->

              <!-- Title  -->
              <v-card-text
                class="px-0 py-0 add-content-card-body"
                v-if="pageRules.title.isShow"
              >
                <v-banner rounded outlined class="mt-5 my-2">
                  <ValidationProvider
                    :name="pageRules.title.defaultLabel"
                    :rules="
                      pageRules.title.isRequired ? 'required|max:150' : null
                    "
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      :label="pageRules.title.defaultLabel"
                      v-model="title"
                      :disabled="pageRules.title.isDisabled"
                      :error-messages="
                        pageRules.title.isRequired ? errors[0] : ''
                      "
                    ></v-text-field>
                  </ValidationProvider>
                  <!-- <v-text-field
                    :label="pageRules.title.defaultLabel"
                    v-model="title"
                  ></v-text-field> -->
                  <v-card-title
                    class="add-content-title"
                    style="font-size: 12px !important;"
                    >{{ pageRules.title.description }}</v-card-title
                  >
                </v-banner>
              </v-card-text>
              <!-- Title end -->

              <!-- İçerik Bölümü -->
              <v-card-text class="px-0 py-2" v-if="pageRules.content.isShow">
                <ValidationProvider
                  :name="pageRules.content.defaultLabel"
                  :rules="pageRules.content.isRequired ? 'required' : null"
                  v-slot="{ errors }"
                >
                  <ckeditor
                    :name="pageRules.content.defaultLabel"
                    :editor="editor"
                    v-model="editorData"
                    :config="editorConfig"
                  ></ckeditor>
                  <div class="error--text">{{ errors[0] }}</div>
                </ValidationProvider>

                <v-card-title
                  class="add-content-title"
                  style="font-size: 12px !important;"
                  >{{ pageRules.content.description }}</v-card-title
                >
              </v-card-text>
              <v-card-text
                class="px-0 py-2 add-content-card-body"
                v-if="pageRules.link.isShow"
              >
                <v-banner rounded outlined class="mt-5 my-2">
                  <ValidationProvider
                    :name="pageRules.link.defaultLabel"
                    :rules="pageRules.link.isRequired ? 'required' : null"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      :label="pageRules.link.defaultLabel"
                      v-model="editorData"
                      :error-messages="errors[0]"
                    >
                    </v-text-field>
                  </ValidationProvider>

                  <v-card-title
                    class="add-content-title"
                    style="font-size: 12px !important;"
                    >{{ pageRules.content.description }}</v-card-title
                  >
                </v-banner>
              </v-card-text>
              <!-- İçerik end -->

              <!-- Custom Field start -->
              <v-card-text
                class="px-0 py-0 add-content-card-body"
                v-if="properties.length > 0"
              >
                <v-banner outlined rounded class="mt-5">
                  <v-card-title class="add-content-title">
                    {{ $t('labels.postCustomField') }}
                  </v-card-title>

                  <v-row class="mx-2">
                    <v-col
                      cols="4"
                      v-for="(element, index) in properties"
                      :key="index"
                    >
                      <!-- If data is string -->
                      <v-text-field
                        v-if="element.DataType == 'string'"
                        v-model="element.Value"
                        :label="element.Name"
                      >
                      </v-text-field>
                      <!-- If data is integer -->
                      <v-text-field
                        v-if="element.DataType == 'number'"
                        v-model="element.Value"
                        :label="element.Name"
                        @keypress="controlIntChar($event)"
                        hide-details
                      ></v-text-field>
                      <!-- If data is date-time -->
                      <v-menu
                        ref="datePickerRef"
                        v-model="menu"
                        v-if="element.DataType == 'date_time'"
                        :close-on-content-click="false"
                        :return-value.sync="date"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="element.Value"
                            :label="element.Name"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="date" locale="tr-TR">
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="menu = false">
                            {{ $t('buttons.cancel') }}
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="
                              $refs.datePickerRef[0].save(date);
                              element.Value = convertToLocal(date);
                            "
                          >
                            {{ $t('buttons.ok') }}
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-banner>
              </v-card-text>
              <!-- Custom field  end -->

              <!-- Users -->
              <v-card-text  v-if="pageRules.user && pageRules.user.isShow" class="px-0 py-0 add-content-card-body">
                <v-banner rounded outlined class="mt-5">
                  <v-card-title class="add-content-title">{{
                    pageRules.user.defaultLabel
                  }}</v-card-title>
                  <v-card-title
                    class="add-content-title"
                    style="font-size: 12px !important;"
                    >{{ pageRules.user.description }}</v-card-title
                  >
                  <v-combobox
                    class="mt-4"
                    clearable
                    solo
                    persistent-hint
                    return-object
                    ref="combobox"
                    v-on:keyup.enter="searchUser"
                    :search-input.sync="searchUserText"
                    v-model="selectedUser"
                    item-value="Id"
                    item-text="userFullValue"
                    :items="users"
                    no-data-text="Arama yapmak için lütfen için bir kullanıcı adı veya sicil numarası giriniz."
                  >
                    <template v-slot:append>
                      <v-btn color="purple" dark @click="searchUser">
                        <v-icon>mdi-magnify</v-icon>
                      </v-btn>
                    </template>
                  </v-combobox>
                </v-banner>
              </v-card-text>
              <!-- Users END -->

              <!-- Photo Upload -->
              <v-card-text
                class="px-0 py-0 add-content-card-body"
                v-if="pageRules.photo.isShow"
              >
                <v-banner rounded outlined class="mt-5">
                  <v-card-title class="add-content-title">{{
                    pageRules.photo.defaultLabel
                  }}</v-card-title>
                  <v-card-title
                    class="add-content-title"
                    style="font-size: 12px !important;"
                    >{{ pageRules.photo.description }}</v-card-title
                  >

                  <v-row>
                    <v-col>
                      <input
                        type="file"
                        accept="image/*"
                        ref="uploader"
                        class="d-none"
                        @change="onFileChange"
                      />

                      <v-btn
                        small
                        class="ma-4 mr-8"
                        elevation="0"
                        outlined
                        color="info"
                        :disabled="photoLength == 1"
                        @click="browsePhoto()"
                      >
                        {{ $t('buttons.uploadNew') }}
                      </v-btn>
                      <!-- <v-btn
                      small
                      class="ma-4 mr-4"
                      elevation="0"
                      outlined
                      :disabled="photoLength == 1"
                      color="info"
                    >
                      {{ $t("buttons.uploadfromLibrary") }}
                    </v-btn> -->
                    </v-col>
                    <v-col
                      align-self="end"
                      class="mr-5"
                      style="text-align: end"
                      v-if="selectedPhotoModel"
                    >
                      {{
                        photoLength == null ? ' ' : photoLength + ' /' + ' 1'
                      }}
                    </v-col>
                  </v-row>
                  <v-banner
                    rounded
                    outlined
                    class="mt-5"
                    v-if="selectedPhotoModel"
                  >
                    <v-hover>
                      <v-img
                        style="border-radius: 8px"
                        max-height="250px"
                        :aspect-ratio="4 / 3"
                        contain
                        :src="computedUrl(selectedPhotoModel)"
                        slot-scope="{ hover }"
                      >
                        <v-expand-transition>
                          <div
                            v-if="hover"
                            class="transition-fast-in-fast-out image-hover white--text"
                            style="height: 100%"
                          >
                            <v-layout
                              row
                              wrap
                              fill-height
                              align-center
                              justify-center
                              ma-0
                            >
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                  <v-btn
                                    @click="deletePhoto(selectedPhotoModel, 0)"
                                    class="ma-0"
                                    icon
                                    v-on="on"
                                    style="z-index: 100"
                                  >
                                    <v-icon color="white">mdi-close</v-icon>
                                  </v-btn>
                                </template>
                                <span>{{ $t('labels.deleteKey') }}</span>
                              </v-tooltip>

                              <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                  <v-btn
                                    class="ma-0"
                                    icon
                                    v-on="on"
                                    style="z-index: 100"
                                  >
                                    <v-icon color="white">mdi-folder</v-icon>
                                  </v-btn>
                                </template>
                                <span>{{ selectedPhotoModel.name }}</span>
                              </v-tooltip>
                            </v-layout>
                          </div>
                        </v-expand-transition>
                      </v-img>
                    </v-hover>
                  </v-banner>
                </v-banner>
              </v-card-text>
              <!-- Photo upload end -->

              <!-- File Upload -->
              <v-card-text
                class="px-0 py-0 add-content-card-body"
                v-if="pageRules.media.isShow"
              >
                <v-banner rounded outlined class="mt-5">
                  <v-card-title class="add-content-title">{{
                    pageRules.media.defaultLabel
                  }}</v-card-title>
                  <v-card-title
                    class="add-content-title"
                    style="font-size: 12px !important;"
                    >{{ pageRules.media.description }}</v-card-title
                  >
                  <v-row>
                    <v-col>
                      <input
                        v-if="isRequiredMedia == false"
                        type="file"
                        accept="application/pdf"
                        ref="fileInput"
                        style="display: none"
                        @change="selectFileHandle"
                      />
                      <input
                        v-else
                        type="file"
                        accept="/*"
                        ref="fileInput"
                        style="display: none"
                        @change="selectFileHandle"
                      />
                      <v-btn
                        small
                        class="ma-4 mr-8"
                        elevation="0"
                        outlined
                        color="info"
                        :disabled="
                          pageRules.media.fileLength
                            ? fileLength === pageRules.media.fileLength
                            : fileLength === 5
                        "
                        @click="
                          browseFile();
                          ('');
                        "
                      >
                        {{ $t('buttons.uploadNew') }}
                      </v-btn>
                      <!-- <v-btn
                      small
                      class="ma-4 mr-4"
                      elevation="0"
                      outlined
                      :disabled="fileLength == 5"
                      color="info"
                    >
                      {{ $t("buttons.uploadfromLibrary") }}
                    </v-btn> -->
                    </v-col>
                    <v-col
                      align-self="end"
                      class="mr-5"
                      style="text-align: end"
                      v-if="selectedFiles.length > 0"
                    >
                      {{
                        fileLength == null
                          ? ' '
                          : fileLength +
                            ' /' +
                            (pageRules.media.fileLength
                              ? ' ' + pageRules.media.fileLength
                              : ' 5')
                      }}
                    </v-col>
                  </v-row>

                  <v-banner
                    rounded
                    outlined
                    class="mt-5"
                    v-if="selectedFiles.length > 0"
                  >
                    <v-col>
                      <v-container class="mt-5" mx-0 px-0 fluid grid-list-xl>
                        <v-layout row wrap>
                          <v-flex
                            xs2
                            v-for="(item, index) in selectedFiles"
                            :key="index"
                          >
                            <v-hover>
                              <v-img
                                style="border-radius: 8px"
                                width="100%"
                                :aspect-ratio="1 / 1"
                                slot-scope="{ hover }"
                                contain
                                :src="
                                  item.type == 'image/jpeg' ||
                                  item.type == 'image/png'
                                    ? selectedFilesUrl[index]
                                    : item.type == 'video/mp4'
                                    ? require('@/../public/img/video-thumb.png')
                                    : require('@/../public/img/article-thumb.png')
                                "
                              >
                                <v-expand-transition>
                                  <div
                                    v-if="hover"
                                    class="transition-fast-in-fast-out image-hover white--text"
                                    style="height: 100%"
                                  >
                                    <v-layout
                                      row
                                      wrap
                                      fill-height
                                      align-center
                                      justify-center
                                      ma-0
                                    >
                                      <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                          <v-btn
                                            @click="deleteFile(item.id, index)"
                                            class="ma-0"
                                            icon
                                            v-on="on"
                                            style="z-index: 100"
                                          >
                                            <v-icon color="white"
                                              >mdi-close</v-icon
                                            >
                                          </v-btn>
                                        </template>
                                        <span>{{
                                          $t('labels.deleteKey')
                                        }}</span>
                                      </v-tooltip>

                                      <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                          <v-btn
                                            class="ma-0"
                                            icon
                                            v-on="on"
                                            style="z-index: 100"
                                          >
                                            <v-icon color="white"
                                              >mdi-folder</v-icon
                                            >
                                          </v-btn>
                                        </template>
                                        <span>{{ item.name }}</span>
                                      </v-tooltip>
                                    </v-layout>
                                  </div>
                                </v-expand-transition>
                              </v-img>
                            </v-hover>
                          </v-flex>
                        </v-layout>
                      </v-container>
                    </v-col>
                  </v-banner>
                </v-banner>
              </v-card-text>
              <!-- File upload end -->

              <!-- Tag start -->
              <v-card-text
                class="px-0 py-0 add-content-card-body"
                v-if="pageRules.tag.isShow"
              >
                <v-banner rounded outlined class="mt-5">
                  <v-card-title class="add-content-title">
                    {{ pageRules.tag.defaultLabel }}
                  </v-card-title>
                  <v-card-title
                    class="add-content-title"
                    style="font-size: 12px !important;"
                    >{{ pageRules.tag.description }}</v-card-title
                  >
                  <v-col class="pt-0">
                    <v-combobox
                      v-model="tagName"
                      :items="tags"
                      :search-input.sync="search"
                      hide-selected
                      :hint="$t('labels.max15')"
                      :label="$t('labels.tags')"
                      multiple
                      :disabled="tagIds.length == 15"
                      persistent-hint
                      deletable-chips
                      small-chips
                      item-text="TagName"
                      item-value="Id"
                      @input="selectTag(tagName)"
                      @keyup.enter="addTag(tagName)"
                      counter="15"
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              "<strong>{{ search }}</strong
                              >" için bir eşleşme bulunamadı. Yeni bir tane
                              oluşturmak için <kbd>enter</kbd> tuşuna basın.
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-combobox>
                    <v-alert
                      v-if="tagIds.length == 15"
                      border="left"
                      close-text="Close Alert"
                      type="info"
                      color="#7443a0"
                      dismissible
                      outlined
                      prominent
                      class="font-weight-black mt-3"
                      style="font-size: 1.2em"
                    >
                      {{ $t('description.tagCountReached') }}
                    </v-alert>
                  </v-col>
                </v-banner>
              </v-card-text>
              <!-- Tag end -->

              <!-- Date and time picker start -->
              <v-card-text
                class="px-0 py-0 add-content-card-body"
                v-if="pageRules.datetime.isShow"
              >
                <v-banner rounded outlined class="mt-5">
                  <v-card-title class="add-content-title">
                    {{ pageRules.datetime.defaultLabel }}
                  </v-card-title>
                  <v-card-title
                    class="add-content-title"
                    style="font-size: 12px !important;"
                    >{{ pageRules.datetime.description }}</v-card-title
                  >
                  <v-row align="center" justify="space-between" class="pr-5">
                    <v-col cols="5">
                      <v-text-field
                        v-model="dateValueDisplay"
                        :label="$t('labels.selectDate')"
                        readonly
                      >
                        <template v-slot:prepend>
                          <date-picker v-model="dateValue" />
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col cols="5">
                      <v-text-field
                        v-model="timeValueDisplay"
                        :label="$t('labels.selectTime')"
                        readonly
                      >
                        <template v-slot:prepend>
                          <time-picker v-model="timeValue" />
                        </template>
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-banner>
              </v-card-text>
              <!-- Date and time picker end -->
            </v-flex>

            <v-flex md4>
              <!-- Test, pin or story start -->
              <v-card-text
                class="px-0 py-0 add-content-card-body"
                v-if="
                  pageRules.showTest ||
                    pageRules.showPin ||
                    pageRules.showNotification
                "
              >
                <v-banner rounded outlined class="mt-5">
                  <v-card-title class="add-content-title">
                    {{ $t('labels.postType') }}
                  </v-card-title>
                  <v-row class="mx-2">
                    <v-col cols="12" sm="6" md="6">
                      <v-checkbox
                        v-if="pageRules.showTest"
                        v-model="checkboxTest"
                        :label="'Test'"
                        hide-details
                      >
                      </v-checkbox>
                      <v-checkbox
                        v-if="pageRules.showPin"
                        v-model="checkboxPin"
                        :label="'Üste Sabitle'"
                        hide-details
                      >
                      </v-checkbox>
                      <v-checkbox
                        v-if="pageRules.showNotification"
                        v-model="checkboxNotification"
                        :label="'Bildirim Gönder'"
                        hide-details
                      ></v-checkbox>
                      <v-checkbox
                        v-if="pageRules.showDocumentType"
                        v-model="checkboxDocument"
                        :label="'Gizli Paylaş'"
                        hide-details
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </v-banner>
              </v-card-text>
              <!-- Test,pin or story end -->

              <!-- Category start  -->
              <v-card-text
                class="px-0 py-0 add-content-card-body"
                v-if="categoryData != null && categoryData.length > 0"
              >
                <v-banner rounded outlined class="mt-5">
                  <v-card-title class="add-content-title">
                    {{
                      $t('labels.postCategory') +
                        $t('labels.postCategoryRequired')
                    }}
                  </v-card-title>
                  <v-treeview
                    v-model="selectedCategoriesModal"
                    :items="categoryData"
                    selection-type="leaf"
                    item-children="Children"
                    item-value="Id"
                    item-text="CategoryNameTR"
                    item-key="Id"
                    selectable
                    open-all
                    style="overflow: auto; height: 300px;"
                  ></v-treeview>
                </v-banner>
              </v-card-text>
              <!-- Category end -->
            </v-flex>
          </v-layout>
        </form>
      </ValidationObserver>

      <!-- Content End -->
    </div>
  </v-container>
</template>

<script>
import ModuleService from '../services/ModuleService';
import GroupService from '../services/GroupService';
import FileService from '../services/FileService';
import CategoryService from '../services/CategoryService';
import TagService from '../services/TagService';
import PostService from '../services/PostService';

import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials';
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold';
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic';
import Link from '@ckeditor/ckeditor5-link/src/link';
import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import List from '@ckeditor/ckeditor5-list/src/list.js';
import Heading from '@ckeditor/ckeditor5-heading/src/heading.js';
import Indent from '@ckeditor/ckeditor5-indent/src/indent.js';
import IndentBlock from '@ckeditor/ckeditor5-indent/src/indentblock';
import Font from '@ckeditor/ckeditor5-font/src/font';

import _ from 'lodash';

import UserService from '../services/UserService';

export default {
  data: () => ({
    moduleDetail: {},
    model: ['Vuetify'],
    search: null,

    //To publish model
    title: '',
    editorData: '',
    fileIds: [],
    tempFiles: [],
    fileControlArr: [],
    groupModel: {
      Groups: [],
    },
    tagIds: [],
    checkboxTest: false,
    checkboxPin: false,
    checkboxStory: false,
    checkboxNotification: false,
    checkboxDocument: false,
    key: '',
    properties: [],
    //End Publish model

    editor: ClassicEditor,
    editorConfig: {
      plugins: [
        Essentials,
        Bold,
        Italic,
        Link,
        Paragraph,
        List,
        Heading,
        Indent,
        IndentBlock,
        Font,
      ],

      toolbar: {
        items: [
          'heading',
          '|',
          'bold',
          'italic',
          'link',
          'bulletedList',
          'numberedList',
          '|',
          'outdent',
          'indent',
          '|',
          'undo',
          'redo',
          '|',
          'fontSize',
          'fontColor',
          'fontBackgroundColor',
        ],
      },
      fontSize: {
        options: [9, 11, 13, 15, 17, 19, 21, 23, 25, 27, 29],
      },
    },

    //File modals
    selectedFiles: [],
    selectedFilesUrl: [],
    fileLength: null,

    //Tag modals
    mySetChangeTracker: 0,
    myTagSetChangeTracker: 0,
    selectedCategories: new Set(),
    tagName: '',
    tags: [],

    //For group modals
    selecItems: [],
    groupData: [],
    groupLengthAlert: false,
    groupArr: [[]],
    groupsSelectArray: [],
    corpGroup: [],
    locGroup: [],
    empGroup: [],
    allSelect: [],

    //Division select modals
    selectedDivision: null,
    divisionItems: [],

    //mandatory photo upload models
    selectedPhotoModel: null,
    photoLength: null,
    selectedPhoto: [],
    selectedPhotoUrl: [],

    //Category modals
    selectedCategoriesModal: [],
    cData: null,
    categoryData: [],

    //Scroll variable
    fab: false,

    //Current date
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),

    //Datepicker menu
    menu: false,
    //Necessary variables for page differentiation
    pageKey: '',
    pageHeader: '',
    flag: false,
    infoDialog: false,
    moduleDescription: '',
    pageRules: null,
    requiredCategoryLength: -1,
    fileErrorFlag: false,

    //Datepicker models
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),

    dateValue: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    timeValue: '',

    contentType: 1, //1: Battan haberler 2:BSP+

    selectedUser: null,
    users: [],
    searchUserText: '',
  }),
  watch: {
    model(val) {
      if (val.length > 5) {
        this.$nextTick(() => this.model.pop());
      }
    },
  },
  beforeMount() {
    this.pageRules = getPageRulesById(this.$route.params.id);
    //#region Default Value Adjustments
    this.title = this.pageRules.title.defaultValue;
    this.editorData = this.pageRules.content.defaultValue
      ? this.pageRules.content.defaultValue
      : this.pageRules.link.defaultValue;
    //#endregion
  },
  mounted() {
    this.setup();
  },
  components: {
    DatePicker: () => import('../components/General/DatePicker.vue'),
    TimePicker: () => import('../components/General/TimePicker.vue'),
  },
  methods: {
    //Sets up the page on page mount
    setup() {
      this.getCategoriesInTreeView();
      this.getModuleDetail();
      if (!this.pageRules.isPublicGroups) {
        // this.fillGroups();
        this.getDivisions();
      }
      this.getTags();
      this.key = this.$route.params.id;
      this.selectedDivision = -1;
    },

    searchUser() {
      if (this.searchUserText) {
        this.$store.state.loadingStatus = true;
        UserService.getUsers(this.searchUserText)
          .then((response) => {
            this.users = response.data.Data;
            this.users.map(
              (item) =>
                (item.userFullValue =
                  item.FirstName +
                  ' ' +
                  item.LastName +
                  '(' +
                  item.Username +
                  '-' +
                  item.Department +
                  ')')
            );
          })
          .catch(window.showError)
          .finally(() => {
            this.$store.state.loadingStatus = false;
          });
      }
    },

    getDivisions() {
      GroupService.getDivisions()
        .then((res) => {
          this.divisionItems = res.data;
          this.divisionItems.unshift({ Id: -1, Name: 'Bölge Seçiniz' });
        })
        .catch((err) => {
          window.showError(err);
        })
        .then(() => {});
    },
    getCategoriesInTreeView() {
      if (this.pageRules.categoryIds.length > 0) {
        this.$store.state.loadingStatus = true;
        this.pageRules.categoryIds.forEach((element, index) => {
          CategoryService.getCategoriesInTreeView(element.id)
            .then((res) => {
              this.categoryData = this.categoryData.concat(res.data);

              // this.categoryData = { ...this.categoryData, ...res.data };
            })
            .catch((err) => {
              window.showError(err);
            })
            .then(() => {
              this.categoryData.forEach((element, index) => {
                this.categoryData[
                  index
                ].IsRequired = this.pageRules.categoryIds[index].isRequired;
              });
              let reqArray = this.categoryData.filter((x) => x.IsRequired)
                .length;
              this.requiredCategoryLength = reqArray;
              this.$store.state.loadingStatus = false;
            });
        });
      }
    },
    //Tooltip popup
    informationPopUp() {
      this.infoDialog = !this.infoDialog;
    },
    //Pop sticky button on scroll 40px
    onScroll(e) {
      if (typeof window === 'undefined') return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 40;
    },
    //Sticky button publish operation
    async stickyPublishOperation() {
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        this.publish();
      }
    },
    //Loads groups when page loaded
    fillGroups() {
      GroupService.getGroups('sirket')
        .then((res) => {
          res.data.forEach((element) => {
            this.corpGroup.push(element.Id);
            let obj = {
              Id: element.Id,
              Name: element.Name,
            };
            this.allSelect.push(obj);
          });
        })
        .catch((err) => {
          window.showError(err);
        })
        .then(() => {});

      GroupService.getGroups('calisan_tipi')
        .then((res) => {
          res.data.forEach((element) => {
            this.empGroup.push(element.Id);
            let obj = {
              Id: element.Id,
              Name: element.Name,
            };
            this.allSelect.push(obj);
          });
        })
        .catch((err) => {
          window.showError(err);
        })
        .then(() => {});
      let all = {
        Name: 'Tümü',
        Id: '',
      };
      this.allSelect.push(all);
    },
    //Loads page detail
    getModuleDetail() {
      ModuleService.getModuleDetails(this.$route.params.id)
        .then((res) => {
          this.moduleDetail = res.data;
          this.pageKey = res.data.Key;
          this.moduleDescription = res.data.LongDescription;

          this.pageHeader = res.data.Name;
          this.getCustomFields(res.data.Key);
          this.key = res.data.Key;
        })
        .catch((err) => {
          window.showError(err);
        });
    },
    //File start //////////////////////////////////////////
    //Local file choose open
    browsePhoto() {
      this.$refs.uploader.click();
    },
    browseFile() {
      this.$refs.fileInput.click();
    },
    onFileChange(e) {
      if (e.target.files[0] === undefined) {
      } else {
        if (e.target.files[0].size < 1000000) {
          this.selectedPhotoModel = e.target.files[0];
          this.uploadPhoto(this.selectedPhotoModel);
        } else {
          window.showInfo(
            this.$t('description.fileSizeError'),
            'standart',
            'error'
          );
        }
      }
      e.target.value = '';
    },
    // Local file choose
    selectFileHandle(e) {
      if (e.target.files[0] === undefined) {
      } else {
        if (e.target.files[0].size < 20000000) {
          this.uploadFile(e.target.files[0]);
        } else {
          window.showInfo(
            this.$t('description.fileSizeError'),
            'standart',
            'error'
          );
        }
      }
      e.target.value = '';
    },
    //Return file url to send service
    computedUrl(file) {
      return URL.createObjectURL(file);
    },
    //Photo upload to service
    uploadPhoto(media) {
      if (media === undefined) {
        console.log('Undefined media');
      } else {
        if (media.size > 1000000) {
          window.showInfo(
            this.$t('description.fileSizeError'),
            'standart',
            'error'
          );
        } else {
          this.selectedPhoto.push(media);
          this.tempFiles.unshift(media);
          this.fileControlArr.unshift('photo');
          this.selectedPhotoUrl.push(this.computedUrl(media));
          this.photoLength = 1;
        }
      }
    },
    //File upload to service
    uploadFile(media) {
      if (media === undefined) {
        console.log('Undefined media');
      } else if (media.type == '') {
        window.showInfo(
          this.$t('description.unsupportedMediaType'),
          'standart',
          'error'
        );
      } else {
        if (media.size > 20000000) {
          window.showInfo(
            this.$t('description.fileSizeError'),
            'standart',
            'error'
          );
        } else {
          this.selectedFiles.push(media);
          this.tempFiles.push(media);
          this.fileControlArr.push('document');
          this.selectedFilesUrl.push(this.computedUrl(media));
          this.fileLength = this.selectedFiles.length;
        }
      }
    },
    //File delete
    deleteFile(fileId, index) {
      this.selectedFiles.splice(index, 1);
      this.tempFiles.splice(index, 1);
      this.fileControlArr.splice(index, 1);
      this.fileIds.splice(index, 1);
      this.fileLength--;
    },
    //Photo delete
    deletePhoto(photo, index) {
      this.selectedPhoto.splice(index, 1);
      this.selectedPhotoUrl.splice(index, 1);
      this.tempFiles.splice(index, 1);

      this.fileControlArr.splice(index, 1);
      this.selectedPhoto = [];
      this.selectedPhotoUrl = [];
      this.selectedPhotoModel = null;

      this.photoLength--;
      this.fileIds.splice(index, 1);
    },
    //File end //////////////////////////////////////////

    //Add tag to service
    addTag(tag) {
      if (this.tagIds.length <= 15) {
        tag.forEach((x, index) => {
          if (x.Id == undefined) {
            let body = {
              TagName: x,
            };
            if (index == tag.length - 1) {
              TagService.createTags(body)
                .then((res) => {
                  //this.tags=  res.data
                  if (res.data.Id) {
                    // this.tags[index] = res.data;
                    this.tagName.push(res.data);
                    this.tagName = this.tagName.filter(item => typeof item !== "string")
                    this.tagIds.push(res.data.Id);
                  } else {
                    window.showInfo(
                      this.$t('description.addTagError'),
                      'standart',
                      'error'
                    );
                    this.tags.pop();
                  }
                })
                .catch((err) => {
                  window.showError(err);
                })
                .then(() => {});
            }
          }
        });
        this.tagIds = this.tagIds.filter((x) => x != undefined);
        this.tagIds = [...new Set(this.tagIds)];
      }
    },
    //Delete tag
    deleteTag(tag) {
      if (this.tagIds.length == 15) {
      }
      this.tags.delete(tag);
      this.myTagSetChangeTracker -= 1;
      this.tagIds.length--;
    },
    //Choose tag to add content
    selectTag(tag) {
      if (tag && this.tagIds.length <= 15 && tag.length <= 15) {
        // this.tagIds = [];
        tag.forEach((x) => this.tagIds.push(x.Id));
      }
      this.tagIds = [...new Set(this.tagIds)];
      this.tagIds = this.tagIds.filter((x) => x != undefined);
    },
    //Change corparate group
    changeGroupInput(element, colIndex, dataIndex) {
      if (element.type == 'sirket') {
        this.groupArr[dataIndex][colIndex + 1] = '';
      }
    },
    // Fill select box items
    clickGroupFilterModal(element, colIndex, dataIndex) {
      if (
        element.type == 'lokasyon' &&
        this.groupArr[dataIndex][colIndex - 1] == undefined
      ) {
        this.groupsSelectArray[dataIndex][colIndex].groups = [...[]];
      }
      if (
        (element.type == 'lokasyon' &&
          this.groupArr[dataIndex][colIndex - 1] != undefined) ||
        element.type == 'calisan_tipi' ||
        element.type == 'sirket'
      ) {
        GroupService.getGroups(
          element.type,
          element.type == 'lokasyon'
            ? this.groupArr[dataIndex][colIndex - 1]
            : ''
        )
          .then((res) => {
            let arr = [];
            res.data.forEach((item) => {
              let obj = {
                id: item.Id,
                name: item.Name,
              };
              arr.push(obj);
            });
            if (
              element.type != 'sirket' ||
              this.$store.getters.getPermissionIds
            )
              arr.unshift({ name: 'Tümü', id: 'All' });
            this.groupsSelectArray[dataIndex][colIndex].groups = arr;
            let listObj = {
              Type: element.type,
              Data: arr,
            };
            this.selecItems.push(listObj);
          })
          .catch((err) => {
            window.showError(err);
          })
          .then(() => {});
      }
    },
    //Group types
    getGroupTypesModal() {
      GroupService.getGroupTypes()
        .then((res) => {
          res.data.forEach((element) => {
            let obj = {
              type: element.Code,
              groups: [],
              name: element.Name,
            };

            this.groupData.push(obj);
          });
          this.groupsSelectArray.push(this.groupData);
        })
        .catch((err) => {
          window.showError(err);
        });
    },
    //Category items
    getCategories() {
      let params = {
        ParentId: 'all',
        PageSize: 1000,
      };
      CategoryService.getCategories2(params)
        .then((res) => {
          this.cData = setCategoryTreeviewData(res.data);
          this.cData = setFilterData(this.cData, this.pageKey, res.data);
        })
        .catch((err) => {
          window.showError(err);
        })
        .then(() => {});
    },
    //Tag items
    getTags() {
      TagService.getTags()
        .then((res) => {
          this.tags = res.data.Result;
        })
        .catch((err) => {
          window.showError(err);
        })
        .then(() => {});
    },
    //To take GroupId
    createGroupIds() {
      for (let i = 0; i < this.groupArr.length; i++) {
        const element = this.groupArr[i];
        let array = this.groupArr[i].filter((x) => x == 'All').length;
        if (array == 3) this.flag = true;
        this.groupModel.Groups[i] = new Array();
        for (let y = 0; y < element.length; y++) {
          if (element[y] === 'All') {
            this.groupsSelectArray[i][y].groups.forEach((element) => {
              this.groupModel.Groups[i].push(element.id);
            });
          }

          this.groupModel.Groups[i].push(element[y]);
        }

        this.groupModel.Groups[i] = this.groupModel.Groups[i].filter(
          (x) => x != 'All'
        );
      }
    },
    //Dynamic Custom Field
    getCustomFields(key) {
      let params = {
        key: key,
      };
      ModuleService.getCustomFields(params)
        .then((res) => {
          res.data.forEach((x) => {
            this.properties.push({
              Key: x.Name,
              Value: '',
              DataType: x.DataType,
              Name: x.DisplayName,
            });
          });
        })
        .catch((err) => {
          window.showError(err);
        })
        .then(() => {});
    },
    //To add new row for new Group
    createNewGroup() {
      if (this.groupsSelectArray.length < 4) {
        this.groupLengthAlert = false;
        let arr = [];
        this.groupData.forEach((element) => {
          let obj = {
            type: element.type,
            groups: [],
            name: element.name,
          };

          arr.push(obj);
        });

        this.groupsSelectArray = [...this.groupsSelectArray, arr];
        this.groupArr.push([]);
      } else {
        this.groupLengthAlert = true;
      }
    },
    deleteGroupRow(rowIndex) {
      if (!(this.groupsSelectArray.length == 1)) {
        if (this.groupsSelectArray.length == 4) {
          this.groupLengthAlert = false;
        }

        this.groupsSelectArray.splice(rowIndex, 1);
        this.groupArr.splice(rowIndex, 1);
      } else {
        window.showInfo(
          this.$t('description.groupRowDelete'),
          'standart',
          'info'
        );
      }
    },
    sendForm() {
      this.publish();
    },
    async uploadFiles(index) {
      if (index < this.tempFiles.length) {
        this.uploadFileCall(this.tempFiles[index], index);
      } else {
        this.publishCall();
      }
    },
    async uploadFileCall(media, index) {
      if (media === undefined) {
        console.log('Undefined media');
      }
      let formData = new FormData();
      if (
        media.type == 'image/jpeg' ||
        media.type == 'image/png' ||
        media.type == 'video/mp4'
      ) {
        formData.append('Files', media);
        formData.append('Type', 'media');
      } else {
        formData.append('Files', media);
        formData.append('Type', 'document');
      }
      await Promise.resolve(
        FileService.uploadFile(formData)
          .then((res) => {
            this.fileIds.push(res.data[0].Id);
          })
          .catch((err) => {
            this.fileErrorFlag = true;
            window.showError(err);
          })
          .then(() => {
            this.uploadFiles(index + 1);
          })
      );
    },
    //Add Content
    async publish() {
      this.$store.state.loadingStatus = true;
      this.createGroupIds();
      if (!this.isRequiredMedia && this.fileLength == null) {
        window.showInfo(
          this.$t('description.contentFileUpload'),
          'standart',
          'info'
        );
        this.$store.state.loadingStatus = false;

        return;
      } else {
        if (
          !this.isRequiredMedia &&
          this.selectedFiles[0].type != 'application/pdf'
        ) {
          window.showInfo(
            this.$t('description.contentFileUploadOnlyPdf'),
            'standart',
            'info'
          );
          this.$store.state.loadingStatus = false;

          return;
        }
      }
      if (
        this.photoLength != 1 &&
        this.isRequiredPhoto &&
        this.isRequiredForAdvancement
      ) {
        window.showInfo(
          this.$t('description.contentPhotoUpload'),
          'standart',
          'info'
        );
        this.$store.state.loadingStatus = false;
      } else {
        if (this.tempFiles.length > 0) {
          let control = await this.uploadControl();
          if (control) {
            await this.uploadFiles(0);
          } else {
            window.showInfo(
              this.$t('description.errorOnCreate'),
              'standart',
              'info'
            );
            this.$store.state.loadingStatus = false;
          }
        } else if (!this.isRequiredPhoto || !this.isRequiredForAdvancement) {
          this.publishCall();
        } else {
          window.showInfo(
            this.$t('description.contentPhotoUpload'),
            'standart',
            'info'
          );
          this.$store.state.loadingStatus = false;
        }
      }
    },

    async uploadControl() {
      if (
        this.pageRules.photo.isRequired &&
        !this.fileControlArr.includes('photo')
      ) {
        return false;
      }
      if (
        this.pageRules.media.isRequired &&
        !this.fileControlArr.includes('document')
      ) {
        return false;
      }
      if (this.pageRules.tag.isRequired && this.tagIds.length < 1) {
        return false;
      }

      //Custom Field control
      if (
        this.pageKey == 'aramiza_katilanlar' ||
        this.pageKey == 'yeni_doganlar'
      ) {
        let tempArr = this.properties.filter((x) => x.Key == 'sicilNumber');
        if (tempArr[0].Value == '' || tempArr[0].Value == '00000000') {
          window.showInfo(
            this.$t('description.propertyEmptySicil'),
            'standart',
            'info'
          );
          return false;
        }
      }
      //Custom Field control this.isRequiredLink
      if (this.pageRules.link.isRequired) {
        let tempArr = this.properties.filter(
          (x) =>
            x.Key == 'publish_date' ||
            x.Key == 'mail_to' ||
            x.Key == 'IK_sicil_number'
        );
        if (
          tempArr[0].Value == '' ||
          tempArr[1].Value == '' ||
          tempArr[2].Value == ''
        ) {
          window.showInfo(
            this.$t('description.propertyEmptyPublishDate'),
            'standart',
            'info'
          );
          return false;
        }
      }
      if (
        this.categoryData.length > 0 &&
        this.selectedCategoriesModal.length < 1
      ) {
        window.showInfo(
          this.$t('description.emptyCategorySelect'),
          'standart',
          'info'
        );
        return false;
      }
      if (this.selectedCategoriesModal.length > 0) {
        let index = 0;
        this.selectedCategoriesModal.forEach((i) => {
          this.categoryData.forEach((element) => {
            var result = this.searchTree(element, i, false);
            if (result) {
              index++;
            }
          });
        });
        if (index < this.requiredCategoryLength) {
          window.showInfo(
            this.$t('description.emptyCategorySelect'),
            'standart',
            'info'
          );
          return false;
        }
      }

      return true;
    },

    searchTree(element, id) {
      if (element.Id == id) {
        return element;
      } else if (element.Children != null) {
        var result = null;
        element.Children.forEach((child) => {
          if (result == null) {
            result = this.searchTree(child, id);
          }
        });
        return result;
      }
      return null;
    },

    async publishCall() {
      this.properties.forEach((x) => {
        if (x.DataType == 'string' || x.DataType == 'number') {
          x.Value = x.Value.trim();
        }
      });
      // Type: this.flag ? "individual" : "corporate", this.pageRules.isPublicGroups ? "individual" : "corporate",
      let divArr = [this.selectedDivision];
      let sendedAt = this.dateValue + ' ' + this.timeValue;

      
      this.editorData = this.editorData?.replaceAll('</p>', '</p>\n')
        .replaceAll('</h1>', '</h1>\n')
        .replaceAll('</h2>', '</h2>\n')
        .replaceAll('</h3>', '</h3>\n')
        .replaceAll('</h4>', '</h4>\n');

      let body = {
        Title: this.title,
        Content: this.editorData,
        Privacy: 'public',
        FileIds: this.fileIds,
        CategoryIds: this.selectedCategoriesModal,
        // GroupIds: this.groupModel.Groups,
        GroupIds:
          this.contentType == 1
            ? []
            : this.selectedDivision != -1
            ? divArr
            : [],
        TagIds: this.tagName?.length > 0 ? this.tagName?.flatMap((item) => item.Id) : [],
        IsPinned: this.checkboxPin,
        IsTest: this.checkboxTest,
        //Type: this.flag ? "individual" : "corporate",
        //Type: this.pageRules.isPublicGroups ? "corporate" : "individual", //bölge seçebiliyorsa individual seçemiyorsa corporate
        Type: this.contentType == 1 ? 'corporate' : 'individual', //bölge seçebiliyorsa individual seçemiyorsa corporate:battan haberler
        SendNotification: this.checkboxNotification,
        IsPrivate: this.checkboxDocument,
        SendedAt: sendedAt,
        Key: this.key,
        Status: 20,
        Properties: this.properties,
      };

      if (this.selectedUser) {
        body.PostCreatorUserId = this.selectedUser.Id;
      }

      let control = await this.uploadControl();

      if (control && !this.fileErrorFlag) {
        //Adjusting sicil number input
        let props = this.properties.find(
          (x) => x.Key == 'sicilNumber' || x.Key == 'IK_sicil_number'
        );

        // if (props) {
        //   //props.Value = validateRegistryNumber(props.Value);
        //   let tempVal = validateRegistryNumber(props.Value);

        //   props.Value = tempVal;
        // }

        if (this.pageRules.isPublicGroups) {
          body.IsAllGroups = true;
        }

        PostService.createPost(body)
          .then((res) => {
            this.$store.state.loadingStatus = false;
            this.$router.push({ name: 'moduls' });
          })
          .catch((err) => {
            this.$store.state.loadingStatus = false;
            window.showError(err);
          })
          .then(() => {});
      } else {
        if (this.fileErrorFlag) {
          window.showInfo(
            this.$t('description.errorOnCreateContent'),
            'standart',
            'info'
          );
        } else {
          window.showInfo(
            this.$t('description.errorOnCreate'),
            'standart',
            'info'
          );
        }
        this.fileErrorFlag = false;
        this.$store.state.loadingStatus = false;
      }
    },
    //Input control for text fields
    //For only letters
    controlKeyChar(e) {
      window.controlKeyChar(e);
    },
    //For only numbers
    controlIntChar(e) {
      window.controlIntChar(e);
    },
  },
  computed: {
    dateValueDisplay() {
      return convertToLocal(this.dateValue);
    },
    timeValueDisplay() {
      return this.timeValue;
    },
    isRequiredTitle() {
      switch (this.key) {
        case 'yeni_doganlar':
          return false;
        case 'vefat_edenler':
          return false;

        default:
          return true;
      }
    },
    isRequiredTag() {
      switch (this.key) {
        case 'aramiza_katilanlar':
          return false;

        default:
          return true;
      }
    },
    isRequiredForAdvancement() {
      switch (this.key) {
        case 'atamalar':
          return false;
        default:
          return true;
      }
    },

    isRequiredMedia() {
      switch (this.key) {
        case 'el_kitap_pdf':
          return false;
        case 'dergilik':
          return false;

        default:
          return true;
      }
    },

    //Determine whether the photo and file sections is necessary or not
    isRequiredPhoto() {
      switch (this.key) {
        case 'phones':
          return false;
        case 'ik_el_kitabi':
          return false;
        case 'personnel_shuttle':
          return false;
        case 'job_notices':
          return false;
        case 'vefat_edenler':
          return false;
        case 'yeni_doganlar':
          return false;
        case 'akademi':
          return false;
        case 'kurumsal_haberler':
          return false;
        case 'aramiza_katilanlar':
          return false;
        case 'ilanlar':
          return false;
        default:
          return true;
      }
    },
    //Determine whether is the content field or link filed && tag field
    isRequiredLink() {
      switch (this.key) {
        case 'job_notices':
          return true;
        default:
          return false;
      }
    },
    isRequiredStory() {
      switch (this.key) {
        case 'job_notices':
          return true;
        case 'yeni_doganlar':
          return true;
        case 'indirimler':
          return true;
        case 'aramiza_katilanlar':
          return true;
        default:
          return false;
      }
    },
    isRequiredContent() {
      switch (this.key) {
        case 'phones':
          return false;
        default:
          return true;
      }
    },
    computedTags() {
      return this.myTagSetChangeTracker && Array.from(this.tags);
    },
    getPermissionCheckStatus() {
      return store.getters.getPermissionIds;
    },
  },
};
</script>

<style>
.error--text {
  font-size: 14px;
}

.add-content-card-body {
  background-color: white;
}

.add-content-title {
  color: #7443a0 !important;
  font-size: 16px !important;
}
</style>
