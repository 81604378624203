var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{ref:"prohibitedWordPopupModal",attrs:{"persistent":"","max-width":"500"},model:{value:(_vm.prohibitedWordDetailStatus),callback:function ($$v) {_vm.prohibitedWordDetailStatus=$$v},expression:"prohibitedWordDetailStatus"}},[(!_vm.isDetails)?_c('v-card',[_c('v-card-title',{staticClass:"headline indigo lighten-2"},[_c('div',{staticClass:"text-center white--text",staticStyle:{"width":"100%"}},[_vm._v(" "+_vm._s(this.modalHeader)+" ")])]),_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.sendForm)}}},[_c('v-card-text',{staticClass:"pt-10 px-10"},[_c('ValidationProvider',{attrs:{"name":"Kelime İçeriği","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"value":_vm.prohibitedWordModel.Word,"label":_vm.$t('labels.prohibitedWord'),"error-messages":errors[0]},on:{"keypress":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();},function($event){return _vm.isLetter($event)}],"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"])){ return null; }$event.preventDefault();}},model:{value:(_vm.prohibitedWordModel.Word),callback:function ($$v) {_vm.$set(_vm.prohibitedWordModel, "Word", $$v)},expression:"prohibitedWordModel.Word"}})]}}],null,true)}),_c('v-card-text',{staticClass:"py-3"})],1),_c('v-card-actions',{staticStyle:{"background-color":"white","position":"sticky","bottom":"0px","border-top":"solid grey 2px"}},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","type":"submit","loading":_vm.loadings.send}},[_vm._v(" "+_vm._s(_vm.$t("buttons.save"))+" ")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.prohibitedWordDetailStatus = false;
                _vm.clearModal();}}},[_vm._v(" "+_vm._s(_vm.$t("buttons.close"))+" ")])],1)],1)]}}],null,false,3208011313)})],1):_vm._e(),(_vm.isDetails)?_c('v-card',[_c('v-card-title',{staticClass:"headline indigo lighten-2"},[_c('div',{staticClass:"text-center white--text",staticStyle:{"width":"100%"}},[_vm._v(" "+_vm._s(this.modalHeader)+" ")])]),_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.sendForm)}}},[_c('v-card-text',{staticClass:"pt-10 px-10"},[_c('ValidationProvider',{attrs:{"name":"Kelime İçeriği","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{attrs:{"value":_vm.inspectedModel.Word,"label":_vm.$t('labels.prohibitedWord'),"error-messages":errors[0]},on:{"keypress":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();},function($event){return _vm.isLetter($event)}],"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"])){ return null; }$event.preventDefault();}},model:{value:(_vm.inspectedModel.Word),callback:function ($$v) {_vm.$set(_vm.inspectedModel, "Word", $$v)},expression:"inspectedModel.Word"}})]}}],null,true)})],1),_c('v-card-actions',{staticStyle:{"background-color":"white","position":"sticky","bottom":"0px","border-top":"solid grey 2px"}},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","type":"submit","loading":_vm.loadings.send}},[_vm._v(" "+_vm._s(_vm.$t("buttons.save"))+" ")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.prohibitedWordDetailStatus = false;
                _vm.clearModal();}}},[_vm._v(" "+_vm._s(_vm.$t("buttons.close"))+" ")])],1)],1)]}}],null,false,1992828366)})],1):_vm._e()],1),_c('delete-modal',{ref:"deleteModalRef",attrs:{"yesFunc":_vm.deleteItem}}),_c('v-card',{staticStyle:{"border-radius":"10px"}},[_c('v-card-title',{staticStyle:{"color":"#595959"}},[_c('v-row',[_c('v-col',{staticClass:"py-2"},[_vm._v(_vm._s(_vm.$t("title.prohibitedWords")))]),_c('v-spacer'),_c('v-btn',{staticClass:"ma-2 mr-4",attrs:{"small":"","elevation":"0","outlined":"","color":"info"},on:{"click":function($event){_vm.prohibitedWordDetailStatus = true;
            _vm.isDetails = false;
            _vm.modalHeader = _vm.$t('title.createProhibitedWord');}}},[_vm._v(" "+_vm._s(_vm.$t("buttons.addNew"))+" ")])],1)],1),_c('v-divider'),_c('v-card-text',{staticClass:"pt-4"},[_c('v-row',{attrs:{"align":"center","justify":"space-between"}},[_c('v-col',{attrs:{"cols":"2"}},[_c('v-select',{attrs:{"items":_vm.perPageValues,"label":_vm.$t('labels.selectItemsPerPage'),"item-value":"value","item-text":"name"},on:{"change":function($event){return _vm.changeItemsPerPage()}},model:{value:(_vm.itemsPerPage),callback:function ($$v) {_vm.itemsPerPage=$$v},expression:"itemsPerPage"}})],1)],1),_c('v-data-table',{ref:"wordList",attrs:{"headers":_vm.headers,"items":_vm.tableData,"loading":_vm.loadings.table,"options":_vm.options,"hide-default-footer":true,"footer-props":{
          disablePagination: true,
          disableItemsPerPage: true,
        },"no-data-text":_vm.$t('description.noList')},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.Name",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticStyle:{"font-weight":"bold !important"}},[_vm._v(_vm._s(item.Name))])]}},{key:"item.User",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getFullName(item))+" ")]}},{key:"item.AnswerCount",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.AnswerCount == 0 ? "Cevaplanmadı" : item.AnswerCount)+" ")]}},{key:"item.CreatedAt",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.convertToLocal(item.CreatedAt))+" ")]}},{key:"item.UpdatedAt",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.UpdatedAt ? _vm.convertToLocal(item.UpdatedAt) : "-")+" ")]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.getWordDetails(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("labels.tooltipEdit")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.openDeleteModal(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("labels.tooltipDelete")))])])]}}])}),_c('div',{staticClass:"text-xs-center pt-2 md4 lg4"},[_c('v-pagination',{attrs:{"value":_vm.pagination.page,"length":_vm.pages,"total-visible":10,"circle":""},on:{"input":_vm.paginationChangeHandler}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }