import Vue from 'vue'
import store from '../store/store'
const _showError = (error) => {
  console.log('error : ' ,error)
  let defaultMsg = 'Beklenmeyen bir hata oluştu, lütfen daha sonra yeniden deneyiniz.'

  try {
    if (error.status == 401) {
      store.state.alertObject = {
        message: 'Oturumunuz sonlanmıştır, lütfen tekrar giriş yapınız.',
        color: 'error',
        y: 'top'
      }
      store.dispatch('logout')
    }

    defaultMsg = error?.response?.data?.Message || defaultMsg;

  } catch (err) { }

  store.state.alertObject = {
    message: defaultMsg,
    color: 'error',
    y: 'top'
  }

}

const _showSuccess = (msg) => {
  store.state.alertObject = { message: msg, color: 'success', y: 'top' }
}

const _showInfo = (msg, type = 'standart', color = 'info') => {
  switch (type) {
    case 'standart':
      store.state.alertObject = { message: msg, color, y: 'top' }
      break
    case 'notification':
      store.state.alertObject = { message: msg, color, y: 'bottom', x: 'right' }
      break
  }
}

const _showWarning = (msg) => {
  store.state.alertObject = { message: msg, color: 'warning', y: 'top' }
}

const _toggleLoading = (val = null) => {
  if (!val) store.state.actionLoading = !store.state.actionLoading
  else store.state.actionLoading = val
}

const _showNewContent = (msg) => {
  store.state.newContentButtonObject = { message: msg, color: 'info', y: 'top' }
}

window.showError = Vue.prototype.showError = _showError
window.showSuccess = Vue.prototype.showSuccess = _showSuccess
window.showInfo = Vue.prototype.showInfo = _showInfo
window.showWarning = Vue.prototype.showWarning = _showWarning
window.showNewContent = Vue.prototype.showNewContent = _showNewContent
window.toggleLoading = Vue.prototype.toggleLoading = _toggleLoading
