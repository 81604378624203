import accountService from "../services/AccountService";
import ModuleService from "../services/ModuleService";

export default {
  login({ commit }, { Username, Password }) {
    return new Promise((resolve, reject) => {
      let body = { Username, Password };
      accountService
        .login(body)
        .then((res) => {
          commit("updateUserInfo", res.data);
          commit("updateUserToken", {
            access: res.data.Token,
            refresh: res.data.Token,
          });
          commit("updateUserType", res.data.Type);
          return resolve(res);
        })
        .catch((err) => {
          window.showError(err);
          return reject(err);
        });
    });
  },

  logout({ commit }) {
    accountService
      .logout()
      .then((res) => {
        console.log("Logout res >> ", res);
      })
      .catch((err) => {
        console.log("Logout err >> ", err);
      })
      .then(() => {
        commit("authLogout", {});
      });
  },

  getMenuData({ commit }) {
    ModuleService.getMyPages()
      .then((res) => {
        console.log("res navigate : ", res.data);
        let items = [];
        res.data.forEach((element) => {
          let obj = {};
          if (element.Type == "static") {
            //Sabit sayfalar
            obj = {
              label: element.Name,
              to: "/home/" + element.Route,
              icon: element.Icon,
            };
          } else {
            //Modül sayfalar , type = 'custom'
            obj = {
              label: element.Name,
              to: "/home/moduls/" + element.Id,
              icon: element.Icon,
            };
          }
          items.push(obj);
        });
        commit("setMenuItems", items);
      })
      .catch((err) => {
        window.showError(err);
      })
      .then(() => {});
  },
};
