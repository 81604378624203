<template>
  <v-container>
    <!-- Loading component -->
    <div class="text-center" v-if="isLoading">
      <v-overlay>
        <v-progress-circular
          :size="70"
          :width="10"
          indeterminate
          color="#E15200"
        ></v-progress-circular>
      </v-overlay>
    </div>
    <div>
      <!-- Sticky publish button start -->
      <v-btn
        v-scroll="onScroll"
        v-show="fab"
        dark
        fixed
        bottom
        right
        big
        class="ma-2 mr-4"
        elevation="0"
        color="primary"
        @click="stickyPublishOperation"
      >
        {{ $t("buttons.publish") }}
      </v-btn>
      <!-- Sticky publish button end -->
      <!-- Page Header End -->
      <!-- Content -->
      <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
        <form @submit.prevent="handleSubmit(sendForm)">
          <!-- Form buttons start -->
          <v-card-title style="color: #595959">
            <v-row>
              <v-col class="py-2 pl-0">{{ $t("title.addStory") }}</v-col>
              <v-spacer />
              <v-btn
                small
                type="submit"
                class="ma-2 mr-4"
                elevation="0"
                outlined
                color="info"
              >
                {{ $t("buttons.publish") }}
              </v-btn>
            </v-row>
          </v-card-title>
          <!-- Form buttons end -->

          <v-divider />

          <v-card-text class="px-0 py-10">
            <!-- Title  -->
            <v-card-text class="px-0 py-0 add-content-card-body">
              <v-banner rounded outlined class="mt-5">
                <v-card-title class="add-content-title">{{
                  $t("labels.storyTitle")
                }}</v-card-title>
                <ValidationProvider
                  name="Başlık"
                  rules="required|max:150"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    :label="$t('labels.header')"
                    v-model="title"
                    clearable
                    class="mx-5"
                    :error-messages="errors[0]"
                  ></v-text-field>
                </ValidationProvider>
              </v-banner>
            </v-card-text>
            <!-- Title end -->

            <!-- ThumbNail Photo Upload -->
            <v-card-text class="px-0 py-0 add-content-card-body">
              <v-banner rounded outlined class="mt-5">
                <v-card-title class="add-content-title">{{
                  $t("labels.storyPhotoUpload")
                }}</v-card-title>
                <v-card-title
                  class="add-content-title"
                  style="font-size: 12px !important"
                  >{{ $t("labels.storyphotoUploadDescription") }}</v-card-title
                >

                <v-row>
                  <v-col>
                    <input
                      type="file"
                      accept="image/*"
                      ref="uploader"
                      class="d-none"
                      @change="onFileChange"
                    />

                    <v-btn
                      small
                      class="ma-4 mr-8"
                      elevation="0"
                      outlined
                      color="info"
                      :disabled="photoLength == 1"
                      @click="browsePhoto()"
                    >
                      {{ $t("buttons.uploadNew") }}
                    </v-btn>
                    <!-- <v-btn
                      small
                      class="ma-4 mr-4"
                      elevation="0"
                      outlined
                      :disabled="photoLength == 1"
                      color="info"
                    >
                      {{ $t("buttons.uploadfromLibrary") }}
                    </v-btn> -->
                  </v-col>
                  <v-col
                    align-self="end"
                    class="mr-5"
                    style="text-align: end"
                    v-if="selectedPhotoModel"
                  >
                    {{ photoLength == null ? " " : photoLength + " /" + " 1" }}
                  </v-col>
                </v-row>

                <v-banner
                  rounded
                  outlined
                  class="mt-5"
                  v-if="selectedPhotoModel"
                >
                  <v-hover>
                    <v-img
                      style="border-radius: 8px"
                      max-height="250px"
                      :aspect-ratio="4 / 3"
                      contain
                      :src="computedUrl(selectedPhotoModel)"
                      slot-scope="{ hover }"
                    >
                      <v-expand-transition>
                        <div
                          v-if="hover"
                          class="
                            transition-fast-in-fast-out
                            image-hover
                            white--text
                          "
                          style="height: 100%"
                        >
                          <v-layout
                            row
                            wrap
                            fill-height
                            align-center
                            justify-center
                            ma-0
                          >
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  @click="deletePhoto(selectedPhotoModel, 0)"
                                  class="ma-0"
                                  icon
                                  v-on="on"
                                  style="z-index: 100"
                                >
                                  <v-icon color="white">mdi-close</v-icon>
                                </v-btn>
                              </template>
                              <span>{{ $t("labels.deleteKey") }}</span>
                            </v-tooltip>

                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  class="ma-0"
                                  icon
                                  v-on="on"
                                  style="z-index: 100"
                                >
                                  <v-icon color="white">mdi-folder</v-icon>
                                </v-btn>
                              </template>
                              <span>{{ selectedPhotoModel.name }}</span>
                            </v-tooltip>
                          </v-layout>
                        </div>
                      </v-expand-transition>
                    </v-img>
                  </v-hover>
                </v-banner>
              </v-banner>
            </v-card-text>
            <!-- Thumbnail Photo upload end -->

            <!-- Large Photo Upload -->
            <v-card-text class="px-0 py-0 add-content-card-body">
              <v-banner rounded outlined class="mt-5">
                <v-card-title class="add-content-title">{{
                  $t("labels.storyFileUpload")
                }}</v-card-title>
                <v-card-title
                  class="add-content-title"
                  style="font-size: 12px !important"
                  >{{ $t("labels.storyFileUploadDescription") }}</v-card-title
                >
                <v-row>
                  <v-col>
                    <input
                      type="file"
                      accept="/*"
                      ref="fileInput"
                      style="display: none"
                      @change="selectFileHandle"
                    />
                    <v-btn
                      small
                      class="ma-4 mr-8"
                      elevation="0"
                      outlined
                      color="info"
                      :disabled="fileLength == 1"
                      @click="
                        browseFile();
                        ('');
                      "
                    >
                      {{ $t("buttons.uploadNew") }}
                    </v-btn>
                    <!-- <v-btn
                      small
                      class="ma-4 mr-4"
                      elevation="0"
                      outlined
                      :disabled="fileLength == 1"
                      color="info"
                    >
                      {{ $t("buttons.uploadfromLibrary") }}
                    </v-btn> -->
                  </v-col>
                  <v-col
                    align-self="end"
                    class="mr-5"
                    style="text-align: end"
                    v-if="selectedFiles.length > 0"
                  >
                    {{ fileLength == null ? " " : fileLength + " /" + " 1" }}
                  </v-col>
                </v-row>

                <v-banner
                  rounded
                  outlined
                  class="mt-5"
                  v-if="selectedFiles.length > 0"
                >
                  <v-col>
                    <v-container class="mt-5" mx-0 px-0 fluid grid-list-xl>
                      <v-layout row wrap>
                        <v-flex
                          xs2
                          v-for="(item, index) in selectedFiles"
                          :key="index"
                        >
                          <v-hover>
                            <v-img
                              style="border-radius: 8px"
                              width="100%"
                              :aspect-ratio="1 / 1"
                              slot-scope="{ hover }"
                              contain
                              :src="
                                item.type == 'image/jpeg' ||
                                item.type == 'image/png'
                                  ? selectedFilesUrl[index]
                                  : item.type == 'video/mp4'
                                  ? require('@/../public/img/video-thumb.png')
                                  : require('@/../public/img/article-thumb.png')
                              "
                            >
                              <v-expand-transition>
                                <div
                                  v-if="hover"
                                  class="
                                    transition-fast-in-fast-out
                                    image-hover
                                    white--text
                                  "
                                  style="height: 100%"
                                >
                                  <v-layout
                                    row
                                    wrap
                                    fill-height
                                    align-center
                                    justify-center
                                    ma-0
                                  >
                                    <v-tooltip bottom>
                                      <template v-slot:activator="{ on }">
                                        <v-btn
                                          @click="deleteFile(item.id, index)"
                                          class="ma-0"
                                          icon
                                          v-on="on"
                                          style="z-index: 100"
                                        >
                                          <v-icon color="white"
                                            >mdi-close</v-icon
                                          >
                                        </v-btn>
                                      </template>
                                      <span>{{ $t("labels.deleteKey") }}</span>
                                    </v-tooltip>

                                    <v-tooltip bottom>
                                      <template v-slot:activator="{ on }">
                                        <v-btn
                                          class="ma-0"
                                          icon
                                          v-on="on"
                                          style="z-index: 100"
                                        >
                                          <v-icon color="white"
                                            >mdi-folder</v-icon
                                          >
                                        </v-btn>
                                      </template>
                                      <span>{{ item.name }}</span>
                                    </v-tooltip>
                                  </v-layout>
                                </div>
                              </v-expand-transition>
                            </v-img>
                          </v-hover>
                        </v-flex>
                      </v-layout>
                    </v-container>
                  </v-col>
                </v-banner>
              </v-banner>
            </v-card-text>
            <!-- Large Photo upload end -->

            <!-- Modul Page Content start -->
            <v-card-text class="px-0 py-0 add-content-card-body">
              <v-banner rounded outlined class="mt-5">
                <v-card-title class="add-content-title">
                  {{ $t("labels.modulChoose") }}
                </v-card-title>
                <v-row class="ma-2 mr-4">
                  <v-col cols="4">
                    <ValidationProvider
                      name="Page"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <v-select
                        v-model="selectedModuleModal"
                        :items="pageList"
                        :label="$t('description.selectPage')"
                        item-text="Value"
                        return-object
                        item-value="Key"
                        :disabled="editItemId != null"
                        :error-messages="errors[0]"
                        @change="fillInnerSelectPage(selectedModuleModal)"
                      ></v-select>
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="4" v-if="selectedModuleModal.IsDetail">
                    <v-select
                      v-model="selectedInnerModuleModal"
                      :items="allInnerModules"
                      :label="$t('description.selectInnerPage')"
                      item-text="Value"
                      item-value="Key"
                      :disabled="editItemId != null"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-banner>
            </v-card-text>
            <!-- Modul Page Content end -->

            <!-- Date and Links Start -->
            <v-card-text class="px-0 py-0 add-content-card-body">
              <v-banner rounded outlined class="mt-5">
                <v-card-title class="add-content-title">
                  {{ $t("labels.dateAndPin") }}
                </v-card-title>
                <v-row class="ma-2 mr-4">
                  <v-col cols="4">
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      :return-value.sync="date"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="date"
                          label="Bitiş Tarihi"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="date" no-title scrollable>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu = false">
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.menu.save(date)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="5" v-if="selectedModuleModal.IsLink">
                    <v-text-field
                      :label="$t('labels.link')"
                      v-model="link"
                      clearable
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="ma-2 mr-4">
                  <v-col cols="2">
                    <v-checkbox
                      v-model="checkboxPin"
                      :label="'Üste Sabitle'"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-banner>
            </v-card-text>
            <!-- Date and Links Start End -->

            <!-- Groups start  -->
            <v-card-text class="px-0 py-0 add-content-card-body">
              <v-banner rounded outlined class="mt-5">
                <v-card-title class="add-content-title">
                  {{ $t("labels.postGroup") }}
                </v-card-title>

                <v-row
                  class="mt-2"
                  v-for="(data, rowIndex) in groupsSelectArray"
                  :key="rowIndex"
                >
                  <v-row>
                    <v-col
                      cols="4"
                      v-for="(element, colIndex) in data"
                      :key="colIndex"
                    >
                      <ValidationProvider
                        :vid="'attribute' + rowIndex + colIndex"
                        name="Grup"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <div row wrap class="px-5 mt-3">
                          <v-select
                            v-model="groupArr[rowIndex][colIndex]"
                            :items="element.groups"
                            @click="
                              clickGroupFilterModal(element, colIndex, rowIndex)
                            "
                            item-text="name"
                            item-value="id"
                            :label="
                              element.name == 'Şirket'
                                ? 'Kuruluş'
                                : element.name
                            "
                            dense
                            required
                            :error-messages="errors[0]"
                          ></v-select>
                        </div>
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                </v-row>
                <v-row>
                  <v-col cols="2">
                    <v-btn
                      small
                      class="ma-1"
                      v-if="!groupLengthAlert"
                      outlined
                      color="info"
                      @click="createNewGroup()"
                    >
                      {{ $t("buttons.addNew") }}
                    </v-btn>
                  </v-col>

                  <v-col class="mt-2">
                    <v-alert
                      v-model="groupLengthAlert"
                      border="left"
                      close-text="Close Alert"
                      type="info"
                      color="#7443a0"
                      dismissible
                      outlined
                      prominent
                      class="font-weight-black"
                      style="font-size: 1.2em"
                    >
                      {{ $t("description.groupLengthMessage") }}
                    </v-alert>
                  </v-col>
                </v-row>
              </v-banner>
            </v-card-text>
            <!-- Groups end -->
          </v-card-text>
        </form>
      </ValidationObserver>

      <!-- Content End -->
    </div>
  </v-container>
</template>

<script>
import GroupService from "../services/GroupService";
import FileService from "../services/FileService";
import PostService from "../services/PostService";
import ContentService from "../services/ContentService";
import SurveyService from "../services/SurveyService";
import _ from "lodash";
import StoryService from "../services/StoryService";
import AccountService from "../services/AccountService";

export default {
  data: () => ({
    //To publish model
    title: "",
    link: "",
    checkboxPin: false,
    fileIds: [],
    tempFiles: [],
    groupModel: {
      Groups: [],
    },
    //End Publish model

    editItemId: null,

    selectedFiles: [],
    selectedFilesUrl: [],
    fileLength: null,

    //For groups area
    selecItems: [],
    groupData: [],
    groupLengthAlert: false,
    groupArr: [[]],
    groupsSelectArray: [],
    corpGroup: [],
    locGroup: [],
    empGroup: [],
    allSelect: [],

    //mandatory photo upload models
    selectedPhotoModel: null,
    photoLength: 0,
    selectedPhoto: [],
    selectedPhotoUrl: [],

    //treeview test
    fab: false,
    isLoading: false,

    selectedModuleModal: "",
    selectedInnerModuleModal: "",
    allModules: [],
    allInnerModules: [],
    loadings: {
      table: false,
      send: false,
      select: false,
    },
    pageList: [
      /*  {
        Value: "Aramıza Katılanlar",
        Key: "Newcomers",
        IsDetail: false,
        ModuleKey: null,
      },
      {
        Value: "Kurumsal Haberler",
        Key: "Home",
        IsDetail: true,
        ModuleKey: "kurumsal_haberler",
      },
      {
        Value: "Anketler",
        Key: "Survey",
        IsDetail: true,
        ModuleKey: "anketler",
      },
      {
        Value: "Yemek Menüsü",
        Key: "FoodMenu",
        IsDetail: false,
        ModuleKey: null,
      },
      {
        Value: "Bugün Doğanlar",
        Key: "BornTodayUsers",
        IsDetail: false,
        ModuleKey: null,
      },
      {
        Value: "Açık Duyurular",
        Key: "CareerOpportunities",
        IsDetail: false,
        ModuleKey: null,
      },
      {
        Value: "Yeni Doğanlar",
        Key: "NewBorn",
        IsDetail: false,
        ModuleKey: null,
      },
      {
        Value: "İndirimler",
        Key: "Rebates",
        IsDetail: false,
        ModuleKey: null,
      },
      {
        Value: "Vefat Edenler",
        Key: "Deceased",
        IsDetail: false,
        ModuleKey: null,
      },
      {
        Value: "Atamalar",
        Key: "Advancement",
        IsDetail: false,
        ModuleKey: null,
      },
      {
        Value: "Topluluk Uygulamaları",
        Key: "Application",
        IsDetail: false,
        ModuleKey: null,
      },
      {
        Value: "Kim Kimdir",
        Key: "WhosWho",
        IsDetail: false,
        ModuleKey: null,
      },
      {
        Value: "Link",
        Key: "link",
        IsLink: true,
        IsExternal: false,
        ModuleKey: null,
      },
      {
        Value: "External Link",
        Key: "external_link",
        IsLink: true,
        IsExternal: true,
        ModuleKey: null,
      }, */
    ],
    date: "",
    menu: false,
    mediaId: "",
    thumbNailId: "",
  }),
  watch: {
    model(val) {
      if (val.length > 5) {
        this.$nextTick(() => this.model.pop());
      }
    },
  },
  mounted() {
    this.setup();
  },
  components: {},
  methods: {
    setup() {
      this.getGroupTypesModal();
      this.fillGroups();
      this.fillRedirectPages();
    },
    //Fill Inner Page Select  Box
    fillInnerSelectPage(modal) {
      console.log("Modal", modal);
      this.allInnerModules = [];
      this.selectedInnerModuleModal = "";
      if (modal.ModuleKey != null && modal.ModuleKey != "anketler") {
        ContentService.getPosts(modal.ModuleKey)
          .then((res) => {
            res.data.Data.forEach((x) => {
              this.allInnerModules.push({ Key: x.Id, Value: x.Title });
            });
          })
          .catch((err) => {
            window.showError(err);
          })
          .then(() => {});
      } else if (modal.ModuleKey == null && modal.ModuleKey == "anketler") {
        SurveyService.getSurveys()
          .then((res) => {
            res.data.forEach((x) => {
              this.allInnerModules.push({ Key: x.Id, Value: x.Name });
            });
          })
          .catch((err) => {
            window.showError(err);
          })
          .then(() => {});
      } else if (modal.ModuleKey != null && modal.ModuleKey == "anketler") {
        SurveyService.getSurveys()
          .then((res) => {
            res.data.forEach((x) => {
              this.allInnerModules.push({ Key: x.Id, Value: x.Name });
            });
          })
          .catch((err) => {
            window.showError(err);
          })
          .then(() => {});
      } else {
        // SurveyService.getSurveys()
        //   .then((res) => {
        //     res.data.forEach((x) => {
        //       this.allInnerModules.push({ Key: x.Id, Value: x.Name });
        //     });
        //   })
        //   .catch((err) => {
        //     window.showError(err);
        //   })
        //   .then(() => {});
      }
    },
    //Pop sticky button on scroll 40px
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 40;
    },
    //Sticky button publish operation
    stickyPublishOperation() {
      this.publish();
    },
    //Loads groups when page loaded
    fillGroups() {
      GroupService.getGroups("sirket")
        .then((res) => {
          res.data.forEach((element) => {
            this.corpGroup.push(element.Id);
            let obj = {
              Id: element.Id,
              Name: element.Name,
            };
            this.allSelect.push(obj);
          });
        })
        .catch((err) => {
          window.showError(err);
        })
        .then(() => {});
      GroupService.getGroups("lokasyon")
        .then((res) => {
          res.data.forEach((element) => {
            this.locGroup.push(element.Id);
            let obj = {
              Id: element.Id,
              Name: element.Name,
            };
            this.allSelect.push(obj);
          });
        })
        .catch((err) => {
          window.showError(err);
        })
        .then(() => {});
      GroupService.getGroups("calisan_tipi")
        .then((res) => {
          res.data.forEach((element) => {
            this.empGroup.push(element.Id);
            let obj = {
              Id: element.Id,
              Name: element.Name,
            };
            this.allSelect.push(obj);
          });
        })
        .catch((err) => {
          window.showError(err);
        })
        .then(() => {});
      let all = {
        Name: "Tümü",
        Id: "",
      };
      this.allSelect.push(all);
    },

    //Loads redirect Page Combobox
    fillRedirectPages() {
      AccountService.getRedirectPages()
        .then((res) => {
          console.log("res redirect pages", res.data);
          this.pageList = res.data;
        })
        .catch((err) => {
          window.showError(err);
        });
    },
    //End redirect page combox
    //File start //////////////////////////////////////////
    //Local file choose open
    browsePhoto() {
      this.$refs.uploader.click();
    },
    browseFile() {
      this.$refs.fileInput.click();
    },
    onFileChange(e) {
      if (e.target.files[0] === undefined) {
      } else {
        this.selectedPhotoModel = e.target.files[0];
        this.uploadPhoto(this.selectedPhotoModel);
      }
    },
    // Local file choose
    selectFileHandle(e) {
      if (e.target.files[0] === undefined) {
      } else {
        this.uploadFile(e.target.files[0]);
      }
    },
    //Return file url to send service
    computedUrl(file) {
      return URL.createObjectURL(file);
    },
    //Photo upload to service
    uploadPhoto(media) {
      let formData = new FormData();

      if (media === undefined) {
        console.log("Undefined media");
      } else {
        if (media.size > 20000000) {
          window.showInfo(
            this.$t("description.fileSizeError"),
            "standart",
            "error"
          );
        } else {
          this.selectedPhoto.push(media);
          this.tempFiles.unshift(media);
          this.selectedPhotoUrl.push(this.computedUrl(media));
          this.photoLength = 1;
        }
      }
    },
    //File upload to service
    uploadFile(media) {
      let formData = new FormData();

      if (media === undefined) {
        console.log("Undefined media");
      } else {
        if (media.size > 50000000) {
          window.showInfo(
            this.$t("description.fileSizeError"),
            "standart",
            "error"
          );
        } else {
          this.selectedFiles.push(media);
          this.tempFiles.push(media);
          this.selectedFilesUrl.push(this.computedUrl(media));
          this.fileLength = this.selectedFiles.length;
        }
      }
    },
    //File delete
    deleteFile(fileId, index) {
      this.selectedFiles.splice(index, 1);
      this.selectedFilesUrl.splice(index, 1);

      this.fileLength = this.selectedFiles.length;
      this.fileIds.splice(index, 1);
    },
    //Photo delete
    deletePhoto(photo, index) {
      this.selectedPhoto.splice(index, 1);
      this.selectedPhotoUrl.splice(index, 1);
      this.selectedPhotoModel = null;

      this.photoLength--;
      this.fileIds.splice(index, 1);
    },
    //File end //////////////////////////////////////////

    // Fill select box items
    clickGroupFilterModal(element, colIndex, dataIndex) {
      // let data = this.selecItems.filter((x) => x.Type == element.type);
      // if (data.length > 0) {
      //   this.groupsSelectArray[dataIndex][colIndex].groups = data[0].Data;
      // } else {
      //   GroupService.getGroups(element.type)
      //     .then((res) => {
      //       let arr = [];
      //       res.data.forEach((item) => {
      //         let obj = {
      //           id: item.Id,
      //           name: item.Name,
      //         };
      //         arr.push(obj);
      //       });
      //       arr.unshift({ name: "Tümü", id: "All" });
      //       this.groupsSelectArray[dataIndex][colIndex].groups = arr;
      //       let listObj = {
      //         Type: element.type,
      //         Data: arr,
      //       };
      //       this.selecItems.push(listObj);
      //     })
      //     .catch((err) => {
      //       window.showError(err);
      //     })
      //     .then(() => {});
      // }

      let data = this.selecItems.filter((x) => x.Type == element.type);
      // if (data.length > 0) {
      //   this.groupsSelectArray[dataIndex][colIndex].groups = data[0].Data;
      // } else {
      if (
        element.type == "lokasyon" &&
        this.groupArr[dataIndex][colIndex - 1] == undefined
      ) {
        console.log("girdi 1");
        this.groupsSelectArray[dataIndex][colIndex].groups = [...[]];
      }
      if (
        (element.type == "lokasyon" &&
          this.groupArr[dataIndex][colIndex - 1] != undefined) ||
        element.type == "calisan_tipi" ||
        element.type == "sirket"
      ) {
        console.log("girdi 2");
        GroupService.getGroups(
          element.type,
          element.type == "lokasyon"
            ? this.groupArr[dataIndex][colIndex - 1]
            : ""
        )
          .then((res) => {
            let arr = [];
            res.data.forEach((item) => {
              let obj = {
                id: item.Id,
                name: item.Name,
              };
              arr.push(obj);
            });
            if (
              element.type != "sirket" ||
              this.$store.getters.getPermissionIds
            )
              arr.unshift({ name: "Tümü", id: "All" });
            this.groupsSelectArray[dataIndex][colIndex].groups = arr;
            let listObj = {
              Type: element.type,
              Data: arr,
            };
            this.selecItems.push(listObj);
          })
          .catch((err) => {
            window.showError(err);
          })
          .then(() => {});
      }
    },
    //Group types
    getGroupTypesModal() {
      GroupService.getGroupTypes()
        .then((res) => {
          res.data.forEach((element) => {
            let obj = {
              type: element.Code,
              groups: [],
              name: element.Name,
            };

            this.groupData.push(obj);
          });
          this.groupsSelectArray.push(this.groupData);
        })
        .catch((err) => {
          window.showError(err);
        });
    },

    //To take GroupId
    createGroupIds() {
      // for (let i = 0; i < this.groupArr.length; i++) {
      //   const element = this.groupArr[i];
      //   this.groupModel.Groups[i] = new Array();
      //   for (let y = 0; y < element.length; y++) {
      //     if (element[y] === "All") {
      //       switch (y) {
      //         case 0:
      //           this.corpGroup.forEach((element) => {
      //             this.groupModel.Groups[i].push(element);
      //           });
      //           continue;
      //         case 1:
      //           this.locGroup.forEach((element) => {
      //             this.groupModel.Groups[i].push(element);
      //           });
      //           continue;
      //         case 2:
      //           this.empGroup.forEach((element) => {
      //             this.groupModel.Groups[i].push(element);
      //           });
      //           continue;

      //         default:
      //           break;
      //       }
      //     }
      //     this.groupModel.Groups[i].push(element[y]);
      //   }
      // }
      console.log("group :", this.groupArr);
      for (let i = 0; i < this.groupArr.length; i++) {
        const element = this.groupArr[i];
        this.groupModel.Groups[i] = new Array();
        for (let y = 0; y < element.length; y++) {
          if (element[y] === "All") {
            this.groupsSelectArray[i][y].groups.forEach((element) => {
              this.groupModel.Groups[i].push(element.id);
            });
          }

          this.groupModel.Groups[i].push(element[y]);
        }

        this.groupModel.Groups[i] = this.groupModel.Groups[i].filter(
          (x) => x != "All"
        );
      }
      console.log("asdasd : ", this.groupModel);
    },
    //To add new row for new Group
    createNewGroup() {
      // if (this.groupsSelectArray.length < 4) {
      //   this.groupLengthAlert = false;
      //   this.groupsSelectArray = [...this.groupsSelectArray, this.groupData];
      //   this.groupArr.push([]);
      // } else {
      //   this.groupLengthAlert = true;
      // }

      if (this.groupsSelectArray.length < 4) {
        this.groupLengthAlert = false;
        let arr = [];
        console.log("data", this.groupData);
        this.groupData.forEach((element) => {
          let obj = {
            type: element.type,
            groups: [],
            name: element.name,
          };

          arr.push(obj);
        });

        this.groupsSelectArray = [...this.groupsSelectArray, arr];
        this.groupArr.push([]);
      } else {
        this.groupLengthAlert = true;
      }
    },
    sendForm() {
      this.publish();
    },

    async uploadFiles(index) {
      if (index < this.tempFiles.length) {
        this.uploadFileCall(this.tempFiles[index], index);
      } else {
        this.publishCall();
      }
    },
    async uploadFileCall(media, index) {
      if (media === undefined) {
        console.log("Undefined media");
      }
      let formData = new FormData();
      if (
        media.type == "image/jpeg" ||
        media.type == "image/png" ||
        media.type == "video/mp4"
      ) {
        formData.append("Files", media);
        formData.append("Type", "media");
      } else {
        formData.append("Files", media);
        formData.append("Type", "document");
      }
      await Promise.resolve(
        FileService.uploadFile(formData)
          .then((res) => {
            this.fileIds.push(res.data[0].Id);
          })
          .catch((err) => {
            window.showError(err);
          })
          .then(() => {
            this.uploadFiles(index + 1);
          })
      );
    },
    //Add Content
    async publish() {
      this.$store.state.loadingStatus = true;
      this.createGroupIds();
      if (this.tempFiles.length > 0) {
        await this.uploadFiles(0);
      } else {
        // this.publishCall();
        //Photo is mandatory
        this.$store.state.loadingStatus = false;
        window.showInfo(
          this.$t("description.storyPhotoError"),
          "standart",
          "info"
        );
      }
    },
    publishCall() {
      let temp = "";
      if (this.selectedInnerModuleModal.length > 1) {
        temp = {
          ContentId: this.selectedInnerModuleModal,
        };
        temp = JSON.stringify(temp);
      }
      this.mediaId = this.fileIds[1];
      this.thumbNailId = this.fileIds[0];

      let body = {
        ThumbnailId: this.thumbNailId,
        MediaId: this.mediaId,
        Title: this.title,
        Text: "",
        MediaType: "image",
        LinkType: !this.selectedModuleModal.IsLink
          ? "screen"
          : this.selectedModuleModal.IsExternal
          ? "external_link"
          : "link",
        Link: this.selectedModuleModal.IsLink
          ? this.link
          : this.selectedModuleModal.Key,
        Extra: temp,
        IsPinned: this.checkboxPin,
        FileIds: this.fileIds,
        GroupIds: this.groupModel.Groups,
        EndDate: this.date,
      };

      console.log("Created body", body);
      StoryService.createStory(body)
        .then((res) => {
          console.log("Post created", res);
          this.$store.state.loadingStatus = false;
          this.$router.push({ name: "stories" });
        })
        .catch((err) => {
          this.$store.state.loadingStatus = false;
          window.showError(err);
        })
        .then(() => {});
    },
    controlLetterChar(e) {
      window.controlLetterChar(e);
    },
    //For only numbers
    controlIntChar(e) {
      window.controlIntChar(e);
    },
  },
  computed: {},
};
</script>
