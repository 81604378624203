<template>
  <div>
    <v-card style="border-radius: 10px">
      <v-card-title style="color: #595959">
        <v-row align="center">
          <v-col align="start" class="py-2">{{
            $t("title.titleReport")
          }}</v-col>
          <v-spacer />
          <!-- Sheet name must be max 31 characters -->
          <vue-excel-xlsx v-if="selectedStatisticType.Key" :data="exportData" :columns="exportHeaders"
            :file-name="selectedStatisticType.Key + '.' + date + '-Raporu'" :file-type="'xlsx'"
            :sheet-name="selectedStatisticType.Value.substring(0, 31)">
            <v-btn small class="ma-2 mr-4" elevation="0" outlined color="info">
              {{ $t("buttons.excelExport") }}
            </v-btn>
          </vue-excel-xlsx>
          <v-btn @click="clearPage()" small class="ma-2 mr-4" elevation="0" outlined color="info">
            {{ $t("buttons.clearFilter") }}
          </v-btn>
        </v-row>
      </v-card-title>

      <v-divider />

      <v-card-text class="pt-4">
        <v-row align="center" justify="start">
          <!-- Range date picker start -->
          <v-col cols="3">
            <v-menu ref="datePickerRef" v-model="datePickerModel" :close-on-content-click="false"
              :return-value.sync="date" transition="scale-transition" offset-y min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="dateRangeText" :label="$t('labels.selectDateRange')" prepend-icon="mdi-calendar"
                  readonly v-bind="attrs" v-on="on"></v-text-field>
              </template>
              <v-date-picker v-model="dates" locale="tr-TR" range>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="
                  datePickerModel = false;
                dates = [];
                                                                        ">
                  {{ $t("buttons.cancel") }}
                </v-btn>
                <v-btn text color="primary" @click="
                  $refs.datePickerRef.save(date);
                saveDatePicker();
                                                                        ">
                  {{ $t("buttons.ok") }}
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <!-- Range date picker end -->
          <!-- Statistic type select start -->
          <v-col cols="4">
            <v-select v-model="selectedStatisticType" :items="statisticsList" :label="$t('labels.selectStatistic')"
              item-text="Value" return-object item-value="Key"
              @change="selectStatistic(selectedStatisticType)"></v-select>
          </v-col>
          <!-- Statistic type select end -->
          <!-- Statistic type select start -->
          <v-col cols="2" v-if="selectedStatisticType.Key === 'Count_Post'">
            <v-select v-model="selectedCountPostType" :items="countPostTypes" :label="$t('labels.selectCountPostType')"
              item-text="Value" return-object item-value="Key"
              @change="selectCountPostType(selectedCountPostType)"></v-select>
          </v-col>
          <!-- Statistic type select end -->
          <!-- Tag Id Input Start -->
          <v-col cols="3" v-if="
            selectedStatisticType.Key === 'GetPostTagCounts' ||
            selectedStatisticType.Key === 'MostLikedPostsReport' ||
            selectedStatisticType.Key === 'MostCommentedPostsReport'
          ">
            <v-autocomplete @change="getMostPosts()" item-text="Name" item-value="Id" v-model="groupId" :items="groups"
              :label="$t('labels.regions')" />
          </v-col>
          <!-- Tag Id Input End -->
          <!-- Pagination select box start -->
          <v-col cols="2" class="ml-auto">
            <v-select v-model="itemsPerPage" :items="perPageValues" :label="$t('labels.selectItemsPerPage')"
              item-value="value" item-text="name" @change="changeItemsPerPage()">
            </v-select>
          </v-col>
          <!-- Pagination select box end -->
        </v-row>
        <v-data-table v-model="selectedReports" item-key="Id" :headers="headers" :items="tableData"
          :options.sync="options" :hide-default-footer="true"
          :footer-props="{ disablePagination: true, disableItemsPerPage: true }" :no-data-text="$t('description.noList')">
          <!-- Commented due to status of STATUS column -->
          <!-- <template v-slot:item.Status="{ item }">
                                                    <v-chip v-if="item.Status == 1" class="ma-2" small color="primary">
                                                      {{ $t("labels.tooltipActive") }}
                                                    </v-chip>
                                                    <v-chip v-if="item.Status == 0" class="ma-2" small color="primary">
                                                      {{ $t("labels.tooltipPassive") }}
                                                    </v-chip>
                                                  </template> -->
        </v-data-table>
        <!-- Page number div start -->
        <div class="text-xs-center pt-2 md4 lg4">
          <v-pagination @input="paginationChangeHandler" :value="pagination.page" :length="pages" :total-visible="10"
            circle></v-pagination>
        </div>
        <!-- Page number div end -->
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import ReportService from "../services/ReportService";
import GroupService from "../services/GroupService";
import { mapActions } from "vuex";

export default {
  data: () => ({
    url: "",
    // For table listing
    options: {
      itemsPerPage: 15,
    },
    top: 15,
    skip: 0,
    tableData: [],
    selectedStatisticType: {},
    selectedCountPostType: {},
    statisticsList: [],
    headers: [],
    selectedReports: [],

    // Request Other Data
    otherData: [],

    // Group Id For Report
    groupId: "",
    groups: [],

    //Datepicker models
    dates: [],
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    datePickerModel: false,
    //Pagination models
    pagination: {},
    itemsPerPage: 10,
    perPageValues: [
      {
        name: "10",
        value: 10,
      },
      {
        name: "15",
        value: 15,
      },
      {
        name: "30",
        value: 30,
      },
      {
        name: "50",
        value: 50,
      },
      {
        name: "100",
        value: 100,
      },
      //    {
      //   name:"Tümü",
      //   value:99999999
      // }
    ],
    countPostTypes: [
      {
        Key: "video",
        Value: "Video",
      },
      {
        Key: "image",
        Value: "Resim",
      },
      {
        Key: "text",
        Value: "Metin",
      },
    ],
    exportHeaders: [],
    exportData: [],
  }),
  computed: {
    // Computes dates that is displaying
    dateRangeText() {
      let arr = this.dates.map((date) => {
        return convertToLocal(date);
      });
      arr = arr.join(" - ");
      return arr;
    },
    totalRecords() {
      return this.items.length;
    },
    pageCount() {
      return this.totalRecords / this.itemsPerPage;
    },
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      ) {
        return 0;
      }

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
  },
  mounted() {
    this.url = process.env.VUE_APP_API_URL;
    this.setup();
  },
  methods: {
    //Page setup
    setup() {
      (this.pagination = {
        totalItems: 200,
        rowsPerPage: this.itemsPerPage,
        page: 1,
      }),
        this.getStatisticsList();
    },

    //Change list pagination page
    paginationChangeHandler(pageNumber) {
      this.pagination.page = pageNumber;
      this.getTableList();
    },

    /**
     * Get Group List
     */
    //Loads page list
    getGroupList() {
      GroupService.getGroups("Region")
        .then((res) => {
          this.groups = [
            {
              Id: "",
              Name: "Tümü"
            },
            ...res.data
          ];
        })
        .catch((err) => {
          if (res.status == 401) {
            window.showInfo(
              this.$t("description.authorizationError"),
              "standart",
              "info"
            );
            this.logout();
          } else {
            window.showError(err);
          }
        })
        .then(() => {
        });
    },

    //Fill's the module list
    getTableList() {
      let startDate = this.dates[0];
      let endDate = this.dates[1];

      if (this.dates.length > 0 && startDate >= endDate) {
        window.showInfo(
          this.$t("description.invalidDatePick"),
          "standart",
          "error"
        );
        //If only groups selected, empty date picker
        this.dates = [];
        startDate = "";
        endDate = "";
      }

      if (startDate === undefined && endDate === undefined) {
        startDate = "";
        endDate = "";
      }

      if (
        this.selectedStatisticType.Key === "Count_Post" &&
        !this.selectedCountPostType.Key
      ) {
        return;
      }

      if (
        this.selectedStatisticType.Key === "GetPostTagCounts" ||
        this.selectedStatisticType.Key === "MostLikedPostsReport" ||
        this.selectedStatisticType.Key === "MostCommentedPostsReport"
      ) {
          this.getGroupList();
      }

      if (this.selectedStatisticType.Key) {
        this.$store.state.loadingStatus = true;
        this.tableData = [];
        this.headers = [];

        ReportService.getReport(
          this.selectedStatisticType.Key,
          startDate,
          endDate,
          this.selectedCountPostType.Key,
          this.pagination.rowsPerPage,
          this.pagination.page,
          this.otherData
        )
          .then((res) => {
            console.log("REPORT DATA >> ", res);

            res.data.Headers.map((header) => {
              let tempObj = {
                text: header.Text,
                value: header.Value,
                align: "start",
                sortable: false,
              };
              this.headers.push(tempObj);
            });

            this.tableData = res.data.Result;
            //Pagination operations
            this.pagination.totalItems = res.data.TotalItems;
            this.options.itemsPerPage = this.itemsPerPage;

            //excel export
            this.exportHeaders = res.data.ExcelHeaders;
            this.exportData = res.data.ExcelResult;
          })
          .catch((err) => {
            window.handleServiceError(err);
            this.$store.state.loadingStatus = false;
          })
          .then(() => {
            this.$store.state.loadingStatus = false;
          });
      }
    },

    //Get statistic types
    getStatisticsList() {

      ReportService.getStatistics()
        .then((res) => {
          this.statisticsList = res.data;
        })
        .catch((err) => {
          window.handleServiceError(res, err);
        })
        .then(() => { });
    },

    //Function that activates with date picker selection
    saveDatePicker() {
      let startDate = this.dates[0];
      let endDate = this.dates[1];
      if (this.dates.length > 0 && startDate >= endDate) {
        window.showInfo(
          this.$t("description.invalidDatePick"),
          "standart",
          "error"
        );
        //If only groups selected, empty date picker
        this.dates = [];
        startDate = "";
        endDate = "";
        return;
      }

      if (startDate === undefined && endDate === undefined) {
        startDate = "";
        endDate = "";
        return;
      }
      this.getTableList();
    },

    //Function that activates with report type selection
    selectStatistic(selectedStatisticType) {
      if (this.selectedStatisticType.Key) {
        this.dates = [];
        this.selectedCountPostType = {};
        this.tableData = [];
        this.headers = [];
        this.pagination.page = 1;
        this.groupId = "";
      }
      this.getTableList();
    },

    //Function that activates with report type selection
    selectCountPostType(selectedCountPostType) {
      if (this.selectedCountPostType.Key) {
        this.dates = [];
        this.pagination.page = 1;
      }
      this.getTableList();
    },

    /**
     * Get Most Posts
     */
    getMostPosts() {
      this.dates = [];
      this.pagination.page = 1;
      this.addOtherData("GroupId", this.groupId);
      this.getTableList();
    },

    /**
     * Adds new key and value to request parameters.
     *
     * @param string key
     * @param {*} value
     * @returns null
     */
    addOtherData(key, value) {
      var index = this.otherData.findIndex((item) => item.Key === key);
      if (index < 0) {
        this.otherData.push({
          Key: key,
          Value: value,
        });
      }
      else{
        this.otherData[index].Value = value;
      }
    },

    //Clearing all page filters
    clearPage() {
      console.log("Clearing page...");
      this.dates = [];
      this.tableData = [];
      this.headers = [];
      this.selectedStatisticType = {};
      this.selectCountPostType = {};
      this.pagination.rowsPerPage = 10;
      this.itemsPerPage = 10;
      this.options.itemsPerPage = this.itemsPerPage;
      this.pagination.page = 1;
      this.otherData = [];
      this.groupId = "";
    },

    //Change displayed items per page, page number one is default
    changeItemsPerPage() {
      this.pagination.rowsPerPage = this.itemsPerPage;
      this.pagination.page = 1;
      this.options.itemsPerPage = this.itemsPerPage;
      this.getTableList();
    },

    //Calls sidebar dynamically
    ...mapActions(["getMenuData"]),
  },
};
</script>

<style></style>
