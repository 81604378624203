import axios from "axios";
import AccountService from "../services/AccountService";
export default {
  updateUserToken(state, token) {
    console.log("token.access  : ", token.access);
    state.Token = token.access;
    state.RefreshToken = token.refresh;
    axios.defaults.headers.common["Authorization"] = token.access;
  },
  updateUserId(state, id) {
    state.Id = id;
  },
  updateUserType(state, type) {
    state.Type = type;
  },
  updateUserInfo(state, data) {
    state.Avatar = data.Avatar;
    state.Email = data.Email;
    state.FirstName = data.FirstName;
    state.FullName = data.FullName;
    state.Id = data.Id;
    state.IsBlocked = data.IsBlocked;
    state.LastName = data.LastName;
    state.Type = data.Type;
    state.Username = data.Username;
    state.GroupPermessionIds = data.GroupPermessionIds;
  },
  authLogout(state) {
    state.Token = "";
    state.RefreshToken = "";
    state.Avatar = "";
    state.Email = "";
    state.FirstName = "";
    state.FullName = "";
    state.Id = "";
    state.IsBlocked = "";
    state.LastName = "";
    state.Type = "";
    state.Username = "";
    axios.defaults.headers.common["Authorization"] = "";
    state.router.push({ name: "login" });
  },
  setMenuItems(state, data) {
    state.menuItems = data;
  },
};
