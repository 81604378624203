// Örnek servis codiagno login
import axios from "../javascript/custom-axios";
const RESOURCE_NAME = "/admin";
const RESOURCE_NAME2 = "/authentication";

export default {
  login(body) {
    return axios.post(`${RESOURCE_NAME}/login`, body);
  },
  logout() {
    return axios.get(`${RESOURCE_NAME2}/logout`);
  },
  forgotPassword(body) {
    return axios.post(`${RESOURCE_NAME}/ForgotPassword`, body);
  },
  getProfile(id) {
    return axios.get(`${RESOURCE_NAME}/Id`, { params: { id: id } });
  },
  updateMyProfile(body) {
    return axios.put(`${RESOURCE_NAME}/profile`, body);
  },
  getCurrentSellerAddress() {
    return axios.get(`/selleraddress`);
  },
  updateCurrentSellerAddress(body) {
    return axios.put(`/selleraddress`, body);
  },
  createCurrentSellerAddress(body) {
    return axios.post(`/selleraddress`, body);
  },
  deleteUser(id) {
    return axios.delete(`${RESOURCE_NAME}/deleteuser/${id}`);
  },
  updateUser(body) {
    return axios.put(`${RESOURCE_NAME}/updateuser/true`, body);
  },
  updateUserPermissions(body) {
    return axios.put(`${RESOURCE_NAME}/updateuserpermission`, body);
  },
  rankDownUser(id) {
    return axios.put(`${RESOURCE_NAME}/rankdownuser/${id}`);
  },
  getRedirectPages() {
    return axios.get(`${RESOURCE_NAME}/pages/direct`);
  },
};
