<template>
  <div>
    <!-- Custom Field modal dialog start-->
    <v-dialog persistent v-model="customFieldDetailStatus" max-width="500">
      <v-card>
        <v-card-title class="headline indigo lighten-2">
          <div class="text-center white--text" style="width: 100%">
            {{ this.modalHeader }}
          </div>
        </v-card-title>
        <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
          <form @submit.prevent="handleSubmit(sendForm)">
            <v-card-text class="py-3">
              <v-row>
                <v-col md="12" xs="12">
                  <!-- Module select box start -->
                  <v-select
                    v-model="selectedModuleModal"
                    :items="allModules"
                    :label="$t('description.selectModule')"
                    item-text="Name"
                    item-value="Key"
                    :disabled="editItemId != null"
                  ></v-select>
                  <!-- Module select box end -->
                  <!-- Custom Field Type select box start -->
                  <v-select
                    v-model="selectedDataType"
                    :items="dataTypes"
                    :label="$t('labels.selectboxType')"
                    item-text="Name"
                    item-value="Code"
                    :disabled="editItemId != null"
                  ></v-select>
                  <!-- Custom Field Type select box end -->
                  <!-- Custom Field Display Name text field start -->
                  <ValidationProvider
                    name="Görüntülenecek Ad"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      v-model="customFieldModel.DisplayName"
                      :value="customFieldModel.DisplayName"
                      :label="$t('title.tableHeaderDisplayName')"
                      :error-messages="errors[0]"
                    ></v-text-field>
                  </ValidationProvider>
                  <!-- Custom Field Display Name text field end -->
                  <!-- Custom Field Name text field start -->
                  <ValidationProvider
                    name="Alan Adı"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      v-model="customFieldModel.Name"
                      :value="customFieldModel.Name"
                      :disabled="editItemId != null"
                      :label="$t('labels.inputName')"
                      :error-messages="errors[0]"
                    ></v-text-field>
                  </ValidationProvider>
                  <!-- Custom Field Name text field end -->
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-actions
              style="
                background-color: white;
                position: sticky;
                bottom: 0px;
                border-top: solid grey 2px;
              "
            >
              <v-spacer></v-spacer>
              <v-btn color="primary" type="submit" :loading="loadings.send">
                {{ $t("buttons.save") }}
              </v-btn>
              <v-btn
                color="primary"
                text
                @click="
                  customFieldDetailStatus = false;
                  clearModal();
                "
              >
                {{ $t("buttons.close") }}
              </v-btn>
            </v-card-actions>
          </form>
        </ValidationObserver>
      </v-card>
    </v-dialog>
    <!-- Custom Field modal dialog end-->
    <!-- Custom Field deletion modal start -->
    <delete-modal :yesFunc="deleteItem" ref="deleteModalRef" />
    <!-- Custom Field deletion modal end -->
    <!-- Main page content start -->
    <v-card style="border-radius: 10px">
      <!-- Card title start -->
      <v-card-title style="color: #595959">
        <v-row>
          <v-col class="py-2">{{ $t("title.titleCustomFields") }}</v-col>
          <v-spacer />
          <v-btn
            small
            class="ma-2 mr-4"
            elevation="0"
            outlined
            color="info"
            @click="
              customFieldDetailStatus = true;
              modalHeader = $t('title.customFieldCreate');
            "
          >
            {{ $t("buttons.addNew") }}
          </v-btn>
        </v-row>
      </v-card-title>
      <!-- Card title end -->
      <v-divider />
      <!-- Card content start -->
      <v-card-text class="pt-4">
        <v-row align="center" justify="space-between">
          <v-col cols="3">
            <v-select
              v-model="selectedModule"
              :items="allModules"
              :label="$t('description.selectModule')"
              item-text="Name"
              item-value="Key"
              @change="filterCustomField()"
            ></v-select>
          </v-col>
        </v-row>

        <v-data-table
          :headers="headers"
          :items="tableData"
          :loading="loadings.table"
          :options.sync="options"
          :footer-props="{ 'items-per-page-options': [5, 10, 15, 30] }"
          :no-data-text="$t('description.selectModule')"
        >
          <!-- eslint-disable -->
          <template v-slot:item.CreatedAt="{ item }">
            {{ convertToLocal(item.CreatedAt) }}
          </template>

          <template v-slot:item.Icon="{ item }">
            <v-icon>{{ item.Icon }}</v-icon>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  class="mr-2"
                  @click="getCustomFieldDetails(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-pencil
                </v-icon>
              </template>
              <span>{{ $t("labels.tooltipEdit") }}</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  class="mr-2"
                  @click="openDeleteModal(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-delete
                </v-icon>
              </template>
              <span>{{ $t("labels.tooltipDelete") }}</span>
            </v-tooltip>
          </template>

          <template v-slot:item.Status="{ item }">
            <v-chip v-if="item.Status == 1" class="ma-2" small color="primary">
              {{ $t("labels.tooltipActive") }}
            </v-chip>
            <v-chip v-if="item.Status == 0" class="ma-2" small color="primary">
              {{ $t("labels.tooltipPassive") }}
            </v-chip>

            <v-chip v-if="item.Status == 10" class="ma-2" small color="primary">
              {{ $t("labels.tooltipReview") }}
            </v-chip>

            <v-chip v-if="item.Status == 20" class="ma-2" small color="primary">
              {{ $t("labels.tooltipPublished") }}
            </v-chip>

            <v-chip
              v-if="item.Status == -20"
              class="ma-2"
              small
              color="primary"
            >
              {{ $t("labels.tooltipReject") }}
            </v-chip>
          </template>
        </v-data-table>
      </v-card-text>
      <!-- Card content end -->
    </v-card>
    <!-- Main page content end -->
  </div>
</template>

<script>
import ContentService from "../services/ContentService";
import ModuleService from "../services/ModuleService";
import CustomFieldService from "../services/CustomFieldService";
import DeleteModal from "../components/General/DeleteModal";

export default {
  data: () => ({
    // For table listing
    options: {
      itemsPerPage: 15,
    },
    top: 15,
    skip: 0,
    tableData: [],
    headers: [],

    //Page loaders
    loadings: {
      table: false,
      send: false,
      select: false,
    },

    //Page models and flags
    moduleDetail: {},
    customFieldModel: {},
    customFieldDetailStatus: false,
    modalHeader: "",
    selectedModule: "",
    selectedModuleModal: "",
    selectedDataType: "",
    allModules: [],
    editItemId: null,
    deleteItemModel: {},
    dataTypes: [],
  }),
  watch: {},
  mounted() {
    //If page is routed from moduleManagement page get responsive ID
    if (this.$route.query.key) {
      this.getCustomFields();
    }
    this.setup();
  },
  components: {
    DeleteModal,
  },
  methods: {
    //Setting up page on mount
    setup() {
      this.getModuls();
      this.modalHeader = this.$t("title.createSurvey");
      this.headers = [
        {
          text: this.$t("title.tableHeaderDisplayName"),
          value: "DisplayName",
          sortable: false,
          align: "start",
        },
        {
          text: this.$t("title.tableHeaderCustomFieldModuleName"),
          value: "Module.Name",
          sortable: false,
        },
        {
          text: this.$t("title.tableHeaderCustomFieldName"),
          value: "Name",
          sortable: false,
        },
        {
          text: this.$t("title.tableHeaderDataType"),
          value: "DataType",
          sortable: false,
        },
        {
          text: this.$t("title.tableHeaderActions"),
          value: "actions",
          sortable: false,
        },
      ];
      this.customFieldModel = {
        Name: "",
        DisplayName: "",
        Key: "",
        DataType: "",
        Status: "",
      };
      this.dataTypes = [
        {
          Name: "Metin",
          Code: "string",
        },
        {
          Name: "Sayı",
          Code: "number",
        },
        {
          Name: "Tarih",
          Code: "date_time",
        },
      ];
    },
    //Clears all modals and references
    clearModal() {
      this.customFieldModel = {
        Name: "",
        DisplayName: "",
        Key: "",
        DataType: "",
        Status: "",
      };
      this.modalHeader = "";
      this.$refs.observer.reset();
      this.editItemId = null;
      this.selectedModuleModal = "";
      this.selectedDataType = "";
    },
    //Get all moduls on page create
    getModuls() {
      this.loadings.select = true;
      ModuleService.getPages()
        .then((res) => {
          this.allModules = res.data;
        })
        .catch((err) => {
          window.handleServiceError(res, err);
        })
        .then(() => {
          this.loadings.select = false;
        });
    },
    //Gets main page content table by id or default
    getCustomFields(key = "") {
      this.loadings.table = true;
      this.tableData = [];
      let body = {};
      //If id doesn't exist get filtered module
      if (key.length < 1) {
        this.selectedModule = this.$route.query.key;
        this.selectedModuleModal = this.selectedModule;
        body = {
          key: this.selectedModule,
        };
        CustomFieldService.getCustomFields(body)
          .then((res) => {
            this.moduleDetail = res.data;
            res.data.forEach((element) => {
              if (element.DeletedAt == null) {
                this.tableData.push(element);
              }
            });
          })
          .catch((err) => {
            window.handleServiceError(res, err);
          })
          .then(() => {
            this.loadings.table = false;
          });
      }
      //Filter operation by selected module
      else {
        body = {
          key: key,
        };
        CustomFieldService.getCustomFields(body)
          .then((res) => {
            this.moduleDetail = res.data;
            res.data.forEach((element) => {
              if (element.DeletedAt == null) {
                this.tableData.push(element);
              }
            });
          })
          .catch((err) => {
            window.handleServiceError(res, err);
          })
          .then(() => {
            this.loadings.table = false;
          });
      }
    },
    //Opens modal for deletion operation
    openDeleteModal(item) {
      this.deleteItemModel = item;
      this.$refs.deleteModalRef.modalStatus = true;
    },
    //Delete operation
    deleteItem() {
      this.loadings.table = true;
      CustomFieldService.deleteCustomField(this.deleteItemModel.Id)
        .then((res) => {
          this.tableData = [];
          this.getCustomFields(this.selectedModule);
          this.$refs.deleteModalRef.modalStatus = false;
        })
        .catch((err) => {
          window.handleServiceError(res, err);
        })
        .then(() => {
          this.loadings.table = false;
        });
    },
    //Gets details of clicked item and prepare model
    getCustomFieldDetails(item) {
      this.modalHeader = this.$t("title.customFieldEdit");
      this.editItemId = item.Id;
      this.selectedDataType = item.DataType;
      this.selectedModuleModal = item.Key;
      this.customFieldModel = {
        Key: this.selectedModuleModal,
        DataType: this.selectedDataType,
        DisplayName: item.DisplayName,
        Name: item.Name,
        Status: 20,
      };
      this.customFieldDetailStatus = true;
    },
    //Sends formm and starts operation update or create
    sendForm() {
      if (this.editItemId) {
        this.updateCustomField();
      } else {
        this.createCustomField();
      }
    },
    //Update operation
    updateCustomField() {
      let id = this.editItemId;
      let body = {
        Name: this.customFieldModel.Name,
        DisplayName: this.customFieldModel.DisplayName,
      };

      this.loadings.send = true;
      CustomFieldService.updateCustomField(id, body)
        .then((res) => {
          this.tableData = [];
          this.getCustomFields(this.selectedModule);
          this.clearModal();
          this.customFieldDetailStatus = false;
        })
        .catch((err) => {
          window.handleServiceError(res, err);
        })
        .then(() => {
          this.loadings.send = false;
        });
    },
    //Create operation
    createCustomField() {
      this.customFieldModel.DataType = this.selectedDataType;
      this.customFieldModel.Key = this.selectedModuleModal;

      let body = {
        Name: this.customFieldModel.Name,
        DisplayName: this.customFieldModel.DisplayName,
        Key: this.customFieldModel.Key,
        DataType: this.customFieldModel.DataType,
        Status: 20,
      };

      this.loadings.send = true;
      CustomFieldService.createCustomField(body)
        .then((res) => {
          this.tableData = [];
          this.getCustomFields(this.selectedModule);
          this.customFieldDetailStatus = false;
          this.clearModal();
        })
        .catch((err) => {
          window.handleServiceError(res, err);
        })
        .then(() => {
          this.loadings.send = false;
        });
    },
    //Filter table by module selection
    filterCustomField() {
      this.allModules.forEach((element) => {
        if (element.Key == this.selectedModule) {
          this.selectedModuleModal = element.Key;
        }
      });
      this.getCustomFields(this.selectedModule);
    },

  },
};
</script>
