<template>
  <div>
    <!-- User creation modal -->
    <v-dialog persistent v-model="userModalStatus" max-width="800">
      <v-card>
        <v-card-title class="headline indigo lighten-2">
          <div class="text-center white--text" style="width: 100%">
            {{ this.modalHeader }}
          </div>
        </v-card-title>

        <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
          <form @submit.prevent="handleSubmit(sendForm)">
            <v-card-text class="py-3">
              <v-row>
                <v-col md="6" xs="6">
                  <ValidationProvider
                    name="Type"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <v-select
                      v-model="userModel.Type"
                      :items="userTypes"
                      item-text="Name"
                      item-value="Code"
                      :label="$t('labels.userType')"
                      :error-messages="errors[0]"
                    ></v-select>
                  </ValidationProvider>

                  <ValidationProvider
                    name="Username"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      v-model="userModel.Username"
                      :value="userModel.Username"
                      :label="$t('labels.userUsername')"
                      :rules="nameRules"
                      :error-messages="errors[0]"
                    ></v-text-field>
                  </ValidationProvider>

                  <ValidationProvider
                    name="Password"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      v-model="userModel.Password"
                      :value="userModel.Password"
                      :type="'password'"
                      :label="$t('labels.userPassword')"
                      :error-messages="errors[0]"
                    ></v-text-field>
                  </ValidationProvider>

                  <ValidationProvider
                    name="Name"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      v-model="userModel.FirstName"
                      :value="userModel.FirstName"
                      :label="$t('labels.userFirstName')"
                      :error-messages="errors[0]"
                    ></v-text-field>
                  </ValidationProvider>

                  <ValidationProvider
                    name="LastName"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      v-model="userModel.LastName"
                      :value="userModel.LastName"
                      :label="$t('labels.userLastname')"
                      :error-messages="errors[0]"
                    ></v-text-field>
                  </ValidationProvider>

                  <ValidationProvider
                    name="Email"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      v-model="userModel.Email"
                      :value="userModel.Email"
                      :rules="emailRules"
                      :label="$t('labels.userEmail')"
                      :error-messages="errors[0]"
                    ></v-text-field>
                  </ValidationProvider>

                  <ValidationProvider
                    name="Phone"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      v-model="userModel.Phone"
                      :value="userModel.Phone"
                      :label="$t('labels.userPhone')"
                      :error-messages="errors[0]"
                    ></v-text-field>
                  </ValidationProvider>

                  <ValidationProvider
                    name="Status"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <v-select
                      v-model="userModel.Status"
                      :items="userStatusItems"
                      item-text="Name"
                      item-value="Code"
                      :label="$t('labels.userStatus')"
                      :error-messages="errors[0]"
                    ></v-select>
                  </ValidationProvider>

                  <v-textarea
                    outlined
                    v-model="userModel.About"
                    :value="userModel.About"
                    :label="$t('labels.userAbout')"
                  ></v-textarea>
                </v-col>
                <v-col md="6" xs="6">
                  <v-col>
                    <v-row
                      v-for="(element, index) in filteredDataSelect"
                      :key="index"
                    >
                      <v-select
                        v-model="createdModelGroupIds[element.type]"
                        :items="element.groups"
                        @change="changeFilter(element.type)"
                        @click="clickFilter(element)"
                        item-text="name"
                        item-value="id"
                        :loading="loadings.select"
                        :label="element.name"
                      ></v-select>
                    </v-row>
                  </v-col>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-actions
              style="
                background-color: white;
                position: sticky;
                bottom: 0px;
                border-top: solid grey 2px;
              "
            >
              <v-spacer></v-spacer>
              <v-btn color="primary" type="submit" :loading="loadings.send">
                {{ $t("buttons.save") }}
              </v-btn>
              <v-btn
                color="primary"
                text
                @click="
                  userModalStatus = false;
                  clearModal();
                "
              >
                {{ $t("buttons.close") }}
              </v-btn>
            </v-card-actions>
          </form>
        </ValidationObserver>
      </v-card>
    </v-dialog>
    <!-- User permission update modal -->
    <delete-modal
      :yesFunc="updateUserPerms"
      :title="this.modalHeader"
      :description="this.modalDescription"
      ref="updateModalRef"
    />
    <!-- User rank down modal -->
    <delete-modal
      :yesFunc="removeAdminPerms"
      :title="this.modalHeader"
      :description="this.modalDescription"
      ref="rankDownModalRef"
    />
    <!-- Page content of users -->
    <v-card style="border-radius: 10px">
      <v-card-title style="color: #595959">
        <v-row>
          <!-- User title -->
          <v-col v-if="!isDetails" class="py-2">{{ $t("title.users") }}</v-col>
          <!-- User details title -->
          <v-col v-if="isDetails" class="py-2">{{
            $t("title.userDetails")
          }}</v-col>
        </v-row>
      </v-card-title>

      <v-divider />
      <!-- Main page content -->
      <v-card-text v-if="!isDetails" class="pt-4">
        <v-row align="center" justify="space-between">
          <v-col cols="3">
            <v-select
              v-model="selectedUserType"
              :items="userTypes.concat([{ Name: 'Tümü', Code: '' }])"
              item-text="Name"
              item-value="Code"
              @change="filterUserByType()"
            >
            </v-select>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              @click:append="searchTable"
              v-on:keyup.enter="searchTable"
              @click:clear="searchTable('clear')"
              :label="$t('labels.userSearch')"
              clear-icon="mdi-close-circle"
              clearable
            ></v-text-field>
          </v-col>
          <v-col cols="4"></v-col>
          <v-col cols="2" align="end">
             <v-select
              v-model="itemsPerPage"
              :items="perPageValues"
              :label="$t('labels.selectItemsPerPage')"
              item-value="value"
              item-text="name"
              @change="changeItemsPerPage()"
            >
            </v-select>
          </v-col>


          <!-- Disabled user create button -->
          <v-col align="end">
            <v-btn
              small
              class="ma-2 mr-4"
              elevation="0"
              outlined
              color="info"
              v-if="false"
              @click="
                userModalStatus = true;
                modalHeader = $t('title.userModalCreate');
              "
            >
              {{ $t("buttons.addNew") }}
            </v-btn>
          </v-col>
        </v-row>
        <v-data-table
          :headers="headers"
          :items="tableData"
          :loading="loadings.table"
          :options.sync="options"
          :hide-default-footer="true"
           :footer-props="{ disablePagination: true, disableItemsPerPage: true }"
          :no-data-text="$t('description.noList')"
        >
          <!-- eslint-disable -->

          <template v-slot:item.CreatedAt="{ item }">
            {{ convertToLocal(item.CreatedAt) }}
          </template>

          <!-- eslint-disable -->
          <template v-slot:item.Avatar="{ item }">
            <v-avatar>
              <img :src="item.Avatar" size="56" />
            </v-avatar>
          </template>

          <template v-slot:item.Username="{ item }">
            <div style="font-weight: bold !important;">{{ item.Username }}</div>
          </template>

          <template v-slot:item.Email="{ item }">
            {{
              item.Email !== null && item.Email.length > 1  ? item.Email : "Eposta Bulunamadı"
            }}
          </template>

          <template v-slot:item.Type="{ item }">
            {{
              item.Type == "SuperAdmin"
                ? "Süper Admin"
                : item.Type == "Admin"
                ? "Admin"
                : "Standart"
            }}
          </template>

          <template v-slot:item.actions="{ item }">
            <div>
              <v-col>
                <v-row
                  v-if="item.Type == 'SuperAdmin' || item.Type == 'Admin'"
                  class="pl-3"
                  align="center"
                  justify="center"
                >
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="mr-2"
                        @click="getUserDetails(item)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-pencil
                      </v-icon>
                    </template>
                    <span>{{ $t("labels.authorizeUser") }}</span>
                  </v-tooltip>
                </v-row>
                <v-row
                  v-if="item.Type == 'standart'"
                  class="pl-3"
                  align="center"
                  justify="center"
                >
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="mr-2"
                        @click="
                          getUserDetails(item);
                          makeAdminStatus = true;
                        "
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-auto-fix
                      </v-icon>
                    </template>
                    <span>{{ $t("labels.makeAdmin") }}</span>
                  </v-tooltip>
                </v-row>
              </v-col>
            </div>
          </template>
        </v-data-table>
          <div class="text-xs-center pt-2 md4 lg4">
          <v-pagination
            @input="paginationChangeHandler"
            :value="pagination.page"
            :length="pages"
            :total-visible="10"
            circle
          ></v-pagination>
        </div>
      </v-card-text>
      <!-- Details of selected authorized user-->
      <v-card-text v-if="isDetails && !makeAdminStatus" class="pt-4">
        <v-row align="center" justify="space-between">
          <v-col align="start">
            <v-btn
              small
              class="ma-2 mr-4"
              elevation="0"
              outlined
              color="info"
              @click="goBack()"
            >
              <v-icon dark>
                mdi-arrow-left-bold
              </v-icon>
            </v-btn>
          </v-col>
          <v-col align="end">
            <v-btn
              small
              class="ma-2 mr-4"
              v-if="allSelect"
              elevation="0"
              outlined
              color="info"
              @click="changeAllPermissions(true)"
            >
              {{ $t("buttons.allPermissionSelect") }}
            </v-btn>
            <v-btn
              small
              class="ma-2 mr-4"
              v-if="!allSelect"
              elevation="0"
              outlined
              color="info"
              @click="changeAllPermissions(false)"
            >
              {{ $t("buttons.allPermissionDeselect") }}
            </v-btn>
            <v-btn
              small
              class="ma-2 mr-4"
              elevation="0"
              outlined
              color="info"
              @click="openRankDownModal()"
            >
              {{ $t("buttons.removeAdminPermissions") }}
            </v-btn>
          </v-col>
        </v-row>
        <v-data-table
          id="authTable"
          :headers="headersAuth"
          :items="currentModules"
          item-key="key"
          :loading="loadings.perm"
          :options="permOptions"
          ref="permTable"
        >
          <!-- eslint-disable -->
          <template v-slot:item.read="{ item, index }">
            <v-checkbox
              v-model="currentModules[index].read"
              :input-value="currentModules[index].read"
              hide-details
              color="#7443a0"
            >
            </v-checkbox>
          </template>

          <!-- eslint-disable -->
          <template v-slot:item.write="{ item, index }">
            <v-checkbox
              v-model="currentModules[index].write"
              :input-value="currentModules[index].write"
              hide-details
              color="#7443a0"
            >
            </v-checkbox>
          </template>

          <!-- eslint-disable -->
          <template v-slot:item.approve="{ item, index }">
            <v-checkbox
              v-model="currentModules[index].approve"
              :input-value="currentModules[index].approve"
              hide-details
              color="#7443a0"
            >
            </v-checkbox>
          </template>
        </v-data-table>
        <v-card-actions class="justify-center">
          <v-btn
            color="primary"
            class="mr-4"
            @click="openPermUpdateModal()"
            :loading="loadings.send"
          >
            {{ $t("buttons.save") }}
          </v-btn>
        </v-card-actions>
      </v-card-text>
      <!-- Details of selected unauthorized user-->
      <v-card-text v-if="isDetails && makeAdminStatus" class="pt-4">
        <v-row align="center" justify="center" class="mt-4">
          <div>
            <v-row>
              <p class="font-weight-black blue--text">
                {{ inspectedItem.FirstName }} {{ inspectedItem.LastName }}
                &nbsp;
              </p>

              <p class="black--text">
                {{ $t("description.authorizationUserText1") }} &nbsp;
              </p>

              <p class="font-weight-black blue--text">
                {{ $t("labels.makeAdmin") }} &nbsp;
              </p>
              <p class="black--text">
                {{ $t("description.authorizationUserText2") }}
              </p>
            </v-row>
          </div>
        </v-row>
        <v-row align="start" justify="space-between">
          <v-col>
            <v-btn
              small
              class="ma-2 mr-4"
              elevation="0"
              outlined
              color="info"
              @click="goBack()"
            >
              <v-icon dark>
                mdi-arrow-left-bold
              </v-icon>
            </v-btn>
          </v-col>
          <v-col align="end">
            <v-btn
              small
              class="ma-2 mr-4"
              elevation="0"
              outlined
              color="info"
              @click="openRankDownModal()"
            >
              {{ $t("buttons.removeAdminPermissions") }}
            </v-btn>
          </v-col>
        </v-row>
        <v-data-table
          id="authTable"
          :headers="headersAuth"
          :items="currentModules"
          item-key="key"
          :loading="loadings.perm"
          :options.sync="permOptions"
          ref="permTable"
        >
          <!-- eslint-disable -->
          <template v-slot:item.read="{ item, index }">
            <v-checkbox
              v-model="currentModules[index].read"
              :input-value="currentModules[index].read"
              hide-details
              color="#7443a0"
            >
            </v-checkbox>
          </template>

          <!-- eslint-disable -->
          <template v-slot:item.write="{ item, index }">
            <v-checkbox
              v-model="currentModules[index].write"
              :input-value="currentModules[index].write"
              hide-details
              color="#7443a0"
            >
            </v-checkbox>
          </template>

          <!-- eslint-disable -->
          <template v-slot:item.approve="{ item, index }">
            <v-checkbox
              v-model="currentModules[index].approve"
              :input-value="currentModules[index].approve"
              hide-details
              color="#7443a0"
            >
            </v-checkbox>
          </template>
        </v-data-table>
        <v-card-actions class="justify-center">
          <v-btn
            class="mr-4"
            @click="openPermUpdateModal()"
            :loading="loadings.send"
          >
            {{ $t("buttons.makeAdmin") }}
          </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import DeleteModal from "../components/General/DeleteModal.vue";
import UserService from "../services/UserService";
import GroupService from "../services/GroupService";
import ModuleService from "../services/ModuleService";
import AccountService from "../services/AccountService";
import { mapActions } from "vuex";
export default {
  data: () => ({
    //List options
    options: {
      itemsPerPage: 15,
    },
    permOptions: {
      itemsPerPage: -1,
    },
    top: 15,
    skip: 0,
    tableData: [],
    userStatusItems: [],
    userTypes: [],
    //Table contents
    headers: [],
    headersAuth: [],
    //Page loaders
    loadings: {
      table: false,
      send: false,
      select: false,
      perm: false,
    },
    //Initial models
    userModalStatus: false,
    userModel: {
      Username: "",
      Email: "",
      Password: "",
      Phone: "",
      FirstName: "",
      LastName: "",
      Type: "",
      About: "",
      GroupIds: [],
    },
    currentModules: [],
    modalHeader: "",
    modalDescription: "",
    selectedUserType: "",
    isDetails: false,
    makeAdminStatus: false,
    inspectedItem: {},
    filteredDataSelect: [],
    filteredModel: {},
    createdModelGroupIds: {},
    updatedModelGroupIds: {},
    nameRules: [
      (v) => !!v || "Kullanıcı adı zorunludur.",
      (v) =>
        (v && v.length >= 6) || "Kullanıcı adı 6 karakterden fazla olmalı.",
    ],
    emailRules: [
      (v) => !!v || "Mail adresi zorunludur.",
      (v) => /.+@.+\..+/.test(v) || "Geçerli bir mail adresi girin.",
    ],
    permTitles: [
      {
        name: "Read",
        value: false,
        code: "read",
        index: 0,
      },
      {
        name: "Write",
        value: false,
        code: "write",
        index: 1,
      },
      {
        name: "Approve",
        value: false,
        code: "approve",
        index: 2,
      },
    ],
    selectedPerms: {
      Id: "",
      Permissions: [],
    },
    groupIds: [],
    groupCodes: [],
    userSearchText: "",
    search: "",
    allSelect: true,
    pagination: {},

      itemsPerPage: 15,
          perPageValues: [
      {
        name:"10",
        value:10
      },
       {
        name:"15",
        value:15
      },
       {
        name:"30",
        value:30
      },
       {
        name:"50",
        value:50
      },
       {
        name:"100",
        value:100
      },

    ]
  }),
  watch: {},
  computed:{
      totalRecords() {
      return this.tableData.length;
    },
    pageCount() {
      return this.totalRecords / this.itemsPerPage;
    },
        pages(val) {

      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      ) {
        return 0;
      }

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
  },
  mounted() {
    this.setup();
  },
  components: {
    DeleteModal,
  },
  methods: {
    //Sets up the page on mount
    setup() {
      this.modalHeader = this.$t("title.userModalCreate");
      this.headers = [
        {
          text: this.$t("title.tableHeaderProfilePic"),
          value: "Avatar",
          sortable: false,
        },
        {
          text: this.$t("labels.sicilNumber"),
          value: "Username",
          sortable: false,
        },
        {
          text: this.$t("title.tableHeaderName"),
          align: "start",
          sortable: false,
          value: "FirstName",
        },
        {
          text: this.$t("title.tableHeaderLastName"),
          value: "LastName",
          sortable: false,
        },
        {
          text: this.$t("title.tableHeaderEmail"),
          value: "Email",
          sortable: false,
        },
        {
          text: this.$t("title.tableHeaderUserType"),
          value: "Type",
          sortable: false,
        },
        {
          text: this.$t("title.tableHeaderCreateDate"),
          value: "CreatedAt",
          sortable: false,
        },
        {
          text: this.$t("title.tableHeaderActions"),
          value: "actions",
          sortable: false,
        },
          (this.pagination = {
          totalItems: 200,
          rowsPerPage: this.itemsPerPage,
          page: 1,
        }),
      ];
      this.headersAuth = [
        {
          text: this.$t("title.tableHeaderModules"),
          align: "start",
          sortable: false,
          value: "name",
        },
        {
          text: this.$t("title.tableHeaderRead"),
          value: "read",
          align: "center",
          sortable: false,
        },
        {
          text: this.$t("title.tableHeaderWrite"),
          value: "write",
          align: "center",
          sortable: false,
        },
        {
          text: this.$t("title.tableHeaderApprove"),
          value: "approve",
          align: "start",
          sortable: false,
        },
      ];
      this.nameRules = [
        (v) => !!v || "Kullanıcı adı zorunludur.",
        (v) =>
          (v && v.length >= 6) || "Kullanıcı adı 6 karakterden fazla olmalı.",
      ];
      this.emailRules = [
        (v) => !!v || "Mail adresi zorunludur.",
        (v) => /.+@.+\..+/.test(v) || "Geçerli bir mail adresi girin.",
      ];
      this.userTypes = [
        {
          Name: "Süper Admin",
          Code: "SuperAdmin",
        },
        {
          Name: "Admin",
          Code: "Admin",
        },
        {
          Name: "Standart",
          Code: "Standart",
        },
      ];
      this.userStatusItems = [
        {
          Name: "Aktif",
          Status: 1,
        },
        {
          Name: "Pasif",
          Status: 0,
        },
      ];
      this.isDetails = false;
      this.selectedPerms = {
        Id: "",
        Permissions: [],
      };
      this.getTableList();
      this.getModules();
    },
    //Converts date data to local time
    DateTime(date) {
      return window.convertToLocal(date);
    },
    //Refreshes the page but user will see the default page content > UI Trick
    goBack() {
      window.location.reload();
    },
    //Selects all permission checkboxes
    changeAllPermissions(key) {
      this.currentModules.forEach((element) => {
        element.approve = key;
        element.read = key;
        element.write = key;
      });
      this.allSelect = !this.allSelect;
    },
    //Loads page list
    getTableList() {
      this.loadings.table = true;
      this.tableData = [];
      UserService.getUsers(this.search,this.pagination.rowsPerPage,
          this.pagination.page,this.selectedUserType)
        .then((res) => {

          res.data.Data.forEach((element) => {
            if (element.DeletedAt == null) {
              this.tableData.push(element);
            } else {
            }
          });
            this.pagination.totalItems = res.data.TotalData;
          this.getGroupTypes();
        })
        .catch((err) => {
          if (res.status == 401) {
            window.showInfo(
              this.$t("description.authorizationError"),
              "standart",
              "info"
            );
            this.logout();
          } else {
            window.showError(err);
          }
        })
        .then(() => {
          this.loadings.table = false;
        });
    },
    //Loads current modules
    getModules() {
      ModuleService.getPages()
        .then((res) => {
          res.data.forEach((element) => {
            let obj = {
              id: element.Id,
              key: element.Key,
              name: element.Name,
            };
            this.currentModules.push(obj);
          });
        })
        .catch((err) => {
          if (res.status == 401) {
            window.showInfo(
              this.$t("description.authorizationError"),
              "standart",
              "info"
            );
            this.logout();
          } else {
            window.showError(err);
          }
        })
        .then(() => {});
    },
    //Gets all group types on page load
    getGroupTypes() {
      this.loadings.table = true;
      this.filteredDataSelect = [];
      GroupService.getGroupTypes()
        .then((res) => {
          res.data.forEach((element) => {
            let obj = {
              type: element.Code,
              name: element.Name,
              groups: [],
            };
            this.filteredDataSelect.push(obj);
          });
        })
        .catch((err) => {
          if (res.status == 401) {
            window.showInfo(
              this.$t("description.authorizationError"),
              "standart",
              "info"
            );
            this.logout();
          } else {
            window.showError(err);
          }
        })
        .then(() => {
          this.loadings.table = false;
        });
    },
    //Search Operation
    searchTable(type = "search") {
      type == "clear" ? (this.search = "") : null;
      this.getTableList();
    },
    //Logs the filter item for each select item > Filters table
    changeFilter(element) {
      if (this.userModalStatus) {
        this.userModel.GroupIds.push(this.createdModelGroupIds[element]);
      } else {
        let index = this.groupCodes.indexOf(element);
        if (index == -1) {
          this.groupCodes.push(element);
          this.groupIds.push(this.filteredModel[element]);
        } else {
          for (let i = 0; i < this.groupCodes.length; i++) {
            if (this.groupCodes[i] == element) {
              this.groupIds[i] = this.filteredModel[element];
            }
          }
        }
        this.filterUser();
      }
    },
    //When a filter is clicked, this method gets dynamic data for the select item
    clickFilter(element) {
      let index = this.filteredDataSelect.findIndex(
        (x) => x.type == element.type
      );

      if (this.filteredDataSelect[index].groups.length == 0) {
        GroupService.getGroups(element.type)
          .then((res) => {
            let arr = [];
            res.data.forEach((item) => {
              let obj = {
                id: item.Id,
                name: item.Name,
              };
              arr.push(obj);
            });
            this.filteredDataSelect[index].groups = arr;
          })
          .catch((err) => {
            if (res.status == 401) {
            window.showInfo(
              this.$t("description.authorizationError"),
              "standart",
              "info"
            );
            this.logout();
          } else {
            window.showError(err);
          }
          })
          .then(() => {});
      }
    },
    //Filters user table by user type
    filterUserByType() {
      this.loadings.table = true;
      this.tableData = [];

      UserService.getUsersByType(this.selectedUserType,this.pagination.rowsPerPage,
          this.pagination.page)
        .then((res) => {
          res.data.Data.forEach((element) => {
            if (element.DeletedAt == null) {
              this.tableData.push(element);
            }
          });
              this.pagination.totalItems = res.data.TotalData

        })
        .catch((err) => {
          if (res.status == 401) {
            window.showInfo(
              this.$t("description.authorizationError"),
              "standart",
              "info"
            );
            this.logout();
          } else {
            window.showError(err);
          }
        })
        .then(() => {
          this.loadings.table = false;
        });
    },
    //List filter for the user
    filterUser() {
      this.loadings.table = true;
      this.tableData = [];
      UserService.getUsers(this.groupIds)
        .then((res) => {
          res.data.forEach((element) => {
            if (element.DeletedAt == null) {
              this.tableData.push(element);
            }
          });
        })
        .catch((err) => {
         if (res.status == 401) {
            window.showInfo(
              this.$t("description.authorizationError"),
              "standart",
              "info"
            );
            this.logout();
          } else {
            window.showError(err);
          }
        })
        .then(() => {
          this.loadings.table = false;
        });
    },
    //Updates, creates or removes user permisssions
    updateUserPerms() {
      this.currentModules.forEach((element) => {
        let obj = {
          PageId: element.id,
          Approve: element.approve,
          Write: element.write,
          Read: element.read,
        };
        this.selectedPerms.Permissions.push(obj);
      });

      this.loadings.send = true;
      AccountService.updateUserPermissions(this.selectedPerms)
        .then((res) => {
          this.$refs.updateModalRef.modalStatus = false;
          // this.$refs.permTable.reload;
          window.location.reload();
        })
        .catch((err) => {
          if (res.status == 401) {
            window.showInfo(
              this.$t("description.authorizationError"),
              "standart",
              "info"
            );
            this.logout();
          } else {
            window.showError(err);
          }
        })
        .then(() => {
          this.loadings.send = false;
        });
    },
    //Ranks down the admins
    removeAdminPerms() {
      this.loadings.perm = true;
      AccountService.rankDownUser(this.inspectedItem.Id)
        .then((res) => {
          this.$refs.rankDownModalRef.modalStatus = false;
          window.location.reload();
        })
        .catch((err) => {
          if (res.status == 401) {
            window.showInfo(
              this.$t("description.authorizationError"),
              "standart",
              "info"
            );
            this.logout();
          } else {
            window.showError(err);
          }
        })
        .then(() => {
          this.loadings.perm = false;
        });
    },
    //Handles the item that will be displayed in details
    getUserDetails(item) {
      console.log("Detay item", item);
      this.loadings.table = true;
      UserService.getUserDetails(item.Id)
        .then((res) => {
          this.inspectedItem = res.data.Data[0];
          this.selectedPerms.Id = this.inspectedItem.Id;

          //Re-arrange current module objects for permission table
          this.currentModules.forEach((element, index) => {
            var filter = item.Permissions.filter(
              (x) => x.PageId == element.id
            )[0];
            let obj = {
              id: element.id,
              key: element.key,
              name: element.name,
              read: filter != null ? filter.Read : false,
              write: filter != null ? filter.Write : false,
              approve: filter != null ? filter.Approve : false,
            };
            this.currentModules[index] = obj;
          });
          // console.log("After permissions >", this.currentModules);
        })
        .catch((err) => {
          if (res.status == 401) {
            window.showInfo(
              this.$t("description.authorizationError"),
              "standart",
              "info"
            );
            this.logout();
          } else {
            window.showError(err);
          }
        })
        .then(() => {
          this.isDetails = true;
          this.loadings.table = false;
        });
    },
    //Creates perm update modal for the user
    openPermUpdateModal() {
      this.modalHeader = this.$t("title.makeAdmin");
      this.modalDescription =
        this.inspectedItem.FirstName +
        " " +
        this.inspectedItem.LastName +
        " " +
        this.$t("description.makeAdminDescription");
      this.$refs.updateModalRef.modalStatus = true;
    },
    //Creates rank down modal for the user
    openRankDownModal() {
      this.modalHeader = this.$t("title.makeAdmin");
      this.modalDescription =
        this.inspectedItem.FirstName +
        " " +
        this.inspectedItem.LastName +
        " " +
        this.$t("description.makeAdminDescription");
      this.$refs.rankDownModalRef.modalStatus = true;
    },
    //Send creation form
    sendForm() {
      this.loadings.table = true;
      let body = {
        Username: this.userModel.Username,
        Email: this.userModel.Email,
        Password: this.userModel.Password,
        FirstName: this.userModel.FirstName,
        LastName: this.userModel.LastName,
        GroupIds: this.userModel.GroupIds,
        Type: this.userModel.Type,
      };
      this.loadings.table = true;
      UserService.createUser(body)
        .then((res) => {
          this.getTableList();
          this.clearModal();
          this.userModalStatus = false;
        })
        .catch((err) => {
          if (res.status == 401) {
            window.showInfo(
              this.$t("description.authorizationError"),
              "standart",
              "info"
            );
            this.logout();
          } else {
            window.showError(err);
          }
        })
        .then(() => {
          this.loadings.table = false;
        });
    },
      changeItemsPerPage() {
      this.pagination.rowsPerPage = this.itemsPerPage;
      this.pagination.page = 1;
      this.options.itemsPerPage = this.itemsPerPage;

      this.getTableList();
    },
     paginationChangeHandler(pageNumber) {

      this.pagination.page = pageNumber;
      this.getTableList();
    },

    //Clear modal and its contents
    clearModal() {
      this.userModel = {
        Username: "",
        Email: "",
        Password: "",
        Firstname: "",
        Lastname: "",
        Phone: "",
        Type: "",
        About: "",
        Type: "",
        GroupIds: [],
      };
      this.$refs.observer.reset();
      this.createdModelGroupIds = {};
      this.selectedUserType = null;
    },
    ...mapActions(["logout"]),
  },
};
</script>
