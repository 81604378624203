<template>
  <div>
    <!-- Stories deletion modal -->
    <delete-modal :yesFunc="deleteItem" ref="deleteModalRef" />
    <!-- Page content -->
    <v-card style="border-radius: 10px">
      <v-card-title style="color: #595959">
        <v-row>
          <v-col class="py-2">{{ $t("title.stories") }}</v-col>
          <v-spacer />
          <v-btn
            small
            class="ma-2 mr-4"
            elevation="0"
            outlined
            color="info"
            @click="$router.push({ name: 'createStory' })"
          >
            {{ $t("buttons.addNew") }}
          </v-btn>
        </v-row>
      </v-card-title>

      <v-divider />

      <v-card-text class="pt-4">
        <v-row align="center" justify="space-between">
          <v-col cols="4">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              @click:append="searchTable"
              :label="$t('labels.search')"
              v-on:keyup.enter="searchTable"
              @click:clear="searchTable('clear')"
              clear-icon="mdi-close-circle"
              clearable
              single-line
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="6"> </v-col>
          <v-col cols="2">
            <v-select
              v-model="itemsPerPage"
              :items="perPageValues"
              :label="$t('labels.selectItemsPerPage')"
              item-value="value"
              item-text="name"
              @change="changeItemsPerPage()"
            >
            </v-select>
          </v-col>
        </v-row>
        <v-data-table
          :headers="headers"
          :items="tableData"
          :loading="loadings.table"
          :options.sync="options"
          :hide-default-footer="true"
          :footer-props="{ disablePagination: true, disableItemsPerPage: true }"
          :no-data-text="$t('description.noList')"
        >
          <!-- eslint-disable -->
          <template v-slot:item.Thumbnail="{ item }">
            <v-avatar size="40" class="my-2">
              <v-img
                :lazy-src="item.Thumbnail.LocalUrl"
                max-height="40"
                max-width="40"
                :src="item.Thumbnail.LocalUrl"
              ></v-img>
            </v-avatar>
          </template>

          <template v-slot:item.CreatedAt="{ item }">
            {{
              item.UpdatedAt !== null
                ? convertToLocal(item.UpdatedAt)
                : convertToLocal(item.CreatedAt)
            }}
          </template>

          <template v-slot:item.Text="{ item }">
            {{ item.Text !== "" ? item.Text : "İçerik yok" }}
          </template>

          <template v-slot:item.LinkType="{ item }">
            {{
              item.LinkType == "screen"
                ? "Ekran"
                : item.LinkType == "link"
                ? "Uygulama İçi"
                : "Uygulama Dışı"
            }}
          </template>

          <template v-slot:item.Title="{ item }">
            <div style="font-weight: bold !important;">{{ item.Title }}</div>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  class="mr-2"
                  @click="openDeleteModal(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-delete
                </v-icon>
              </template>
              <span>{{ $t("labels.tooltipDelete") }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
        <div class="text-xs-center pt-2 md4 lg4">
          <v-pagination
            @input="paginationChangeHandler"
            :value="pagination.page"
            :length="pages"
            :total-visible="10"
            circle
          ></v-pagination>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import ModuleService from "../services/ModuleService";
import DeleteModal from "../components/General/DeleteModal";
import { mapActions } from "vuex";
import StoryService from "../services/StoryService";

export default {
  data: () => ({
    // List options
    options: {
      itemsPerPage: 15,
    },
    top: 15,
    skip: 0,
    tableData: [],
    headers: [],
    //Loading states
    loadings: {
      table: false,
      send: false,
    },
    //Initial models
    deleteItemModel: {},
    search: "",
    itemsPerPage: 15,
    perPageValues: [10, 15, 30, 50, 100, "Tümü"],
    perPageValues: [
      {
        name: "10",
        value: 10,
      },
      {
        name: "15",
        value: 15,
      },
      {
        name: "30",
        value: 30,
      },
      {
        name: "50",
        value: 50,
      },
      {
        name: "100",
        value: 100,
      },
    ],
  }),
  watch: {},
  computed: {
    totalRecords() {
      return this.items.length;
    },
    pageCount() {
      return this.totalRecords / this.itemsPerPage;
    },
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      ) {
        return 0;
      }

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
  },
  mounted() {
    this.setup();
    this.getTableList();
  },
  components: {
    DeleteModal,
  },
  methods: {
    //Page setup
    setup() {
      this.headers = [
        {
          text: this.$t("title.tableHeaderThumbNail"),
          value: "Thumbnail",
          sortable: false,
        },
        {
          text: this.$t("title.tableHeaderTitle"),
          value: "Title",
          sortable: false,
        },
        {
          text: this.$t("title.storyData"),
          value: "Text",
          sortable: false,
        },
        {
          text: this.$t("title.storyLinkType"),
          value: "LinkType",
          sortable: false,
          align: "center",
        },
        {
          text: this.$t("title.tableHeaderCreateDate"),
          value: "CreatedAt",
          sortable: false,
          align: "center",
        },
        {
          text: this.$t("title.tableHeaderActions"),
          value: "actions",
          sortable: false,
        },
        (this.pagination = {
          totalItems: 200,
          rowsPerPage: this.itemsPerPage,
          page: 1,
        }),
      ];
    },
    //Fill's the story  list
    getTableList() {
      this.$store.state.loadingStatus = true;

      StoryService.getStories(
        this.search,
        this.pagination.rowsPerPage,
        this.pagination.page
      )
        .then((res) => {
          this.tableData = res.data.Result;
          this.pagination.totalItems = res.data.TotalItems;
        })
        .catch((err) => {
          window.handleServiceError(res, err);
        })
        .then(() => {
          this.$store.state.loadingStatus = false;
        });
    },
    searchTable(type = "search") {
      type == "clear" ? (this.search = "") : null;
      this.getTableList(this.search);
    },
    //Delete stories
    deleteItem() {
      this.$store.state.loadingStatus = true;

      StoryService.deleteStory(this.deleteItemModel.Id)
        .then(() => {
          this.getTableList();
          this.$refs.deleteModalRef.modalStatus = false;
          this.getMenuData();
        })
        .catch((err) => {
          window.handleServiceError(res, err);
        })
        .then(() => {
          this.$store.state.loadingStatus = false;
        });
    },
    //Opens modal for deletion operation
    openDeleteModal(item) {
      this.deleteItemModel = item;
      this.$refs.deleteModalRef.modalStatus = true;
    },
    paginationChangeHandler(pageNumber) {
      this.pagination.page = pageNumber;
      this.getTableList();
    },
    changeItemsPerPage() {
      this.pagination.rowsPerPage = this.itemsPerPage;
      this.pagination.page = 1;
      this.getTableList();
    },
    ...mapActions(["getMenuData"]),
  },
};
</script>
