<template>
  <div
    style="
      height: 100%;
      padding-top: 70px;
      padding-left: 300px;
      padding-right: 0px;
      padding-bottom: 70px;
    "
  >
    <v-container>
      <router-view />
    </v-container>
  </div>
</template>
<script>
export default {
  name: "Container",
  data() {
    return {};
  },
  methods: {},
};
</script>
