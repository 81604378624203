<template>
  <v-dialog v-model="modalStatus" max-width="400">
    <v-card>
      <v-card-title class="headline indigo lighten-2">
        <div class="text-center white--text" style="width: 100%">
          {{ title  ? title : $t('description.deleteModalTitle')}}
        </div>
      </v-card-title>

      <v-card-text class="py-3">
        <div class="text-center" style="width: 100%">
          {{ description ? description :  $t('description.deleteModalDescription')}}
        </div>
      </v-card-text>

      <v-card-actions
        style="
          background-color: white;
          position: sticky;
          bottom: 0px;
          border-top: solid grey 2px;
        "
      >
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="yesFunc()">
          {{ $t("buttons.yes") }}
        </v-btn>
        <v-btn color="primary" text @click="modalStatus = false">
          {{ $t("buttons.cancel") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      //default: () => this.$t('description.deleteModalTitle'),
    },
    description: {
      type: String,
      //default: () => this.$t('description.deleteModalDescription'),
    },
    yesFunc: {
      type: Function,
      default: () => true,
    },
  },
  data: () => ({
    modalStatus: false,
  }),
  mounted() {},
  watch: {},
  computed: {},
  methods: {},
};
</script>
