<template>
  <div>
    <!-- Page content of BlockedUsers -->
    <v-card style="border-radius: 10px">
      <v-card-title style="color: #595959">
        <v-row>
          <v-col class="py-2">{{ $t("title.blockedUsers") }}</v-col>
        </v-row>
      </v-card-title>

      <v-divider />
      <!-- Main page content -->
      <v-card-text class="pt-4">
        <v-row align="center" justify="space-between">
          <v-col cols="3">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              @click:append="searchTable"
              v-on:keyup.enter="searchTable"
              @click:clear="searchTable('clear')"
              :label="$t('labels.userSearch')"
              clear-icon="mdi-close-circle"
              clearable
            ></v-text-field>
          </v-col>
          <v-col cols="7"></v-col>
          <v-col cols="2" align="end">
            <v-select
              v-model="itemsPerPage"
              :items="perPageValues"
              :label="$t('labels.selectItemsPerPage')"
              item-value="value"
              item-text="name"
              @change="changeItemsPerPage()"
            >
            </v-select>
          </v-col>
        </v-row>
        <v-data-table
          :headers="headers"
          :items="tableData"
          :loading="loadings.table"
          :options.sync="options"
          :hide-default-footer="true"
          :footer-props="{ disablePagination: true, disableItemsPerPage: true }"
          :no-data-text="$t('description.noList')"
        >
          <!-- eslint-disable -->

          <template v-slot:item.CreatedAt="{ item }">
            {{ convertToLocal(item.CreatedAt) }}
          </template>

          <template v-slot:item.Username="{ item }">
            <div style="font-weight: bold !important;">{{ item.Username }}</div>
          </template>

          <template v-slot:item.Email="{ item }">
            {{ item.Email.length > 1 ? item.Email : "Eposta Bulunamadı" }}
          </template>

          <template v-slot:item.Type="{ item }">
            {{
              item.Type == "SuperAdmin"
                ? "Süper Admin"
                : item.Type == "Admin"
                ? "Admin"
                : "Standart"
            }}
          </template>

          <template v-slot:item.actions="{ item }">
            <div>
              <v-col>
                <v-row
                  class="pl-3"
                  align="center"
                  justify="center"
                >
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="mr-2"
                        @click="unBlockUser(item)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi mdi-account-lock
                      </v-icon>
                    </template>
                    <span>{{ $t("labels.unblockUser") }}</span>
                  </v-tooltip>
                </v-row>
              </v-col>
            </div>
          </template>
        </v-data-table>
        <div class="text-xs-center pt-2 md4 lg4">
          <v-pagination
            @input="paginationChangeHandler"
            :value="pagination.page"
            :length="pages"
            :total-visible="10"
            circle
          ></v-pagination>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import DeleteModal from "../components/General/DeleteModal.vue";
import UserService from "../services/UserService";
import ModuleService from "../services/ModuleService";

export default {
  data: () => ({
    //List options
    options: {
      itemsPerPage: 15,
    },
    tableData: [],
    userStatusItems: [],

    //Table contents
    headers: [],
    //Page loaders
    loadings: {
      table: false,
    },
    //Initial models
    currentModules: [],
    selectedUserType: "",
    search: "",
    pagination: {},
    itemsPerPage: 15,
    perPageValues: [
      {
        name: "10",
        value: 10,
      },
      {
        name: "15",
        value: 15,
      },
      {
        name: "30",
        value: 30,
      },
      {
        name: "50",
        value: 50,
      },
      {
        name: "100",
        value: 100,
      },
    ],
  }),
  watch: {},
  computed: {
    totalRecords() {
      return this.tableData.length;
    },
    pageCount() {
      return this.totalRecords / this.itemsPerPage;
    },
    pages(val) {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      ) {
        return 0;
      }

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
  },
  mounted() {
    this.setup();
  },
  components: {
    DeleteModal,
  },
  methods: {
    //Sets up the page on mount
    setup() {
      this.headers = [
        {
          text: this.$t("labels.userName"),
          value: "Username",
          sortable: false,
        },
        {
          text: this.$t("title.tableHeaderName"),
          align: "start",
          sortable: false,
          value: "FirstName",
        },
        {
          text: this.$t("title.tableHeaderLastName"),
          value: "LastName",
          sortable: false,
        },
        {
          text: this.$t("title.tableHeaderEmail"),
          value: "Email",
          sortable: false,
        },
        {
          text: this.$t("title.tableHeaderUserType"),
          value: "Type",
          sortable: false,
        },
        {
          text: this.$t("title.tableHeaderCreateDate"),
          value: "CreatedAt",
          sortable: false,
        },
        {
          text: this.$t("title.tableHeaderActions"),
          value: "actions",
          sortable: false,
          align: "center",
        },
        (this.pagination = {
          totalItems: 200,
          rowsPerPage: this.itemsPerPage,
          page: 1,
        }),
      ];

      this.getTableList();
      this.getModules();
    },
    //Converts date data to local time
    DateTime(date) {
      return window.convertToLocal(date);
    },

    //Loads page list
    getTableList() {
      this.$store.state.loadingStatus = true;

      this.tableData = [];
      UserService.getBlockedUsers(
        this.search,
        this.pagination.rowsPerPage,
        this.pagination.page
      )
        .then((res) => {
          console.log("res", res);
          if (res.data.Result != null) {
            this.tableData = res.data.Result;
            this.pagination.totalItems = res.data.TotalItems;
          } else {
            this.pagination.totalItems = 0;
            this.tableData = [];
          }
        })
        .catch((err) => {
          window.handleServiceError(err);
        })
        .then(() => {
          this.$store.state.loadingStatus = false;
        });
    },
    //Loads current modules
    getModules() {
      ModuleService.getPages()
        .then((res) => {
          res.data.forEach((element) => {
            let obj = {
              id: element.Id,
              key: element.Key,
              name: element.Name,
            };
            this.currentModules.push(obj);
          });
        })
        .catch((err) => {
          window.handleServiceError(err);
        })
        .then(() => {});
    },

    //Search Operation
    searchTable(type = "search") {
      type == "clear" ? (this.search = "") : null;
      this.getTableList();
    },

    //Filters user table by user type
    filterUserByType() {
      this.$store.state.loadingStatus = true;
      this.tableData = [];

      UserService.getUsersByType(
        this.selectedUserType,
        this.pagination.rowsPerPage,
        this.pagination.page
      )
        .then((res) => {
          res.data.Data.forEach((element) => {
            if (element.DeletedAt == null) {
              this.tableData.push(element);
            }
          });
          this.pagination.totalItems = res.data.TotalData;
        })
        .catch((err) => {
          window.handleServiceError(err);
        })
        .then(() => {
          this.$store.state.loadingStatus = false;
        });
    },

    changeItemsPerPage() {
      this.pagination.rowsPerPage = this.itemsPerPage;
      this.pagination.page = 1;
      this.options.itemsPerPage = this.itemsPerPage;

      this.getTableList();
    },
    paginationChangeHandler(pageNumber) {
      this.pagination.page = pageNumber;
      this.getTableList();
    },

    unBlockUser(item) {
      this.$store.state.loadingStatus = true;
      let body = {
        UserId: item.Id,
      };

      UserService.removeBlockedUser(body)
        .then((res) => {
          this.getTableList();
          this.$store.state.loadingStatus = false;
        })
        .catch((err) => {
          window.handleServiceError(err);
        })
        .then(() => {
          this.$store.state.loadingStatus = false;
          window.showInfo(
            "İşlem başarılı bir şekilde gerçekleştirildi.",
            "standart",
            "success"
          );
        });
    },
    
  },
};
</script>
