import axios from '../javascript/custom-axios'
const RESOURCE_NAME = 'categories/'

export default {
  getCategories(search, ParentId) {
    return axios.get(`${RESOURCE_NAME}`, {params:{Name:search, ParentId, PageSize:1000}})
  },

  createCategory(body) {
    return axios.post(`${RESOURCE_NAME}create`, body)
  },

  updateCategoryTranslations (body) {
    return axios.put(`${RESOURCE_NAME}translations/update`,body)
  },

  deleteCategory (id) {
    return axios.delete(`${RESOURCE_NAME}${id}`)
  },
  getCategories2(params) {
    return axios.get(`${RESOURCE_NAME}`, {params})
  },
  getCategoriesInTreeView(id) {
    return axios.get(`${RESOURCE_NAME}treeview`, {params:{id:id}})
  },
  getCategoriesInTreeView2(id) {
    return axios.get(`${RESOURCE_NAME}treeview?parentid=${id}`)
  },
}