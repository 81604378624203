<template>
  <v-app>
    <alert />
    <loading />
    <router-view :key="$route.fullPath" />
  </v-app>
</template>

<script>
import Alert from "./components/General/Alert.vue";
import Loading from "./components/General/Loading.vue";

export default {
  name: "App",
  components: {
    Alert,
    Loading,
  },
  data() {
    return {};
  },
};
</script>

<style>
.v-data-table--fixed-header > .v-data-table__wrapper > table > thead > tr > th {
  z-index: 0 !important;
}
.v-input__slot {
  justify-content: center !important;
}
/* .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  border-bottom: 2px solid #3572A8 !important;
  border-top: 2px solid #3572A8 !important;
} */
.theme--light.v-data-table .v-row-group__header,
.theme--light.v-data-table .v-row-group__summary {
  background: #f4f9ff !important;
  /*color: #3572A8;*/
  text-transform: uppercase;
  font-weight: bold;
}

.theme--light.v-data-table
  .v-row-group__header
  > .text-start
  > button:last-child {
  display: none;
}

.theme--light.v-data-table
  .v-row-group__header
  > .text-start
  > button:first-child {
  position: absolute;
  right: 20px;
}

/* The emerging W3C standard that is currently Firefox-only */
* {
  scrollbar-width: thin;
  scrollbar-color: #888888 #cccccc;
}

/* Works on Chrome/Edge/Safari */
*::-webkit-scrollbar {
  width: 10px;
}
*::-webkit-scrollbar-track {
  background: #cccccc;
}
*::-webkit-scrollbar-thumb {
  background-color: #888888;
  border-radius: 10px;
}
.v-expansion-panels {
  z-index: 0 !important;
}
.v-data-footer__pagination {
  display: none !important;
}

/* Tabloda son kolonda butonların ortalanması için */
/* Start */
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr
  > th:last-child {
  text-align: center !important;
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr
  > td:last-child {
  text-align: center !important;
}

.v-input__slot::before {
  border-color: #7443a0 !important;
  color: red !important;
}
.theme--light.v-label {
  color: #7443a0 !important;
  font-size: 12px;
}
.v-select__selection--comma {
  color: #7443a0 !important;
  font-size: 12px;
}

.v-card__title {
  color: #ff7b00 !important;
}
.theme--light.v-pagination .v-pagination__item--active {
  background: #7443a0 !important;
}
.theme--light.v-application {
  background: #f9f9f9 !important;
}
.theme--light.v-icon {
  color: #7443a0 !important;
}

.v-treeview-node__label {
  color: black !important;
  font-size: 12px !important;
}
.v-treeview-node__root {
  min-height: 30px !important;
}

.v-btn {
  border-radius: 5px !important;
}

.add-content-card-body {
  background-color: white;
}

.add-content-title {
  color: #7443a0 !important;
  font-size: 16px !important;
  padding: 0px !important;
}

.add-content-document-title {
  color: #7443a0 !important;
  font-size: 16px !important;
  padding: 0px !important;
  padding-right: 10px !important;
}

.add-content-document-sub-title {
  color: #7986cb !important;
  font-size: 14px !important;
  padding: 0px !important;
  padding-right: 10px !important;
}

.v-list-item__subtitle,
.v-list-item__title {
  white-space: pre-line !important;
}

/* End */
</style>
