<template>
  <v-container class="mb-3">
    <!-- Module deletion modal -->
    <delete-modal :yesFunc="deleteItem" ref="deleteModalRef" />
    <!-- Notification create start -->
    <div v-if="isCreate">
      <!-- Sticky publish button start -->
      <v-btn
        v-scroll="onScroll"
        v-show="fab"
        dark
        fixed
        bottom
        right
        big
        class="ma-2 mr-4"
        elevation="0"
        style="z-index: 9999"
        color="primary"
        @click="stickyPublishOperation"
      >
        {{ $t("buttons.save") }}
      </v-btn>
      <!-- Sticky publish button end -->
      <!-- Page Header End -->
      <!-- Notification -->
      <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
        <form @submit.prevent="handleSubmit(sendForm)">
          <!-- Form buttons start -->
          <!-- Page Header -->

          <v-card-title style="color: #595959">
            <v-row>
              <v-col class="py-2 pl-0">{{ $t("title.notifications") }}</v-col>
              <v-spacer />
              <v-btn
                small
                type="submit"
                class="ma-2 mr-4"
                elevation="0"
                outlined
                color="info"
              >
                {{ $t("buttons.save") }}
              </v-btn>
              <v-btn
                small
                class="ma-2 mr-4"
                elevation="0"
                outlined
                color="info"
                @click="
                  isCreate = false;
                  clearModal();
                  getTableList();
                "
              >
                {{ $t("buttons.cancel") }}
              </v-btn>
            </v-row>
          </v-card-title>
          <!-- Form buttons end -->

          <v-divider />

          <v-card-text class="px-0 py-10">
            <!-- Title start -->
            <v-card-text class="px-0 py-0 add-content-card-body">
              <v-banner rounded outlined class="mt-5">
                <v-card-title class="add-content-title">{{
                  $t("labels.notificationTitleDescription")
                }}</v-card-title>
                <ValidationProvider
                  name="Başlık"
                  rules="required|max:150"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    :label="$t('labels.header')"
                    clearable
                    class="mx-5"
                    v-model="notificationModel.Title"
                    :error-messages="errors[0]"
                  ></v-text-field>
                </ValidationProvider>

                <ValidationProvider
                  name="Mesaj"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-textarea
                    v-model="notificationModel.Description"
                    name="Case Details"
                    rows="2"
                    auto-grow
                    row-height="15"
                    clearable
                    class="mx-5"
                    clear-icon="mdi-close-circle"
                    append-outer-icon="mdi-emoticon-happy"
                    @click:append-outer="openEmojiPicker()"
                    :label="$t('labels.notificationMessage')"
                    :error-messages="errors[0]"
                  ></v-textarea>
                  <!-- <div class="error--text">{{ errors[0] }}</div> -->
                </ValidationProvider>

                <!-- Emoji Picker Start-->
                <v-dialog
                  v-model="openEmojiPickerDialog"
                  max-width="325"
                  style="border-radius: 0px"
                  hide-overlay
                  shaped
                  persistent
                >
                  <v-container fluid ma-0 pa-0 fill-height>
                    <VEmojiPicker @select="selectEmoji" />
                  </v-container>

                  <v-spacer></v-spacer>
                  <v-card
                    style="border-radius: 0px"
                    align="center"
                    justify="center"
                  >
                    <v-btn
                      color="primary"
                      text
                      @click="openEmojiPickerDialog = false"
                      style="border-radius: 0px"
                    >
                      {{ $t("buttons.ok") }}
                    </v-btn>
                  </v-card>
                </v-dialog>
                <!-- Emoji Picker end-->
              </v-banner>
            </v-card-text>
            <!-- Title end -->

            <!-- Description start -->
            <v-card-text class="px-0 py-0 add-content-card-body"> </v-card-text>
            <!-- Description end -->

            <!-- Photo Upload -->
            <!-- <v-card-text class="px-0 py-0 add-content-card-body">
              <v-banner rounded outlined class="mt-5">
                <v-card-title class="add-content-title">{{
                  $t("labels.photoUploadNotifications")
                }}</v-card-title>

                <v-row>
                  <v-col>
                    <input
                      type="file"
                      accept="image/*"
                      ref="uploader"
                      class="d-none"
                      @change="onFileChange"
                    />

                    <v-btn
                      small
                      class="ma-4 mr-8"
                      elevation="0"
                      outlined
                      color="info"
                      :disabled="photoLength == 1"
                      @click="browsePhoto()"
                    >
                      {{ $t("buttons.uploadNew") }}
                    </v-btn>
                  </v-col>
                  <v-col
                    align-self="end"
                    class="mr-5"
                    style="text-align: end"
                    v-if="selectedPhotoModel"
                  >
                    {{ photoLength == null ? " " : photoLength + " /" + " 1" }}
                  </v-col>
                </v-row>

                <v-banner
                  rounded
                  outlined
                  class="mt-5"
                  v-if="selectedPhotoModel"
                >
                  <v-hover>
                    <v-img
                      style="border-radius: 8px"
                      max-height="250px"
                      :aspect-ratio="4 / 3"
                      contain
                      :src="computedUrl(selectedPhotoModel)"
                      slot-scope="{ hover }"
                    >
                      <v-expand-transition>
                        <div
                          v-if="hover"
                          class="
                            transition-fast-in-fast-out
                            image-hover
                            white--text
                          "
                          style="height: 100%"
                        >
                          <v-layout
                            row
                            wrap
                            fill-height
                            align-center
                            justify-center
                            ma-0
                          >
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  @click="deletePhoto(selectedPhotoModel, 0)"
                                  class="ma-0"
                                  icon
                                  v-on="on"
                                  style="z-index: 100"
                                >
                                  <v-icon color="white">mdi-close</v-icon>
                                </v-btn>
                              </template>
                              <span>{{ $t("labels.deleteKey") }}</span>
                            </v-tooltip>

                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  class="ma-0"
                                  icon
                                  v-on="on"
                                  style="z-index: 100"
                                >
                                  <v-icon color="white">mdi-folder</v-icon>
                                </v-btn>
                              </template>
                              <span>{{ selectedPhotoModel.name }}</span>
                            </v-tooltip>
                          </v-layout>
                        </div>
                      </v-expand-transition>
                    </v-img>
                  </v-hover>
                </v-banner>
              </v-banner>
            </v-card-text> -->
            <!-- Photo upload end -->

            <!-- Notification route start -->
            <v-card-text class="px-0 py-0 add-content-card-body">
              <v-banner rounded outlined class="mt-5">
                <v-card-title class="add-content-title">
                  {{ $t("title.notificationRedirectPage") }}
                </v-card-title>

                <v-row class="ma-2 mr-4" justify="start" align="center">
                  <v-col cols="4">
                    <ValidationProvider
                      name="Yönlendirilecek Sayfa"
                      rules="required"
                      v-slot="{ errors }"
                      ref="moduleSelection"
                    >
                      <v-select
                        v-model="selectedModuleModal"
                        :items="pageList"
                        :label="$t('labels.selectPage')"
                        item-text="Value"
                        return-object
                        item-value="Key"
                        :error-messages="errors[0]"
                        @change="fillInnerSelectPage(selectedModuleModal)"
                      ></v-select>
                    </ValidationProvider>
                  </v-col>
                  <v-col
                    cols="4"
                    v-if="
                      selectedModuleModal.IsLink &&
                        selectedModuleModal.LinkStatus
                    "
                  >
                    <ValidationProvider
                      name="Bağlantı"
                      rules="required"
                      v-slot="{ errors }"
                      ref="moduleSelection"
                    >
                      <v-text-field
                        v-model="linkText"
                        clearable
                        :value="linkText"
                        :label="$t('labels.notificationLink')"
                        :error-messages="errors[0]"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>

                  <v-col
                    cols="4"
                    v-if="
                      selectedModuleModal.IsLink &&
                        !selectedModuleModal.LinkStatus
                    "
                  >
                    <ValidationProvider
                      name="Bağlantı"
                      rules="required"
                      v-slot="{ errors }"
                      ref="moduleSelection"
                    >
                      <v-text-field
                        v-model="linkText"
                        :value="linkText"
                        clearable
                        :label="$t('labels.notificationExternalLink')"
                        :error-messages="errors[0]"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>

                  <v-col
                    cols="4"
                    v-if="selectedModuleModal.IsDetail && flag && isDetailFlag"
                  >
                    <ValidationProvider
                      name="Yönlendirilecek sayfa"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <v-autocomplete
                        v-model="selectedInnerModuleModal"
                        :loading="autoLoading"
                        :items="allInnerModules"
                        :label="$t('labels.selectInnerPage')"
                        item-text="Value"
                        item-value="Key"
                        :search-input.sync="search2"
                        prepend-icon="mdi-database-search"
                        :error-messages="errors[0]"
                        class="mt-3"
                        cache-items
                        clearable
                        dense
                      ></v-autocomplete>
                    </ValidationProvider>
                  </v-col>
                  <v-col
                    cols="2"
                    v-if="selectedModuleModal.IsDetail && flag && !isDetailFlag"
                  >
                    <v-btn
                      small
                      class="ma-1"
                      outlined
                      color="info"
                      @click="activateDetails()"
                    >
                      Detay Sayfası Seç
                    </v-btn>
                  </v-col>
                  <v-col
                    cols="2"
                    v-if="selectedModuleModal.IsDetail && flag && isDetailFlag"
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon
                          v-on="on"
                          @click="closeDetails(selectedModuleModal)"
                          >mdi-close</v-icon
                        >
                      </template>
                      <span>Detayı Kapat</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-banner>
            </v-card-text>
            <!-- Notification route end -->

            <!-- Users & Groups start  -->
            <v-card-text class="px-0 py-0 add-content-card-body">
              <v-banner rounded outlined class="mt-5">
                <v-card-title
                  class="add-content-title"
                  v-if="
                    comboSelect.length == 0 &&
                      (selectedDivision == -1 || selectedDivision == null) &&
                      !sendAll
                  "
                >
                  {{ $t("labels.testNotification") }}
                </v-card-title>

                <v-row
                  class="mt-2"
                  v-if="
                    comboSelect.length == 0 &&
                      (selectedDivision == -1 || selectedDivision == null) &&
                      !sendAll
                  "
                >
                  <v-checkbox
                    class="ml-6 font-weight-medium"
                    style="color: black !important"
                    v-model="isTest"
                    :label="$t('labels.notificationTestMessage')"
                  ></v-checkbox>
                </v-row>
                <v-divider
                  class="mb-5 mt-5"
                  v-if="
                    comboSelect.length == 0 &&
                      (selectedDivision == -1 || selectedDivision == null) &&
                      !sendAll
                  "
                />
                <v-card-title
                  class="add-content-title"
                  v-if="
                    comboSelect.length == 0 &&
                      (selectedDivision == -1 || selectedDivision == null) &&
                      !isTest
                  "
                >
                  {{ $t("labels.notificationTargetTypes") }}
                </v-card-title>

                <v-row
                  class="mt-2"
                  v-if="
                    comboSelect.length == 0 &&
                      (selectedDivision == -1 || selectedDivision == null) &&
                      !isTest
                  "
                >
                  <v-checkbox
                    class="ml-6 font-weight-medium"
                    style="color: black !important"
                    v-model="sendAll"
                    @click="changeSendAllState(sendAll)"
                    :label="$t('labels.notificationAllTargets')"
                  ></v-checkbox>
                </v-row>
                <!-- v-if="!sendAll" -->
                <v-divider
                  class="mb-5 mt-5"
                  v-if="
                    comboSelect.length == 0 &&
                      (selectedDivision == -1 || selectedDivision == null) &&
                      !isTest
                  "
                />

                <!-- <v-row>
                  <v-col cols="5">
                    <v-checkbox
                      v-model="sendAll"
                      :label="`Tüm kullanıcılara gönder`"
                    ></v-checkbox>
                  </v-col>
                </v-row> -->

                <v-card-title
                  v-if="
                    !sendAll &&
                      (selectedDivision == -1 || selectedDivision == null) &&
                      !isTest
                  "
                  class="add-content-title"
                >
                  {{ $t("labels.notificationUsers") }}
                </v-card-title>

                <v-row
                  class="mt-2"
                  v-if="
                    !sendAll &&
                      (selectedDivision == -1 || selectedDivision == null) &&
                      !isTest
                  "
                >
                  <v-col cols="5">
                    <v-combobox
                      v-model="comboSelect"
                      :items="comboItems"
                      item-text="FullName"
                      item-value="Id"
                      :label="$t('labels.notificationUserSelect')"
                      deletable-chips
                      class="ml-5"
                      multiple
                      small-chips
                      solo
                      hide-selected
                      :search-input.sync="search"
                      :loading="isLoading"
                      clear-icon="mdi-close-circle"
                      clearable
                      v-on:keyup.enter="searchCallNewTest"
                    >
                      <template v-slot:append-outer>
                        <v-btn
                          small
                          elevation="0"
                          outlined
                          color="info"
                          @click="searchCallNewTest()"
                        >
                          Ara
                        </v-btn>
                      </template>
                      <template v-slot:item="{ item }">
                        {{ item.FullName + " (" + item.Username + ")" }}
                      </template>
                      <template v-slot:selection="{ attrs, item, parent }">
                        <v-chip
                          v-if="item === Object(item)"
                          v-bind="attrs"
                          :color="`grey lighten-3`"
                          label
                          small
                        >
                          <span class="pr-2">
                            {{ item.FullName + " (" + item.Username + ")" }}
                          </span>
                          <v-icon small @click="parent.selectItem(item)">
                            $delete
                          </v-icon>
                        </v-chip>
                      </template>
                      <template v-slot:no-data>
                        <p class="ml-3 mt-3">
                          {{
                            searchResult == ""
                              ? $t("labels.search")
                              : searchResult
                          }}
                        </p>
                      </template>
                    </v-combobox>
                  </v-col>
                </v-row>

                <v-divider
                  class="mb-5 mt-5"
                  v-if="
                    !sendAll &&
                      (selectedDivision == -1 || selectedDivision == null) &&
                      !isTest
                  "
                />

                <v-card-title
                  v-if="!sendAll && comboSelect.length == 0 && !isTest"
                  class="add-content-title"
                >
                  {{ $t("labels.postDivision") }}
                </v-card-title>

                <v-row
                  class="mt-2"
                  v-if="!sendAll && comboSelect.length == 0 && !isTest"
                >
                  <v-col cols="3">
                    <v-select
                      :items="divisionItems"
                      item-text="Name"
                      item-value="Id"
                      v-model="selectedDivision"
                      dense
                      clearable
                    ></v-select>
                  </v-col>
                </v-row>

                <!-- <v-divider class="mb-5 mt-5" v-if="!sendAll" />

                <v-card-title class="add-content-title" v-if="!sendAll">
                  {{ $t("labels.postGroup") }}
                </v-card-title>

                <v-row
                  class="mt-2"
                  v-for="(data, rowIndex) in groupsSelectArray"
                  :key="rowIndex"
                >
                  <v-row v-if="!sendAll">
                    <v-col
                      cols="3"
                      class="ml-2"
                      v-for="(element, colIndex) in data"
                      :key="colIndex"
                    >
                      <div row wrap class="px-5 mt-3">
                        <v-select
                          v-model="groupArr[rowIndex][colIndex]"
                          :items="element.groups"
                          @click="
                            clickGroupFilterModal(element, colIndex, rowIndex)
                          "
                          item-text="name"
                          item-value="id"
                          :label="
                            element.name == 'Şirket' ? 'Kuruluş' : element.name
                          "
                          dense
                          required
                        ></v-select>
                      </div>
                    </v-col>
                  </v-row>
                </v-row>
                <v-row v-if="!sendAll">
                  <v-col cols="2">
                    <v-btn
                      small
                      v-if="!groupLengthAlert"
                      class="ma-1 ml-4"
                      outlined
                      color="info"
                      @click="createNewGroup()"
                    >
                      {{ $t("buttons.addNew") }}
                    </v-btn>
                  </v-col>

                  <v-col class="mt-2">
                    <v-alert
                      v-model="groupLengthAlert"
                      border="left"
                      close-text="Close Alert"
                      type="info"
                      color="#7443a0"
                      dismissible
                      outlined
                      prominent
                      class="font-weight-black"
                      style="font-size: 1.2em"
                    >
                      {{ $t("description.groupLengthMessage") }}
                    </v-alert>
                  </v-col>
                </v-row> -->
              </v-banner>
            </v-card-text>
            <!-- Users & Groups end -->

            <!-- Date and time picker start -->
            <v-card-text class="px-0 py-0 add-content-card-body">
              <v-banner rounded outlined class="mt-5">
                <v-card-title class="add-content-title">
                  {{ $t("labels.notificationDateTime") }}
                </v-card-title>

                <v-row align="center" justify="space-between" class="pr-5">
                  <v-col cols="5">
                    <v-text-field
                      v-model="dateValueDisplay"
                      :label="$t('labels.selectDate')"
                      readonly
                    >
                      <template v-slot:prepend>
                        <date-picker v-model="dateValue" />
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col cols="5">
                    <v-text-field
                      v-model="timeValueDisplay"
                      :label="$t('labels.selectTime')"
                      readonly
                    >
                      <template v-slot:prepend>
                        <time-picker v-model="timeValue" />
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-banner>
            </v-card-text>
            <!-- Date and time picker end -->
          </v-card-text>
        </form>
      </ValidationObserver>
    </div>
    <!-- Notification create end -->
    <!-- Notification main page start -->
    <div v-if="!isCreate">
      <!-- Page content -->
      <v-card style="border-radius: 10px">
        <v-card-title style="color: #595959">
          <v-row>
            <v-col class="py-2">{{ $t("title.notifications") }}</v-col>
            <v-spacer />
            <v-btn
              small
              class="ma-2 mr-4"
              elevation="0"
              outlined
              color="info"
              @click="
                isCreate = true;
                $store.state.loadingStatus = false;
              "
            >
              {{ $t("buttons.addNew") }}
            </v-btn>
          </v-row>
        </v-card-title>

        <v-divider />

        <v-card-text class="pt-4">
          <v-row justify="end">
            <v-col cols="2">
              <v-select
                v-model="itemsPerPage"
                :items="perPageValues"
                :label="$t('labels.selectItemsPerPage')"
                item-value="value"
                item-text="name"
                @change="changeItemsPerPage()"
              >
              </v-select>
            </v-col>
          </v-row>
          <v-data-table
            :headers="headers"
            :items="tableData"
            :loading="loadings.table"
            :options.sync="options"
            :hide-default-footer="true"
            :footer-props="{
              disablePagination: true,
              disableItemsPerPage: true,
            }"
            :no-data-text="$t('description.noList')"
          >
            <!-- eslint-disable -->
            <template v-slot:item.CreatedAt="{ item }">
              {{ convertToLocal(item.CreatedAt) }}
            </template>
            <!-- item.TargetType == "Topic" ? "Tümü" : -->
            <template v-slot:item.TargetType="{ item }">
              {{
                item.TargetType == "User"
                  ? "Kullanıcı"
                  : item.TargetType == "Topic"
                  ? "Grup"
                  : "Tümü"
              }}
            </template>
            <template v-slot:item.Title="{ item }">
              <div style="font-weight: bold !important">{{ item.Title }}</div>
            </template>

            <!-- <template v-slot:item.actions="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">

                  <v-icon
                    small
                    class="mr-2"
                    @click="openDeleteModal(item)"
                    v-bind="attrs"
                    v-on="on"

                  >
                    mdi-delete
                  </v-icon>
                </template>
                <span>{{ $t("labels.tooltipDelete") }}</span>
              </v-tooltip>
            </template> -->
          </v-data-table>
          <div class="text-xs-center pt-2 md4 lg4">
            <v-pagination
              @input="paginationChangeHandler"
              :value="pagination.page"
              :length="pages"
              :total-visible="10"
              circle
            ></v-pagination>
          </div>
        </v-card-text>
      </v-card>
    </div>
    <!-- Notification main page end -->
  </v-container>
</template>

<script>
import GroupService from "../services/GroupService";
import FileService from "../services/FileService";
import ContentService from "../services/ContentService";
import AccountService from "../services/AccountService";
import SurveyService from "../services/SurveyService";
import NotificationService from "../services/NotificationService";
import UserService from "../services/UserService";
import DeleteModal from "../components/General/DeleteModal";
import { mapActions } from "vuex";
import { VEmojiPicker } from "v-emoji-picker";
import _ from "lodash";

export default {
  data: () => ({
    model: ["Vuetify"],

    //Notification model
    notificationModel: {},
    items: [
      {
        Name: "Anasayfa",
        Code: "anasayfa",
      },
      {
        Name: "Bildirimler",
        Code: "bildirimler",
      },
      {
        Name: "Link",
        Code: "link",
      },
      {
        Name: "Anket",
        Code: "anket",
      },
    ],
    options: {
      itemsPerPage: 15,
    },
    top: 15,
    skip: 0,
    tableData: [],
    headers: [],
    title: "",
    editorData: "",
    fileIds: [],
    tempFiles: [],
    selectedModuleModal: "",
    selectedInnerModuleModal: "",
    loadings: {
      table: false,
      send: false,
    },
    pageList: [
      // {
      //   Value: "Aramıza Katılanlar",
      //   Key: "Newcomers",
      //   IsDetail: false,
      //   ModuleKey: null,
      // },
      // {
      //   Value: "Kurumsal Haberler",
      //   Key: "Home",
      //   IsDetail: true,
      //   ModuleKey: "kurumsal_haberler",
      // },
      // {
      //   Value: "Anketler",
      //   Key: "Survey",
      //   IsDetail: true,
      //   ModuleKey: "anketler",
      // },
      // {
      //   Value: "Yemek Menüsü",
      //   Key: "FoodMenu",
      //   IsDetail: false,
      //   ModuleKey: null,
      // },
      // {
      //   Value: "Bugün Doğanlar",
      //   Key: "BornTodayUsers",
      //   IsDetail: false,
      //   ModuleKey: null,
      // },
      // {
      //   Value: "Yeni Doğan Bebekler",
      //   Key: "NewBorn",
      //   IsDetail: false,
      //   ModuleKey: null,
      // },
      // {
      //   Value: "Eczacıbaşılılara Özel İndirimler",
      //   Key: "Rebates",
      //   IsDetail: false,
      //   ModuleKey: null,
      // },
      // {
      //   Value: "Vefat Edenler",
      //   Key: "Deceased",
      //   IsDetail: false,
      //   ModuleKey: null,
      // },
      // {
      //   Value: "Topluluk Uygulamaları",
      //   Key: "Application",
      //   IsDetail: false,
      //   ModuleKey: null,
      // },
      // {
      //   Value: "Link",
      //   Key: "link",
      //   IsLink: true,
      //   LinkStatus: true,
      //   ModuleKey: null,
      // },
      // {
      //   Value: "External Link",
      //   Key: "external_link",
      //   IsLink: true,
      //   LinkStatus: false,
      //   ModuleKey: null,
      // },
      // {
      //   Value: "Telefon Listesi",
      //   Key: "PhoneBook",
      //   IsDetail: false,
      //   ModuleKey: null,
      // },
      // // {
      // //   Value: "Diğer El Kitapları (PDF)",
      // //   Key: "Library",
      // //   IsDetail: false,
      // //   ModuleKey: null,
      // // },
      // {
      //   Value: "Kütüphane",
      //   Key: "Library",
      //   IsDetail: false,
      //   ModuleKey: null,
      // },
      // {
      //   Value: "Açık Pozisyon Duyuruları",
      //   Key: "CareerOpportunities",
      //   IsDetail: false,
      //   ModuleKey: null,
      // },
      // {
      //   Value: "Servis Güzergahları",
      //   Key: "PersonalService",
      //   IsDetail: false,
      //   ModuleKey: null,
      // },
    ],
    allInnerModules: [],
    groupModel: {
      Groups: [],
    },
    //End Publish model

    //For groups area
    selecItems: [],
    groupData: [],
    groupLengthAlert: false,
    groupArr: [[]],
    groupsSelectArray: [],
    corpGroup: [],
    locGroup: [],
    empGroup: [],
    allSelect: [],
    groupTypes: [],
    groupIds: [],

    //mandatory photo upload models
    selectedPhotoModel: null,
    photoLength: 0,
    selectedPhoto: [],
    selectedPhotoUrl: [],

    //Loading models
    fab: false,

    //Notification route flags
    isDetailFlag: false,
    flag: true,
    searchFlag: false,

    //Search modals
    select: null,
    autoLoading: false,
    search: null,
    search2: null,
    searchText: "",
    lastSearchText: "",
    lastEditTime: null,
    intervalTemp: null,
    searchResult: "",
    isCreate: false,
    deleteItemModel: {},
    userId: "",
    sendAll: false,
    isTest: false,
    comboSelect: [],
    comboItems: [],
    linkStatus: false,
    externaLinkStatus: false,
    linkText: "",
    openEmojiPickerDialog: false,
    isLoading: false,

    //Division select modals
    selectedDivision: null,
    divisionItems: [],

    //Date time modals
    dateValue: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    timeValue: "",

    //pagination field
    pagination: {},
    itemsPerPage: 15,
    perPageValues: [
      {
        name: "10",
        value: 10,
      },
      {
        name: "15",
        value: 15,
      },
      {
        name: "30",
        value: 30,
      },
      {
        name: "50",
        value: 50,
      },
      {
        name: "100",
        value: 100,
      },
    ],
  }),
  watch: {
    model(val) {
      if (val.length > 5) {
        this.$nextTick(() => this.model.pop());
      }
    },
    //Every input character is searched
    search(val) {
      val && val !== this.select && this.searchCallNew(val);
      if (val !== "" && val != null) {
      } else {
        this.comboItems = [];
        this.searchResult = "";
      }
    },
    search2(val) {
      val && val !== this.select;
    },
  },
  mounted() {
    this.getDivisions();
    this.setup();
    this.getTableList();
    // this.getUserList();
    this.getRedirectionPages();
  },
  beforeDestroy() {
    if (this.intervalTemp) {
      clearInterval(this.intervalTemp);
      this.intervalTemp = null;
    }
  },
  components: {
    DeleteModal,
    VEmojiPicker,
    DatePicker: () => import("../components/General/DatePicker.vue"),
    TimePicker: () => import("../components/General/TimePicker.vue"),
  },
  computed: {
    dateValueDisplay() {
      return convertToLocal(this.dateValue);
    },
    timeValueDisplay() {
      return this.timeValue;
    },
    totalRecords() {
      return this.items.length;
    },
    pageCount() {
      return this.totalRecords / this.itemsPerPage;
    },
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      ) {
        return 0;
      }

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
  },
  methods: {
    getDivisions() {
      GroupService.getDivisions()
        .then((res) => {
          this.divisionItems = res.data;
          this.divisionItems.unshift({ Id: -1, Name: "Tümü" });
        })
        .catch((err) => {
          window.showError(err);
        })
        .then(() => {});
    },
    changeSendAllState(item) {
      item = !item;
    },

    setup() {
      this.userId = this.$store.state.Id;
      this.selectedDivision = -1;
      this.headers = [
        {
          text: this.$t("title.notificationTitle"),
          value: "Title",
          sortable: false,
        },
        {
          text: this.$t("title.notificationData"),
          value: "Description",
          sortable: false,
        },
        {
          text: this.$t("title.notificationType"),
          value: "TargetType",
          sortable: false,
          align: "center",
        },
        {
          text: this.$t("title.tableHeaderCreateDate"),
          value: "CreatedAt",
          sortable: false,
          align: "center",
        },
        // {
        //   text: this.$t("title.tableHeaderActions"),
        //   value: "actions",
        //   sortable: false,
        // },
        (this.pagination = {
          totalItems: 200,
          rowsPerPage: this.itemsPerPage,
          page: 1,
        }),
      ];
      this.notificationModel = {
        Title: "",
        Description: "",
      };
    },
    clearModal() {
      this.notificationModel = {
        Title: "",
        Description: "",
      };
      this.$refs.observer.reset();
      this.openEmojiPickerDialog = false;
      this.sendAll = false;
      this.comboSelect = [];
      this.selectedModuleModal = "";
      this.selectedInnerModuleModal = "";
      this.flag = false;
      this.searchFlag = false;
      this.groupArr = [[]];
      this.selectedPhotoModel = null;
      this.photoLength = 0;
      this.dateValue = new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10);
      this.timeValue = "";
    },
    //Fills table data
    getTableList() {
      this.$store.state.loadingStatus = true;
      // this.loadings.table = true;
      this.tableData = [];

      NotificationService.getNotifications(
        this.pagination.rowsPerPage,
        this.pagination.page
      )
        .then((res) => {
          res.data.Result.forEach((element) => {
            if (element.DeletedAt === null) {
              this.tableData.push(element);
            }
          });
          // this.loadings.table = false;
          this.$store.state.loadingStatus = false;
          this.pagination.totalItems = res.data.TotalItems;
        })
        .catch((err) => {
          window.handleServiceError(err);
          this.$store.state.loadingStatus = false;
        })
        .then(() => {});
    },
    //Gets redirection pages
    getRedirectionPages() {
      this.pageList = [];

      AccountService.getRedirectPages()
        .then((res) => {
          console.log("res redirect pages >> ", res.data);
          this.pageList = res.data;
        })
        .catch((err) => {
          window.showError(err);
        });
    },
    //Gets users lists for combo box
    getUserList() {
      this.$store.state.loadingStatus = true;
      let pageSize = 100;
      let page = 1;
      UserService.getUsers(" ", pageSize, page)
        .then((res) => {
          // this.comboItems = res.data.Data;
        })
        .catch((err) => {
          window.handleServiceError(err);
        })
        .then(() => {
          // this.loadings.table = false;
          // this.$store.state.loadingStatus = false;
        });
    },

    //Pop sticky button on scroll 40px
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 40;
    },
    //Sticky button publish operation
    async stickyPublishOperation() {
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        this.publish();
      }
    },
    // Emoji picker
    selectEmoji(emoji) {
      if (this.notificationModel.Description !== null) {
        this.notificationModel.Description = `${this.notificationModel.Description} ${emoji.data}`;
      } else {
        this.notificationModel.Description = emoji.data;
      }
    },
    openEmojiPicker() {
      this.openEmojiPickerDialog = true;
    },
    //Details search activated
    activateDetails() {
      this.isDetailFlag = !this.isDetailFlag;
      clearInterval(this.intervalTemp);
      this.intervalTemp = null;
    },
    //Details column is deactivated
    closeDetails(item) {
      this.isDetailFlag = true;
      this.flag = false;
      clearInterval(this.intervalTemp);
      this.intervalTemp = null;
      this.searchText = "";
      this.lastSearchText = "";
      this.allInnerModules = [];
      this.autoLoading = false;
    },

    searchCallNewTest() {
      let pageSize = 99999;
      let page = 1;
      this.isLoading = true;
      UserService.getUsers(this.searchText, pageSize, page)
        .then((res) => {
          this.comboItems = res.data.Data;
          if (this.comboItems.length <= 0) {
            this.searchResult =
              "Aradığınız kriterde kullanıcı bulunamadı.Yeniden deneyiniz.";
          }
        })
        .catch((err) => {
          window.handleServiceError(err);
        })
        .then(() => {
          // this.loadings.table = false;
          // this.$store.state.loadingStatus = false;
          this.isLoading = false;
        });
    },
    //Search operation new
    searchCallNew(val) {
      this.searchText = val;
      // if (
      //   this.lastEditTime !== null &&
      //   this.lastSearchText !== this.searchText &&
      //   new Date().getTime() - this.lastEditTime > 1000 &&
      //   this.searchText !== ""
      // ) {
      //   let pageSize = 99999;
      //   let page = 1;
      //   this.isLoading = true;
      //   if (this.searchText == "" || this.searchText == null) {
      //     UserService.getUsers("", pageSize, page)
      //       .then((res) => {
      //         this.comboItems = res.data.Data;
      //       })
      //       .catch((err) => {
      //         window.showInfo(
      //           this.$t("description.tableLoadingError"),
      //           "standart",
      //           "error"
      //         );
      //       })
      //       .then(() => {
      //         // this.loadings.table = false;
      //         // this.$store.state.loadingStatus = false;
      //         this.isLoading = false;
      //       });
      //   } else {
      //     this.isLoading = true;
      //     UserService.getUsers(this.searchText, pageSize, page)
      //       .then((res) => {
      //         this.comboItems = res.data.Data;
      //         if (this.comboItems.length <= 0) {
      //           this.searchResult =
      //             "Aradığınız kriterde kullanıcı bulunamadı.Yeniden deneyiniz.";
      //         }
      //       })
      //       .catch((err) => {
      //         window.showInfo(
      //           this.$t("description.tableLoadingError"),
      //           "standart",
      //           "error"
      //         );
      //       })
      //       .then(() => {
      //         // this.loadings.table = false;
      //         // this.$store.state.loadingStatus = false;
      //         this.isLoading = false;
      //       });
      //   }
      // }
    },
    //Search operation new end
    //Search operation
    searchCall() {
      if (
        this.lastEditTime !== null &&
        this.lastSearchText !== this.searchText &&
        new Date().getTime() - this.lastEditTime > 1000 &&
        this.searchText !== ""
      ) {
        let pageSize = 99999;
        let page = 1;
        this.isLoading = true;
        if (this.searchText == "" || this.searchText == null) {
          UserService.getUsers("", pageSize, page)
            .then((res) => {
              this.comboItems = res.data.Data;
            })
            .catch((err) => {
              window.handleServiceError(err);
            })
            .then(() => {
              // this.loadings.table = false;
              // this.$store.state.loadingStatus = false;
              this.isLoading = false;
            });
        } else {
          this.isLoading = true;
          UserService.getUsers(this.searchText, pageSize, page)
            .then((res) => {
              this.comboItems = res.data.Data;
              if (this.comboItems.length <= 0) {
                this.searchResult =
                  "Aradığınız kriterde kullanıcı bulunamadı.Yeniden deneyiniz.";
              }
            })
            .catch((err) => {
              window.handleServiceError(err);
            })
            .then(() => {
              // this.loadings.table = false;
              // this.$store.state.loadingStatus = false;
              this.isLoading = false;
            });
        }

        //TODO service call
        // let param = {
        //   Search: this.searchText,
        // };
        // this.allInnerModules = [];

        // if (this.selectedModuleModal.ModuleKey == "kurumsal_haberler") {
        //   ContentService.getPosts(param)
        //     .then((res) => {
        //       console.log("Search res haber>", res.data);
        //       res.data.Data.forEach((x) => {
        //         this.allInnerModules.push({ Key: x.Id, Value: x.Title });
        //       });
        //     })
        //     .catch((err) => {
        //       window.showError(err);
        //     })
        //     .then(() => {});
        // } else if (this.selectedModuleModal.ModuleKey == "anketler") {
        //   SurveyService.getSurveys(param)
        //     .then((res) => {
        //       console.log("Search res anket> ", res.data);
        //       res.data.forEach((x) => {
        //         this.allInnerModules.push({ Key: x.Id, Value: x.Name });
        //       });
        //     })
        //     .catch((err) => {
        //       window.showError(err);
        //     })
        //     .then(() => {});
        // }
        //Clear search props
        this.lastSearchText = this.searchText;
        clearInterval(this.intervalTemp);
        this.intervalTemp = null;
      }
    },

    querySelections(v) {
      this.lastEditTime = new Date().getTime();
      this.searchText = v;
      if (!this.intervalTemp) {
        this.intervalTemp = setInterval(() => {
          this.searchCall();
        }, 2000);
      }
    },

    //File start //////////////////////////////////////////
    //Local file choose open
    browsePhoto() {
      this.$refs.uploader.click();
    },
    // Local file choose
    selectFileHandle(e) {
      if (e.target.files[0] === undefined) {
      } else {
        this.uploadFile(e.target.files[0]);
      }
    },
    onFileChange(e) {
      if (e.target.files[0] === undefined) {
      } else {
        this.selectedPhotoModel = e.target.files[0];
        this.uploadPhoto(this.selectedPhotoModel);
      }
    },
    //Return file url to send service
    computedUrl(file) {
      return URL.createObjectURL(file);
    },
    //Photo upload to service
    uploadPhoto(media) {
      let formData = new FormData();

      if (media === undefined) {
        console.log("Undefined media");
      } else {
        if (media.size > 20000000) {
          window.showInfo(
            this.$t("description.fileSizeError"),
            "standart",
            "error"
          );
        } else {
          this.selectedPhoto.push(media);
          this.tempFiles.push(media);
          this.selectedPhotoUrl.push(this.computedUrl(media));
          this.photoLength = 1;
        }
      }
    },
    //Photo delete
    deletePhoto(photo, index) {
      this.selectedPhoto.splice(index, 1);
      this.selectedPhotoUrl.splice(index, 1);
      this.selectedPhotoModel = null;

      this.photoLength--;
      this.fileIds.splice(index, 1);
    },
    //File end //////////////////////////////////////////

    //Create started
    sendForm() {
      this.publish();
    },
    //Upload photo on UI
    async uploadFiles(index) {
      if (index < this.tempFiles.length) {
        this.uploadFileCall(this.tempFiles[index], index);
      } else {
        this.publishCall();
      }
    },
    //Upload photo call
    async uploadFileCall(media, index) {
      if (media === undefined) {
        console.log("Undefined media");
      }
      let formData = new FormData();
      if (
        media.type == "image/jpeg" ||
        media.type == "image/png" ||
        media.type == "video/mp4"
      ) {
        formData.append("Files", media);
        formData.append("Type", "media");
      } else {
        formData.append("Files", media);
        formData.append("Type", "document");
      }

      await Promise.resolve(
        FileService.uploadFile(formData)
          .then((res) => {
            this.fileIds.push(res.data[0].Id);
          })
          .catch((err) => {
            window.handleServiceError(err);
          })
          .then(() => {
            this.uploadFiles(index + 1);
          })
      );
    },
    // Fills the detailed selectbox data
    fillInnerSelectPage(modal) {
      this.flag = true;
      this.isDetailFlag = false;
      this.linkText = "";
      this.allInnerModules = [];

      if (
        modal.ModuleKey != null &&
        modal.ModuleKey != "anketler" &&
        modal.ModuleKey != "bugun_doganlar"
      ) {
        this.autoLoading = true;
        ContentService.getPosts({ key: modal.ModuleKey })
          .then((res) => {
            res.data.Data.forEach((x) => {
              this.allInnerModules.push({
                Key: x.Id,
                Value: x.Title
                  ? x.Title
                  : x.Content
                  ? x.Content.split(" ").slice(0, 5)
                  : x.User.FullName + " Gönderisi",
              });
            });
          })
          .catch((err) => {
            window.handleServiceError(err);
            this.autoLoading = false;
          })
          .then(() => {
            this.autoLoading = false;
          });
      } else if (modal.ModuleKey == null && modal.ModuleKey == "anketler") {
        this.autoLoading = true;
        SurveyService.getSurveys(100)
          .then((res) => {
            res.data.Rsult.forEach((x) => {
              this.allInnerModules.push({ Key: x.Id, Value: x.Name });
            });
          })
          .catch((err) => {
            window.handleServiceError(err);
            this.autoLoading = false;
          })
          .then(() => {
            this.autoLoading = false;
          });
      } else if (modal.ModuleKey != null && modal.ModuleKey == "anketler") {
        this.autoLoading = true;
        SurveyService.getSurveys(100)
          .then((res) => {
            res.data.Result.forEach((x) => {
              this.allInnerModules.push({ Key: x.Id, Value: x.Name });
            });
          })
          .catch((err) => {
            window.handleServiceError(err);
            this.autoLoading = false;
          })
          .then(() => {
            this.autoLoading = false;
          });
      } else {
        this.autoLoading = false;
      }
    },
    //Create notification
    async publish() {
      this.$store.state.loadingStatus = true;
      // this.createGroupIds();

      // if (this.tempFiles.length > 0) {
      //   await this.uploadFiles(0);
      // } else {
      //   this.publishCall();
      // }
      this.publishCall();
    },
    //Create notification and call service
    publishCall() {
      var sendedAt = this.dateValue + " " + this.timeValue;
      console.log("SENDED AT >> ", sendedAt);
      //If the group selection is empty, select all groups
      // if (this.groupModel.Groups[0].length < 1) {
      //   this.notificationModel.GroupIds = this.allSelect.filter(
      //     (x) => x.Id != ""
      //   );
      // }
      // this.notificationModel.GroupIds = this.groupModel.Groups;
      // this.notificationModel.FileIds = this.fileIds;
      if (this.sendAll) {
        //Send all groups and users
        let obj = {
          LinkType: !this.selectedModuleModal.IsLink
            ? "screen"
            : this.selectedModuleModal.LinkStatus
            ? "link"
            : "external_link",
          Link: !this.selectedModuleModal.IsLink
            ? this.selectedModuleModal.Key
            : this.linkText,
          Extra:
            this.selectedInnerModuleModal != ""
              ? this.selectedInnerModuleModal
              : "",
        };
        let tempObj = [[]];
        tempObj[0].push("all");
        // tempObj = tempObj[0].filter((x) => {
        //   return x !== undefined || null;
        // });
        // console.log("Temp obj2 >> ", tempObj);
        let body = {
          Title: this.notificationModel.Title,
          Description: this.notificationModel.Description,
          // FileIds:
          //   this.notificationModel.FileIds.length > 0
          //     ? this.notificationModel.FileIds
          //     : "",
          Target: tempObj,
          TargetType: "Topic",
          Data: JSON.stringify(obj),
          SendedAt: sendedAt,
        };
        this.sendNotificationService(body);
        console.log("BODY >> ", body);
      } else if (this.isTest) {
        //Send test notification
        let obj = {
          LinkType: !this.selectedModuleModal.IsLink
            ? "screen"
            : this.selectedModuleModal.LinkStatus
            ? "link"
            : "external_link",
          Link: !this.selectedModuleModal.IsLink
            ? this.selectedModuleModal.Key
            : this.linkText,
          Extra:
            this.selectedInnerModuleModal != ""
              ? this.selectedInnerModuleModal
              : "",
        };
        let tempObj = [[]];
        tempObj[0].push("test");
        // tempObj = tempObj[0].filter((x) => {
        //   return x !== undefined || null;
        // });
        // console.log("Temp obj2 >> ", tempObj);
        let body = {
          Title: this.notificationModel.Title,
          Description: this.notificationModel.Description,
          // FileIds:
          //   this.notificationModel.FileIds.length > 0
          //     ? this.notificationModel.FileIds
          //     : "",
          Target: tempObj,
          TargetType: "Topic",
          Data: JSON.stringify(obj),
          SendedAt: sendedAt,
          IsTest: this.isTest,
        };
        this.sendNotificationService(body);
        console.log("BODY test >> ", body);
      } else {
        let body;

        let obj = {
          LinkType: !this.selectedModuleModal.IsLink
            ? "screen"
            : this.selectedModuleModal.LinkStatus
            ? "link"
            : "external_link",
          Link: !this.selectedModuleModal.IsLink
            ? this.selectedModuleModal.Key
            : this.linkText,
          Extra:
            this.selectedInnerModuleModal != ""
              ? this.selectedInnerModuleModal
              : "",
        };
        //Control selected users or groups
        if (this.comboSelect.length > 0) {
          let arr = [[]];
          this.comboSelect.forEach((element) => {
            arr[0].push(element.Id);
          });
          arr = arr[0].filter((x) => {
            return x !== undefined || null;
          });
          let arr2 = [[]];

          arr.forEach((element) => {
            arr2[0].push(element);
          });
          //User call
          body = {
            Title: this.notificationModel.Title,
            Description: this.notificationModel.Description,
            // FileIds:
            //   this.notificationModel.FileIds.length > 0
            //     ? this.notificationModel.FileIds
            //     : "",
            Target: arr2,
            TargetType: "User",
            Data: JSON.stringify(obj),
            SendedAt: sendedAt,
          };
          this.sendNotificationService(body);
          console.log("BODY >> ", body);
        } else if (this.groupArr.length > 0) {
          //Group call
          body = {
            Title: this.notificationModel.Title,
            Description: this.notificationModel.Description,
            // FileIds:
            //   this.notificationModel.FileIds.length > 0
            //     ? this.notificationModel.FileIds
            //     : "",
            Target:
              this.selectedDivision != -1 ? [[this.selectedDivision]] : [[]],
            TargetType: "Topic",
            Data: JSON.stringify(obj),
            SendedAt: sendedAt,
          };
          this.sendNotificationService(body);
          console.log("BODY >> ", body);
        }
      }
    },

    sendNotificationService(body) {
      console.log("BODY >> ", body);

      if (body.Target === undefined) {
        window.showInfo(
          this.$t("description.notificationTargetError"),
          "standart",
          "error"
        );
        this.$store.state.loadingStatus = false;
        return;
      } else {
        NotificationService.createNotification(body)
          .then((res) => {
            console.log("Notification send", res);
          })
          .catch((err) => {
            window.handleServiceError(err);
          })
          .then(() => {
            this.$store.state.loadingStatus = false;
            this.isCreate = false;
            this.getTableList();
            this.clearModal();
          });
      }
    },

    //Opens modal for deletion operation
    openDeleteModal(item) {
      this.deleteItemModel = item;
      this.$refs.deleteModalRef.modalStatus = true;
    },
    //Delete operation
    deleteItem() {
      // this.loadings.table = true;
      this.$store.state.loadingStatus = true;
      NotificationService.deleteNotification(this.deleteItemModel.Id)
        .then(() => {
          this.$store.state.loadingStatus = false;
          this.$refs.deleteModalRef.modalStatus = false;
          this.getTableList();
          this.getMenuData();
        })
        .catch((err) => {
          window.handleServiceError(err);
        })
        .then(() => {
          // this.loadings.table = false;
          // this.$store.state.loadingStatus = false;
        });
    },

    changeItemsPerPage() {
      this.pagination.rowsPerPage = this.itemsPerPage;
      this.pagination.page = 1;
      this.options.itemsPerPage = this.itemsPerPage;
      this.getTableList();
    },
    paginationChangeHandler(pageNumber) {
      this.pagination.page = pageNumber;
      this.getTableList();
    },

    ...mapActions(["getMenuData"]),
  },
};
</script>

<style>
.error--text {
  font-size: 14px;
}

.ck-editor__editable {
  min-height: 278px;
}

.ck-content {
  border-bottom-left-radius: 11px !important;
  border-bottom-right-radius: 11px !important;
}

.ck-toolbar {
  border-top-left-radius: 11px !important;
  border-top-right-radius: 11px !important;
  background: #fafafa !important;
}

.ck.ck-toolbar {
  background: transparent;
  background-image: linear-gradient(#ffffff, #ededed);
}
</style>
