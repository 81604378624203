<template>
  <div>
    <!-- Module modal -->
    <v-dialog persistent v-model="modulDetailStatus" max-width="400">
      <v-card>
        <v-card-title class="headline indigo lighten-2">
          <div class="text-center white--text" style="width: 100%">
            {{ this.modalHeader }}
          </div>
        </v-card-title>
        <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
          <form @submit.prevent="handleSubmit(sendForm)">
            <v-card-text class="py-3">
              <v-row>
                <v-col md="12" xs="12">
                  <v-checkbox
                    v-model="isStatic"
                    :label="$t('labels.modulIsStatic')"
                  ></v-checkbox>
                  <ValidationProvider
                    name="İsim"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      v-model="moduleModel.Name"
                      :value="moduleModel.Name"
                      :label="$t('labels.modulName')"
                      :error-messages="errors[0]"
                    ></v-text-field>
                  </ValidationProvider>

                  <ValidationProvider
                    name="Yol Dizini"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      v-model="moduleModel.Route"
                      :value="moduleModel.Route"
                      :label="$t('labels.modulRoutue')"
                      :error-messages="errors[0]"
                    ></v-text-field>
                  </ValidationProvider>

                  <ValidationProvider
                    name="Anahtar"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      :disabled="!!editItemId"
                      v-model="moduleModel.Key"
                      :value="moduleModel.Key"
                      @keypress="controlKeyChar($event)"
                      :label="$t('labels.modulKey')"
                      :error-messages="errors[0]"
                    ></v-text-field>
                  </ValidationProvider>

                  <v-autocomplete
                    v-model="moduleModel.Icon"
                    :items="iconItems"
                    class="mt-2"
                    clearable
                    item-text="text"
                    item-value="value"
                    label="Icon"
                  >
                    <template v-slot:selection="{ item }">
                      <v-icon color="grey darken-2" class="mr-3 mb-1">{{
                        item.value
                      }}</v-icon>
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.text"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </template>

                    <template v-slot:item="{ item }">
                      <v-list-item-avatar
                        color="grey darken-2"
                        class="text-h5 font-weight-light white--text"
                      >
                        <v-icon color="white">{{ item.value }}</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.text"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>

                  <v-textarea
                    outlined
                    v-model="moduleModel.LongDescription"
                    :value="moduleModel.LongDescription"
                    :label="$t('labels.modulDescription')"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-actions
              style="
                background-color: white;
                position: sticky;
                bottom: 0px;
                border-top: solid grey 2px;
              "
            >
              <v-spacer></v-spacer>
              <v-btn color="primary" type="submit" :loading="loadings.send">
                {{ $t("buttons.save") }}
              </v-btn>
              <v-btn
                color="primary"
                text
                @click="
                  modulDetailStatus = false;
                  clearModal();
                "
              >
                {{ $t("buttons.close") }}
              </v-btn>
            </v-card-actions>
          </form>
        </ValidationObserver>
      </v-card>
    </v-dialog>
    <!-- Module deletion modal -->
    <delete-modal :yesFunc="deleteItem" ref="deleteModalRef" />
    <!-- Page content -->
    <v-card style="border-radius: 10px">
      <v-card-title style="color: #595959">
        <v-row>
          <v-col class="py-2">{{ $t("title.modulManagement") }}</v-col>
          <v-spacer />
          <v-btn
            small
            class="ma-2 mr-4"
            elevation="0"
            outlined
            color="info"
            @click="
              modulDetailStatus = true;
              modalHeader = $t('title.moduleModalCreate');
            "
          >
            {{ $t("buttons.addNew") }}
          </v-btn>
        </v-row>
      </v-card-title>

      <v-divider />

      <v-card-text class="pt-4">
        <v-data-table
          :headers="headers"
          :items="tableData"
          :loading="loadings.table"
          :options.sync="options"
          :footer-props="{ 'items-per-page-options': [5, 10, 15, 30] }"
          :no-data-text="$t('description.noList')"
        >
          <!-- eslint-disable -->
          <template v-slot:item.CreatedAt="{ item }">
            {{ convertToLocal(item.CreatedAt) }}
          </template>

          <template v-slot:item.Icon="{ item }">
            <v-icon>{{ item.Icon }}</v-icon>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  class="mr-2"
                  @click="routeToCustom(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-star
                </v-icon>
              </template>
              <span>{{ $t("labels.tooltipCustomFiels") }}</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  class="mr-2"
                  @click="getModuleDetails(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-pencil
                </v-icon>
              </template>
              <span>{{ $t("labels.tooltipEdit") }}</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  class="mr-2"
                  @click="openDeleteModal(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-delete
                </v-icon>
              </template>
              <span>{{ $t("labels.tooltipDelete") }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import ModuleService from "../services/ModuleService";
import DeleteModal from "../components/General/DeleteModal";
import { mapActions } from "vuex";
export default {
  data: () => ({
    // List options
    options: {
      itemsPerPage: 15,
    },
    top: 15,
    skip: 0,
    tableData: [],
    headers: [],
    //Loading states
    loadings: {
      table: false,
      send: false,
    },
    //Initial models
    modulDetailStatus: false,
    isStatic: false,
    moduleModel: {
      Name: "",
      Route: "",
      Key: "",
      LongDescription: "",
      Icon: "mdi-home",
      Type: "",
    },
    deleteItemModel: {},
    editItemId: null, //Inspected item ID
    modalHeader: "", //Global header for opened modal

    iconItems: [
      { text: "Ana Sayfa", value: "mdi-home" },
      { text: "E Harfi", value: "mdi-abjad-arabic" },
      { text: "Kullanıcı", value: "mdi-account-reactivate" },
      { text: "Uçak", value: "mdi-airplane-landing" },
      { text: "Kitap", value: "mdi-book" },
      { text: "Sonsuzluk", value: "mdi-all-inclusive-box" },
      { text: "Uçan Balon", value: "mdi-airballoon" },
      { text: "Pergel", value: "mdi-android-studio" },
      { text: "Çarpraz ok", value: "mdi-arrow-top-right-thin-circle-outline" },
      { text: "Batarya Zayıf", value: "mdi-battery-charging-low" },
    ],
  }),
  watch: {},
  mounted() {
    this.setup();
    this.getTableList();
  },
  components: {
    DeleteModal,
  },
  methods: {
    //Page setup
    setup() {
      this.modalHeader = this.$t("title.createSurvey");
      this.headers = [
        {
          text: this.$t("title.tableHeaderIcon"),
          value: "Icon",
          sortable: false,
        },
        {
          text: this.$t("title.tableHeaderName"),
          value: "Name",
          sortable: false,
        },
        {
          text: this.$t("title.tableHeaderKey"),
          value: "Key",
          sortable: false,
        },
        {
          text: this.$t("title.tableHeaderCreateDate"),
          value: "CreatedAt",
          sortable: false,
        },
        {
          text: this.$t("title.tableHeaderActions"),
          value: "actions",
          sortable: false,
        },
      ];
    },
    //Clears all modal's states and item ID
    clearModal() {
      this.moduleModel = {
        Name: "",
        Route: "",
        Key: "",
        LongDescription: "",
        Icon: "mdi-home", //Default icon pre-production
        Type: "",
      };
      this.modalHeader = "";
      this.$refs.observer.reset();
      this.editItemId = null;
      this.isStatic = false;
    },
    //Inspect an item in list
    getModuleDetails(item) {
      this.modalHeader = this.$t("title.moduleModalUpdate");
      this.editItemId = item.Id;
      console.log("ITEM >> ", item);
      this.moduleModel = {
        Name: item.Name,
        Route: item.Route,
        Key: item.Key,
        Icon: item.Icon,
        LongDescription: item.LongDescription,
        Type: item.Type,
      };
      this.moduleModel.Type === "static"
        ? (this.isStatic = true)
        : (this.isStatic = false);
      this.modulDetailStatus = true;
    },
    //Fill's the module list
    getTableList() {
      this.loadings.table = true;
      ModuleService.getPages()
        .then((res) => {
          this.tableData = res.data;
        })
        .catch((err) => {
          window.handleServiceError(err);
        })
        .then(() => {
          this.loadings.table = false;
        });
    },
    //Submits the form whether it is an update operation or create operation
    sendForm() {
      if (window.controlKey(this.moduleModel.Key)) {
        this.isStatic == true
          ? (this.moduleModel.Type = "static")
          : (this.moduleModel.Type = "custom");
        if (this.editItemId) {
          this.updateModule();
        } else {
          this.createModule();
        }
      } else {
        window.showInfo(this.$t("description.invalidKey"), "standart", "error");
      }
    },
    //Update operation
    updateModule() {
      this.loadings.table = true;
      ModuleService.updateModuleDetails(this.editItemId, this.moduleModel)
        .then((res) => {
          this.getTableList();
          this.modulDetailStatus = false;
          this.clearModal();
          this.getMenuData();
        })
        .catch((err) => {
          window.handleServiceError(err);
        })
        .then(() => {
          this.loadings.table = false;
        });
    },
    //Create operation
    createModule() {
      this.loadings.send = true;
      ModuleService.createModule(this.moduleModel)
        .then((res) => {
          this.getTableList();
          this.modulDetailStatus = false;
          this.clearModal();
          this.getMenuData();
        })
        .catch((err) => {
          window.handleServiceError(err);
        })
        .then(() => {
          this.loadings.send = false;
        });
    },
    //Delete operation
    deleteItem() {
      this.loadings.table = true;
      ModuleService.deleteModule(this.deleteItemModel.Id)
        .then(() => {
          this.getTableList();
          this.$refs.deleteModalRef.modalStatus = false;
          this.getMenuData();
        })
        .catch((err) => {
          window.handleServiceError(err);
        })
        .then(() => {
          this.loadings.table = false;
        });
    },
    //Opens modal for deletion operation
    openDeleteModal(item) {
      this.deleteItemModel = item;
      this.$refs.deleteModalRef.modalStatus = true;
    },
    //Routes to the custom field page
    routeToCustom(item) {
      this.$router.push({ path: `custom_fields`, query: { key: item.Key } });
    },
    //Input control for KEY variable
    controlKeyChar(e) {
      window.controlKeyChar(e);
    },

    ...mapActions(["getMenuData"]),
  },
};
</script>
