<template>
  <div>
    <!-- Change password dialog start -->
    <v-dialog max-width="500" v-model="changePasswordModal" persistent>
      <v-card>
        <v-card-title class="text-h4 text-center justify-center mb-5">
          {{ $t("labels.changePassword") }}
        </v-card-title>
        <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
          <form @submit.prevent="handleSubmit(changePassword)">
            <v-card-text
              class="font-weight-bold pl-8 body-1"
              style="line-height: 1.8; color: #7443a0;"
            >
              <v-text-field
                :label="$t('labels.currentPassword')"
                v-model="currentPassword"
                :rules="exampleRules"
                :append-icon="showCurrentPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showCurrentPassword ? 'text' : 'password'"
                @click:append="showCurrentPassword = !showCurrentPassword"
              ></v-text-field>

              <v-text-field
                :label="$t('labels.newPassword')"
                v-model="newPassword"
                :rules="[
                  newPassword != '' || 'Field required',
                  newPassword.length >= 8 ||
                    'Bu alan en az 8 karakter olmalıdır',
                  newPassword.length <= 16 ||
                    'Bu alan izin verilen maksimum 16 karakteri aşıyor',
                  reNewPassword == newPassword ||
                    'Şifre yeni şifre ile eşleşmiyor',
                ]"
                :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showNewPassword ? 'text' : 'password'"
                @click:append="showNewPassword = !showNewPassword"
                ref="newPassword"
              ></v-text-field>

              <v-text-field
                :label="$t('labels.reNewPassword')"
                v-model="reNewPassword"
                :rules="[
                  reNewPassword != '' || 'Bu alan zorunludur',
                  reNewPassword.length >= 8 ||
                    'Bu alan en az 8 karakter olmalıdır',
                  reNewPassword.length <= 16 ||
                    'Bu alan izin verilen maksimum 16 karakteri aşıyor',
                  reNewPassword == newPassword ||
                    'Şifre yeni şifre ile eşleşmiyor',
                ]"
                :append-icon="showReNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showReNewPassword ? 'text' : 'password'"
                @click:append="showReNewPassword = !showReNewPassword"
              ></v-text-field>
              <!-- </ValidationProvider> -->
            </v-card-text>
            <v-card-actions
              style="
          background-color: white;
          position: sticky;
          bottom: 0px;
        "
            >
              <v-spacer></v-spacer>

              <v-btn
                :disabled="!updateButtonControl"
                color="deep-purple lighten-2"
                text
                @click="changePassword()"
              >
                {{ $t("buttons.update") }}
              </v-btn>

              <v-btn
                color="deep-purple lighten-2"
                text
                v-if="!changePasswordFlag"
                @click="
                  changePasswordModal = false;
                  clearModal();
                "
              >
                {{ $t("buttons.close") }}
              </v-btn>
            </v-card-actions>
          </form>
        </ValidationObserver>
      </v-card>
    </v-dialog>
    <!-- Change password  dialog end -->
    <!-- Logout dialog start -->
    <v-dialog v-model="dialogLogout" persistent max-width="320">
      <v-card>
        <v-card-title class="headline">
          {{ $t("title.logout") }}
        </v-card-title>
        <v-card-text>
          {{ $t("description.logout") }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="LogOut">
            {{ $t("buttons.yes") }}
          </v-btn>
          <v-btn color="green darken-1" text @click="dialogLogout = false">
            {{ $t("buttons.no") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Logout dialog end -->

    <!-- Profile Dialog -->
    <v-dialog v-model="profileDialog" persistent max-width="500">
      <v-card>
        <v-card-title class="headline">
          {{ $t("title.ProfileInfo") }}
        </v-card-title>
        <v-card-text class="mx-0 py-0">
          <v-row class="py-5">
            <v-col align-content="center" cols="12">
              <v-row justify="center">
                <v-img
                  position="center"
                  max-height="100"
                  contain
                  :src="profileData ? profileData.Avatar : null"
                >
                </v-img>
              </v-row>
            </v-col>
          </v-row>
          <v-text-field
            :label="
              $t('labels.userFirstName') + ' ' + $t('labels.userLastname')
            "
            v-model="profileData.FullName"
            disabled
          ></v-text-field>
          <v-text-field
            :label="$t('labels.userName')"
            v-model="profileData.Username"
            disabled
          ></v-text-field>
          <v-text-field
            :label="$t('labels.userType')"
            v-model="profileData.Type"
            disabled
          ></v-text-field>
          <v-text-field
            :label="$t('labels.userPhone')"
            v-model="profileData.Phone"
            disabled
          ></v-text-field>
          <v-text-field
            :label="$t('labels.email')"
            v-model="profileData.Email"
            disabled
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="green darken-1" text @click="profileDialog = false">
            {{ $t("buttons.close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Profile Dialog end  -->
    <v-app-bar
      absolute
      color="white"
      height="50"
      style="padding-left: 25px; padding-right: 15px;"
    >
      <v-spacer />
      <v-toolbar-title>
        <v-img
          height="30"
          style="margin-left: 50px"
          contain
          :src="require('@/../public/img/BSP-Banner-Transparent.png')"
        ></v-img>
        <!--<span class="ml-2 error--text font-weight-medium">~Yapım Aşamasında~</span>-->
      </v-toolbar-title>
      <v-spacer />
      <!-- TODO: guidline update -->
      <!-- Using Guide -->
      <!-- <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-avatar
            class="mr-3"
            v-bind="attrs"
            v-on="on"
            size="35"
            color="#cfcfcf"
          >
            <v-btn
              style="background-color:transparent;color:white"
              :href="
                url +
                  '/admin/downloads/e-Live_Humanious_Admin_Panel_Kullanım_Kılavuzu.pdf'
              "
              download
            >
              <v-icon dark size="15px">
                mdi-book-open-page-variant
              </v-icon>
            </v-btn>
          </v-avatar>
        </template>
        <span>{{ $t("labels.useGuide") }}</span>
      </v-tooltip> -->
      <!-- Using Guide End -->
      <!-- reload button start -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-avatar
            class="mr-3"
            v-bind="attrs"
            v-on="on"
            size="35"
            color="#cfcfcf"
          >
            <v-icon dark size="15px" @click="refreshPage()">
              mdi-archive-refresh
            </v-icon>
          </v-avatar>
        </template>
        <span>{{ $t("labels.hardReload") }}</span>
      </v-tooltip>
      <!-- reload button end -->
      <!-- change password button start -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-avatar
            class="mr-3"
            v-bind="attrs"
            v-on="on"
            size="35"
            color="#cfcfcf"
          >
            <v-icon dark size="15px" @click="changePasswordModal = true">
              mdi-link-lock
            </v-icon>
          </v-avatar>
        </template>
        <span>{{ $t("labels.changePassword") }}</span>
      </v-tooltip>
      <!-- change password button end -->

      <v-menu offset-y open-on-hover>
        <template v-slot:activator="{ on, attrs }">
          <v-avatar v-bind="attrs" v-on="on" size="35" color="#cfcfcf">
            <v-icon dark size="15px"> mdi-account </v-icon>
          </v-avatar>
        </template>
        <v-list>
          <v-list-item link @click="openProfile()">
            <v-list-item-icon class="mr-3">
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ this.name }}</v-list-item-title>
          </v-list-item>
          <v-list-item link>
            <v-list-item-icon class="mr-3">
              <v-icon>mdi-account-box</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ this.userType }}</v-list-item-title>
          </v-list-item>
          <v-list-item link @click="dialogLogout = true">
            <v-list-item-icon class="mr-3">
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t("buttons.logout") }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
  </div>
</template>

<script>
import store from "../store/store";
import { mapActions } from "vuex";
import UserService from "../services/UserService";

export default {
  name: "NavMenu",
  data() {
    return {
      url: "",
      profileData: {},
      dialogLogout: false,
      profileDialog: false,
      name: store.getters.getUserFullName,
      userType: store.getters.getUserType,
      changePasswordModal: false,
      Id: store.getters.getId,
      currentPassword: "",
      newPassword: "",
      reNewPassword: "",
      showCurrentPassword: false,
      showNewPassword: false,
      showReNewPassword: false,
      updateButtonControl: false,
      changePasswordFlag: store.getters.userPasswordControl,
      //#region Validation Rules
      exampleRules: [
        (v) => !!v || "Bu Alan zorunludur",
        (v) => (v && v.length >= 8) || "Bu alan  en az 8 karakter olmalıdır",
        (v) =>
          (v && v.length <= 16) ||
          "Bu alan izin verilen maksimum 16 karakteri aşıyor",
      ],
      exampleRules2: [
        (v) => (v && v.length >= 8) || "Bu alan  en az 8 karakter olmalıdır",
        (v) =>
          (v && v.length <= 16) ||
          "Bu alan izin verilen maksimum 16 karakteri aşıyor",
        (v) =>
          (v && v == this.newPassword) || "Bu alan yeni şifre ile uyuşmuyor",
      ],
      exampleRules3: [
        (v) => (v && v.length >= 8) || "Bu alan  en az 8 karakter olmalıdır",
        (v) =>
          (v && v.length <= 16) ||
          "Bu alan izin verilen maksimum 16 karakteri aşıyor",
        (v) =>
          this.reNewPassword === "" ||
          v == this.reNewPassword ||
          "Bu alan yeni şifre ile uyuşmuyor",
      ],
      //#endregion
    };
  },
  mounted() {
    this.getProfileInfo();
  },
  beforeMount() {
    this.url = process.env.VUE_APP_API_URL;
  },
  watch: {
    reNewPassword: function(val) {
      if (
        val == this.newPassword &&
        val.length >= 8 &&
        this.currentPassword != ""
      ) {
        this.updateButtonControl = true;
      } else {
        this.updateButtonControl = false;
      }
    },
    currentPassword: function(val) {
      if (
        this.reNewPassword == this.newPassword &&
        this.reNewPassword.length >= 8 &&
        val != "" &&
        val.length >= 8
      ) {
        this.updateButtonControl = true;
      } else {
        this.updateButtonControl = false;
      }
    },
    newPassword: function(val) {
      if (
        this.reNewPassword == val &&
        this.reNewPassword.length >= 8 &&
        val != "" &&
        val.length >= 8
      ) {
        this.updateButtonControl = true;
      } else {
        this.updateButtonControl = false;
      }
    },
  },
  methods: {
    clearModal() {
      this.changePasswordModal = false;
      this.currentPassword = "";
      this.newPassword = "";
      this.reNewPassword = "";
      this.$refs.observer.reset();
    },
    LogOut() {
      this.dialogLogout = false;
      this.logout();
    },

    refreshPage() {
      location.reload(true);
    },

    changePassword() {
      this.$store.state.loadingStatus = true;

      let body = {
        OldPassword: this.currentPassword,
        NewPassword: this.newPassword,
      };
      UserService.updatePassword(body)
        .then((res) => {
          console.log("res update password=>", res);
        })
        .catch((err) => {
          window.showError(err);
        })
        .then(() => {
          this.$store.state.loadingStatus = false;
          this.changePasswordModal = false;
          this.LogOut();
        });

      this.clearModal();
    },
    openProfile() {
      this.profileDialog = true;
    },
    getProfileInfo() {
      UserService.getUserDetails(this.Id)
        .then((res) => {
          if(res.data.Data.length > 0){
           this.profileData = res.data.Data[0]; 
          }else{
            window.showInfo('Kullanıcı bilgileri bulunamadı', 'standart', 'error');
            this.LogOut();
          }
        })
        .catch((err) => {
          window.showError(err);
        })
        .then(() => {});
    },

    ...mapActions(["logout"]),
  },
};
</script>
