<template>
  <div>
    <delete-modal
      :yesFunc="deleteItem"
      ref="deleteModalRef"
      :description="$t('description.deleteContent')"
      :title="$t('title.deleteContentTitle')"
    />

    <v-card style="border-radius: 10px">
      <v-card-title style="color: #595959">
        <v-row>
          <v-col class="py-2">{{ moduleDetail.Name }}</v-col>
          <v-spacer />
          <v-btn
            @click="$router.push({ name: 'addContentAdd' })"
            small
            class="ma-2 mr-4"
            elevation="0"
            outlined
            color="info"
          >
            {{ $t("buttons.addNew") }}
          </v-btn>
        </v-row>
      </v-card-title>

      <v-divider />
      <v-layout v-if="pageRules.isCategoryView">
        <v-flex md8 class="pr-10">
          <v-card-text class="pt-4">
            <v-row align="center" justify="space-between">
              <v-col cols="3" class="mb-3">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  @click:append="searchTable"
                  :label="
                    !isRequiredSearchTitle
                      ? $t('labels.searchInTitle')
                      : $t('labels.search')
                  "
                  v-on:keyup.enter="searchTable"
                  @click:clear="searchTable('clear')"
                  clear-icon="mdi-close-circle"
                  clearable
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
            <v-data-table
              :headers="headers"
              :items="tableData"
              :loading="loadings.table"
              :options.sync="options"
              :hide-default-footer="true"
              :footer-props="{
                disablePagination: true,
                disableItemsPerPage: true,
              }"
              :no-data-text="$t('description.noList')"
            >
              <!-- eslint-disable -->
              <template v-slot:item.Title="{ item }">
                <div style="font-weight: bold !important">
                  {{ item.Title }}
                </div>
              </template>
              <template v-slot:item.Content="{ item }">
                <div
                  style="
                    max-height: 40px;
                    max-width: 300px;
                    overflow-y: scroll;
                    overflow-y: hidden;
                  "
                  v-html="
                    item.Content.length > 0 ? item.Content : 'İçeriksiz Gönderi'
                  "
                ></div>
              </template>
              <template v-slot:item.Status="{ item }">
                <div style="font-weight: bold !important">
                  {{ item.Status }}
                </div>
              </template>
              <template v-slot:item.Categories="{ item }">
                <v-tooltip top v-if="item.Categories.length > 0">
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip color="transparent" label v-bind="attrs" v-on="on">
                      <div
                        class="text-truncate text-center"
                        style="width: 150px"
                      >
                        {{ item.Categories.length }} Adet Kategori
                      </div>
                    </v-chip>
                  </template>
                  <span v-html="getCategoryName(item)"></span>
                </v-tooltip>
                <v-tooltip top v-if="item.Categories.length == 0">
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip color="transparent" label v-bind="attrs" v-on="on">
                      <div
                        class="text-truncate text-center"
                        style="width: 150px"
                      >
                        {{ $t("labels.noCategory") }}
                      </div>
                    </v-chip>
                  </template>
                  {{ $t("labels.noCategorySelected") }}
                </v-tooltip>
              </template>
              <template v-slot:item.CreatedAt="{ item }">
                {{ convertToLocal(item.CreatedAt) }}
              </template>
              <template v-slot:item.actions="{ item }">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      small
                      @click="getDetails(item)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-pencil
                    </v-icon>
                  </template>
                  <span>{{ $t("labels.tooltipEdit") }}</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      small
                      v-if="item.Status == 20"
                      @click="publishOperation(item)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-publish-off
                    </v-icon>
                  </template>
                  <span>{{ $t("labels.unPublish") }}</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      small
                      v-if="item.Status == 0"
                      @click="publishOperation(item)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-publish
                    </v-icon>
                  </template>
                  <span>{{ $t("labels.publishItem") }}</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      small
                      @click="openDeleteModal(item)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                  <span>{{ $t("labels.tooltipDelete") }}</span>
                </v-tooltip>
              </template>
            </v-data-table>
            <div class="text-xs-center pt-2 md4 lg4">
              <v-pagination
                @input="paginationChangeHandler"
                :value="pagination.page"
                :length="pages"
                :total-visible="10"
                circle
              ></v-pagination>
            </div>
          </v-card-text>
        </v-flex>

        <v-divider vertical></v-divider>

        <v-flex md4 class="pl-10">
          <v-card-text class="pt-4 mb-3">
            <v-text-field
              v-model="searchCategory"
              :label="$t('labels.searchCategory')"
              hide-details
              clearable
              clear-icon="mdi-close-circle-outline"
            ></v-text-field>
            <v-treeview
              class="pt-10"
              return-object
              v-model="selectedCategoriesModal"
              :items="categoryData"
              selection-type="leaf"
              item-children="Children"
              item-value="Id"
              item-text="CategoryNameTR"
              item-key="Id"
              :search="searchCategory"
              :filter="filter"
              color="#7443a0"
              open-all
              activatable
              open-on-click
              @update:active="selectOneCategory"
              style="overflow: auto; height: 1000px"
            ></v-treeview>
            <template slot-scope="{ item }">
              <a @click="selectOneCategory(item)">{{ item.CategoryNameTR }}</a>
            </template>
          </v-card-text>
        </v-flex>
      </v-layout>
      <v-card-text class="pt-4" v-if="!pageRules.isCategoryView">
        <v-row align="center" justify="space-between">
          <v-col cols="3" class="mb-3">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              @click:append="searchTable"
              :label="
                !isRequiredSearchTitle
                  ? $t('labels.searchInTitle')
                  : $t('labels.search')
              "
              v-on:keyup.enter="searchTable"
              @click:clear="searchTable('clear')"
              clear-icon="mdi-close-circle"
              clearable
              single-line
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
        <v-data-table
          :headers="headers"
          :items="tableData"
          :loading="loadings.table"
          :options.sync="options"
          :hide-default-footer="true"
          :footer-props="{
            disablePagination: true,
            disableItemsPerPage: true,
          }"
          :no-data-text="$t('description.noList')"
        >
          <!-- item.Content.length <= 0
                  ? "İçeriksiz gönderi"
                  : item.Content.charAt(0) === "<"
                  ? item.Content.slice(3, -4)
                  : item.Content -->
          <!-- eslint-disable -->
          <template v-slot:item.Title="{ item }">
            <div style="font-weight: bold !important">
              {{ item.Title ? item.Title : "Sosyal Gönderi" }}
            </div>
          </template>
          <!-- <template v-slot:item.Content="{ item }">
            <div
              style="font-weight: bold !important; display: flex !important;  justify-content: start !important;  align-items: center !important; align-content: center !important; "
              v-html="tableTitle(item)"
            >
              {{}}
            </div>
          </template> -->
          <template v-slot:item.Content="{ item }">
            <div
              style="
                max-height: 40px;
                max-width: 300px;
                overflow-y: scroll;
                overflow-y: hidden;
              "
              v-html="
                item.Content.length > 0 ? item.Content : 'İçeriksiz Gönderi'
              "
            ></div>
          </template>
          <template v-slot:item.Status="{ item }">
            <div style="font-weight: bold !important">
              {{ item.Status }}
            </div>
          </template>
          <template v-slot:item.Categories="{ item }">
            <v-tooltip top v-if="item.Categories.length > 0">
              <template v-slot:activator="{ on, attrs }">
                <v-chip color="transparent" label v-bind="attrs" v-on="on">
                  <div class="text-truncate text-center" style="width: 150px">
                    {{ item.Categories.length }} Adet Kategori
                  </div>
                </v-chip>
              </template>
              <span v-html="getCategoryName(item)"></span>
            </v-tooltip>
            <v-tooltip top v-if="item.Categories.length == 0">
              <template v-slot:activator="{ on, attrs }">
                <v-chip color="transparent" label v-bind="attrs" v-on="on">
                  <div class="text-truncate text-center" style="width: 150px">
                    {{ $t("labels.noCategory") }}
                  </div>
                </v-chip>
              </template>
              {{ $t("labels.noCategorySelected") }}
            </v-tooltip>
          </template>
          <template v-slot:item.User="{ item }">
            {{ item.User.FullName }} 
          </template>
          <template v-slot:item.CreatedAt="{ item }">
            {{ convertToLocal(item.CreatedAt) }}
          </template>
          <template v-slot:item.actions="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  @click="getDetails(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-pencil
                </v-icon>
              </template>
              <span>{{ $t("labels.tooltipEdit") }}</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  v-if="item.Status == 20"
                  @click="publishOperation(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-publish-off
                </v-icon>
              </template>
              <span>{{ $t("labels.unPublish") }}</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  v-if="item.Status == 0"
                  @click="publishOperation(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-publish
                </v-icon>
              </template>
              <span>{{ $t("labels.publishItem") }}</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  @click="openDeleteModal(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-delete
                </v-icon>
              </template>
              <span>{{ $t("labels.tooltipDelete") }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
        <div class="text-xs-center pt-2 md4 lg4">
          <v-pagination
            @input="paginationChangeHandler"
            :value="pagination.page"
            :length="pages"
            :total-visible="10"
            circle
          ></v-pagination>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import ContentService from "../services/ContentService";
import ModuleService from "../services/ModuleService";
import PostService from "../services/PostService";
import CategoryService from "../services/CategoryService";
import DeleteModal from "../components/General/DeleteModal";

export default {
  data: () => ({
    //List options
    options: {
      itemsPerPage: 15,
    },
    top: 15,
    skip: 0,
    tableData: [],
    key: "",
    //Table contents
    headers: [],

    //Page loaders
    loadings: {
      table: false,
      send: false,
    },

    //Page detail
    moduleDetail: {},

    //delete model item
    deleteItemModel: {},

    search: "",
    //Pagination models
    pagination: {},
    itemsPerPage: 15,
    perPageValues: [10, 15, 30, 50, 100],
    pageHeader: "",
    pageId: -1,
    moduleDescription: "",
    //Page restrictions
    pageRules: null,
    selectedCategoriesModal: [],
    categoryData: [],
    searchCategory: "",
  }),
  watch: {},
  beforeMount() {
    this.pageRules = getPageRulesById(this.$route.params.id);
  },
  mounted() {
    this.setup();
    this.getModuleDetail();
  },
  components: {
    DeleteModal,
  },
  computed: {
    totalRecords() {
      return this.items.length;
    },
    pageCount() {
      return this.totalRecords / this.itemsPerPage;
    },
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      ) {
        return 0;
      }

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },

    isRequiredSearchTitle() {
      switch (this.key) {
        case "ik_el_kitabi":
          return false;

        default:
          return true;
      }
    },
    filter() {
      return this.true
        ? (item, searchCategory, textKey) =>
            item[textKey].indexOf(searchCategory) > -1
        : undefined;
    },
  },
  methods: {
    tableTitle(item) {
      return item.Content <= 0 ? "İçeriksiz Gönderi" : item.Content;
    },

    async setup() {
      this.headers = [
        { text: "Başlık", value: "Title", sortable: false },
        { text: "İçerik", value: "Content", sortable: false },
        {
          text: "Kategoriler",
          value: "Categories",
          sortable: false,
          align: "center",
        },
        { text: "Oluşturan", value: "User.FullName", sortable: false, align: "center", },
        { text: "Oluşturulma Tarihi", value: "CreatedAt", sortable: false },
        { text: "İşlem", value: "actions", sortable: false },
        (this.pagination = {
          totalItems: 200,
          rowsPerPage: this.itemsPerPage,
          page: 1,
        }),
      ];
      await this.getCategories();
    },
    async getCategories() {
      return new Promise(async (resolve, reject) => {
        try {
          if (this.pageRules.categoryIds.length > 0) {
            await Promise.all(
              this.pageRules.categoryIds.map(async (element, index) => {
                await CategoryService.getCategoriesInTreeView(element.id)
                  .then((res) => {
                    this.categoryData = this.categoryData.concat(res.data);
                  })
                  .catch((err) => {
                    window.handleServiceError(res, err);
                  })
                  .then(() => {});
              })
            );
          }
          resolve(true);
        } catch (error) {
          reject(error);
        }
      });
    },
    //Change list pagination page
    paginationChangeHandler(pageNumber) {
      this.pagination.page = pageNumber;
      this.getTableList();
    },
    //Loads page detail
    getModuleDetail() {
      ModuleService.getModuleDetails(this.$route.params.id)
        .then((res) => {
          this.moduleDetail = res.data;
          console.log("MODULE DETAIL > ", this.moduleDetail);
          this.pageId = this.moduleDetail.Id;
          this.moduleDescription = res.data.LongDescription;
          this.key = res.data.Key;

          this.getTableList();
        })
        .catch((err) => {
          window.handleServiceError(res, err);
        })
        .then(() => {});
    },

    //Loads page list
    getTableList(search = "", flag = false) {
      if (flag) {
        this.loadings.table = true;
      } else if (!flag) {
        this.$store.state.loadingStatus = true;
      }
      let param = {};
      if (!flag) {
        param = {
          Search: search,
          key: this.moduleDetail.Key,
          PageSize: this.pagination.rowsPerPage,
          Page: this.pagination.page,
        };
      } else if (flag) {
        param = {
          CategoryIds: search,
          key: this.moduleDetail.Key,
          PageSize: this.pagination.rowsPerPage,
          Page: this.pagination.page,
        };
      }
      this.tableData = [];
      ContentService.getPosts(param)
        .then((res) => {
          this.tableData = res.data.Data;
          this.pagination.totalItems = res.data.TotalData;
        })
        .catch((err) => {
          window.handleServiceError(res, err);
        })
        .then(() => {
          // this.$store.state.loadingStatus = false;
          if (flag) {
            this.loadings.table = false;
          } else if (!flag) {
            this.$store.state.loadingStatus = false;
          }
        });
    },

    getCategoryName(item) {
      let tempString = "";
      item.Categories.forEach((element) => {
        tempString += getCategoriName(element.Translation) + "<br/>";
      });
      return tempString;
    },
    getDetails(item) {
      this.pageHeader = this.moduleDetail.Name;
      //this.$router.push({name:'addContent',params:{data:item,isDetail:true}})
      this.$router.push({
        path: `/home/updateContent/${item.Id}`,
        query: {
          pageName: this.pageHeader || "",
          moduleDescription: this.moduleDescription || "",
          pageId: this.pageId || -1,
        },
      });
    },
    openDeleteModal(item) {
      this.deleteItemModel = item;
      this.$refs.deleteModalRef.modalStatus = true;
    },
    //Delete operation
    deleteItem() {
      this.$store.state.loadingStatus = true;
      PostService.deletePost(this.deleteItemModel.Id)
        .then(() => {
          this.getTableList();
          this.$refs.deleteModalRef.modalStatus = false;
          //this.getMenuData();
        })
        .catch((err) => {
          window.handleServiceError(res, err);
          this.$refs.deleteModalRef.modalStatus = false;
          this.$store.state.loadingStatus = false;
        })
        .then(() => {});
    },
    publishOperation(item) {
      let body = {
        Id: item.Id,
        Status: item.Status == 20 ? 0 : 20,
      };
      this.$store.state.loadingStatus = true;
      ContentService.changeContentStatus(body)
        .then(() => {
          this.getTableList();
          //this.getMenuData();
        })
        .catch((err) => {
          window.handleServiceError(res, err);
          this.$store.state.loadingStatus = false;
        })
        .then(() => {
          this.$store.state.loadingStatus = false;
        });
    },

    //Search Operation
    searchTable(type = "search") {
      type == "clear" ? (this.search = "") : null;
      this.pagination.page = 1;
      this.pagination.rowsPerPage = this.itemsPerPage;
      this.getTableList(this.search);
    },

    selectOneCategory(selectedCategory) {
      if (selectedCategory.length > 0) {
        console.log("Selected cat value > ", selectedCategory[0]);
        this.getTableList(selectedCategory[0].Id, true);
      } else {
        console.log("Boş table list");
        this.getTableList();
      }
    },
  },
};
</script>
