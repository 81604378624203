// Örnek servis codiagno login
import axios from "../javascript/custom-axios";
const RESOURCE_NAME = "/surveys";

export default {
  getSurveys(PageSize=15, Page = 1, Type = '') {
    return axios.get(`/admin/surveys`, {params:{PageSize,Page, Type}});
  },
  createSurvey(body) {
    return axios.post(`${RESOURCE_NAME}`, body);
  },
  getSurveysByType(type = "") {
    return axios.get(`/admin/surveys?Type=${type}`);
  },
  getSurveysByGroup(group = "") {
    if (group.length != 0) {
      let headerString = "";
      group.forEach((element) => {
        if (element != "") {
          headerString = headerString + "&Groups=" + element;
        }
      });
      return axios.get(`${RESOURCE_NAME}?${headerString}`);
    } else {
      return axios.get(`${RESOURCE_NAME}`);
    }
    // return axios.get(`${RESOURCE_NAME}?Groups=${group}`);
  },
  deleteSurvey(id) {
    return axios.delete(`${RESOURCE_NAME}/${id}`);
  },
  getSurveyDetails(id) {
    return axios.get(`${RESOURCE_NAME}/details/${id}`);
  },
  updateSurveyDetails(body) {
    return axios.put(`${RESOURCE_NAME}`, body);
  },
  downloadSurveyAnswers(id, fileName) {
    return axios.get( `${process.env.VUE_APP_API_URL}${RESOURCE_NAME}/downloadsurveyanswers/${id}`, { responseType: 'blob' }) 
      .then((response) => {
        const url = URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Sonuçlar_${fileName}.xlsx`)
        link.click()
      })  
    // TODO arda için 
    // return axios.get( `${process.env.VUE_APP_API_URL}${RESOURCE_NAME}/downloadsurveyanswers/${id}`) 
    //   .then((response) => {
    //     let responseHTML = response.data;
    //     var myWindow = window.open("", "response", "resizable=yes");
    //     myWindow.document.write(responseHTML);
    //   })
    // return axios.get(`${RESOURCE_NAME}/downloadsurveyanswers/${id}`);
  },
};
