<template>
  <div>
    <!-- Module modal -->
    <v-dialog persistent v-model="groupDetailStatus" max-width="400">
      <v-card>
        <v-card-title class="headline indigo lighten-2">
          <div class="text-center white--text" style="width: 100%">
            {{ this.modalHeader }}
          </div>
        </v-card-title>

        <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
          <form @submit.prevent="handleSubmit(sendForm)">
            <v-card-text class="py-3">
              <v-row>
                <v-col md="12" xs="12">
                  <ValidationProvider
                    name="Type"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <v-select
                      v-model="selectedGroupType"
                      :items="groupTypes"
                      :disabled="editStatus"
                      item-text="Name"
                      item-value="Code"
                      :label="$t('labels.groupType')"
                      :error-messages="errors[0]"
                    ></v-select>
                  </ValidationProvider>

                  <ValidationProvider
                    name="Name"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      v-model="groupModel.Name"
                      :value="groupModel.Name"
                      :label="$t('labels.groupName')"
                      :error-messages="errors[0]"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-actions
              style="
                background-color: white;
                position: sticky;
                bottom: 0px;
                border-top: solid grey 2px;
              "
            >
              <v-spacer></v-spacer>
              <v-btn color="primary" type="submit" :loading="loadings.send">
                {{ $t("buttons.save") }}
              </v-btn>
              <v-btn
                color="primary"
                text
                @click="
                  groupDetailStatus = false;
                  clearModal();
                "
              >
                {{ $t("buttons.close") }}
              </v-btn>
            </v-card-actions>
          </form>
        </ValidationObserver>
      </v-card>
    </v-dialog>
    <!-- Module deletion modal -->
    <delete-modal :yesFunc="deleteItem" ref="deleteModalRef" />
    <!-- Page content -->
    <v-card style="border-radius: 10px">
      <v-card-title style="color: #595959">
        <v-row>
          <v-col class="py-2">{{ $t("title.groups") }}</v-col>
          <v-col align="end">
            <v-btn
              small
              class="ma-2 mr-4"
              elevation="0"

              outlined
              color="info"
              @click="
                groupDetailStatus = true;
                modalHeader = $t('title.groupModalCreate');
              "
            >
              {{ $t("buttons.addNew") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>

      <v-divider />

      <v-card-text class="pt-4">
        <v-row align="center" justify="space-between">
          <v-col cols="3">
            <v-select
              v-model="allGroupTypes"
              :items="groupTypes.concat([{ Name: 'Tümü', Code: '' }])"
              item-text="Name"
              item-value="Code"
              @change="filterGroup"
              :label="$t('labels.groupType')"
            ></v-select>
          </v-col>

        </v-row>
        <v-data-table
          :headers="headers"
          :items="tableData"
          :loading="loadings.table"
          group-by="Type"
          :options.sync="options"
          :footer-props="{ 'items-per-page-options': [5, 10, 15, 30] }"
          :no-data-text="$t('description.noList')"
        >
          <!-- eslint-disable -->
          <template v-slot:group.header="{ items, isOpen, toggle }">
            <th colspan="4" style="text-transform: capitalize;">
              <span style="font-size:18px" class="primary--text">{{ getGroupType(items[0].Type) }}</span>
              <v-icon @click="toggle" style="position:absolute; right:30px" slot="right"
                >{{ isOpen ? "mdi-minus" : "mdi-plus" }}
              </v-icon>
            </th>
          </template>
          <template v-slot:item.CreatedAt="{ item }">
            {{ convertToLocal(item.CreatedAt) }}
          </template>
          <template v-slot:item.Icon="{ item }">
            <v-icon>{{ item.Icon }}</v-icon>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="getGroupDetails(item)">
              mdi-pencil
            </v-icon>
            <v-icon small @click="openDeleteModal(item)"> mdi-delete </v-icon>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import GroupService from "../services/GroupService";
import DeleteModal from "../components/General/DeleteModal.vue";
import { mapActions } from "vuex";

export default {
  data: () => ({
    //List options
    options: {
      itemsPerPage: 15,
    },
    top: 15,
    skip: 0,
    tableData: [],
    //Single select list models
    selectedGroupType: "",
    allGroupTypes: "",
    groupTypes: [],
    //Table contents
    headers: [
      { text: "İsim", value: "Name", sortable: false },
      { text: "Grup Numarası", value: "Key", sortable: false },
      //{ text: "Tip", value: "Type", sortable: false },
      { text: "Oluşturulma Tarihi", value: "CreatedAt", sortable: false },
      { text: "İşlem", value: "actions", sortable: false },
    ],
    //Page loaders
    loadings: {
      table: false,
      send: false,
    },
    //Initial models
    groupDetailStatus: false,
    editStatus: false,
    groupModel: {
      Type: "",
      Name: "",
      Id: "",
    },
    deleteItemModel: {},
    modalHeader: "",
  }),
  watch: {},
  mounted() {
    this.setup();
    this.getTableList();
    this.getGroupTypes();
  },
  components: {
    DeleteModal,
  },
  methods: {
    //Sets up the page on mount
    setup() {
      this.modalHeader = this.$t("title.groupModalCreate");
      this.allGroupTypes = this.$t("labels.allGroupTypes");
    },
    //Loads page list
    getTableList() {
      this.loadings.table = true;
      GroupService.getGroups()
        .then((res) => {
          this.tableData = res.data;
          console.log('res', res.data)
        })
        .catch((err) => {
          if (res.status == 401) {
            window.showInfo(
              this.$t("description.authorizationError"),
              "standart",
              "info"
            );
            this.logout();
          } else {
            window.showError(err);
          }
        })
        .then(() => {
          this.loadings.table = false;
        });
    },
    //Gets all group types on page load
    getGroupTypes() {
      this.loadings.table = true;
      GroupService.getGroupTypes()
        .then((res) => {

           res.data.forEach(element => {
              if(element.Name =='Şirket'){
                element.Name = "Kuruluş"
              }
           });
          this.groupTypes = res.data;
        })
        .catch((err) => {
          if (res.status == 401) {
            window.showInfo(
              this.$t("description.authorizationError"),
              "standart",
              "info"
            );
            this.logout();
          } else {
            window.showError(err);
          }
        })
        .then(() => {
          this.loadings.table = false;
        });
    },
    //Inspects an item
    getGroupDetails(item) {
      this.modalHeader = this.$t("title.groupModalUpdate");
      this.selectedGroupType = item.Type;

      this.groupModel = {
        Name: item.Name,
        Code: item.Code,
        Id: item.Id,
      };
      this.editStatus = true;
      this.groupDetailStatus = true;
    },
    //List filter for the page
    filterGroup(type) {
      this.loadings.table = true;
      GroupService.getGroups(type)
        .then((res) => {
          this.tableData = res.data;
        })
        .catch((err) => {
          if (res.status == 401) {
            window.showInfo(
              this.$t("description.authorizationError"),
              "standart",
              "info"
            );
            this.logout();
          } else {
            window.showError(err);
          }
        })
        .then(() => {
          this.loadings.table = false;
        });
    },
    //Submits form whether it's update or create operation
    sendForm() {
      if (this.groupModel.Id) {
        this.updateGroup();
      } else {
        this.createGroup();
      }
    },
    //Create operation
    createGroup() {
      this.groupModel.Type = this.selectedGroupType;

      this.loadings.send = true;
      GroupService.createGroup(this.groupModel)
        .then((res) => {
          this.getTableList();
          this.groupDetailStatus = false;
          this.selectedGroupType = "";
        })
        .catch((err) => {
          if (res.status == 401) {
            window.showInfo(
              this.$t("description.authorizationError"),
              "standart",
              "info"
            );
            this.logout();
          } else {
            window.showError(err);
          }
        })
        .then(() => {
          this.clearModal();
          this.loadings.send = false;
        });
    },
    //Update operation
    updateGroup() {
      this.loadings.table = true;
      this.groupModel.Type = this.selectedGroupType;
      GroupService.getGroupDetails(this.groupModel)
        .then((res) => {
          this.getTableList();
          this.groupDetailStatus = false;
          this.clearModal();
        })
        .catch((err) => {
          if (res.status == 401) {
            window.showInfo(
              this.$t("description.authorizationError"),
              "standart",
              "info"
            );
            this.logout();
          } else {
            window.showError(err);
          }
        })
        .then(() => {
          this.loadings.table = false;
        });
    },
    //Creates delete modal
    openDeleteModal(item) {
      this.deleteItemModel = item;
      this.$refs.deleteModalRef.modalStatus = true;
    },
    //Delete operation
    deleteItem() {
      this.loadings.table = true;
      GroupService.deleteGroup(this.deleteItemModel.Id)
        .then((res) => {
          this.getTableList();
          this.$refs.deleteModalRef.modalStatus = false;
        })
        .catch((err) => {
          if (res.status == 401) {
            window.showInfo(
              this.$t("description.authorizationError"),
              "standart",
              "info"
            );
            this.logout();
          } else {
            window.showError(err);
          }
        })
        .then(() => {
          this.loadings.table = false;
        });
    },
    //Clear modal and its content
    clearModal() {
      this.groupModel = {
        Type: "",
        Name: "",
        Id: "",
      };
      this.$refs.observer.reset();
      this.editStatus = false;
      this.selectedGroupType = "";
    },

    getGroupType(groupType) {
      let type = this.groupTypes.filter((x) => x.Code == groupType);
      if (type.length > 0) return this.$t('labels.groupType') + ': ' + type[0].Name;
      else "";
    },

    ...mapActions(["logout"]),
  },
};
</script>
