// Örnek servis codiagno login
import axios from "../javascript/custom-axios";
const RESOURCE_NAME = "/custom_fields";

export default {
  getCustomFields(body) {
    return axios.get(`${RESOURCE_NAME}`, {params:body});
  },

  createCustomField(body) {
    return axios.post(`${RESOURCE_NAME}`, body);
  },

  updateCustomField(id, body) {
    return axios.put(`${RESOURCE_NAME}/${id}`, body);
  },

  deleteCustomField(id) {
    return axios.delete(`${RESOURCE_NAME}/${id}`);
  },
};
